import { useMutation } from 'react-query';
import UseMutationOptions from '../UseMutationOptions';
import { Budget, SavedBudget } from '../../Functions/Budget';
import axiosInstance from '../../Core/Axios';

type PostBudgetRequest = {
    budget: Budget;
    organizationId: string;
};

const usePostBudgetMutation = (options?: UseMutationOptions<PostBudgetRequest, SavedBudget>) =>
    useMutation(
        ({ budget, organizationId }: PostBudgetRequest) =>
            axiosInstance.post<SavedBudget>(`budget/myBudgets/${organizationId}/`, budget),
        options,
    );

export default usePostBudgetMutation;
