import { format, isPast } from 'date-fns';
import React, { useState } from 'react';
import { SavedBudget } from '../../Functions/Budget';
import { DataPoint } from '../../Functions/Chart';
import { sortBy } from '../../Functions/Lists';
import { getPnlValue, pnlProperties } from '../../Functions/ProfitAndLossChartHelpers';
import Texts from '../../Functions/Texts.json';
import { LineChart } from '../LineChart';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';

export type BudgetCompareChartProps = {
    profitAndLoss: ProfitAndLossRow[] | undefined;
    budgets: SavedBudget[];
};
const BudgetCompareChart = ({ profitAndLoss, budgets }: BudgetCompareChartProps) => {
    const [selectedBudgets, setSelectedBudgets] = useState(new Set([0]));
    const [selectedBudgetProperty, setSelectedBudgetProperty] = useState(
        pnlProperties.find((p) => p.property === 'revenue')!,
    );

    const budgetChartIsLoading = !profitAndLoss || !budgets;
    const budgetDataMissing = budgets?.length === 0;

    const firstSelectedBudget = budgets?.find((b, index) => selectedBudgets.has(index));
    const actualOutcome =
        firstSelectedBudget &&
        profitAndLoss &&
        sortBy(profitAndLoss, 'period').filter(
            (m) =>
                m.financialYear === firstSelectedBudget.yearlyProfitAndLoss.financialYear &&
                isPast(new Date(m.period)),
        );

    const updateSelectedBudgets = (index: number) => {
        if (budgets.length === 1) return;

        let _selectedBudgets = new Set(selectedBudgets);
        const wasDeleted = _selectedBudgets.delete(index);
        if (!wasDeleted) {
            _selectedBudgets.add(index);
        }
        setSelectedBudgets(_selectedBudgets);
    };

    return (
        <div className='chart-box legends-as-legends '>
            {!budgetChartIsLoading && !budgetDataMissing && (
                <>
                    <h4>{Texts.company_dashboard.budget_chart_title}</h4>
                    <div className='dropdowns-wrapper'>
                        <div className='secondary-legends'>
                            <span>{Texts.company_dashboard.choose_budget}:</span>
                            {budgets.map((budget, index) => (
                                <div
                                    onClick={() => updateSelectedBudgets(index)}
                                    key={budget.id}
                                    className={`secondary-legends-button ${
                                        selectedBudgets.has(index) && ' checked'
                                    }`}
                                >
                                    {budget.name}
                                </div>
                            ))}
                        </div>
                        <div className='secondary-legends '>
                            <span>{Texts.charts.key_figures}:</span>
                            {pnlProperties.map((element, index) => (
                                <div
                                    onClick={() => setSelectedBudgetProperty(element)}
                                    className={`secondary-legends-button ${
                                        element === selectedBudgetProperty && ' checked'
                                    }`}
                                    key={index}
                                >
                                    {element.label}
                                </div>
                            ))}
                        </div>
                    </div>

                    {firstSelectedBudget ? (
                        <LineChart
                            chartsAreLoading={budgetChartIsLoading}
                            data={[
                                {
                                    seriesName: Texts.company_dashboard.compare_outcome.replace(
                                        '$year',
                                        format(
                                            new Date(
                                                firstSelectedBudget.yearlyProfitAndLoss.financialYearFrom,
                                            ),
                                            'yyyy',
                                        ),
                                    ),
                                    values:
                                        actualOutcome?.map((d) =>
                                            getPnlValue(d, selectedBudgetProperty),
                                        ) ?? [],
                                    color: '#5bbfbb',
                                    renderAs: 'spline',
                                },
                                ...budgets
                                    .filter((b, idx) => selectedBudgets.has(idx))
                                    .map<DataPoint>((b) => ({
                                        seriesName: Texts.company_dashboard.compare_budget.replace(
                                            '$budget',
                                            b.name,
                                        ),
                                        values: b.monthlyProfitAndLoss.map((d: ProfitAndLossRow) =>
                                            getPnlValue(d, selectedBudgetProperty),
                                        ),
                                        renderAs: 'splinearea',
                                    })),
                            ]}
                            chartConfig={{
                                legendNumColumns: 2,
                            }}
                            palette={[
                                '#5bbfbb',
                                '#3a306e',
                                '#DA546C',
                                '#2aa268',
                                '#599ef9',
                                '#e6007e',
                            ]}
                            xLabels={firstSelectedBudget.monthlyProfitAndLoss.map((m) =>
                                format(new Date(m.period), 'MMM'),
                            )}
                        />
                    ) : (
                        <span className='choose-at-least-one-budget'>
                            {Texts.budget.choose_a_budget}
                        </span>
                    )}
                </>
            )}
            {budgetChartIsLoading && !budgetDataMissing && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {budgetDataMissing && !budgetChartIsLoading && (
                <div className='chart-data-missing'>
                    {Texts.company_dashboard.chart_budget_missing}
                </div>
            )}
        </div>
    );
};

export default BudgetCompareChart;
