import React, { useState } from 'react';
import Texts from '../Functions/Texts.json';
import { NavLink } from 'react-router-dom';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import Reports from './Reports/Reports';

type CompanyMenuProps = {
    companyDetails: CompanyDetails | undefined;
};

const CompanyMenu = ({ companyDetails }: CompanyMenuProps) => {
    const [reportGeneratorOverlayIsOpen, setReportGeneratorOverlayIsOpen] =
        useState<boolean>(false);

    const reloadIfSameUrl = (linkUrl: string) => {
        const windowUrl = document.location.href
            .replace(window.location.hostname, '')
            .replace(':' + window.location.port, '')
            .replace(window.location.protocol + '//', '');
        if (linkUrl === windowUrl) {
            document.location.reload();
        }
    };

    if (!companyDetails) {
        return null;
    }

    const { organizationNumber } = companyDetails;

    return (
        <div className='company-menu'>
            <ul>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/company/' + organizationNumber);
                    }}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={`/company/${organizationNumber}`}
                    end
                >
                    <li>{Texts.summary_company__CompanyMenuLinkText}</li>
                </NavLink>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/profit-and-loss/' + organizationNumber);
                    }}
                    className={() =>
                        document.location.pathname.indexOf('/profit-and-loss') !== -1
                            ? 'active'
                            : ''
                    }
                    to={`/company/${organizationNumber}/profit-and-loss/`}
                    end
                >
                    <li>{Texts.profit_and_loss__CompanyMenuLinkText}</li>
                </NavLink>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/balance-sheets/' + organizationNumber);
                    }}
                    className={() =>
                        document.location.pathname.indexOf('/balance-sheets') !== -1 ? 'active' : ''
                    }
                    to={`/company/${organizationNumber}/balance-sheets/`}
                    end
                >
                    <li>{Texts.balance_sheets__CompanyMenuLinkText}</li>
                </NavLink>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/financial-ratio/' + organizationNumber);
                    }}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={`/company/${organizationNumber}/financial-ratio/`}
                    end
                >
                    <li>{Texts.financial_ratio__CompanyMenuLinkText}</li>
                </NavLink>
                <NavLink // Temporary fix hiding yearly cashflow
                    onClick={() => {
                        reloadIfSameUrl('/cashflow-by-month/' + organizationNumber);
                    }}
                    className={() =>
                        document.location.pathname.indexOf('/cashflow-by-month') !== -1
                            ? 'active'
                            : ''
                    }
                    to={`/company/${organizationNumber}/cashflow-by-month/`}
                    end
                >
                    <li>{Texts.cashflow__CompanyMenuLinkText}</li>
                </NavLink>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/charts/' + organizationNumber);
                    }}
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={`/company/${organizationNumber}/charts/`}
                    end
                >
                    <li>Grafer</li>
                </NavLink>
                <NavLink
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={`/company/${organizationNumber}/budget`}
                >
                    <li>Budget</li>
                </NavLink>
            </ul>
            <div
                className='button report-generator-opener'
                onClick={(e) => setReportGeneratorOverlayIsOpen(!reportGeneratorOverlayIsOpen)}
            >
                <div className='icon report-regerator'></div>
                {Texts.report_generator.open_report_generator}
            </div>
            {reportGeneratorOverlayIsOpen && (
                <Reports
                    setReportGeneratorOverlayIsOpen={setReportGeneratorOverlayIsOpen}
                    companyDetails={companyDetails}
                />
            )}
        </div>
    );
};

export default CompanyMenu;
