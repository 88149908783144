import { Params } from 'react-router-dom';
import useGetFinancialRatioQuery from '../../../Api/Reports/useGetFinancialRatioQuery';
import useGetOfficialFinancialRatioQuery from '../../../Api/Reports/useGetOfficialFinancialRatioQuery';
import { NextYear, GetYearData, DecimalToPercent } from '../../../Functions/Calculations';
import MapExternalFinancialRatio from '../../../Functions/MapExternalFinancialRatio';
import useGetOrgId from '../../../Functions/useGetOrgId';
import FinancialRatioRow from '../../../Types/Api/Response/FinancialRatioRow';
import { ColumnData } from '../../../Components/NewColumnBasedTable';
import useGetPnLAggregatedQuery from '../../../Api/Reports/useGetPnLAggregatedQuery';
import useGetPnLOfficialQuery from '../../../Api/Reports/useGetPnLOfficialQuery';

/** Fetches Financial ratio from MyNumbers source and official. For each year,
 *  FR are takeon from official if exists, otherwise MyNumbers.
 *
 * Also populated with some data from PnL.
 */
export type ExpandedFinancialRatioRow = FinancialRatioRow &
    ColumnData & {
        isOfficial?: boolean;
        revenue: number;
        currentProfitEarningBeforeTax: number;
    };

const useGetFinancialRatio = (params: Readonly<Params<string>>) => {
    const { organizationId } = useGetOrgId(params);
    const { data: officialFinancialRatio, isLoading: officialFrLoading } =
        useGetOfficialFinancialRatioQuery(organizationId);
    const { data: financialRatio, isLoading: frLoading } =
        useGetFinancialRatioQuery(organizationId);
    const { data: profitAndLossAggregated, isLoading: pnlAggLoading } =
        useGetPnLAggregatedQuery(organizationId);
    const { data: officialProfitAndLoss, isLoading: officialPnLLoading } =
        useGetPnLOfficialQuery(organizationId);

    const isLoading = frLoading || officialFrLoading || pnlAggLoading || officialPnLLoading;

    const RoundFinancialRatioValue = (value: number) => {
        return Math.round(value * 0.001);
    };

    const getOfficialData = (year: string): ExpandedFinancialRatioRow | null => {
        const officialFRData = GetYearData(year, officialFinancialRatio!.data);
        const officialPnLData = GetYearData(year, officialProfitAndLoss!.data);

        if (officialFRData === null || officialPnLData === null) {
            return null;
        }

        return {
            ...MapExternalFinancialRatio(officialFRData),
            revenue: officialPnLData.revenue,
            currentProfitEarningBeforeTax: officialPnLData.currentProfitEarningBeforeTax,
            isOfficial: true,
            columnClassName: '',
        };
    };

    const getMNData = (year: string): ExpandedFinancialRatioRow | null => {
        const frData = GetYearData(year, financialRatio!.data);
        const pnLData = GetYearData(year, profitAndLossAggregated!.data);

        if (frData === null || pnLData === null) {
            return null;
        }

        return {
            ...frData,
            g6_netMargin: DecimalToPercent(frData.g6_netMargin),
            g5_operatingMargin: DecimalToPercent(frData.g5_operatingMargin),
            g1_returnOnEquity: DecimalToPercent(frData.g1_returnOnEquity),
            g12_quickRatio: DecimalToPercent(frData.g12_quickRatio),
            g9_solvency: DecimalToPercent(frData.g9_solvency),
            g8_employmentCost: DecimalToPercent(frData.g8_employmentCost),
            t1_grossProfitMargin: DecimalToPercent(frData.t1_grossProfitMargin),
            g4_riskBufferTotalCapital: DecimalToPercent(frData.g4_riskBufferTotalCapital),
            g2_returnOnTotalCapital: DecimalToPercent(frData.g2_returnOnTotalCapital),
            t31_returnOnWorkingCapital: DecimalToPercent(frData.t31_returnOnWorkingCapital),
            t46_currentRatio: DecimalToPercent(frData.t46_currentRatio),
            g11_workingCapital: RoundFinancialRatioValue(frData.g11_workingCapital ?? 0),
            g3_interestPayable: DecimalToPercent(frData.g3_interestPayable),
            x2_cashFlowFromOperation: DecimalToPercent(frData.x2_cashFlowFromOperation),
            t26_degreeOfDebt: frData.t26_degreeOfDebt === 0 ? undefined : frData.t26_degreeOfDebt,
            g7_netRevPerEmployee:
                frData.g7_netRevPerEmployee &&
                RoundFinancialRatioValue(frData.g7_netRevPerEmployee),
            revenue: RoundFinancialRatioValue(pnLData.revenue),
            currentProfitEarningBeforeTax: RoundFinancialRatioValue(
                pnLData.currentProfitEarningBeforeTax,
            ),
            isOfficial: false,
            columnClassName: 'my-numbers-data',
        };
    };

    const getRatios = (): ExpandedFinancialRatioRow[] => {
        if (
            !profitAndLossAggregated ||
            !financialRatio ||
            !officialFinancialRatio ||
            !officialProfitAndLoss
        ) {
            return [];
        }

        const yearsData: ExpandedFinancialRatioRow[] = [];
        const nextYear = NextYear();
        for (var year = nextYear; year > nextYear - 10 && yearsData.length < 6; year--) {
            const officialData = getOfficialData(year.toString());
            if (officialData) {
                yearsData.push(officialData);
            } else {
                const mnData = getMNData(year.toString());
                if (mnData) {
                    yearsData.push(mnData);
                }
            }
        }

        return yearsData;
    };

    const ratios = getRatios();

    return { ratios, isLoading };
};

export default useGetFinancialRatio;
