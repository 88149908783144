import {
    SnackbarProvider as NotistackSnackbarProvider,
    SnackbarProviderProps as NotisSnackbarProviderProps,
    SnackbarKey,
} from 'notistack';
import { forwardRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faCircleExclamation,
    faInfoCircle,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import SnackbarButtonDismiss from './SnackbarButtonDismiss';
import ErrorSnackbar from './Snackbars/ErrorSnackbar';
import InfoSnackbar from './Snackbars/InfoSnackbar';
import SuccessSnackbar from './Snackbars/SuccessSnackbar';
import WarningSnackbar from './Snackbars/WarningSnackbar';
import '../css/Snackbar.css';

export type SnackbarProviderProps = NotisSnackbarProviderProps & {};

const SnackbarProvider = forwardRef<any, SnackbarProviderProps>(
    ({ children, iconVariant, ...rest }, ref) => {
        const DismissButton = useCallback(
            (key: SnackbarKey) => <SnackbarButtonDismiss snackbarKey={key} />,
            [],
        );

        return (
            <NotistackSnackbarProvider
                ref={ref}
                maxSnack={2}
                variant='default'
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                }}
                iconVariant={{
                    success: (
                        <FontAwesomeIcon
                            icon={faCheckCircle}
                            style={{
                                marginRight: '12px',
                                height: '21px',
                                width: '21px',
                            }}
                        />
                    ),
                    error: (
                        <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                                marginRight: '12px',
                                height: '1.5em',
                                width: '1.5em',
                            }}
                        />
                    ),
                    warning: (
                        <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            style={{
                                marginRight: '12px',
                                height: '1.5em',
                                width: '1.5em',
                            }}
                        />
                    ),
                    info: (
                        <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{
                                marginRight: '12px',
                                height: '1.5em',
                                width: '1.5em',
                            }}
                        />
                    ),
                    ...iconVariant,
                }}
                action={DismissButton}
                Components={{
                    error: ErrorSnackbar,
                    success: SuccessSnackbar,
                    warning: WarningSnackbar,
                    info: InfoSnackbar,
                }}
                autoHideDuration={5000}
                {...rest}
            >
                {children}
            </NotistackSnackbarProvider>
        );
    },
);

export default SnackbarProvider;
