import React from 'react';
import { range, sortBy } from '../Functions/Lists';
import Texts from "../Functions/Texts.json";
import { ColumnData, TableRow, TableRowInstance, TableRowProps } from "./NewColumnBasedTable";
import groupBy from "lodash/groupBy";

export type AggregateBaseProps = {
    accountGroup: string
    accountGroupIndex: number
    accountNumber: number
    description: string
    groupIndex: number
    reportGroup: string
    reportStructure: string
    structureIndex: number
}
export type MonthlyAggregate = AggregateBaseProps & {
    period: string
    closingBalance: number
    openingBalance: number
    transactionSum: number
}
export type AggProp = "closingBalance" | "openingBalance" | "transactionSum"

type AggregateColumns = ColumnData & {
    period: string
    entries: MonthlyAggregate[]
}

export const aggregatesToColumnsAndGroups = (aggs: MonthlyAggregate[]): [AggregateColumns[], AggregateBaseProps[]] => {
    const aggColumns = aggs && sortBy(
        Object.entries(groupBy(aggs, "period")).map(([period, entries]) => ({
            period,
            entries,
            columnClassName: "my-numbers-data",
        })),
        "period"
    ).reverse();
        
    const grouped = groupBy(aggs, d => [d.structureIndex, d.groupIndex, d.accountGroupIndex, d.accountNumber, d.description]);
    const aggProps = Object.values(grouped).map(entries => {
        const first = entries[0];
        return {
            structureIndex: first.structureIndex,
            groupIndex: first.groupIndex,
            accountGroupIndex: first.accountGroupIndex,
            reportStructure: first.reportStructure,
            reportGroup: first.reportGroup,
            accountGroup: first.accountGroup,
            accountNumber: first.accountNumber,
            description: first.description,
        };
    });

    return [aggColumns, aggProps]
}

export const aggregateSelector = (prop: AggProp, structureIndex: number, groupIndex?: number, accountGroupIndex?: number, accountNumber?: number, description?: string) => (d: AggregateColumns) => {
    return d.entries.filter(agg =>
        agg.structureIndex === structureIndex
        && (groupIndex === undefined || agg.groupIndex === groupIndex)
        && (accountGroupIndex === undefined || agg.accountGroupIndex === accountGroupIndex)
        && (accountNumber === undefined || agg.accountNumber === accountNumber)
        && (description === undefined || agg.description === description)
    ).reduce((sum, agg) => sum + agg[prop], 0);
}

export type MonthlyAggregateTableRowProps = TableRowProps<AggregateColumns> & {
    valueBy: AggProp
    structureIndex: number
    groupIndex: number
    groups: AggregateBaseProps[] | undefined
}
export const MonthlyAggregateTableRow = ({valueBy, structureIndex, groupIndex, groups, ...props}: MonthlyAggregateTableRowProps) => {
    const myGroups = groups?.filter(gr => gr.structureIndex === structureIndex && gr.groupIndex === groupIndex);
    const [minAccountGroup, maxAccountGroup] = myGroups?.reduce(
        ([min, max], {accountGroupIndex}) => [Math.min(min, accountGroupIndex), Math.max(max, accountGroupIndex)],
        [0, -1]
    ) ?? [0, -1];

    return <TableRow<AggregateColumns>
        selector={aggregateSelector(valueBy, structureIndex, groupIndex)}
        label={myGroups?.[0]?.reportGroup}
        {...props}
    >
        {range(maxAccountGroup - minAccountGroup + 1).map(n => n+minAccountGroup).map(accountGroupIndex => {
            const groups = myGroups?.filter(gr => gr.accountGroupIndex === accountGroupIndex);
            const group = groups?.[0];
            if (!group) return null;

            const accounts = sortBy(sortBy(groups, "description"), "accountNumber");

            return <TableRow<AggregateColumns>
                key={accountGroupIndex}
                label={group.accountGroup}
                selector={aggregateSelector(valueBy, structureIndex, groupIndex, accountGroupIndex)}
            >
                {accounts.map(({accountNumber, description}, index) => {
                    return <TableRow<AggregateColumns>
                        key={index}
                        label={`${accountNumber}, ${description}`}
                        selector={aggregateSelector(valueBy, structureIndex, groupIndex, accountGroupIndex, accountNumber, description)}
                    />
                })}
            </TableRow>
        }).filter((node): node is TableRowInstance<AggregateColumns> => !!node)}
    </TableRow>
}
export type MonthlyAggregateTableSumRowProps = TableRowProps<AggregateColumns> & {
    valueBy: AggProp
    structureIndex: number
    groups: AggregateBaseProps[] | undefined
}
export const MonthlyAggregateTableSumRow = ({valueBy, structureIndex, groups, ...props}: MonthlyAggregateTableSumRowProps) => {
    const myGroup = groups?.find(gr => gr.structureIndex === structureIndex);
    return <TableRow<AggregateColumns>
        selector={aggregateSelector(valueBy, structureIndex)}
        label={myGroup && `${Texts.sum__BalanceSheetsTableFooter} ${myGroup.reportStructure}`}
        {...props} 
    />
}