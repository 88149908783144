import { useQuery } from 'react-query';

import axiosInstance from '../../Core/Axios';
import UseQueryOptions from '../UseQueryOptions';
import { SavedBudget } from '../../Functions/Budget';

const useGetMyBudgetsQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<Array<SavedBudget>>,
) =>
    useQuery(
        ['MyBudgets', companyId],
        async () => axiosInstance.get<Array<SavedBudget>>(`budget/myBudgets/${companyId}/`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetMyBudgetsQuery;
