import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export type Month = {
    year: number;
    month: number;
};

type Props = {
    title: string;
    value: Month;
    setValue: (newValue: Month) => void;
};

const maximumYear = new Date().getFullYear();

const MonthPicker = ({ title, value, setValue }: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [currentYear, setCurrentYear] = useState<number>(value.year);

    const pickerLang = {
        months: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'Maj',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dec',
        ],
        from: 'Från',
        to: 'Till',
    };

    const makeText = (m: Month) => {
        if (m && m.year && m.month) return pickerLang.months[m.month - 1] + '. ' + m.year;
        return '?';
    };

    const setMonth = (newMonth: number) => {
        setValue({
            year: currentYear,
            month: newMonth + 1,
        });
        setIsEditing(false);
    };

    return (
        <div className='month-picker'>
            <span className='picker-title'>{title}</span>
            <div className='picker-value' onClick={() => setIsEditing(true)}>
                <span>{makeText(value)}</span>
            </div>
            {isEditing && (
                <>
                    <div className='invisible-overlay' onClick={() => setIsEditing(false)} />
                    <div className='popup-container'>
                        <div className='popup'>
                            <div className='header'>
                                <span onClick={() => setCurrentYear(currentYear - 1)}>
                                    <FontAwesomeIcon icon={faChevronLeft} />
                                </span>
                                <label>{currentYear}</label>
                                <span
                                    className={currentYear >= maximumYear ? 'inactive' : ''}
                                    onClick={() =>
                                        setCurrentYear(Math.min(maximumYear, currentYear + 1))
                                    }
                                >
                                    <FontAwesomeIcon icon={faChevronRight} />
                                </span>
                            </div>
                            <ul>
                                {pickerLang.months.map((month, index) => (
                                    <li
                                        key={`month-${index + 1}`}
                                        className={
                                            currentYear === value.year && index + 1 === value.month
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() => setMonth(index)}
                                    >
                                        {month}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default MonthPicker;
