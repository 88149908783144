import React from 'react';
import Texts from "../Functions/Texts.json";
import ReactFC from "react-fusioncharts";
import { CommonChartProps, DataPoint, dataPointsToDataset, defaultChartConfig, defaultPalette, setDefaultProperties } from '../Functions/Chart';

export type StackedColumnChartProps = CommonChartProps & {
    data: DataPoint[][]
}
export const StackedColumnChart = (props: StackedColumnChartProps) => {
    const paletteInput = props.palette;
    const {
        chartsAreLoading,
        xLabels,
        data,
        chartConfig,
    }  = props;
    
    if (chartsAreLoading) {
        return <div className="bar-chart-wrapper">
            <div className='spinner-wrapper'>
                <div className='spinner'></div>
            </div>
        </div>;
    }

    const palette = paletteInput ?? defaultPalette;

    const datasets = data.map(d => ({
        dataset: dataPointsToDataset(setDefaultProperties(d, palette, "column")),
    }));

    const fcConfig = {
        width: "500",
        height: "350",
        type: "msstackedcolumn2d",
        dataFormat: "json",
        dataSource: {
            dataset: datasets,
            categories: [{
                category: xLabels.map(l => ({ label: l })),
            }],
            chart: {
                ...defaultChartConfig,
                showLegend: 1,
                ...chartConfig,
            },
        },
    };
    
    return (
        <div className="bar-chart-wrapper">
            {data.length > 0 ? <div className="chart-wrapper">
                <ReactFC {...fcConfig} />
            </div> : <div className="chart-data-missing">
                {Texts.chart_data_missing__InfoText}
            </div>}
        </div>
    )
}
