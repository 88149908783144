import React from 'react';
import Texts from '../Functions/Texts.json';
import { NavLink } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';

type Props = {
    disabledCompaniesCount: number;
    pendingCompaniesCount: number;
    newCompaniesCount: number;
    companiesCount: number;
};
const DashboardMenu = ({
    disabledCompaniesCount,
    pendingCompaniesCount,
    newCompaniesCount,
    companiesCount,
}: Props) => {
    const reloadIfSameUrl = (linkUrl: string) => {
        const windowUrl = document.location.href
            .replace(window.location.hostname, '')
            .replace(':' + window.location.port, '')
            .replace(window.location.protocol + '//', '');
        if (linkUrl === windowUrl) {
            document.location.reload();
        }
    };
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    return (
        <div className='content-menu'>
            <ul>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/');
                    }}
                    end
                    className={() =>
                        ['/', '/compare-companies/', '/companies-basic-data/'].indexOf(
                            window.location.pathname,
                        ) !== -1
                            ? 'active'
                            : ''
                    }
                    to={'/'}
                >
                    <li>
                        {Texts.all__DashboardMenuItemText} ({companiesCount})
                    </li>
                </NavLink>
                <NavLink
                    onClick={() => {
                        reloadIfSameUrl('/new-companies/');
                    }}
                    end
                    className={({ isActive }) => (isActive ? 'active' : '')}
                    to={'/new-companies/'}
                >
                    <li>
                        {newCompaniesCount != null && newCompaniesCount !== 0 && (
                            <div className='alert-bubble new-companies'>{newCompaniesCount}</div>
                        )}
                        {Texts.new__DashboardMenuItemText}
                    </li>
                </NavLink>
                {licenseChoice.type === 'creditor' && (
                    <>
                        <NavLink
                            onClick={() => {
                                reloadIfSameUrl('/disabled-companies/');
                            }}
                            end
                            className={({ isActive }) => (isActive ? 'active' : '')}
                            to={'/disabled-companies/'}
                        >
                            <li>
                                {disabledCompaniesCount != null && disabledCompaniesCount !== 0 && (
                                    <div className='alert-bubble disabled-companies'>
                                        {disabledCompaniesCount}
                                    </div>
                                )}
                                {Texts.rejected__DashboardMenuItemText}
                            </li>
                        </NavLink>
                        <NavLink
                            onClick={() => {
                                reloadIfSameUrl('/pending-companies/');
                            }}
                            end
                            className={({ isActive }) => (isActive ? 'active' : '')}
                            to={'/pending-companies/'}
                        >
                            <li>
                                {pendingCompaniesCount != null && pendingCompaniesCount !== 0 && (
                                    <div className='alert-bubble pending-companies'>
                                        {pendingCompaniesCount}
                                    </div>
                                )}
                                {Texts.pending__DashboardMenuItemText}
                            </li>
                        </NavLink>
                    </>
                )}
            </ul>
        </div>
    );
};

export default DashboardMenu;
