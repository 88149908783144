import { isAfter, isFuture, isPast } from 'date-fns';
import { sum } from './Lists';
import ProfitAndLossRow, {
    ProfitAndLossRowWithDerivedValues,
    ProfitAndLossValues,
} from '../Types/Api/Response/ProfitAndLoss';

export type Budget = {
    name: string;
    period: string;
    yearlyProfitAndLoss: ProfitAndLossRow;
    monthlyProfitAndLoss: ProfitAndLossRow[];
    copyYear: CopyYear | undefined;
};
export type SavedBudget = Budget & {
    id: string;
    modified: string;
    yearlyProfitAndLoss: ProfitAndLossRowWithDerivedValues;
    monthlyProfitAndLoss: ProfitAndLossRowWithDerivedValues[];
    active: boolean;
};

export type ProfitAndLossKey = keyof ProfitAndLossValues;
export type CopyYear = {
    yearlyData: ProfitAndLossRow;
    monthlyData: ProfitAndLossRow[];
};
export type DerivedProfitAndLossKey =
    | 'totalOperatingIncome'
    | 'ebit'
    | 'sumOfExpenses'
    | 'netProfit'
    | 'currentProfitEarningBeforeTax'
    | 'sumOfFinancialPosts'
    | 'otherOperatingExpensesAggregated';

type BudgetDependency =
    | ProfitAndLossKey
    | {
          name: DerivedProfitAndLossKey;
          children: BudgetDependency[];
      };
type BudgetEntry = {
    selector: ProfitAndLossKey[];
};
type BudgetEntries = Record<string, BudgetEntry>;

function createBudgetEntries(deps: BudgetDependency): BudgetEntries {
    const initialBudget: BudgetEntries = {};

    const createInitialBudget = (dep: BudgetDependency): ProfitAndLossKey[] => {
        if (typeof dep === 'string') {
            initialBudget[dep] = {
                selector: [dep],
            };
            return [dep];
        }
        const deepChildren = dep.children.map(createInitialBudget).reduce((x, y) => [...x, ...y]);
        initialBudget[dep.name] = {
            selector: deepChildren,
        };
        return deepChildren;
    };
    createInitialBudget(deps);

    return initialBudget;
}

const defaultBudgetConf: BudgetDependency = {
    name: 'netProfit',
    children: [
        {
            name: 'currentProfitEarningBeforeTax',
            children: [
                {
                    name: 'ebit',
                    children: [
                        {
                            name: 'totalOperatingIncome',
                            children: ['revenue', 'otherOperatingIncome'],
                        },
                        {
                            name: 'sumOfExpenses',
                            children: [
                                'directCost',
                                {
                                    name: 'otherOperatingExpensesAggregated',
                                    children: [
                                        'costOfPremises',
                                        'sellingExpenses',
                                        'administrativeExpenses',
                                        'otherOperatingExpenses',
                                    ],
                                },
                                'personnelCosts',
                                'depreciationAndWritedowns',
                            ],
                        },
                    ],
                },
                {
                    name: 'sumOfFinancialPosts',
                    children: ['financialIncomes', 'financialExpenses'],
                },
            ],
        },
        'extraordinaryIncomeAndExpenses',
        'appropriations',
        'tax',
    ],
};
export const valueSelectors = createBudgetEntries(defaultBudgetConf);

export function updateBudget<TBudget extends Budget>(
    budget: TBudget,
    key: ProfitAndLossKey,
    yearOrMonthIndex: 'year' | number,
    valThousands: number,
): TBudget {
    return {
        ...budget,
        yearlyProfitAndLoss: {
            ...budget.yearlyProfitAndLoss,
            [key]:
                yearOrMonthIndex === 'year' ? valThousands * 1000 : budget.yearlyProfitAndLoss[key],
        },
        monthlyProfitAndLoss: budget.monthlyProfitAndLoss.map((m, idx) => ({
            ...m,
            [key]: yearOrMonthIndex === idx ? valThousands * 1000 : m[key],
        })),
    };
}

export const emptyProfitAndLossRow: ProfitAndLossRowWithDerivedValues = {
    revenue: 0,
    otherOperatingIncome: 0,
    directCost: 0,
    costOfPremises: 0,
    sellingExpenses: 0,
    administrativeExpenses: 0,
    otherOperatingExpenses: 0,
    personnelCosts: 0,
    depreciationAndWritedowns: 0,
    financialIncomes: 0,
    financialExpenses: 0,
    extraordinaryIncomeAndExpenses: 0,
    appropriations: 0,
    tax: 0,
    totalOperatingIncome: 0,
    ebit: 0,
    sumOfExpenses: 0,
    netProfit: 0,
    currentProfitEarningBeforeTax: 0,
    sumOfFinancialPosts: 0,
    otherOperatingExpensesAggregated: 0,
    grossProfit: 0,
};

export function fixAggregatedValues<TBudget extends Budget>(budget: TBudget): TBudget {
    return Object.keys(valueSelectors).reduce(
        (prev, field) => ({
            ...prev,
            yearlyProfitAndLoss: {
                ...prev.yearlyProfitAndLoss,
                [field]: getBudgetValue(budget.yearlyProfitAndLoss, field),
            },
            monthlyProfitAndLoss: prev.monthlyProfitAndLoss.map((m, idx) => ({
                ...m,
                [field]: getBudgetValue(budget.monthlyProfitAndLoss[idx], field),
            })),
        }),
        budget,
    );
}

export const getBudgetValue = (row: ProfitAndLossValues, field: string) =>
    sum(valueSelectors[field].selector.map((key) => row[key]));

export function profitAndLossKeys(): ProfitAndLossKey[] {
    return Object.keys(emptyProfitAndLossRow) as ProfitAndLossKey[];
}

export const toThousands = (val: number) => Math.round(val / 1000) * 1000;

export const linearDistribution = <TBudget extends Budget>(
    budget: TBudget,
    key: ProfitAndLossKey,
): TBudget => ({
    ...budget,
    monthlyProfitAndLoss: budget.monthlyProfitAndLoss.map((m) => ({
        ...m,
        [key]: toThousands(budget.copyYear!.yearlyData[key] / 12),
    })),
});

export const proRata = <TBudget extends Budget>(
    budget: TBudget,
    key: ProfitAndLossKey,
    { monthlyData }: CopyYear,
): TBudget => ({
    ...budget,
    monthlyProfitAndLoss: budget.monthlyProfitAndLoss.map((m, idx) => {
        return {
            ...m,
            [key]: toThousands(monthlyData[idx][key]),
        };
    }),
});

export function getYearToDate(budget: Budget): ProfitAndLossRow {
    if (
        !isPast(new Date(budget.yearlyProfitAndLoss.financialYearFrom)) ||
        !isFuture(new Date(budget.yearlyProfitAndLoss.financialYearTo))
    ) {
        // No need to calculate year-to-date if the period is not the current fiscal year
        return budget.yearlyProfitAndLoss;
    }
    const keys = [
        ...profitAndLossKeys(),
        'totalOperatingIncome',
        'ebit',
        'sumOfExpenses',
        'netProfit',
        'currentProfitEarningBeforeTax',
        'sumOfFinancialPosts',
        'otherOperatingExpensesAggregated',
    ];
    const months = budget.monthlyProfitAndLoss.filter((m) => isPast(new Date(m.period)));
    const res = { ...budget.yearlyProfitAndLoss };
    for (const key of keys as ProfitAndLossKey[]) {
        res[key] = months.reduce((sum, m) => sum + m[key], 0);
    }
    return res;
}

export const sumToDate = (budget: Budget, endPeriod: Date): ProfitAndLossRow => {
    const months = budget.monthlyProfitAndLoss.filter(
        (m) => !isAfter(new Date(m.period), endPeriod),
    );
    const res = { ...budget.yearlyProfitAndLoss };

    type PnLKeys = keyof ProfitAndLossRowWithDerivedValues;
    var keys = Object.keys(emptyProfitAndLossRow) as PnLKeys[];

    for (const key of keys) {
        res[key] = months.reduce((sum, m) => sum + m[key], 0);
    }
    return res;
};
