import { add, format } from 'date-fns';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import { Quarter } from './GetQuartersFromFiscalYears';
import { profitAndLossKeys } from '../../Functions/Budget';
import { PopulateBalanceSheetsQuarters } from './Templates';
import TableColumn from './Types/TableColumn';

const QuarterAggregator = () => {
    const pNLKeys = profitAndLossKeys();

    const getQuarterPeriods = (quarter: Quarter) => {
        const { from } = quarter;
        return [
            `${format(add(new Date(from), { months: 1, days: -1 }), 'yyyy-MM-dd')}T00:00:00`,
            `${format(add(new Date(from), { months: 2, days: -1 }), 'yyyy-MM-dd')}T00:00:00`,
            `${format(add(new Date(from), { months: 3, days: -1 }), 'yyyy-MM-dd')}T00:00:00`,
        ];
    };

    const AggregateQuarterPnL = (
        quarter: Quarter,
        profitAndLoss: ProfitAndLossRow[],
    ): ProfitAndLossRow => {
        const quarterPeriods = getQuarterPeriods(quarter);
        const months = profitAndLoss.filter((pnl) =>
            quarterPeriods.some((qp) => qp === pnl.period),
        );
        let quarterPnL = { ...months[0] };
        for (const month of months.slice(1)) {
            for (const key of pNLKeys) {
                quarterPnL[key] += month[key];
            }
        }

        return quarterPnL;
    };

    const GetQuarterBS = (quarter: Quarter, balanceSheets: BalanceSheetRow[]): TableColumn => {
        // BS for the quarter is the same as BS for the last month of that quarter
        const quarterTo = `${quarter.to}T00:00:00`;
        const bs = balanceSheets.find((bs) => bs.period === quarterTo);

        if (bs === undefined) {
            throw new Error(`Unable to find period ${quarter.to} among balancesheets`);
        }
        return PopulateBalanceSheetsQuarters(bs, quarter);
    };

    return {
        AggregateQuarterPnL,
        GetQuarterBS,
    };
};

export default QuarterAggregator;
