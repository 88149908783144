import { ReactNode } from 'react';
import Texts from '../../Functions/Texts.json';
import logo from '../../Assets/Images/logoandsymbol-darkbg.svg';

type Props = {
    children: ReactNode;
};

const SignInWrapper = ({ children }: Props) => (
    <div id='sign-in-wrapper'>
        <div className='sign-in-header'>
            <div id='mynumbers-logo' style={{ backgroundImage: `url(${logo})` }}></div>
            <h1 className='sign-in-heading'>
                {Texts.corporate_portal__indefinite_form}{' '}
                <span id='sign-in-beta'>({Texts.corporate_portal__beta})</span>
            </h1>
        </div>
        {children}
    </div>
);

export default SignInWrapper;
