import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import settings from '../settings/settings';

const useQuery = () => new URLSearchParams(useLocation().search);

// Handles initialization of SSO from Accountec. This component will set a token cookie
// (used to verify that it was initialized here), fetch a redirect url from backend and
// redirect the browser there
export const SsoInitiate = () => {
    const fetched = useRef<boolean>(false);

    const query = useQuery();
    const idp = query.get('idp');

    const submit = async (idp: string) => {
        const token = `s_${new Date().getTime()}`;
        document.cookie = `ssocToken=${token};path=/`;

        try {
            const res = await axios.post(
                `${settings.apiBase}sso/initiate-corpportal-accountec?idp=${idp}&token=${token}`,
            );
            var redirectUrl = res.data as string;

            document.location = redirectUrl;
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        if (!fetched.current) {
            fetched.current = true;
            submit(idp as string);
        }
    });

    return <div>Redirecting...</div>;
};
