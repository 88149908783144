import { ExportConfig, generateAndSavePDF } from '../../Functions/Exports';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import { Quarter } from './GetQuartersFromFiscalYears';
import { TimeSpan } from './Reports';
import Texts from '../../Functions/Texts.json';
import {
    BalanceSheetsMonthsLabels,
    BalanceSheetsQuartersLabels,
    BalanceSheetsYearsLabels,
    MonthReportColumnType,
    PopulateBalanceSheetsMonths,
    PopulateProfitAndLossMonth,
    PopulateProfitAndLossQuarters,
    PopulateProfitAndLossYears,
    ProfitAndLossMonthsLabels,
    ProfitAndLossQuartersLabels,
    ProfitAndLossYearsLabels,
    populateBalanceSheetsYears,
} from './Templates';
import QuarterAggregator from './QuarterAggregator';
import TableColumn from './Types/TableColumn';
import MonthSelection from './Types/MonthSelection';
import CompanyDetails from '../../Types/Api/Response/CompanyDetails';
import { SavedBudget } from '../../Functions/Budget';

export type GenerateReportProps = {
    profitAndLoss: ProfitAndLossRow[];
    profitAndLossAggregated: ProfitAndLossRow[];
    companyDetails: CompanyDetails | undefined;
    balanceSheetsAggregated: BalanceSheetRow[];
    balanceSheets: BalanceSheetRow[];
    budgets: SavedBudget[];

    isPnLReport: boolean;
    isBSReport: boolean;
    timeSpan: TimeSpan;
    selectedFinancialYears: string[];
    selectedQuarters: string[];
    selectedBudgetId: string | null | undefined;
    quarters: Quarter[];
    monthSelection: MonthSelection;
};

const useReportGenerator = () => {
    const sortPnLRow = (a: ProfitAndLossRow, b: ProfitAndLossRow) =>
        a.period === b.period ? 0 : a.period < b.period ? 1 : -1;
    const sortBSRow = (a: BalanceSheetRow, b: BalanceSheetRow) =>
        a.period === b.period ? 0 : a.period < b.period ? 1 : -1;
    const sortQuarters = (a: Quarter, b: Quarter) =>
        a.from === b.from ? 0 : a.from < b.from ? 1 : -1;

    const { AggregateQuarterPnL, GetQuarterBS } = QuarterAggregator();

    const GenerateReport = ({
        profitAndLoss,
        profitAndLossAggregated,
        companyDetails,
        balanceSheetsAggregated,
        balanceSheets,
        budgets,
        isPnLReport,
        isBSReport,
        timeSpan,
        selectedFinancialYears,
        selectedQuarters,
        selectedBudgetId,
        quarters,
        monthSelection,
    }: GenerateReportProps) => {
        let report: TableColumn[][] = [];
        const options: ExportConfig = {
            action: 'pdfExport',
            name: '',
        };

        switch (timeSpan) {
            case TimeSpan.year:
                if (isPnLReport) {
                    const budgetColumns =
                        budgets
                            .filter((budget) => budget.id === selectedBudgetId)
                            .map((budget) =>
                                PopulateProfitAndLossYears(budget.yearlyProfitAndLoss, budget.name),
                            ) ?? [];
                    const pnLYearly = [
                        ProfitAndLossYearsLabels,
                        ...budgetColumns,
                        ...profitAndLossAggregated
                            .filter((pnl) =>
                                selectedFinancialYears.some((fy) => fy === pnl.financialYear),
                            )
                            .sort(sortPnLRow)
                            .map((pnl) => PopulateProfitAndLossYears(pnl, null)),
                    ];
                    options.name = Texts.report_generator.profitAndLossYear;
                    report.push(pnLYearly);
                }
                if (isBSReport) {
                    const BsYearly = [
                        BalanceSheetsYearsLabels,
                        ...balanceSheetsAggregated
                            .filter((bs) =>
                                selectedFinancialYears.some((fy) => fy === bs.financialYear),
                            )
                            .sort(sortBSRow)
                            .map((bs) => populateBalanceSheetsYears(bs)),
                    ];
                    report.push(BsYearly);
                    options.name = isPnLReport
                        ? Texts.report_generator.combinedYear
                        : Texts.report_generator.balanceSheetsYear;
                }
                break;
            case TimeSpan.quarter:
                const filteredQuarters = quarters
                    .filter((q) => selectedQuarters.some((sq) => sq === q.label))
                    .sort(sortQuarters);

                if (isPnLReport) {
                    const pnLQuarterly = [
                        ProfitAndLossQuartersLabels,
                        ...filteredQuarters.map((q) =>
                            PopulateProfitAndLossQuarters(AggregateQuarterPnL(q, profitAndLoss), q),
                        ),
                    ];
                    report.push(pnLQuarterly);
                    options.name = Texts.report_generator.profitAndLossQuarter;
                }
                if (isBSReport) {
                    const bsQuarterly = [
                        BalanceSheetsQuartersLabels,
                        ...filteredQuarters.map((q) => GetQuarterBS(q, balanceSheets)),
                    ];
                    report.push(bsQuarterly);
                    options.name = isPnLReport
                        ? Texts.report_generator.combinedQuarter
                        : Texts.report_generator.balanceSheetsQuarter;
                }
                break;
            case TimeSpan.month:
                const monthText = `${monthSelection.primary?.substring(0, 8)}01 - ${
                    monthSelection.primary
                }`;
                if (isPnLReport) {
                    const selectedBudget = budgets.find((budget) => budget.id === selectedBudgetId);
                    const budgetPnL = selectedBudget
                        ? [
                              PopulateProfitAndLossMonth(
                                  selectedBudget.monthlyProfitAndLoss.find(
                                      // Compare only Month & Date part to support budgets of other years
                                      (b) =>
                                          b.period.substring(4, 10) ===
                                          monthSelection.primary?.substring(4.1),
                                  )!,
                                  selectedBudget.name,
                                  MonthReportColumnType.Budget,
                              ),
                          ]
                        : [];

                    const pnLMonthly = [
                        ProfitAndLossMonthsLabels,
                        PopulateProfitAndLossMonth(
                            profitAndLoss.find(
                                (pnl) => pnl.period === `${monthSelection.primary}T00:00:00`,
                            )!,
                            null,
                            MonthReportColumnType.Primary,
                        ),
                        ...(monthSelection.secondary
                            ? [
                                  PopulateProfitAndLossMonth(
                                      profitAndLoss.find(
                                          (pnl) =>
                                              pnl.period === `${monthSelection.secondary}T00:00:00`,
                                      )!,
                                      null,
                                      MonthReportColumnType.Secondary,
                                  ),
                              ]
                            : []),
                        ...budgetPnL,
                    ];
                    report.push(pnLMonthly);
                    options.name = Texts.report_generator.profitAndLossMonth.replace(
                        '{month}',
                        monthText,
                    );
                }
                if (isBSReport) {
                    const bsMonthly = [
                        BalanceSheetsMonthsLabels,
                        PopulateBalanceSheetsMonths(
                            balanceSheets.find(
                                (bs) => bs.period === `${monthSelection.primary}T00:00:00`,
                            )!,
                            MonthReportColumnType.Primary,
                        ),
                        ...(monthSelection.secondary
                            ? [
                                  PopulateBalanceSheetsMonths(
                                      balanceSheets.find(
                                          (bs) =>
                                              bs.period === `${monthSelection.secondary}T00:00:00`,
                                      )!,
                                      MonthReportColumnType.Secondary,
                                  ),
                              ]
                            : []),
                    ];
                    report.push(bsMonthly);
                    options.name = isPnLReport
                        ? Texts.report_generator.combinedMonth.replace('{month}', monthText)
                        : Texts.report_generator.balanceSheetsMonth.replace('{month}', monthText);
                }
                break;
            default:
                throw new Error('selected timespan not supported');
        }

        generateAndSavePDF(options, companyDetails!, report);
    };

    return {
        GenerateReport,
    };
};

export default useReportGenerator;
