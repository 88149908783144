import React, { useState, useEffect } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import { FormatOrganizationNumber } from '../Functions/Formatting';
import { SortByTextAndNull } from '../Functions/Calculations';
import { useRecoilValue } from 'recoil';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import { companiesState } from '../Core/State/CompanyListState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import { userCreditorNameState } from '../Core/State/UserState';
import { add30Days } from '../Functions/Functions';
import DisabledCompany from '../Types/Api/Response/DisabledCompany';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';

const DisabledCompanies = () => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );
    const { getCompanyList } = useGetCompanyListRequest();
    const creditorName = useRecoilValue(userCreditorNameState);

    const setupTable = (companies: DisabledCompany[] | undefined): RowTable => {
        if (companies === undefined) {
            return emptyTable;
        }

        const table: RowTable = {
            head: [
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium right',
                },
                {
                    label: Texts.disabled_date__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium right',
                },
                {
                    label: Texts.removal_date__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium right',
                },
            ],
            rows: [],
            actions: [],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        const rows = companies.map(
            (company) =>
                ({
                    metaInformation: {
                        visible: true,
                    },
                    cells: [
                        {
                            text: company.companyName,
                            className: 'large',
                        },
                        {
                            text: company.organizationNumber
                                ? FormatOrganizationNumber(company.organizationNumber)
                                : company.organizationNumber,
                            className: 'medium right',
                        },
                        {
                            text: company.disabledDate
                                ? company.disabledDate.substring(0, 10).replace('T', ' ')
                                : '',
                            className: 'medium right',
                        },
                        {
                            text: company.disabledDate ? add30Days(company.disabledDate) : '',
                            className: 'medium right',
                        },
                    ],
                } as RowTableRow),
        );

        table.rows = rows != null && rows.length > 0 ? rows.sort(SortByTextAndNull(0)) : [];

        return table;
    };

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(disabledCompanies?.data);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['companies', 'dashboard']}
                />
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                viewTitle={Texts.dashboard_disabled_companies__DisabledCompaniesViewTitle}
                toggleHelp={() => setHelpIsOpen(true)}
            />
            <RowBasedTableWrapper>
                <div className='row-based-table-wrapper-heading'>
                    <p>
                        {Texts.below_list_shows__DisabledCompaniesViewTitle} {creditorName}{' '}
                        {Texts.disabled_explanation__DisabledCompaniesViewPreable} {creditorName}.
                    </p>
                </div>
                <RowBasedTable table={table} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default DisabledCompanies;
