import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { add, endOfMonth, format, isEqual } from 'date-fns';
import { emptyProfitAndLossRow, Budget, SavedBudget } from '../../Functions/Budget';
import { range } from '../../Functions/Lists';
import { BudgetTable } from '../../Components/Budget/BudgetTable';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import usePostBudgetMutation from '../../Api/Budget/usePostBudgetMutation';
import { AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import Alert from '../../Components/Alert';
import useGetBudgetYears from './Hooks/useGetBudgetYears';

type BudgetCreatorProps = {
    yearlyData: ProfitAndLossRow[];
    monthlyData: ProfitAndLossRow[];
    organizationId: string;
};
export const BudgetCreator = ({ yearlyData, monthlyData, organizationId }: BudgetCreatorProps) => {
    const navigate = useNavigate();
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const [name, setName] = useState('');
    const [isCreating, setIsCreating] = useState(false);
    const [initialBudget, setInitialBudget] = useState<Budget | null>(null);
    const [budget, setBudget] = useState<Budget | null>(null);
    const { mutate, isLoading } = usePostBudgetMutation();
    const queryClient = useQueryClient();

    const {
        startPeriod,
        budgetYears,
        isLoading: periodsAreLoading,
    } = useGetBudgetYears(organizationId);

    const [budgetPeriod, setBudgetPeriod] = useState<Date | undefined>(budgetYears[0]);
    useEffect(() => {
        if (budgetYears.length > 0 && budgetPeriod === undefined) {
            setBudgetPeriod(budgetYears[0]);
        }
    }, [budgetPeriod, budgetYears]);

    const disabled = name.trim() === '' || !budgetPeriod || isLoading;
    const createBudget = () => {
        if (disabled) return;

        const yearFrom = format(budgetPeriod, 'yyyy-MM-dd');
        const yearTo = format(endOfMonth(add(budgetPeriod, { months: 11 })), 'yyyy-MM-dd');
        const yearlyPnl: ProfitAndLossRow = {
            ...emptyProfitAndLossRow,
            financialYearFrom: yearFrom + 'T00:00:00',
            financialYearTo: yearTo + 'T00:00:00',
            financialYear: `${yearFrom} : ${yearTo}`,
            period: yearFrom + 'T00:00:00',
        };

        const b: Budget = {
            name,
            period: format(budgetPeriod, 'yyyy-MM-dd') + 'T00:00:00',
            yearlyProfitAndLoss: yearlyPnl,
            monthlyProfitAndLoss: range(12).map((months) => ({
                ...yearlyPnl,
                period:
                    format(endOfMonth(add(budgetPeriod, { months })), 'yyyy-MM-dd') + 'T00:00:00',
            })),
            copyYear: undefined,
        };
        setInitialBudget(b);
        setBudget(b);
    };

    if (periodsAreLoading) {
        return (
            <div className='spinner-wrapper'>
                <div className='spinner'></div>
            </div>
        );
    }

    if (initialBudget === null || budget === null) {
        return (
            <div>
                {helpIsOpen && (
                    <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['budget']} />
                )}
                <div className='budget-title-row'>
                    <h4>{Texts.budget.create_new}</h4>
                    <button className='help' onClick={() => setHelpIsOpen(true)}>
                        {Texts.help__HelpButtonText}
                    </button>
                </div>
                {startPeriod === undefined ? (
                    <Alert severity='warning' message={Texts.budget.missing_financial_years} />
                ) : (
                    <>
                        <div className='budget-create-row'>
                            <span>{Texts.budget.name}:</span>
                            <div className='input'>
                                <input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                ></input>
                            </div>
                        </div>
                        <div className='budget-create-row'>
                            <span>{Texts.budget.choose_budget_period}</span>
                            <div className='input dropdown'>
                                <div className='dropdowns-wrapper'>
                                    <div className='secondary-legends'>
                                        {budgetYears.map((y) => (
                                            <div
                                                onClick={() => setBudgetPeriod(y)}
                                                key={y.getFullYear()}
                                                className={`secondary-legends-button ${
                                                    isEqual(budgetPeriod ?? 0, y) && 'checked'
                                                }`}
                                            >
                                                {format(y, 'yyyy-MM')} -{' '}
                                                {format(add(y, { years: 1, days: -1 }), 'yyyy-MM')}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            className='button'
                            disabled={disabled}
                            onClick={() => createBudget()}
                        >
                            {Texts.budget.create}
                        </button>
                    </>
                )}
            </div>
        );
    }

    const saveBudget = async () => {
        setIsCreating(true);
        mutate(
            { budget, organizationId },
            {
                onSuccess: (budgetResponse: AxiosResponse<SavedBudget>) => {
                    queryClient.invalidateQueries(['MyBudgets']);
                    queryClient.invalidateQueries(['ActiveBudgets']);

                    // Ugly  workaround to make sure the invalidated query has been re-run before navigating
                    setTimeout(() => {
                        navigate(`../${budgetResponse.data.id}`);
                        setIsCreating(false);
                    }, 1000);
                },
                onError: () => setIsCreating(false),
            },
        );
    };

    return (
        <div>
            {helpIsOpen && <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['budget']} />}
            <div className='budget-title-row'>
                <h3>Budgetnamn: {name}</h3>
                <div>
                    <button className='help' onClick={() => setHelpIsOpen(true)}>
                        {Texts.help__HelpButtonText}
                    </button>
                    <button
                        className='save'
                        disabled={isLoading || isCreating}
                        onClick={() => saveBudget()}
                    >
                        {isLoading ? Texts.budget.saving : Texts.budget.save}
                    </button>
                </div>
            </div>
            <BudgetTable
                initialBudget={initialBudget}
                budgetUpdated={setBudget}
                yearlyData={yearlyData}
                monthlyData={monthlyData}
                inputDisabled={isLoading || isCreating}
            />
        </div>
    );
};
