import { AxiosError } from 'axios';
import { ReactNode } from 'react';
import { QueryClientProvider, QueryClient as ReactQueryClient } from 'react-query';
import Texts from '../Functions/Texts.json';
import useSnackbar from '../Components/Snackbar/useSnackbar';

const getErrorMessage = (errorCode: number | undefined): string => {
    switch (errorCode) {
        case 400:
            return Texts.responses.bad_request;
        case 401:
            return Texts.responses.unauthorized;
        case 403:
            return Texts.responses.forbidden;
        case 405:
            return Texts.responses.not_allowed;
        case 408:
            return Texts.responses.timeout;
        case 500:
            return Texts.responses.internal_server_error;
        default:
            return Texts.responses.unkown_error;
    }
};

type Props = {
    children: ReactNode;
};

const useQueryClient = () => {
    const { enqueueErrorSnackbar } = useSnackbar();

    const handleOnError = ({ response }: AxiosError) => {
        enqueueErrorSnackbar({
            title: Texts.responses.general,
            message: getErrorMessage(response?.status),
        });
    };

    const queryClient = new ReactQueryClient({
        defaultOptions: {
            queries: {
                staleTime: 10 * 60000,
                cacheTime: 10 * 60000,
                onError: (error) => {
                    handleOnError(error as AxiosError);
                },
            },
            mutations: {
                onError: (error) => {
                    handleOnError(error as AxiosError);
                },
            },
        },
    });

    return queryClient;
};

const QueryClient = ({ children }: Props) => {
    const client = useQueryClient();

    return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

export default QueryClient;
