import React, { useEffect, useState } from 'react';
import Texts from '../Functions/Texts.json';
import Breadcrumbs from './Breadcrumbs';
import CompanyMenu from './CompanyMenu';
import { DatetimeAsDate } from '../Functions/Formatting';
import { IsNewSinceTwoDays } from '../Functions/Calculations';
import CompanyIndustry from '../Types/Api/Response/CompanyIndustry';
import { useParams } from 'react-router-dom';
import useGetCompanyDetailsQuery from '../Api/Companies/useGetCompanyDetailsQuery';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import { orgNrToIdMapState } from '../Core/State/CompanyListState';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import { add30Days } from '../Functions/Functions';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './Wrappers/ErrorFallback';

const CompanyDetailsView = () => {
    const [companyInfoIsExpanded, setCompanyInfoIsExpanded] = useState<boolean>(false);
    const { organizationNumber } = useParams();
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const orgNrToIdMap = useRecoilValue(orgNrToIdMapState);
    const organizationId = organizationNumber ? orgNrToIdMap[organizationNumber] : null;

    // Needed for the orgNrToIdMapState to be set
    const { getCompanyList } = useGetCompanyListRequest();

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const { isLoading, data: companyDetailsData } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });

    const getMainSni = (codes: CompanyIndustry[]) => {
        const mainCode = codes && codes.length > 0 && codes.find((c) => c.mainIndustry === true);
        if (!mainCode) {
            return '-';
        }
        return `${mainCode.sni} ${mainCode.descriptionSwedish}`;
    };

    if (isLoading || !companyDetailsData) {
        return (
            <div className='content-loading-wrapper'>
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            </div>
        );
    }

    const companyDetails = companyDetailsData.data;
    const {
        organizationName,
        organizationNumber: orgNumber,
        officialInformation,
        officialInformation: { sniCodes },
        consentStatus,
        organizationCreated,
        disabledDate,
        latestUpload,
        latestVoucher,
        program,
    } = companyDetails;

    const companyStatusText =
        officialInformation.companyStatus === 'Active'
            ? Texts.active_company__CompanyInfoText
            : 'officialInformation.companyStatus';

    return (
        <div className='content-summary-wrapper'>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <div className='content-heading'>
                    <Breadcrumbs companyDetails={companyDetails} />
                    <h1>
                        {organizationName}
                        <span>{orgNumber}</span>
                    </h1>
                    <div
                        onClick={() => setCompanyInfoIsExpanded(!companyInfoIsExpanded)}
                        className={`company-info-visibility-toggle ${
                            !companyInfoIsExpanded && 'folded'
                        }`}
                    ></div>
                    {!companyInfoIsExpanded ? (
                        <div className='info-boxes slim'>
                            <div className='company-info-box'>
                                {consentStatus === 'OK' && latestUpload != null && (
                                    <div>
                                        <span className='status-ok'>
                                            {Texts.latest_fetch_from__CompanyInfoText} {program}:{' '}
                                            {DatetimeAsDate(latestUpload)}
                                        </span>
                                    </div>
                                )}
                                {consentStatus === 'OK' && latestUpload == null && (
                                    <div>
                                        <span className='status-not-ok'>
                                            {Texts.info_about_latest_fetch_missing__CompanyInfoText}
                                        </span>
                                    </div>
                                )}
                                {consentStatus !== 'OK' && (
                                    <div>
                                        <span className='status-not-ok'>
                                            {Texts.company_inactivated__CompantInfoText}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div className='info-boxes'>
                            <div className='company-info-box'>
                                <div>
                                    {officialInformation.companyType},{' '}
                                    {Texts.was_registered__CompanyInfoText}{' '}
                                    {DatetimeAsDate(officialInformation.incorporationDate)}
                                </div>
                                <div className='has-info-icon employees'>
                                    {officialInformation.nrEmployees}{' '}
                                    {Texts.employees__CompanyInfoText}
                                </div>
                                <div className='has-info-icon city'>
                                    {officialInformation.zipcode}, {officialInformation.town}
                                </div>
                                <div>
                                    {Texts.company_is__CompanyInfoText} {companyStatusText}
                                </div>
                                <div>
                                    Verksamhet:{' '}
                                    {getMainSni(sniCodes)?.substring(
                                        sniCodes.length - (sniCodes.length - 6),
                                    )}
                                </div>
                                <div>SNI: {getMainSni(sniCodes)?.substring(0, 5)}</div>
                            </div>

                            <div className='company-info-box'>
                                {consentStatus === 'OK' && IsNewSinceTwoDays(latestUpload) && (
                                    <div>
                                        <span className='status-ok'>
                                            {Texts.active_company__CompanyInfoText},{' '}
                                            {Texts.connected_to_mynnumbers_since__CompanyInfoText}:{' '}
                                            {DatetimeAsDate(organizationCreated)}
                                        </span>
                                    </div>
                                )}
                                {consentStatus === 'OK' &&
                                    !IsNewSinceTwoDays(latestUpload) &&
                                    latestUpload != null && (
                                        <div>
                                            <span className='status-not-ok'>
                                                {Texts.inactive__CompanyInfoText} /{' '}
                                                {Texts.not_updated_since__CompanyInfoText}{' '}
                                                {DatetimeAsDate(latestUpload)}
                                            </span>
                                        </div>
                                    )}
                                {consentStatus === 'DISABLED' &&
                                    (disabledDate == null ? (
                                        <div>
                                            <span className='status-not-ok'>
                                                {Texts.inactivated__CompanyInfoText} -{' '}
                                                {Texts.date_missing__CompanyInfoText}{' '}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className='status-not-ok'>
                                                {Texts.inactivated__CompanyInfoText}{' '}
                                                {DatetimeAsDate(disabledDate)},{' '}
                                                {Texts.will_be_removed__CompanyInfoText}{' '}
                                                {add30Days(disabledDate)}{' '}
                                            </span>
                                        </div>
                                    ))}
                                {consentStatus === 'OK' && latestUpload == null && (
                                    <div>
                                        <span className='status-not-ok'>
                                            {Texts.info_about_latest_fetch_missing__CompanyInfoText}
                                        </span>
                                    </div>
                                )}
                                {consentStatus === 'OK' && latestUpload != null && (
                                    <div>
                                        <span className='status-ok'>
                                            {Texts.latest_fetch_from__CompanyInfoText} {program}:{' '}
                                            {DatetimeAsDate(latestUpload)}
                                        </span>
                                    </div>
                                )}
                                {consentStatus === 'OK' && latestVoucher == null && (
                                    <div>
                                        <span className='status-not-ok'>
                                            {
                                                Texts.info_about_latest_voucher_missing__CompanyInfoText
                                            }
                                        </span>
                                    </div>
                                )}
                                {consentStatus === 'OK' && latestVoucher != null && (
                                    <div>
                                        <span className='status-ok'>
                                            {Texts.latest_voucher__CompanyInfoText}{' '}
                                            {DatetimeAsDate(latestVoucher)}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <CompanyMenu companyDetails={companyDetails} />
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default CompanyDetailsView;
