import { useQuery } from 'react-query';
import axiosInstance from '../../Core/Axios';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import UseQueryOptions from '../UseQueryOptions';

const useGetBalanceSheetsAggregated = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<BalanceSheetRow[]>,
) =>
    useQuery(
        ['balanceSheetsAggregated', companyId],
        async () => axiosInstance.get<BalanceSheetRow[]>(`Reports/balancesheets/${companyId}`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetBalanceSheetsAggregated;
