import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { SavedBudget } from '../../Functions/Budget';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import { add, format } from 'date-fns';
import { Dialog } from '../../Components/Dialog';
import useBudgetRequests from '../../Api/Budget/useBudgetRequests';

type BudgetSelectorProps = {
    budgets: SavedBudget[];
    organizationId: string;
};
export const BudgetSelector = ({ budgets, organizationId }: BudgetSelectorProps) => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const [budgetToDelete, setBudgetToDelete] = useState<SavedBudget | null>(null);
    const [budgetToActivate, setBudgetToActivate] = useState<SavedBudget | null>(null);
    const { saveBudget, deleteBudget, isLoading } = useBudgetRequests();
    const setActive = async (budget: SavedBudget, active: boolean) => {
        setBudgetToActivate(null);
        saveBudget({ ...budget, active }, organizationId);
    };
    const removeBudget = (budget: SavedBudget) => {
        deleteBudget(budget, organizationId);
    };

    return (
        <>
            {helpIsOpen && <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['budget']} />}
            <Dialog
                open={!!budgetToDelete}
                header={Texts.budget.delete}
                ok={() => removeBudget(budgetToDelete!)}
                cancel={() => setBudgetToDelete(null)}
            >
                {Texts.budget.sure_delete.replace('{budget}', budgetToDelete?.name ?? '')}
            </Dialog>
            <Dialog
                open={!!budgetToActivate}
                header={Texts.budget.activate}
                ok={() => setActive(budgetToActivate!, true)}
                cancel={() => setBudgetToActivate(null)}
            >
                {Texts.budget.activate_description}
            </Dialog>
            <div className='budget-title-row'>
                <h1>{Texts.budget.budget_list_title}</h1>
                <button className='help' onClick={() => setHelpIsOpen(true)}>
                    {Texts.help__HelpButtonText}
                </button>
            </div>
            <div className='budget-list'>
                {(budgets?.length ?? 0) === 0 ? (
                    <p>{Texts.budget.no_budgets}</p>
                ) : (
                    <div className='budget-list-title-row'>
                        <span>{Texts.budget.name}</span>
                        <span className='budget-period'>{Texts.budget.period.title}</span>
                    </div>
                )}
                {budgets?.map((b) => (
                    <div key={b.id} className='budget-list-item'>
                        <span className='budget-name'>{b.name}</span>
                        <span className='budget-period'>
                            {format(new Date(b.period), 'yyyy-MM')} -{' '}
                            {format(add(new Date(b.period), { years: 1, days: -1 }), 'yyyy-MM')}
                        </span>
                        <NavLink to={b.id}>
                            <button className='fa-button edit'>{Texts.budget.edit}</button>
                        </NavLink>
                        {b.active ? (
                            <button
                                className='fa-button toggle toggle-on'
                                disabled={isLoading}
                                onClick={() => setActive(b, false)}
                            >
                                {Texts.budget.disable}
                            </button>
                        ) : (
                            <button
                                className='fa-button toggle toggle-off'
                                disabled={isLoading}
                                onClick={() => setBudgetToActivate(b)}
                            >
                                {Texts.budget.activate}
                            </button>
                        )}
                        <button
                            className='fa-button trash'
                            disabled={isLoading}
                            onClick={() => setBudgetToDelete(b)}
                        >
                            {Texts.budget.delete}
                        </button>
                    </div>
                ))}
            </div>
            <div className='create-button-row'>
                <NavLink to='create' className='create-budget fa-button unfold'>
                    {Texts.budget.create}
                </NavLink>
            </div>
        </>
    );
};
