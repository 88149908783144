import useGetBasicCompanyListQuery from '../../Api/Companies/useGetBasicCompanyListQuery';
import axiosInstance from '../../Core/Axios';
import { ActivateLicenseRequest } from '../../Functions/Authentication';
import Texts from '../../Functions/Texts.json';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import { FieldValues, useForm, ValidateResult } from 'react-hook-form';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import { ActivationData } from './RegisterView';
import { useState } from 'react';
import useSnackbar from '../../Components/Snackbar/useSnackbar';
import useGetSystemConfiguration from '../../Api/SystemConfiguration/useGetSystemConfiguration';

type Props = {
    userInfo: CorporatePortalUser | null;
    userName: string;
    setLicenseChoice: React.Dispatch<React.SetStateAction<ActivationData | null>>;
    cancelActivation: () => void;
};

const ExpiredLicense = ({ userInfo, userName, setLicenseChoice, cancelActivation }: Props) => {
    const {
        register,
        watch,
        handleSubmit,
        getValues,
        formState: { isDirty, isValid },
    } = useForm({ mode: 'onChange' });
    const [isActivating, setIsActivating] = useState(false);
    const { data: companyList, isLoading: companyListIsLoading } = useGetBasicCompanyListQuery();
    const { data: systemConfiguration } = useGetSystemConfiguration();
    const { enqueueErrorSnackbar } = useSnackbar();
    const billingInterval = watch('interval');

    if (userInfo === null) {
        throw new Error('userinfo is null');
    }

    const firms = userInfo.accountingFirmsExpiredTrial.concat(
        userInfo.accountingFirmsExpiredLicense,
    );

    const validateActivateInput = (): ValidateResult => {
        const billingInterval = getValues('interval');
        const company = getValues('company');
        const firm = getValues('firm');

        if (
            billingInterval !== null &&
            (company || (companyList && companyList.data.length === 0) || firm)
        ) {
            return true;
        }
        return false;
    };

    const onActivateSubmit = async (formData: FieldValues) => {
        setIsActivating(true);
        if (userInfo === null) {
            throw new Error('userinfo is null, cannot activate');
        }
        const chosenFirm = firms.find((f) => f.id === formData.firm);
        let data: ActivateLicenseRequest = {
            billingInterval: parseInt(formData.interval),
            companyId: formData.company,
            accountingFirmId: formData.firm,
            billingEmailAddress: formData.billingAddress,
        };
        try {
            var response = await axiosInstance.post<CorporatePortalUser>(
                'corporatePortal/activateLicense',
                data,
            );
        } catch (e) {
            enqueueErrorSnackbar(Texts.responses.general);
            return;
        }
        setIsActivating(false);
        const license: LicenseChoiceModel = chosenFirm
            ? { type: 'firm', id: chosenFirm.id, title: chosenFirm.name }
            : { type: 'sme', id: 'sme', title: '' };
        setLicenseChoice({
            isTrial: false,
            usrData: response.data,
            license,
        });
    };

    const monthlyPlus = systemConfiguration?.data
        ? systemConfiguration?.data.find((sc) => sc.key === 'monthlyPlus')?.value ?? ''
        : '';
    const yearlyPlus = systemConfiguration?.data
        ? systemConfiguration?.data.find((sc) => sc.key === 'yearlyPlus')?.value ?? ''
        : '';

    const activateText =
        userInfo?.hasExpiredTrial || userInfo?.accountingFirmsExpiredTrial?.length > 0
            ? Texts.signin.activate_license
            : Texts.signin.reactivate_license;

    return (
        <div className='message' id='activate'>
            {companyListIsLoading ? (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            ) : userInfo.hasExpiredTrial && companyList && companyList.data.length === 0 ? (
                <div
                    dangerouslySetInnerHTML={{
                        __html: Texts.signin.sme_no_companies.replace('{name}', userName),
                    }}
                />
            ) : (
                <form className='activate-form' onSubmit={handleSubmit(onActivateSubmit)}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: activateText.replace('{name}', userName),
                        }}
                    ></div>
                    <div className='box-wrapper'>
                        <input
                            id='monthly'
                            type='radio'
                            value={1}
                            disabled={isActivating}
                            {...register('interval', {
                                validate: validateActivateInput,
                                onChange: validateActivateInput,
                            })}
                        />
                        <label htmlFor='monthly'>
                            {Texts.signin.monthly.replace('{monthlyPlus}', monthlyPlus)}
                        </label>
                    </div>
                    <div className='box-wrapper'>
                        <input
                            id='yearly'
                            type='radio'
                            value={12}
                            disabled={isActivating}
                            {...register('interval', {
                                validate: validateActivateInput,
                                onChange: validateActivateInput,
                            })}
                        />
                        <label htmlFor='yearly'>
                            {Texts.signin.yearly.replace('{yearlyPlus}', yearlyPlus)}
                        </label>
                    </div>
                    {firms.length === 0 && companyList && companyList.data.length > 0 && (
                        <div>
                            <p>{Texts.signin.choose_company}</p>
                            {companyList.data.map((company) => (
                                <div className='box-wrapper' key={company.id}>
                                    <input
                                        id={company.id}
                                        type='radio'
                                        value={company.id}
                                        disabled={isActivating}
                                        {...register('company', {
                                            validate: validateActivateInput,
                                            onChange: validateActivateInput,
                                        })}
                                    />
                                    <label htmlFor={company.id}>{company.name}</label>
                                </div>
                            ))}
                        </div>
                    )}
                    {firms.length > 0 && (
                        <div>
                            <p>{Texts.signin.choose_firm}</p>
                            {firms.map((firm) => (
                                <div className='box-wrapper' key={firm.id}>
                                    <input
                                        id={firm.id}
                                        type='radio'
                                        value={firm.id}
                                        disabled={isActivating}
                                        {...register('firm', {
                                            validate: validateActivateInput,
                                            onChange: validateActivateInput,
                                        })}
                                    />
                                    <label htmlFor={firm.id}>{firm.name}</label>
                                </div>
                            ))}
                        </div>
                    )}
                    {billingInterval === '1' && <p>{Texts.signin.billing_info_monthly}</p>}
                    {billingInterval === '12' && <p>{Texts.signin.billing_info_yearly}</p>}
                    {billingInterval && (
                        <>
                            <p>{Texts.signin.verify_billing_address}</p>
                            <input
                                className='input'
                                disabled={isActivating}
                                type='text'
                                placeholder={Texts.signin.billing_address_placeholder}
                                defaultValue={userInfo.user.email}
                                {...register('billingAddress', {
                                    required: true,
                                    pattern: /^\S+@\S+$/i,
                                })}
                            />
                        </>
                    )}
                    <button
                        className='button'
                        type='submit'
                        value='Subm'
                        disabled={!isDirty || !isValid}
                    >
                        {Texts.signin.order}
                    </button>
                </form>
            )}
            <button className='button button-secondary' onClick={cancelActivation}>
                {Texts.signin.cancel_activation}
            </button>
            <p>{Texts.signin.exklusive_moms}</p>
        </div>
    );
};

export default ExpiredLicense;
