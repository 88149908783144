import { useMemo, useState } from 'react';
import useGetBudgetYears from './Hooks/useGetBudgetYears';
import { add, format } from 'date-fns';
import SingleSelect, { SelectOption } from '../../Components/InputFields/SingleSelect';
import Texts from '../../Functions/Texts.json';
import { SavedBudget } from '../../Functions/Budget';

type BudgetPeriodPickerProps = {
    period: string;
    organizationId: string;
    budget: SavedBudget;
    periodSelected: (d: Date) => void;
    isSaving: boolean;
};

const BudgetPeriodPicker = ({
    period,
    organizationId,
    isSaving,
    periodSelected,
}: BudgetPeriodPickerProps) => {
    const [selectedPeriod, setSelectedPeriod] = useState<Date>(new Date(period));

    const { budgetYearsWithOld, isLoading: periodsAreLoading } = useGetBudgetYears(organizationId);

    const periodsOptions: SelectOption<Date>[] = useMemo(() => {
        const allPeriods = [new Date(period), ...budgetYearsWithOld]
            .sort((a, b) => (a > b ? 1 : 0))
            .filter((date, i, self) => self.findIndex((d) => d.getTime() === date.getTime()) === i);
        return allPeriods.map((p) => ({
            key: format(p, 'yyyy-MM'),
            value: p,
            title: `${format(p, 'yyyy-MM')} - ${format(add(p, { years: 1, days: -1 }), 'yyyy-MM')}`,
        }));
    }, [period, budgetYearsWithOld]);

    const periodValue = useMemo(
        () =>
            periodsOptions.find((p) => p.value.getTime() === new Date(selectedPeriod).getTime()) ??
            null,
        [selectedPeriod, periodsOptions],
    );

    return (
        <div id='budget-period-picker'>
            {periodsAreLoading || isSaving ? (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            ) : (
                <div id='budget-picker'>
                    <SingleSelect
                        options={periodsOptions}
                        value={periodValue}
                        onChange={(p) => {
                            if (p) {
                                setSelectedPeriod(p.value);
                                periodSelected(p.value);
                            }
                        }}
                        label={`${Texts.budget.period.choose}`}
                        size='large'
                    />
                </div>
            )}
        </div>
    );
};

export default BudgetPeriodPicker;
