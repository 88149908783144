import React from 'react';

export type SelectOption<T = any> = {
    key: string;
    value: T;
    title?: string;
};

export type SingleSelectProps<T = any> = {
    options: Array<SelectOption<T>>;
    value: SelectOption<T> | null;
    onChange: (value: SelectOption<T> | null) => void;
    label?: string;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
};

const SingleSelect = <T,>({
    options,
    value,
    onChange,
    label,
    disabled = false,
    size = 'small',
}: SingleSelectProps<T>) => {
    return (
        <div
            className={`dropdown-input size-${size} ${
                disabled === false && options.length > 0 ? 'enabled' : ''
            }`}
        >
            {label && <span>{label}</span>}
            <div className='input-wrapper'>
                {options.map((option, index) => (
                    <div
                        onClick={disabled ? undefined : () => onChange(option)}
                        key={`${option.title}-${index}`}
                        className={`dropdown-input-button ${option === value ? 'checked' : ''}`}
                    >
                        {option === value && <span className='check-mark' />}
                        {option.title}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SingleSelect;
