import { Params } from 'react-router-dom';
import { orgNrToIdMapState } from '../Core/State/CompanyListState';
import { useRecoilValue } from 'recoil';

const useGetOrgId = (params: Readonly<Params<string>>) => {
    const orgNrToIdMap = useRecoilValue(orgNrToIdMapState);
    const { organizationNumber } = params;
    const organizationId = organizationNumber ? orgNrToIdMap[organizationNumber] : null;

    return { organizationId };
};

export default useGetOrgId;
