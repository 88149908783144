import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { ExpandedProfitAndLossRow, ProfitAndLossTable } from '../../Components/ProfitAndLossTable';
import { CurrentYear } from '../../Functions/Calculations';
import { useParams } from 'react-router-dom';
import { TableRow } from '../../Components/NewColumnBasedTable';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import GetFinancialYearsFromPnL from '../../Components/Reports/Helpers/GetFinancialYearsFromPnL';
import GetQuartersFromFiscalYears from '../../Components/Reports/GetQuartersFromFiscalYears';
import QuarterAggregator from '../../Components/Reports/QuarterAggregator';
import { ColumnData } from '../../Functions/PdfExport';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

type QuarterExpandedProfitAndLossRow = ExpandedProfitAndLossRow & {
    quarter?: string;
    quarterPeriod?: string;
};

const ProfitAndLossByQuarter = () => {
    const params = useParams();
    const organizationNumber = params.organizationNumber;
    const { organizationId } = useGetOrgId(params);
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: profitAndLoss } = useGetPnLQuery(organizationId);

    const { AggregateQuarterPnL } = QuarterAggregator();

    const availableFYs = profitAndLoss ? GetFinancialYearsFromPnL(profitAndLoss.data) : [];
    const unSortedQuarters = GetQuartersFromFiscalYears(availableFYs);
    const quarters = [...unSortedQuarters].sort((a, b) => (a.from > b.from ? -1 : 1));

    // Perhaps needs to be filtered for past quarters only ?

    const data: QuarterExpandedProfitAndLossRow[] = profitAndLoss
        ? quarters.map((q) => ({
              ...AggregateQuarterPnL(q, profitAndLoss.data),
              columnClassName: 'my-numbers-data',
              quarter: `${q.q} ${q.financialYear}`,
              quarterPeriod: q.label,
              isBudget: false,
          }))
        : [];

    const quarterOfYear = (d: QuarterExpandedProfitAndLossRow) => d.quarter;

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['profit-and-loss']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/profit-and-loss/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'by-year',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month current',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-accounts/${CurrentYear()}`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-waterfall/`,
                        text: Texts.charts__ViewOptionButtonText,
                        className: 'view-option-links by-chart',
                    },
                ]}
            />
            <ProfitAndLossTable
                columnWidth={190}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={false}
                data={data}
                csvExportFilename={`${Texts.profit_and_loss_by_month__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                stepHeader={(first, last) => (
                    <>
                        {quarterOfYear(first)}
                        {last && ` - ${quarterOfYear(last)}`}
                    </>
                )}
                style={{ marginRight: 90 }}
                netProfitLabel={Texts.report_generator.quarterResult}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & { quarter: string }>
                    className='bold'
                    label={Texts.profit_and_loss__ViewTitle}
                    selector={(d) => d.quarter}
                />
                <TableRow<ColumnData & { quarterPeriod: string }>
                    className='tiny-row'
                    label={Texts.profitandloss__period}
                    selector={(d) => d.quarterPeriod}
                />
            </ProfitAndLossTable>
        </ErrorBoundary>
    );
};

export default ProfitAndLossByQuarter;
