import { useQuery } from 'react-query';
import axiosInstance from '../../Core/Axios';
import UseQueryOptions from '../UseQueryOptions';
import CashflowRow from '../../Types/Api/Response/CashflowRow';

const useGetCashflow = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<CashflowRow[]>,
) =>
    useQuery(
        ['cashflow', companyId],
        async () => axiosInstance.get<CashflowRow[]>(`Reports/cashflow/${companyId}/monthly`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetCashflow;
