import React, { useState } from 'react';
import { concatenateOfficialData } from '../Functions/Functions';
import Texts from '../Functions/Texts.json';
import { WaterfallChart } from './NewWaterfallChart';
import { format } from 'date-fns';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';

export type ProfitAndLossWaterfallChartProps = {
    officialProfitAndLoss: ProfitAndLossRow[] | undefined;
    profitAndLossAggregated: ProfitAndLossRow[] | undefined;
};
export const ProfitAndLossWaterfallChart = ({
    officialProfitAndLoss,
    profitAndLossAggregated,
}: ProfitAndLossWaterfallChartProps) => {
    const [selectedProfitAndLossYear, setSelectedProfitAndLossYear] = useState(0);

    const yearlyData =
        officialProfitAndLoss &&
        profitAndLossAggregated &&
        concatenateOfficialData(officialProfitAndLoss, profitAndLossAggregated);

    const selectedProfitAndLossData = yearlyData?.[selectedProfitAndLossYear];

    return (
        <div className='chart-box'>
            {selectedProfitAndLossData && (
                <>
                    <h4>
                        {Texts.charts.profit_and_loss}{' '}
                        {format(new Date(selectedProfitAndLossData.financialYearFrom), 'yyyy-MM')}{' '}
                        {selectedProfitAndLossData.isOfficial ? '*' : ''}
                    </h4>
                    <div className='dropdowns-wrapper'>
                        <div className='secondary-legends'>
                            <span>{Texts.charts.choose_year}:</span>
                            {yearlyData.map((year, i) => (
                                <div
                                    key={i}
                                    onClick={() => setSelectedProfitAndLossYear(i)}
                                    className={`secondary-legends-button ${
                                        i === selectedProfitAndLossYear && ' checked'
                                    }`}
                                >
                                    {format(new Date(year.financialYearFrom), 'yyyy-MM')}
                                </div>
                            ))}
                        </div>
                    </div>
                    <WaterfallChart
                        chartsAreLoading={!selectedProfitAndLossData}
                        data={[
                            {
                                label: 'Nettoomsättning',
                                value: selectedProfitAndLossData.revenue,
                            },
                            {
                                label: 'Övriga rörelseintäkter',
                                value: selectedProfitAndLossData.otherOperatingIncome,
                            },
                            {
                                label: 'Övriga rörelsekostnader',
                                value: selectedProfitAndLossData.otherOperatingExpensesAggregated,
                            },
                            {
                                label: 'Material och varukostnader',
                                value: selectedProfitAndLossData.directCost,
                            },
                            {
                                label: 'Personalkostnader',
                                value: selectedProfitAndLossData.personnelCosts,
                            },
                            {
                                label: 'Avskrivningar',
                                value: selectedProfitAndLossData.depreciationAndWritedowns,
                            },
                            {
                                label: 'EBIT',
                                color: '#599ef9',
                                isSum: true,
                            },
                            {
                                label: 'Finansiella poster',
                                value:
                                    selectedProfitAndLossData.financialIncomes +
                                    selectedProfitAndLossData.financialExpenses,
                            },
                            {
                                label: 'Skatt',
                                value: selectedProfitAndLossData.tax,
                            },
                            {
                                label: 'Bokslutsdispositioner',
                                value: selectedProfitAndLossData.appropriations,
                            },
                            {
                                label: 'Resultat',
                                color: '#599ef9',
                                isSum: true,
                            },
                        ]}
                        chartConfig={{
                            forceDecimals: true,
                        }}
                        hideZeroValues={true}
                    />
                </>
            )}
        </div>
    );
};
