import { ReactElement } from 'react';
import ExclamationCircle from '../Assets/Images/ExclamationCircle';
import CheckCircle from '../Assets/Images/CheckCircle';
import InfoCircle from '../Assets/Images/InfoCircle';
import TriangleExclamation from '../Assets/Images/TriangleExclamation';

type AlertProps = {
    severity: 'success' | 'information' | 'warning' | 'error';
    message?: string;
    variant?: 'normal' | 'compact';
    children?: ReactElement;
};

const Alert = ({ severity, message, children, variant = 'normal' }: AlertProps) => {
    return (
        <div className={`alert no-float severity-${severity} ${variant}`}>
            <div>
                {
                    {
                        success: <CheckCircle color={'#282'} />,
                        information: <InfoCircle color={'#cc9'} />,
                        error: <ExclamationCircle color={'#da546c'} />,
                        warning: <TriangleExclamation color={'#46371a'} />,
                    }[severity]
                }
                {message && <span className='message'>{message}</span>}
                {children && <div>{children}</div>}
            </div>
        </div>
    );
};

export default Alert;
