import { RowTableRow } from '../Components/RowBasedTable';
import FinancialRatioRow from '../Types/Api/Response/FinancialRatioRow';

export const Rand = () => {
    return Math.floor(Math.random() * 99999999);
};

export const ConvertToNumber = (val: string) => {
    return parseInt(val.split(' ').join(''));
};

export const CurrentYear = () => {
    return new Date().getFullYear();
};

export const CurrentMonth = () => {
    return new Date().getMonth();
};

export const NextYear = () => {
    return new Date().getFullYear() + 1;
};

/** Gets the last most occurance of FinancialRatio data for a given financial year */
export const GetYearData = <T extends { financialYear: string; period?: string }>(
    year: string,
    dataObjectArray: Array<T>,
) => {
    for (let i = dataObjectArray.length - 1; i >= 0; i--) {
        if (!dataObjectArray[i] || !dataObjectArray[i].financialYear) {
            return null;
        }
        const financialYear =
            dataObjectArray[i].financialYear.indexOf(':') !== -1
                ? dataObjectArray[i].financialYear.split(':')[1]
                : dataObjectArray[i].financialYear;
        if (financialYear.trim().indexOf(year) !== -1) {
            return dataObjectArray[i];
        }
    }

    return null;
};

export const MonthData = (yearMonth: string, dataObjectArray: Array<{ period: string }>) => {
    let outData;
    let period = '';
    for (let i = 0; i < dataObjectArray.length; i++) {
        period = '';
        if (dataObjectArray[i].period.indexOf('T') !== -1) {
            period = dataObjectArray[i].period.split('T')[0];
        }
        if (period.indexOf(yearMonth.substring(0, 7)) !== -1) {
            outData = dataObjectArray[i];
        }
    }
    return outData;
};

export const FinancialYearBreakMonth = (dataArray: FinancialRatioRow[], year: number) => {
    let financialYear = null;
    for (let i = 0; i <= dataArray.length; i++) {
        if (
            dataArray[i] &&
            dataArray[i].financialYear &&
            dataArray[i].financialYear.split('-')[0] === year.toString()
        ) {
            financialYear = dataArray[i].financialYear;
        }
    }

    let breakMonth = null; // let´s say full year if missing
    if (financialYear && financialYear.indexOf(':') !== -1) {
        breakMonth = financialYear.split(':')[1]; // get a year
        breakMonth = breakMonth.split('-')[1]; // get month (skipping day for now, not sure if it is needed)
    }

    return breakMonth;
};

export const IsFutureDate = (dateString: string) => {
    var now = new Date();
    now.setDate(now.getDate() + 30);
    return new Date(dateString) > now;
};

export const IsFutureYear = (year: string) => {
    var now = new Date();
    year = year.split(':')[0];
    year = year.split('-')[0];
    year = year.trim();
    return year > now.getFullYear().toString();
};

export const IsNewSinceLastLogin = (lastLogin: Date | null, dateString: string) => {
    const compare = lastLogin?.toISOString() || '1970-01-01T00:00:00.001Z';
    return dateString.substring(0, 10) >= compare.substring(0, 10);
};

export const IsNewSinceTwoDays = (dateString: string | null) => {
    if (dateString === null) {
        return false;
    }
    let today = new Date();
    let twoDaysAgo = today.setDate(today.getDate() - 2);
    return new Date(dateString).getTime() > twoDaysAgo;
};

export const GetMonthBeforeCurrent = () => {
    return new Date().getMonth();
};

export const SortByNumber = (columnIndex: number) => {
    return function (a: RowTableRow, b: RowTableRow) {
        let aNumber = '-999999999999';
        let bNumber = '-999999999999';

        if (typeof a.cells[columnIndex].text === 'string') {
            aNumber = a.cells[columnIndex].text.toString();
        }
        if (typeof b.cells[columnIndex].text === 'string') {
            bNumber = b.cells[columnIndex].text.toString();
        }
        // let aNumber: string = typeof a.cells[columnIndex].text === 'string' ? a.cells[columnIndex].text : '-9999999999';
        // let bNumber = typeof b.cells[columnIndex].text === 'string' ? b.cells[columnIndex].text : '-9999999999';

        if (aNumber === null) {
            aNumber = '-999999999999';
        }

        if (bNumber === null) {
            bNumber = '-999999999999';
        }

        let aSign = Math.sign(parseInt(aNumber));
        let bSign = Math.sign(parseInt(bNumber));

        aNumber = aNumber.replace(/\D/g, '');
        bNumber = bNumber.replace(/\D/g, '');

        if (aSign === -1) {
            aNumber = '-' + aNumber;
        }

        if (bSign === -1) {
            bNumber = '-' + bNumber;
        }

        let aParsedNumber = parseInt(aNumber);
        let bParsedNumber = parseInt(bNumber);

        if (isNaN(aParsedNumber)) {
            aParsedNumber = -999999999999;
        }

        if (isNaN(bParsedNumber)) {
            bParsedNumber = -999999999999;
        }

        if (aParsedNumber < bParsedNumber) {
            return -1;
        }
        if (aParsedNumber > bParsedNumber) {
            return 1;
        }

        return 0;
    };
};

export const SortByTextAndNull = (columnIndex: number) => {
    return function (a: RowTableRow, b: RowTableRow) {
        let aText = a.cells[columnIndex].text;
        let bText = b.cells[columnIndex].text;

        if (aText == null) {
            aText = '-';
        }
        if (bText == null) {
            bText = '-';
        }

        if (
            typeof aText === 'object' &&
            aText.props &&
            aText.props.children &&
            typeof aText.props.children === 'string'
        ) {
            aText = aText.props.children;
        }

        if (
            typeof bText === 'object' &&
            bText.props &&
            bText.props.children &&
            typeof bText.props.children === 'string'
        ) {
            bText = bText.props.children;
        }

        if (aText < bText) {
            return -1;
        }
        if (aText > bText) {
            return 1;
        }
        return 0;
    };
};

export const DecimalToPercent = (value: number | undefined) => {
    if (value === undefined) {
        return undefined;
    }
    value = value * 100;
    return value;
};

export const RoundIfExceeds100Percent = (value: number | string) => {
    if (typeof value === 'number' && (value >= 100 || value <= -100)) {
        return Math.round(value);
    }
    return value;
};

export const RoundDecimals = (value: number) => {
    return Math.round(value);
};

export const Tkr = (value: number) => {
    return (value = value / 1000);
};

export const FactorThousandForCharts = (value: number) => {
    return (value = value * 1000);
};

export const FactorHundredForCompare = (value: number) => {
    if (typeof value !== 'number') {
        return '-';
    }
    return (value = value * 100);
};

export const CalculateInterestCoverageRatio = (value: number | null) => {
    if (value === null || value === 0) {
        return '-';
    }
    return value;
};

export const CalculateOfficialInterestCoverageRatio = (value: number | null) => {
    if (value == null || value === 0) {
        return '-';
    }
    return value;
};
