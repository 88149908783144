import TokenState, { initialAuthState } from '../../Types/Context/TokenState';

const storageName = 'userState';

const TokenStateService = {
    GetParsedStorageData: (): TokenState => {
        const data = localStorage.getItem(storageName);
        if (data) {
            return JSON.parse(data);
        }
        return initialAuthState;
    },
    SaveStorageData: (data: TokenState) => {
        localStorage.setItem(storageName, JSON.stringify(data));
    },
    GetAccessToken: (): string => {
        const data = TokenStateService.GetParsedStorageData();
        return data?.token ?? '';
    },
    GetRefreshToken: (): string => {
        const data = TokenStateService.GetParsedStorageData();
        return data?.refreshToken ?? '';
    },
    UpdateToken: (token: string, refreshToken: string, isSignIn: boolean) => {
        const sessionData = TokenStateService.GetParsedStorageData();
        let tokenExpireTimestamp = null;
        try {
            tokenExpireTimestamp = JSON.parse(atob(token.split('.')[1])).exp;
        } catch {
            console.warn('Could not extract timestamp from token.');
        }
        let toUpdate = {
            ...sessionData,
            isAuthenticated: true,
            token,
            tokenExpireTimestamp,
            refreshToken,
        };
        if (isSignIn) {
            let loginValidUntil = new Date();
            loginValidUntil.setDate(loginValidUntil.getDate() + 1);
            toUpdate = { ...toUpdate, loginValidUntil };
        }
        localStorage.setItem(storageName, JSON.stringify(toUpdate));
    },
    ClearAll: () => {
        localStorage.removeItem(storageName);
    },
    UpdateAuthenticationState: (newState: Partial<TokenState>, callback?: () => void) => {
        const sessionData = TokenStateService.GetParsedStorageData();
        localStorage.setItem(storageName, JSON.stringify({ ...sessionData, ...newState }));
        callback?.();
    },
};

export default TokenStateService;
