import React from 'react';
import Texts from '../../Functions/Texts.json';
import {
    ColumnBasedTableProps,
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../NewColumnBasedTable';

import GroupOverviewTableRow from './Types/GroupOverviewTableRow';
import { FormatFraction } from '../../Functions/FormattingTS';

// This is done to fix the generic parameter, so that it doesn't need to be entered in every row.
const TableRow: (
    props: TableRowProps<GroupOverviewTableRow>,
) => TableRowInstance<GroupOverviewTableRow> = TableRowBase;

const GroupOverviewAlternativeTable = ({
    children,
    ...props
}: ColumnBasedTableProps<GroupOverviewTableRow>) => {
    return (
        <NewColumnBasedTable<GroupOverviewTableRow> {...props}>
            {children as any /* it works */}
            <TableRow
                className='line-under'
                label={Texts.profitandloss__revenue}
                selector={(d) => d.revenue}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__directCost}
                selector={(d) => d.directCost}
            />
            <TableRow
                className='summary bold'
                label={Texts.profitandloss__grossProfit}
                selector={(d) => d.grossProfit}
            />
            <TableRow className='empty-row' />
            <TableRow
                className='bold'
                label={Texts.profitandloss_grossProfitMargin}
                selector={(d) => FormatFraction(d.grossProfit, d.revenue)}
            />
            <TableRow className='empty-row' />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__otherOperatingExpensesAggregated}
                selector={(d) => d.otherOperatingExpensesAggregated}
            />
            <TableRow
                className=''
                label=''
                selector={(d) => FormatFraction(d.otherOperatingExpensesAggregated, d.revenue)}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__personnelCosts}
                selector={(d) => d.personnelCosts}
            />
            <TableRow
                className=''
                label=''
                selector={(d) => FormatFraction(d.personnelCosts, d.revenue)}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__depreciationAndWritedowns}
                selector={(d) => d.depreciationAndWritedowns}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__sumOfFinancialPosts}
                selector={(d) => d.sumOfFinancialPosts}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__currentProfitEarningBeforeTax}
                selector={(d) => d.currentProfitEarningBeforeTax}
            />
            <TableRow className='empty-row' />
            <TableRow
                className='summary bold'
                label={Texts.group_overview.net_profit_label}
                selector={(d) => d.netProfit}
            />
            <TableRow
                className='bold'
                label={Texts.profitandloss_profitMargin}
                selector={(d) => FormatFraction(d.netProfit, d.revenue)}
            />
        </NewColumnBasedTable>
    );
};

export default GroupOverviewAlternativeTable;
