import React from "react";
import { NavLink } from "react-router-dom";

type ViewOption = {
  link: string;
  text: string;
  className: string;
};

type ViewOptionsProps = {
  viewOptions: ViewOption[];
};

const ViewOptions = ({ viewOptions }: ViewOptionsProps) => (
  <div className="top-buttons">
    {viewOptions &&
      viewOptions.map(
        (action) =>
          action.link && (
            <NavLink
              className={action.className}
              key={action.link}
              to={action.link}
            >
              <div className="mask"></div>
              {action.text}
            </NavLink>
          )
      )}
  </div>
);

export default ViewOptions;
