import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import CompanyDetails from '../../Types/Api/Response/CompanyDetails';

type GetCompanyListRequestProps = {
    organizationId: string | null | undefined;
    licenseChoiceQueryString: string | undefined;
};

const useGetCompanyDetailsQuery = (
    { organizationId, licenseChoiceQueryString }: GetCompanyListRequestProps,
    options?: UseQueryOptions<CompanyDetails>,
) => {
    return useQuery(
        ['companyDetails', organizationId, licenseChoiceQueryString],
        async () =>
            axiosInstance.get<CompanyDetails>(
                `corporatePortal/CompanyDetails/${organizationId}?${licenseChoiceQueryString}`,
            ),
        {
            enabled: !!organizationId && !!licenseChoiceQueryString,
            ...options,
        },
    );
};
export default useGetCompanyDetailsQuery;
