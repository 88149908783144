import local from './local.config';
import development from './development.config';
import production from './production.config';
import stage from './stage.config';

export type Configuration = {
    apiBase: string;
    bankId: {
        showPersonalNumberInput: boolean;
    };
};

const configuration = process.env.REACT_APP_CONFIGURATION;

let config: Configuration = {
    apiBase: '',
    bankId: {
        showPersonalNumberInput: false,
    },
};

if (configuration === undefined || configuration === 'local') {
    config = local;
}

if (configuration === 'development') {
    config = development;
}

if (configuration === 'stage') {
    config = stage;
}

if (configuration === 'production') {
    config = production;
}

export default config;
