export type UserRegistrationToken = {
    user: string;
    engagements: string;
};

export type AuthenticationTokenResponse = {
    tokenType: string;
    idToken: string;
    accessToken: string;
    refreshToken: string;
};

export type BankIDCollectResponse = {
    status: string;
    hint: string;
    animatedQrCode: string;
    authenticationToken: AuthenticationTokenResponse;
    userRegistrationToken: UserRegistrationToken;
};

export type InitialResponse = {
    orderRef: string;
    autoStartToken: string;
    animatedQrCode: string;
};

export const InitialBankIDCollectResponse: BankIDCollectResponse = {
    status: 'pending',
    hint: '',
    animatedQrCode: '',
    authenticationToken: {
        tokenType: '',
        idToken: '',
        accessToken: '',
        refreshToken: '',
    },
    userRegistrationToken: {
        user: '',
        engagements: '',
    },
};
