import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Texts from '../Functions/Texts.json';
import logo from '../Assets/Images/top-bar-mynumbers-logo.svg';
import LicenseChoice from './LicenseChoice';
import {
    userAccountingFirmsState,
    userCreditorsState,
    userFirstNameState,
    userHasLicenseState,
    userLastNameState,
} from '../Core/State/UserState';
import { useRecoilValue } from 'recoil';
import useAuthentication from '../Core/Authentication/useAuthentication';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';

const TopBar = () => {
    const [licenseChoiceOpen, setLicenseChoiceOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const hasLicense = useRecoilValue(userHasLicenseState);
    const accountingFirms = useRecoilValue(userAccountingFirmsState);
    const creditors = useRecoilValue(userCreditorsState);
    const firstName = useRecoilValue(userFirstNameState);
    const lastName = useRecoilValue(userLastNameState);
    const { logout } = useAuthentication();

    const hasMultipleLicenses =
        (hasLicense ? 1 : 0) + accountingFirms.length + creditors.length > 1;

    const title = licenseChoice.type === 'sme' ? Texts.licenseChoiceSMEHeader : licenseChoice.title;
    const userTitle = `\u00A0${firstName}\u00A0${lastName}\u00A0 - \u00A0${title}`;

    return (
        <div className='top-bar'>
            <Link to='/'>
                <div className='logo' style={{ backgroundImage: `url(${logo})` }}></div>
            </Link>
            <div className='top-bar-logout'>
                <span
                    className='license-switch'
                    onClick={() => setLicenseChoiceOpen(!licenseChoiceOpen)}
                >
                    {userTitle}
                    <div className='mask'></div>
                </span>

                <span
                    className='logout'
                    onClick={() => logout(Texts.you_are_now_logged_out__LogoutMessage)}
                >
                    {Texts.log_out__TopBarLogOutButton}
                </span>

                {licenseChoiceOpen && (
                    <div className='user-menu'>
                        <Link
                            to='/settings/'
                            className='settings-link'
                            onClick={() => setLicenseChoiceOpen(false)}
                        >
                            <div className='settings-icon' />
                            <span>{Texts.settings.title}</span>
                        </Link>
                        {hasMultipleLicenses && (
                            <div className='license-switch-choice'>
                                <p>{Texts.licenseChoiceHeader}</p>
                                <LicenseChoice
                                    choiceChangeCallback={() => setLicenseChoiceOpen(false)}
                                ></LicenseChoice>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopBar;
