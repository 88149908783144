import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import { add, endOfMonth, format } from 'date-fns';
import { Dialog } from '../../Components/Dialog';
import BudgetPeriodPicker from './BudgetPeriodPicker';
import { SavedBudget } from '../../Functions/Budget';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import useBudgetRequests from '../../Api/Budget/useBudgetRequests';

type Props = {
    budgetPeriod: string;
    budgetDirty: boolean;
    budget: SavedBudget;
    organizationId: string;
};

const BudgetPeriod = ({ budgetPeriod, budgetDirty, budget, organizationId }: Props) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [selectedPeriod, setSelectedPeriod] = useState<Date | null>();
    const { saveBudget, isLoading: isSaving } = useBudgetRequests();

    const updateBudgetYear = () => {
        if (selectedPeriod === null) return;

        const yearFrom = format(selectedPeriod!, 'yyyy-MM-dd');
        const yearTo = format(endOfMonth(add(selectedPeriod!, { months: 11 })), 'yyyy-MM-dd');
        const financialYearFrom = `${yearFrom}T00:00:00`;
        const financialYearTo = `${yearTo}T00:00:00`;
        const financialYear = `${yearFrom} : ${yearTo}`;
        const yearlyPnl: ProfitAndLossRow = {
            ...budget.yearlyProfitAndLoss,
            financialYearFrom,
            financialYearTo,
            financialYear,
            period: yearFrom + 'T00:00:00',
        };

        const b: SavedBudget = {
            name: budget.name,
            id: budget.id,
            modified: budget.modified,
            active: budget.active,
            period: format(selectedPeriod!, 'yyyy-MM-dd') + 'T00:00:00',
            yearlyProfitAndLoss: yearlyPnl,
            monthlyProfitAndLoss: budget.monthlyProfitAndLoss.map((pnl, months) => ({
                ...pnl,
                period:
                    format(endOfMonth(add(selectedPeriod!, { months })), 'yyyy-MM-dd') +
                    'T00:00:00',
                financialYearFrom,
                financialYearTo,
                financialYear,
            })),

            copyYear: budget.copyYear,
        };

        saveBudget(b, organizationId, () => setIsEditing(false));
    };

    return (
        <div id='budget-period'>
            <span className='title'>{Texts.budget.period.title}</span>
            <div>
                <span>
                    {format(new Date(budgetPeriod), 'yyyy-MM')} -{' '}
                    {format(add(new Date(budgetPeriod), { years: 1, days: -1 }), 'yyyy-MM')}
                </span>
                <button
                    className={`auto-tooltip ${budgetDirty ? 'disabled' : ''}`}
                    onClick={() => {
                        if (!budgetDirty) {
                            setIsEditing(true);
                        }
                    }}
                >
                    {Texts.budget.period.change}
                    {budgetDirty && (
                        <span className='tooltip-message'>{Texts.budget.period.disabled}</span>
                    )}
                </button>
            </div>
            <Dialog
                open={isEditing}
                header={Texts.budget.period.change}
                ok={updateBudgetYear}
                cancel={() => setIsEditing(false)}
            >
                <BudgetPeriodPicker
                    period={budgetPeriod}
                    organizationId={organizationId}
                    budget={budget}
                    isSaving={isSaving}
                    periodSelected={setSelectedPeriod}
                />
            </Dialog>
        </div>
    );
};

export default BudgetPeriod;
