import { SavedBudget, fixAggregatedValues } from '../../Functions/Budget';
import usePutBudgetMutation from './usePutBudgetMutation';
import { useQueryClient } from 'react-query';
import useDeleteBudgetMutation from './useDeleteBudgetMutation';

const useBudgetRequests = () => {
    const { mutate: putMutate, isLoading: putIsLoading } = usePutBudgetMutation();
    const { mutate: deleteMutate, isLoading: deleteIsLoading } = useDeleteBudgetMutation();
    const queryClient = useQueryClient();
    const invalidateQueries = () => {
        queryClient.invalidateQueries(['MyBudgets']);
        queryClient.invalidateQueries(['ActiveBudgets']);
    };

    const saveBudget = (budget: SavedBudget, organizationId: string, onSuccess?: () => void) => {
        const fixedBudget = fixAggregatedValues(budget);
        putMutate(
            { budget: fixedBudget, organizationId },
            {
                onSuccess: () => {
                    invalidateQueries();
                    onSuccess?.();
                },
            },
        );
    };

    const deleteBudget = (budget: SavedBudget, organizationId: string) => {
        deleteMutate(
            { budget, organizationId },
            {
                onSuccess: invalidateQueries,
            },
        );
    };

    const isLoading = putIsLoading || deleteIsLoading;

    return { saveBudget, deleteBudget, isLoading };
};

export default useBudgetRequests;
