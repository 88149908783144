import MonthPicker, { Month } from '../InputFields/MonthPicker';
import Texts from '../../Functions/Texts.json';

export type MonthRange = {
    from: Month;
    to: Month;
};

type Props = {
    monthRange: MonthRange;
    setMonthRange: (newValue: MonthRange) => void;
};

const MonthRangePicker = ({ monthRange, setMonthRange }: Props) => {
    const updateSelectedMonthRange = (month: Month, fromOrTo: 'from' | 'to') => {
        if (
            month.year < monthRange.from.year ||
            (monthRange.from.year === month.year && month.month < monthRange.from.month)
        ) {
            setMonthRange({ ...monthRange, from: month });
        } else if (
            month.year > monthRange.to.year ||
            (monthRange.to.year === month.year && month.month > monthRange.to.month)
        ) {
            setMonthRange({ ...monthRange, to: month });
        } else if (fromOrTo === 'from') {
            setMonthRange({ ...monthRange, from: month });
        } else {
            setMonthRange({ ...monthRange, to: month });
        }
    };

    return (
        <>
            <MonthPicker
                title={Texts.group_overview.fromMonth}
                value={monthRange.from}
                setValue={(m) => updateSelectedMonthRange(m, 'from')}
            />
            <MonthPicker
                title={Texts.group_overview.toMonth}
                value={monthRange.to}
                setValue={(m) => updateSelectedMonthRange(m, 'to')}
            />
        </>
    );
};

export default MonthRangePicker;
