import { format, isPast } from 'date-fns';
import React, { useState } from 'react';
import { LineChart } from './LineChart';
import Texts from '../Functions/Texts.json';
import { sortBy } from '../Functions/Lists';

type Cashflow = {
    financialYearFrom: string;
    financialYearTo: string;
    financialYear: string;
    period: string;
    bankOpeningBalance: number;
    totalCashFlowAndLiquidity: number;
};
export type CashflowChartProps = {
    cashflow: Cashflow[] | undefined;
};
export const CashflowChart = ({ cashflow }: CashflowChartProps) => {
    const [selectedCashflowYear, setSelectedCashflowYear] = useState(0);

    const sortedCashflow = cashflow && sortBy(cashflow, 'period');

    const availableYearsCashflowChart = sortedCashflow && [
        ...new Set(
            sortedCashflow
                .filter((m) => isPast(new Date(m.financialYearFrom)))
                .map((m) => m.financialYear)
                .reverse(),
        ),
    ];
    const selectedCashFlow = sortedCashflow?.filter(
        (m) =>
            m.financialYear === availableYearsCashflowChart![selectedCashflowYear] &&
            isPast(new Date(m.period)),
    );

    const cashflowDataMissing = sortedCashflow?.length === 0;
    const cashflowChartIsLoading = !availableYearsCashflowChart || !selectedCashFlow;

    return (
        <div className='chart-box legends-as-legends '>
            {!cashflowChartIsLoading && !cashflowDataMissing && (
                <>
                    <h4>{Texts.charts.cashflow}</h4>
                    <div className='dropdowns-wrapper'>
                        <div className='secondary-legends'>
                            <span>{Texts.charts.choose_year}:</span>
                            {availableYearsCashflowChart.map((year, index) => (
                                <div
                                    onClick={() => setSelectedCashflowYear(index)}
                                    key={'cashflowDrop1' + year}
                                    className={`secondary-legends-button ${
                                        index === selectedCashflowYear && ' checked'
                                    }`}
                                >
                                    {year.split(':')[1]}
                                </div>
                            ))}
                        </div>
                    </div>
                    <LineChart
                        chartsAreLoading={cashflowChartIsLoading}
                        data={[
                            {
                                seriesName: Texts.charts.period_cashflow,
                                values: selectedCashFlow.map(
                                    (d) => d.totalCashFlowAndLiquidity - d.bankOpeningBalance,
                                ),
                                renderAs: 'column',
                            },
                            {
                                seriesName: Texts.charts.cash_and_bank,
                                values: selectedCashFlow.map((d) => d.totalCashFlowAndLiquidity),
                                renderAs: 'spline',
                            },
                        ]}
                        xLabels={selectedCashFlow.map((m) => format(new Date(m.period), 'MMM'))}
                    />
                </>
            )}
            {cashflowChartIsLoading && !cashflowDataMissing && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {!cashflowChartIsLoading && cashflowDataMissing && (
                <div className='chart-data-missing'>{Texts.chart_data_missing__InfoText}</div>
            )}
        </div>
    );
};
