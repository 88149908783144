// const SumMonthlyValues = (financialYear, value, dataArray) => {
//     let sum = 0;
//     for (let i = 0; i < dataArray.length; i++) {
//         if (dataArray[i].financialYear === financialYear) {
//             sum +=
//                 typeof dataArray[i][value] === 'string'
//                     ? parseFloat(dataArray[i][value])
//                     : dataArray[i][value];
//         }
//     }
//     return sum;
// };

// const FinancialYearStartMonth = (financialYear) => {
//     let firstMonthOfFinancialYear = '12';
//     if (financialYear.indexOf(':') !== -1) {
//         financialYear = financialYear.split(' : ')[0];
//     }
//     firstMonthOfFinancialYear = financialYear.split('-')[1];
//     return firstMonthOfFinancialYear;
// };

/** This entire component needs to be refactored into Typescript and using non-context
 *  state as well as updated API
 */

const CashflowWaterfall = () => {
    return null;
    // const params = useParams();
    // const waterFallChart = React.createRef();
    // const organizationNumber = params.organizationNumber;
    // const [displayFinancialYear, updateDisplayFinancialYear] = useState(null);
    // const [drilldownStarted, updateDrilldownStarted] = useState(false);
    // const [availableYears, updateAvailableYears] = useState([]);
    // const context = useContext(Context);
    // const [helpIsOpen, setHelpIsOpen] = useState(false);
    // const [viewRenderStarted, updateViewRenderStarted] = useState(0);
    // const [cashflowByPostTypeChart, updateCashflowByPostTypeChart] = useState(null);
    // const [cashflowByMonthChartConfig, updateCashflowByMonthChartConfig] = useState({});
    // const [chartsAreLoading, updateChartsAreLoading] = useState(true);
    // const [viewConfig, setViewConfig] = useState({
    //     requiredData: ['cashflow', 'companyDetails'],
    //     helpItems: ['cashflow'],
    //     tableOptions: [],
    //     viewOptions: [
    //         {
    //             link: `/company/${organizationNumber}/cashflow/`,
    //             text: Texts.yearly__ViewOptionButtonText,
    //             className: 'view-option-links by-year ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/cashflow-by-month/`,
    //             text: Texts.monthly__ViewOptionButtonText,
    //             className: 'view-option-links by-month ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/cashflow-waterfall/`,
    //             text: Texts.charts__ViewOptionButtonText,
    //             className: 'view-option-links by-chart current ',
    //         },
    //     ],
    //     viewTitle: Texts.cashflow__ViewTitle,
    //     translations: {},
    // });

    // const generalChartConfig = {
    //     baseFont: 'Lato',
    //     baseFontSize: '14',
    //     baseFontColor: '#160A25',
    //     legendItemFontBold: '0',
    //     legendItemFont: 'Lato',
    //     legendItemFontSize: '10',
    //     legendItemFontColor: '#160A25',
    //     legendNumRows: '2',
    //     legendNumColumns: '1',
    //     legendIconScale: '1.2',
    //     paletteColors: '#88DAD6,#27B6B0,#DA546C,#F9CE39',
    //     numberSuffix: Texts.chartNumberSuffix,
    //     decimalSeparator: ',',
    //     thousandSeparator: ' ',
    //     theme: 'fusion',
    //     showSumAtEnd: '0',
    //     positiveColor: '#27B6B0',
    //     negativeColor: '#DA546C',
    //     decimals: '2',
    //     numberScaleValue: '1000,1000,1000',
    //     numberScaleUnit: Texts.revenueChartNumberScaleUnit,
    //     formatNumberScale: '1',
    //     plotSpacePercent: '70',
    // };

    // const getLatestFinancialYear = (dataSet) => {
    //     if (dataSet.length === 0) {
    //         return;
    //     }
    //     const financialYear = dataSet.reduce(function (prev, current) {
    //         return prev.financialYear > current.financialYear ? prev : current;
    //     });
    //     updateDisplayFinancialYear(FinancialYearAsYearMonth(financialYear.financialYear));
    // };

    // const PrepareData = () => {
    //     if (drilldownStarted) {
    //         document.location.reload();
    //     }

    //     let cashflow = context.apiRawData.cashflow;
    //     let availableYears = [];

    //     updateAvailableYears(availableYears);

    //     if (!cashflow) {
    //         return;
    //     }

    //     if (params.financialyear != null) {
    //         updateDisplayFinancialYear(params.financialyear.trim());
    //     } else {
    //         getLatestFinancialYear(cashflow);
    //     }

    //     for (let i = 0; i < cashflow.length; i++) {
    //         if (availableYears.indexOf(cashflow[i].financialYear) === -1) {
    //             availableYears.push(cashflow[i].financialYear);
    //         }
    //     }

    //     if (displayFinancialYear) {
    //         updateViewRenderStarted(1);
    //         setupCashflowByMonthChart(cashflow);
    //         setupCashflowByPostTypeChart(cashflow);
    //         updateChartsAreLoading(false);
    //     }
    // };

    // const setupCashflowByMonthChart = (cashflow) => {
    //     let cashflowByMonthChartData = [];
    //     let cashflowByMonthLinkedData = [];
    //     let cashflowByMonthCalcualtedTotal = 0;
    //     let bankOpeningBalanceIncluded = 0;

    //     if (!displayFinancialYear) {
    //         return;
    //     }

    //     for (let i = 0; i < cashflow.length; i++) {
    //         if (
    //             FinancialYearAsYearMonth(cashflow[i].financialYear) === displayFinancialYear &&
    //             !IsFutureDate(cashflow[i].period)
    //         ) {
    //             const bankOpeningBalance = getBankOpeningBalance(cashflow[i].financialYear);

    //             if (bankOpeningBalanceIncluded === 0) {
    //                 cashflowByMonthChartData.push({
    //                     label: Texts.cashflow__bankOpeningBalance,
    //                     value: bankOpeningBalance,
    //                     tooltext:
    //                         Texts.cashflow__bankOpeningBalance +
    //                         '{br}' +
    //                         '<b>' +
    //                         Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                         ' ' +
    //                         FormatNumber(cashflow[i].bankOpeningBalance) +
    //                         Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                         '</b>',
    //                 });
    //                 bankOpeningBalanceIncluded = 1;
    //                 cashflowByMonthCalcualtedTotal += parseInt(bankOpeningBalance);
    //             }

    //             cashflowByMonthCalcualtedTotal += parseInt(cashflow[i].accumulatedCashFlow);

    //             cashflowByMonthChartData.push({
    //                 label: DatetimeAsYearMonth(cashflow[i].period),
    //                 value: cashflow[i].accumulatedCashFlow,
    //                 link: 'newchart-json-Level1AccumulatedCashFlow' + i + '',
    //                 tooltext:
    //                     DatetimeAsYearMonth(cashflow[i].period) +
    //                     '{br}' +
    //                     '<b>' +
    //                     Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                     ' ' +
    //                     FormatNumber(cashflow[i].accumulatedCashFlow) +
    //                     Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                     '</b>',
    //             });

    //             cashflowByMonthLinkedData.push({
    //                 id: 'Level1AccumulatedCashFlow' + i,
    //                 linkedchart: {
    //                     chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                         caption:
    //                             Texts.cashflow__CashflowChartCaption +
    //                             DatetimeAsYearMonth(cashflow[i].period),
    //                         subcaption: '',
    //                         rotateValues: '0',
    //                         plottooltext: '$label, $dataValue',
    //                         renderAt: 'chartDrilldownContainer',
    //                     }),
    //                     data: [
    //                         {
    //                             label: Texts.cashflow__cashFlowFromOperationsPL + '',
    //                             value: cashflow[i].cashFlowFromOperationsPL,
    //                             link: 'newchart-json-Level2CashFlowFromOperationsPL' + i + '',
    //                             tooltext:
    //                                 Texts.cashflow__cashFlowFromOperationsPL +
    //                                 '{br}' +
    //                                 '<b>' +
    //                                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                                 ' ' +
    //                                 FormatNumber(cashflow[i].cashFlowFromOperationsPL) +
    //                                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                                 '</b>',
    //                         },
    //                         {
    //                             label: Texts.cashflow__cashFlowFromCapitalBalanceSheet,
    //                             value: cashflow[i].cashFlowFromCapitalBalanceSheet,
    //                             link:
    //                                 'newchart-json-Level2CashFlowFromCapitalBalanceSheet' + i + '',
    //                             tooltext:
    //                                 Texts.cashflow__cashFlowFromCapitalBalanceSheet +
    //                                 '{br}' +
    //                                 '<b>' +
    //                                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                                 ' ' +
    //                                 FormatNumber(cashflow[i].cashFlowFromCapitalBalanceSheet) +
    //                                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                                 '</b>',
    //                         },
    //                         {
    //                             label: Texts.cashflow__cashFlowFromInvestments,
    //                             value: cashflow[i].cashFlowFromInvestments,
    //                             link: 'newchart-json-Level2CashFlowFromInvestments' + i + '',
    //                             tooltext:
    //                                 Texts.cashflow__cashFlowFromInvestments +
    //                                 '{br}' +
    //                                 '<b>' +
    //                                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                                 ' ' +
    //                                 FormatNumber(cashflow[i].cashFlowFromInvestments) +
    //                                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                                 '</b>',
    //                         },
    //                         {
    //                             label: Texts.cashflow__cashFlowFromFinancing,
    //                             value: cashflow[i].cashFlowFromFinancing,
    //                             link: 'newchart-json-Level2CashFlowFromFinancing' + i + '',
    //                             tooltext:
    //                                 Texts.cashflow__cashFlowFromFinancing +
    //                                 '{br}' +
    //                                 '<b>' +
    //                                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                                 ' ' +
    //                                 FormatNumber(cashflow[i].cashFlowFromFinancing) +
    //                                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                                 '</b>',
    //                         },
    //                         {
    //                             label: Texts.cashflow__accumulatedCashFlow,
    //                             value: cashflow[i].accumulatedCashFlow,
    //                             color: '#421C52',
    //                             issum: '1',
    //                             cumulative: '0',
    //                             tooltext:
    //                                 Texts.cashflow__accumulatedCashFlow +
    //                                 '{br}' +
    //                                 '<b>' +
    //                                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                                 ' ' +
    //                                 FormatNumber(cashflow[i].accumulatedCashFlow) +
    //                                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                                 '</b>',
    //                         },
    //                     ],
    //                     linkeddata: [
    //                         {
    //                             id: 'Level2CashFlowFromOperationsPL' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.cashflow__cashFlowFromOperationsPL,
    //                                         subcaption:
    //                                             Texts.change_by_month__CashflowChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     cashflow[i].financialYear,
    //                                     'cashFlowFromOperationsPL',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2CashFlowFromInvestments' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.cashflow__cashFlowFromInvestments,
    //                                         subcaption:
    //                                             Texts.change_by_month__CashflowChartSubCaption,
    //                                         type: 'waterfall2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     cashflow[i].financialYear,
    //                                     'cashFlowFromInvestments',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2CashFlowFromFinancing' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.cashflow__cashFlowFromFinancing,
    //                                         subcaption:
    //                                             Texts.change_by_month__CashflowChartSubCaption,
    //                                         type: 'waterfall2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     cashflow[i].financialYear,
    //                                     'cashFlowFromFinancing',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2CashFlowFromCapitalBalanceSheet' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption:
    //                                             Texts.cashflow__cashFlowFromCapitalBalanceSheet,
    //                                         subcaption:
    //                                             Texts.change_by_month__CashflowChartSubCaption,
    //                                         type: 'waterfall2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     cashflow[i].financialYear,
    //                                     'cashFlowFromCapitalBalanceSheet',
    //                                 ),
    //                             },
    //                         },
    //                     ],
    //                 },
    //             });
    //         }
    //     }

    //     cashflowByMonthChartData.push({
    //         label: Texts.cashflow__totalCashFlowAndLiquidity,
    //         value: cashflowByMonthCalcualtedTotal,
    //         color: '#421C52',
    //         issum: '1',
    //         cumulative: '0',
    //         tooltext:
    //             Texts.cashflow__totalCashFlowAndLiquidity +
    //             '{br}' +
    //             '<b>' +
    //             Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //             ' ' +
    //             FormatNumber(cashflowByMonthCalcualtedTotal) +
    //             Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //             '</b>',
    //     });

    //     let cashflowByMonthChart = Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //         caption: Texts.cashflow__CashflowChartCaption,
    //         subcaption:
    //             displayFinancialYear.substring(0, displayFinancialYear.length - 3) +
    //             ' ' +
    //             +Texts.per_month__CashflowChartSubCaption,
    //         yAxisname: Texts.sum__CashflowChartLabel + ' ' + Texts.sek__CashflowChartLabel,
    //         connectordashed: '1',
    //         sumlabel: Texts.total__CashflowChartLabel,
    //     });

    //     cashflowByMonthChartConfig.type = 'waterfall2d';
    //     cashflowByMonthChartConfig.width = '100%';
    //     cashflowByMonthChartConfig.height = '450';
    //     cashflowByMonthChartConfig.dataSource = {
    //         data: cashflowByMonthChartData,
    //         linkedData: cashflowByMonthLinkedData,
    //         chart: cashflowByMonthChart,
    //     };

    //     updateCashflowByMonthChartConfig(cashflowByMonthChartConfig);
    // };

    // const setupCashflowByPostTypeChart = (cashflow) => {
    //     if (!displayFinancialYear) {
    //         return;
    //     }

    //     let financialYear;

    //     for (let i = 0; i < cashflow.length; i++) {
    //         if (
    //             FinancialYearAsYearMonth(cashflow[i].financialYear) === displayFinancialYear &&
    //             !IsFutureDate(cashflow[i].period)
    //         ) {
    //             financialYear = cashflow[i].financialYear;
    //         }
    //     }

    //     let cashflowByPostTypeData = [
    //         {
    //             label: Texts.cashflow__bankOpeningBalance,
    //             value: getBankOpeningBalance(financialYear),
    //             tooltext:
    //                 Texts.cashflow__bankOpeningBalance +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(parseFloat(getBankOpeningBalance(financialYear))) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             //  by design no drilldown for this value
    //         },
    //         {
    //             label: Texts.cashflow__cashFlowFromOperationsPL,
    //             value: SumMonthlyValues(financialYear, 'cashFlowFromOperationsPL', cashflow),
    //             link: 'newchart-json-LinkedCashFlowFromOperationsPL',
    //             tooltext:
    //                 Texts.cashflow__cashFlowFromOperationsPL +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     parseFloat(
    //                         SumMonthlyValues(financialYear, 'cashFlowFromOperationsPL', cashflow),
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //         },
    //         {
    //             label: Texts.cashflow__cashFlowFromCapitalBalanceSheet,
    //             value: SumMonthlyValues(financialYear, 'cashFlowFromCapitalBalanceSheet', cashflow),
    //             link: 'newchart-json-LinkedCashFlowFromCapitalBalanceSheet',
    //             tooltext:
    //                 Texts.cashflow__cashFlowFromCapitalBalanceSheet +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     parseFloat(
    //                         SumMonthlyValues(
    //                             financialYear,
    //                             'cashFlowFromCapitalBalanceSheet',
    //                             cashflow,
    //                         ),
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //         },
    //         {
    //             label: Texts.cashflow__cashFlowFromInvestments,
    //             value: SumMonthlyValues(financialYear, 'cashFlowFromInvestments', cashflow),
    //             link: 'newchart-json-LinkedCashFlowFromInvestments',
    //             tooltext:
    //                 Texts.cashflow__cashFlowFromInvestments +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     parseFloat(
    //                         SumMonthlyValues(financialYear, 'cashFlowFromInvestments', cashflow),
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //         },
    //         {
    //             label: Texts.cashflow__cashFlowFromFinancing,
    //             value: SumMonthlyValues(financialYear, 'cashFlowFromFinancing', cashflow),
    //             link: 'newchart-json-LinkedCashFlowFromFinancing',
    //             tooltext:
    //                 Texts.cashflow__cashFlowFromFinancing +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     parseFloat(
    //                         SumMonthlyValues(financialYear, 'cashFlowFromFinancing', cashflow),
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //         },
    //         {
    //             label: Texts.cashflow__accumulatedCashFlow,
    //             value: SumMonthlyValues(financialYear, 'accumulatedCashFlow', cashflow),
    //             tooltext:
    //                 Texts.cashflow__accumulatedCashFlow +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     parseFloat(
    //                         SumMonthlyValues(financialYear, 'accumulatedCashFlow', cashflow),
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             color: '#421C52',
    //             issum: '1',
    //             cumulative: '0',
    //         },
    //     ];

    //     let cashflowByPostTypeLinkedData = [
    //         {
    //             id: 'LinkedCashFlowFromOperationsPL',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.cashflow__cashFlowFromOperationsPL,
    //                     subcaption: Texts.change_by_month__CashflowChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'cashFlowFromOperationsPL'),
    //             },
    //         },
    //         {
    //             id: 'LinkedCashFlowFromCapitalBalanceSheet',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.cashflow__cashFlowFromCapitalBalanceSheet,
    //                     subcaption: Texts.change_by_month__CashflowChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'cashFlowFromCapitalBalanceSheet'),
    //             },
    //         },
    //         {
    //             id: 'LinkedCashFlowFromFinancing',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.cashflow__cashFlowFromFinancing,
    //                     subcaption: Texts.change_by_month__CashflowChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'cashFlowFromFinancing'),
    //             },
    //         },
    //         {
    //             id: 'LinkedCashFlowFromInvestments',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.cashflow__cashFlowFromInvestments,
    //                     subcaption: Texts.change_by_month__CashflowChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'cashFlowFromInvestments'),
    //             },
    //         },
    //     ];

    //     let cashflowByPostTypeDataSource = {
    //         data: cashflowByPostTypeData,
    //         linkedData: cashflowByPostTypeLinkedData,
    //         chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //             caption: Texts.cashflow__CashflowChartCaption,
    //             subcaption:
    //                 displayFinancialYear.substring(0, displayFinancialYear.length - 3) +
    //                 ' ' +
    //                 Texts.per_account__CashflowChartSubCaption,
    //             yAxisname: Texts.sum__CashflowChartLabel + ' ' + Texts.sek__CashflowChartLabel,
    //             connectordashed: '1',
    //             sumlabel: Texts.total__CashflowChartLabel,
    //         }),
    //     };

    //     let cashflowByPostTypeChart = {
    //         type: 'waterfall2d',
    //         renderAt: 'chartDrilldownContainer',
    //         width: '100%',
    //         height: '400',
    //         dataFormat: 'json',
    //         dataSource: cashflowByPostTypeDataSource,
    //     };

    //     updateCashflowByPostTypeChart(cashflowByPostTypeChart);
    // };

    // const getMonthlyValues = (financialYear, value) => {
    //     let cashflow = context.apiRawData.cashflow;
    //     let data = [];
    //     let calculatedTotal = 0;
    //     for (let i = 0; i < cashflow.length; i++) {
    //         if (cashflow[i].financialYear === financialYear) {
    //             calculatedTotal += parseFloat(cashflow[i][value]);
    //             data.push({
    //                 label: DatetimeAsYearMonth(cashflow[i].period),
    //                 value: parseFloat(cashflow[i][value]),
    //                 tooltext:
    //                     DatetimeAsYearMonth(cashflow[i].period) +
    //                     '{br}' +
    //                     '<b>' +
    //                     Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                     ' ' +
    //                     FormatNumber(parseFloat(cashflow[i][value])) +
    //                     Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                     '</b>',
    //             });
    //         }
    //     }
    //     data.push({
    //         label: Texts.total__CashflowChartLabel,
    //         value: parseFloat(calculatedTotal),
    //         tooltext:
    //             Texts.total__CashflowChartLabel +
    //             '{br}' +
    //             '<b>' +
    //             Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //             ' ' +
    //             FormatNumber(parseFloat(calculatedTotal)) +
    //             Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //             '</b>',
    //         color: '#421C52',
    //         issum: '1',
    //         cumulative: '0',
    //     });

    //     return data;
    // };

    // const getBankOpeningBalance = (financialYear) => {
    //     let cashflow = context.apiRawData.cashflow;
    //     let balance = 0;
    //     for (let i = 0; i < cashflow.length; i++) {
    //         if (cashflow[i].financialYear === financialYear) {
    //             if (
    //                 DatetimeAsMonth(cashflow[i].period) ===
    //                 FinancialYearStartMonth(cashflow[i].financialYear)
    //             ) {
    //                 balance = parseFloat(cashflow[i].bankOpeningBalance);
    //             }
    //         }
    //     }
    //     return balance;
    // };

    // const resetCharts = () => {
    //     document.location.reload();
    // };

    // useEffect(() => {
    //     context.requireViewData(viewConfig.requiredData, organizationNumber);
    // }, []);

    // useEffect(() => {
    //     if (context.apiRawData.cashflow && viewRenderStarted === 0) {
    //         PrepareData();
    //     }
    // });

    // return (
    //     <>
    //         {helpIsOpen && (
    //             <Help closeHelp={() => setHelpIsOpen(false)} helpItems={viewConfig.helpItems} />
    //         )}
    //         <div className='chart-view-options'>
    //             <ViewOptions viewOptions={viewConfig.viewOptions} />
    //         </div>
    //         <div className='company-heading company-waterfall-heading'>
    //             <div className='chart-options'>
    //                 {availableYears &&
    //                     availableYears.map(
    //                         (year, index) =>
    //                             year && (
    //                                 <NavLink
    //                                     className={`chart-option ${
    //                                         FinancialYearAsYearMonth(year) === displayFinancialYear
    //                                             ? 'current'
    //                                             : ''
    //                                     }`}
    //                                     key={Rand()}
    //                                     onClick={() => [
    //                                         updateViewRenderStarted(0),
    //                                         updateDisplayFinancialYear(
    //                                             FinancialYearAsYearMonth(year),
    //                                         ),
    //                                     ]}
    //                                     to={`/company/${organizationNumber}/cashflow-waterfall/${FinancialYearAsYearMonth(
    //                                         year,
    //                                     )}`}
    //                                 >
    //                                     {FinancialYearAsYearMonth(year)}
    //                                 </NavLink>
    //                             ),
    //                     )}
    //             </div>
    //             <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
    //                 <div className='mask'></div>
    //                 {Texts.help__HelpButtonText}
    //             </div>
    //             <div className='reset-charts' onClick={() => resetCharts()}>
    //                 <div className='mask'></div>
    //                 {Texts.reset_chart__ButtonText}
    //             </div>
    //         </div>
    //         <div className='charts-wrapper'>
    //             <WaterfallChart
    //                 ref={waterFallChart}
    //                 chartConfig={cashflowByMonthChartConfig}
    //                 viewOptiopns={viewConfig.viewOptions}
    //                 chartsAreLoading={chartsAreLoading}
    //                 updateDrilldownStarted={updateDrilldownStarted}
    //                 initialBreakdownChart={cashflowByPostTypeChart}
    //             />
    //         </div>
    //     </>
    // );
};

export default CashflowWaterfall;
