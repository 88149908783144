import React from 'react';
import './Views/css/Views.css';
import './Components/css/Components.css';
import QueryClient from './Api/QueryClient';
import { RouterProvider } from 'react-router-dom';
import Router from './Router';
import { RecoilRoot } from 'recoil';
import SnackbarProvider from './Components/Snackbar/SnackbarProvider';

const App = () => (
    <SnackbarProvider>
        <QueryClient>
            <RecoilRoot>
                <RouterProvider router={Router()} fallbackElement={<p>Laddar</p>} />
            </RecoilRoot>
        </QueryClient>
    </SnackbarProvider>
);

export default App;
