import React, { useState, useEffect } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import AllCompaniesMenu from '../Components/AllCompaniesMenu';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import { SortByTextAndNull, IsNewSinceTwoDays } from '../Functions/Calculations';
import {
    DatetimeAsDate,
    FinancialYearAsMonthDate,
    FormatOrganizationNumber,
} from '../Functions/Formatting';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import { companiesState } from '../Core/State/CompanyListState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import CompanyList from '../Types/Api/Response/CompanyList';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';

const Companies = () => {
    const navigate = useNavigate();

    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );
    const { getCompanyList } = useGetCompanyListRequest();

    const setupTable = (tableData: CompanyList | null): RowTable => {
        if (!tableData) {
            return emptyTable;
        }

        let consentDateOrLatestVoucherText = 'Senaste ver.';
        if (licenseChoice.type === 'creditor') {
            consentDateOrLatestVoucherText = Texts.connected__CompaniesTableLabel;
        }

        let rows = [];
        let newTable: RowTable = {
            head: [
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.updated__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: consentDateOrLatestVoucherText,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.status__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small',
                },
                {
                    label: Texts.erp_program__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium',
                },
                {
                    label: Texts.connection_type__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small',
                },
                {
                    label: Texts.statement_date__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small',
                },
            ],
            rows: [],
            actions: [],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        let companies = tableData.companies;

        for (let i = 0; i < companies.length; i++) {
            const latestVoucher = companies[i].latestVoucher
                ? DatetimeAsDate(companies[i].latestVoucher!)
                : null;
            const latestUpload = companies[i].latestUpload
                ? DatetimeAsDate(companies[i].latestUpload)
                : null;
            const consentDateOrLatestVoucher =
                companies[i].consentDate !== null && licenseChoice.type === 'creditor'
                    ? DatetimeAsDate(companies[i].consentDate)
                    : DatetimeAsDate(latestVoucher);

            let extractorType = Texts.automatic__TableCellValue;
            if (companies[i].extractorType === 'SIE4') {
                extractorType = Texts.manual__TableCellValue;
            }
            if (companies[i].extractorType == null) {
                extractorType = '-';
            }

            let consentStatus: JSX.Element = <>{Texts.active__TableCellValue}</>;
            if (companies[i].consentStatus === 'DISABLED') {
                consentStatus = (
                    <span className='status-not-ok'>{Texts.disabled__TableCellValue}</span>
                );
            }
            if (companies[i].consentStatus === 'OK' && IsNewSinceTwoDays(latestUpload)) {
                consentStatus = <span className='status-ok'>{Texts.active__TableCellValue}</span>;
            }
            if (companies[i].consentStatus === 'OK' && latestUpload == null) {
                consentStatus = (
                    <span className='status-not-ok'>{Texts.data_missing__TableCellValue}</span>
                );
            }
            if (
                companies[i].consentStatus === 'OK' &&
                !IsNewSinceTwoDays(latestUpload) &&
                companies[i].latestUpload != null
            ) {
                consentStatus = (
                    <span className='status-not-ok'>{Texts.not_daily_update__TableCellValue}</span>
                );
            }

            let extractorInfo = '-';
            if (companies[i].program) {
                extractorInfo = companies[i].program;
            }

            let row: RowTableRow = {
                metaInformation: {
                    visible: true,
                },
                cells: [
                    {
                        text: companies[i].organizationName,
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'large',
                    },
                    {
                        text: FormatOrganizationNumber(companies[i].organizationNumber),
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'small right',
                    },
                    {
                        text: latestUpload ?? '-',
                        className: 'small right',
                    },
                    {
                        text: consentDateOrLatestVoucher ?? '-',
                        className: 'small right',
                    },
                    {
                        text: consentStatus,
                        className: 'small',
                    },
                    {
                        text: extractorInfo,
                        className: 'medium',
                    },
                    {
                        text: extractorType,
                        className: 'small',
                    },
                    {
                        text: FinancialYearAsMonthDate(companies[i].financialYearToDate) || '-',
                        className: 'small',
                    },
                ],
            };
            rows.push(row);
        }

        if (rows != null && rows.length > 0) {
            rows = rows.sort(SortByTextAndNull(0));
            newTable.metaInformation = tableData.metaInformation;
        }

        newTable.rows = rows;

        return newTable;
    };

    if (companyList && companyList.companies.length === 1) {
        navigate(`/company/${companyList.companies[0].organizationNumber}`);
    }

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(companyList);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['companies', 'dashboard']}
                />
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                toggleHelp={() => setHelpIsOpen(true)}
                viewTitle={Texts.dashboard_all_companies__DashboardViewTitle}
            />
            <AllCompaniesMenu />
            <RowBasedTableWrapper>
                <div className='row-based-table-wrapper-heading'>
                    {table.rows && (
                        <>
                            {licenseChoice.type === 'sme' && (
                                <p>
                                    {Texts.you_have__DashboardViewTitle} {table.rows.length}{' '}
                                    {Texts.company_count__DashboardViewTitle}.
                                </p>
                            )}
                            {licenseChoice.type === 'firm' && (
                                <p>
                                    {Texts.you_have_firm__DashboardViewTitle} {table.rows.length}{' '}
                                    {Texts.you_have_firm_companies__DashboardViewTitle}{' '}
                                    {licenseChoice.title}.
                                </p>
                            )}
                            {licenseChoice.type === 'creditor' && (
                                <p>
                                    {Texts.there_are__DashboardViewTitle} {table.rows.length}{' '}
                                    {Texts.companies_connected_to__DashboardViewTitle}{' '}
                                    {licenseChoice.title}.
                                </p>
                            )}
                        </>
                    )}
                </div>
                <RowBasedTable table={table} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default Companies;
