import React from 'react';
import Texts from '../Functions/Texts.json';
import { NavLink } from 'react-router-dom';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';

type Props = {
    companyDetails: CompanyDetails | null | undefined;
};

const Breadcrumbs = ({ companyDetails }: Props) => {
    if (companyDetails == null || companyDetails === undefined) {
        return null;
    }

    const { organizationNumber, organizationName } = companyDetails;

    let breadcrumbs = [];

    const dashboardCrumb = (
        <span key='breadcrumb-span-dashboard'>
            <NavLink key='breadcrumb-dashboard' to={'/'} end>
                {Texts.dashboard__BreadcrumbLinkText}
            </NavLink>
        </span>
    );

    if (document.location.pathname === '/new-companies/') {
        breadcrumbs.push(
            dashboardCrumb,
            <span key='breadcrumb-new-companies'>{Texts.new_companies__BreadcrumbLinkText}</span>,
        );
    }

    if (document.location.pathname === '/disabled-companies/') {
        breadcrumbs.push(
            dashboardCrumb,
            <span key='breadcrumb-disabled-companies'>
                {Texts.disabled_companies__BreadcrumbLinkText}
            </span>,
        );
    }

    if (document.location.pathname === '/pending-companies/') {
        breadcrumbs.push(
            dashboardCrumb,
            <span key='breadcrumb-pending-companies'>
                {Texts.pending_companies__BreadcrumbLinkText}
            </span>,
        );
    }

    if (document.location.pathname === '/') {
        breadcrumbs.push(
            <span key='breadcrumb-span-dashboard'>
                {Texts.dashboard__BreadcrumbLinkText} - {Texts.all_companies__BreadcrumbLinkText}
            </span>,
        );
    }

    if (
        document.location.pathname.indexOf('company') !== -1 ||
        document.location.pathname.indexOf('profit-and-loss') !== -1 ||
        document.location.pathname.indexOf('balance-sheets') !== -1 ||
        document.location.pathname.indexOf('financial-ratio') !== -1 ||
        document.location.pathname.indexOf('cashflow') !== -1
    ) {
        breadcrumbs.push(
            <span key='breadcrumb-span-dashboard'>
                <NavLink key='breadcrumb-nav-dashboard' to={'/'} end>
                    {Texts.dashboard__BreadcrumbLinkText} -{' '}
                    {Texts.all_companies__BreadcrumbLinkText}
                </NavLink>
            </span>,
        );
        if (document.location.pathname !== '/company/' + organizationNumber) {
            breadcrumbs.push(
                <span key='breadcrumb-span-company'>
                    <NavLink key='breadcrumb-nav-company' to={'/company/' + organizationNumber} end>
                        {organizationName}
                    </NavLink>
                </span>,
            );
        }
    }

    if (document.location.pathname === '/company/' + organizationNumber) {
        breadcrumbs.push(
            <span key='breadcrumb-span-company'>
                {organizationName} - {Texts.summary__BreadcrumbLinkText}
            </span>,
        );
    }

    if (document.location.pathname.indexOf('profit-and-loss') !== -1) {
        breadcrumbs.push(
            <span key='breadcrumb-span-pnl'>{Texts.profit_and_loss__BreadcrumbLinkText}</span>,
        );
    }

    if (document.location.pathname.indexOf('balance-sheets') !== -1) {
        breadcrumbs.push(
            <span key='breadcrumb-span-bs'>{Texts.balance_sheets__BreadcrumbLinkText}</span>,
        );
    }

    if (document.location.pathname.indexOf('financial-ratio') !== -1) {
        breadcrumbs.push(
            <span key='breadcrumb-span-fr'>{Texts.financial_ratio__BreadcrumbLinkText}</span>,
        );
    }

    if (document.location.pathname.indexOf('cashflow') !== -1) {
        breadcrumbs.push(
            <span key='breadcrumb-span-cf'>{Texts.cashflow__BreadcrumbLinkText}</span>,
        );
    }

    return <div className='breadcrumbs'>{breadcrumbs}</div>;
};

export default Breadcrumbs;
