import React, { memo } from 'react';
import Texts from '../../Functions/Texts.json';
import FinancialYear from './Types/FinancialYear';

type FinancialYearSelectorProps = {
    availableFinancialYears: FinancialYear[];
    selectedFinancialYears: string[];
    toggleSelectedFinancialYear: (year: string) => void;
};

const FinancialYearSelector = memo(
    ({
        availableFinancialYears,
        selectedFinancialYears,
        toggleSelectedFinancialYear,
    }: FinancialYearSelectorProps) => {
        return (
            <div>
                <h4>{Texts.report_generator.choose_financial_year}</h4>
                {availableFinancialYears.map((year) => (
                    <div key={'year' + year.label} className='report-generator-row'>
                        <label className='checkbox-container'>
                            <input
                                value={year.label}
                                className={'year-checkbox'}
                                type='checkbox'
                                checked={selectedFinancialYears.some((fy) => fy === year.label)}
                                onChange={() => toggleSelectedFinancialYear(year.label)}
                            />
                            <span className='checkmark'></span>
                            <div>{`${year.label} - ${Texts.report_generator.mynumbers}`}</div>
                        </label>
                    </div>
                ))}
            </div>
        );
    },
);

export default FinancialYearSelector;
