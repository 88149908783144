import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { useParams } from 'react-router-dom';
import { ColumnData, TableRow } from '../../Components/NewColumnBasedTable';
import { BalanceSheetsTable } from '../../Components/BalanceSheetsTable';
import { format, isPast, startOfMonth } from 'date-fns';
import { sortBy } from '../../Functions/Lists';
import { useRecoilValue } from 'recoil';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetBalanceSheets from '../../Api/Reports/useGetBalanceSheets';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetOrgId from '../../Functions/useGetOrgId';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const BalanceSheetsByMonth = () => {
    const { organizationNumber } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });

    const { data: balanceSheets } = useGetBalanceSheets(organizationId);

    const sorted = balanceSheets && sortBy(balanceSheets.data, 'period').reverse();
    const data = sorted
        ?.filter((d) => isPast(startOfMonth(new Date(d.period))))
        ?.map((b) => ({ ...b, columnClassName: 'my-numbers-data' }));

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['balance-sheets']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/balance-sheets/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'view-option-links by-year ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month current ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-accounts/`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts ',
                    },
                ]}
            />
            <BalanceSheetsTable
                columnWidth={170}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={true}
                data={data}
                csvExportFilename={`${Texts.balance_sheets_by_month__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                stepHeader={(first, last) => (
                    <>
                        {format(new Date(first.period), 'yyyy-MM-dd')}
                        {last && ' - ' + format(new Date(last.period), 'yyyy-MM-dd')}
                    </>
                )}
                style={{ marginRight: 90 }}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & { period: string }>
                    className='bold'
                    label={Texts.balance_sheets__ViewTitle}
                    selector={(d) => format(new Date(d.period), 'yyyy-MM-dd')}
                />
            </BalanceSheetsTable>
        </ErrorBoundary>
    );
};

export default BalanceSheetsByMonth;
