import { useMutation } from 'react-query';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import axiosInstance from '../../Core/Axios';
import UseMutationOptions from '../UseMutationOptions';

const useGetUserInfoMutation = (options?: UseMutationOptions<null, CorporatePortalUser>) =>
    useMutation(
        async () => axiosInstance.get<CorporatePortalUser>('corporatePortal/userinfo'),
        options,
    );

export default useGetUserInfoMutation;
