import { add, format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import {
    ProfitAndLossKey,
    Budget,
    updateBudget,
    linearDistribution,
    proRata,
} from '../../Functions/Budget';
import { range, sum } from '../../Functions/Lists';
import Texts from '../../Functions/Texts.json';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import BudgetRow, { BudgetRowProps } from './BudgetRow';
import BudgetUpdater from './BudgetUpdater';

export type BudgetTableProps<TBudget extends Budget> = {
    initialBudget: TBudget;
    budgetUpdated: (b: TBudget) => void;
    yearlyData: ProfitAndLossRow[];
    monthlyData: ProfitAndLossRow[];
    inputDisabled?: boolean;
};
export const BudgetTable = <TBudget extends Budget>({
    initialBudget,
    budgetUpdated,
    yearlyData,
    monthlyData,
    inputDisabled = false,
}: BudgetTableProps<TBudget>) => {
    const [expandAll, setExpandAll] = useState(false);
    const [budgetList, setBudgetList] = useState<TBudget[]>([initialBudget]);
    const [budgetIndex, setBudgetIndex] = useState(0);
    const budget = budgetList[budgetIndex];

    useEffect(() => {
        budgetUpdated(budget);
    }, [budget]);

    const selectedYear = new Date(budget.period);

    const formatMonth = (months: number) => {
        const result = add(selectedYear, { months });
        return format(result, 'MMM');
    };

    const nextBudget = (s: TBudget) => {
        setBudgetList([s, ...budgetList.slice(budgetIndex, 999)]);
        setBudgetIndex(0);
    };
    const resetYear = () => {
        nextBudget(initialBudget);
    };
    const updateCopyYear = (yearIndex: number) => {
        if (yearIndex === -1) {
            nextBudget({ ...budget, copyYear: undefined });
        } else {
            doCopyYear(yearIndex);
        }
    };
    const doCopyYear = (yearIndex: number) => {
        const newCopyYear = yearlyData[yearIndex];
        const newCopyMonths = monthlyData.filter(
            (m) => m.financialYear === newCopyYear.financialYear,
        );

        nextBudget({
            ...budget,
            copyYear: {
                yearlyData: newCopyYear,
                monthlyData: newCopyMonths,
            },
        });
    };

    const budgetUpdater: BudgetUpdater = (key, idx, val) => {
        let updated = updateBudget(budget, key, idx, val);
        const yearTotal = sum(updated.monthlyProfitAndLoss.map((m) => m[key]));
        updated = updateBudget(updated, key, 'year', yearTotal / 1000);
        nextBudget(updated);
    };
    const _linearDistribution = (key: ProfitAndLossKey) => {
        const updated = linearDistribution(budget, key);
        const yearTotal = sum(updated.monthlyProfitAndLoss.map((m) => m[key]));
        nextBudget(updateBudget(updated, key, 'year', yearTotal / 1000));
    };
    const _proRata = (key: ProfitAndLossKey) => {
        const updated = proRata(budget, key, budget.copyYear!);
        const yearTotal = sum(updated.monthlyProfitAndLoss.map((m) => m[key]));
        nextBudget(updateBudget(updated, key, 'year', yearTotal / 1000));
    };

    const rowProps: Omit<BudgetRowProps, 'field'> = {
        budget,
        setValue: budgetUpdater,
        linearDistribution: _linearDistribution,
        proRataDistribution: _proRata,
        forceExpand: expandAll,
        inputDisabled,
    };

    return (
        <>
            <div className='budget-action-list'>
                <button
                    onClick={() => setExpandAll(!expandAll)}
                    className={`fa-button ${expandAll ? 'fold' : 'unfold'} budget-action-button`}
                    style={{ marginRight: 'auto' }}
                >
                    {expandAll ? 'Fäll in alla' : 'Fäll ut alla'}
                </button>
                <select
                    onChange={(e) => updateCopyYear(parseInt(e.target.value))}
                    value={yearlyData.findIndex(
                        (y) => budget.copyYear?.yearlyData.financialYear === y.financialYear,
                    )}
                >
                    <option value={-1}>{Texts.budget.choose_copy_previous_values}</option>
                    {yearlyData.map((y, idx) => (
                        <option key={y.financialYear} value={idx}>
                            {format(new Date(y.financialYearFrom), 'yyyy-MM')} -{' '}
                            {format(new Date(y.financialYearTo), 'yyyy-MM')}
                        </option>
                    ))}
                </select>
                <button onClick={() => resetYear()} className='budget-action-button'>
                    {Texts.budget.reset_budget}
                </button>
                <button
                    className='fa-button rotate-left budget-action-button'
                    onClick={() => setBudgetIndex(budgetIndex + 1)}
                    disabled={budgetIndex + 1 === budgetList.length}
                >
                    Ångra
                </button>
                <button
                    className='fa-button-after rotate-right budget-action-button'
                    onClick={() => setBudgetIndex(budgetIndex - 1)}
                    disabled={budgetIndex === 0}
                >
                    Gör om
                </button>
            </div>
            <div className='budget-table-row'>
                <table className='budget-table'>
                    <colgroup>
                        <col span={1} />
                        <col style={{ width: 5 }} />
                        <col style={{ width: 60 }} />
                        <col span={12} style={{ width: 60 }} />
                        <col span={1} style={{ width: 80 }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th className='left-align'>{Texts.budget.budget_result}</th>
                            <th></th>
                            <th></th>
                            {range(12).map((months) => (
                                <th key={months}>{formatMonth(months + 12)}</th>
                            ))}
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <BudgetRow {...rowProps} field='revenue' />
                        <BudgetRow {...rowProps} field='otherOperatingIncome' />
                        <BudgetRow
                            {...rowProps}
                            field='totalOperatingIncome'
                            className='bold line-above'
                        />
                        <tr className='spacer'></tr>
                        <BudgetRow {...rowProps} negativeField={true} field='directCost' />
                        <BudgetRow {...rowProps} negativeField={true} field='costOfPremises' />
                        <BudgetRow {...rowProps} negativeField={true} field='sellingExpenses' />
                        <BudgetRow
                            {...rowProps}
                            negativeField={true}
                            field='administrativeExpenses'
                        />
                        <BudgetRow
                            {...rowProps}
                            negativeField={true}
                            field='otherOperatingExpenses'
                        />
                        <BudgetRow {...rowProps} negativeField={true} field='personnelCosts' />
                        <BudgetRow
                            {...rowProps}
                            negativeField={true}
                            field='depreciationAndWritedowns'
                        />
                        <BudgetRow
                            {...rowProps}
                            negativeField={true}
                            field='sumOfExpenses'
                            className='bold line-above'
                        />
                        <tr className='spacer'></tr>
                        <BudgetRow {...rowProps} field='ebit' className='bold' />
                        <tr className='spacer'></tr>
                        <BudgetRow {...rowProps} field='financialIncomes' />
                        <BudgetRow {...rowProps} negativeField={true} field='financialExpenses' />
                        <BudgetRow {...rowProps} field='sumOfFinancialPosts' className='bold' />
                        <BudgetRow
                            {...rowProps}
                            field='currentProfitEarningBeforeTax'
                            className='bold line-above'
                        />
                        <tr className='spacer'></tr>
                        <BudgetRow {...rowProps} field='extraordinaryIncomeAndExpenses' />
                        <BudgetRow {...rowProps} field='appropriations' />
                        <BudgetRow {...rowProps} field='tax' className='bold' />
                        <BudgetRow
                            {...rowProps}
                            field='netProfit'
                            label={Texts.netProfit_monthly}
                            className='bold line-above'
                        />
                    </tbody>
                </table>
            </div>
        </>
    );
};
