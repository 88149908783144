export const initialAuthState: TokenState = {
    token: null,
    tokenExpireTimestamp: null,
    refreshToken: null,
    isAuthenticated: false,
    loginValidUntil: null,
};

type TokenState = {
    token: string | null;
    tokenExpireTimestamp: number | null;
    refreshToken: string | null;
    isAuthenticated: boolean;
    loginValidUntil: Date | null;
};

export default TokenState;
