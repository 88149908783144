import ExternalFinancialRatioRow from '../Types/Api/Response/ExternalFinancialRatioRow';
import FinancialRatioRow from '../Types/Api/Response/FinancialRatioRow';

const MapExternalFinancialRatio = (row: ExternalFinancialRatioRow): FinancialRatioRow => ({
    financialYearFrom: row.financialYearFrom,
    financialYearTo: row.financialYearTo,
    organizationNumber: row.organizationNumber,
    period: '',
    financialYear: row.financialYear,
    t1_grossProfitMargin: row.grossProfitMargin,
    g1_returnOnEquity: row.returnOnEquity,
    g2_returnOnTotalCapital: row.returnOnTotalCapital,
    g3_interestPayable: row.debtToEquityRatio,
    g4_riskBufferTotalCapital: row.riskbufferTotalCapital,
    g5_operatingMargin: row.operatingMargin,
    g6_netMargin: row.netMargin,
    g7_netRevPerEmployee: row.turnoverPerEmployee,
    g9_solvency: row.solvency,
    g10_capitalTurnover: row.capitalTurnover,
    g11_workingCapital: row.workingCapital,
    g12_quickRatio: row.quickRatio,
    t3_interestCoverageRatio: row.interestCoverageRatio,
    t31_returnOnWorkingCapital: row.returnOnWorkingCapital,
    t26_degreeOfDebt: row.degreeOfDebt,
    t46_currentRatio: row.currentRatio,
    x1_returnOnOperationalCapital: row.returnOnOperationalCapital,
    x2_cashFlowFromOperation: row.cashFlowFromOperation,
    x3_accountsReceivableTurnover: row.accountsReceivableTurnover,
    x4_averagePaymentPeriodDays: row.averagePaymentPeriod_Days,
});

export default MapExternalFinancialRatio;
