import {
    faCheckCircle,
    faCircleExclamation,
    faInfoCircle,
    faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomContentProps, SnackbarContent, SnackbarContentProps, SnackbarKey } from 'notistack';
import React, { forwardRef, ReactNode } from 'react';
import SnackbarButtonDismiss from './SnackbarButtonDismiss';

export type SnackbarProps = {
    id: SnackbarKey;
    title?: ReactNode;
    message?: ReactNode;
    hideIcon?: boolean;
} & SnackbarContentProps &
    Omit<Partial<CustomContentProps>, 'color'>;

const getSnackbarIcon = (color: SnackbarContentProps['color']) => {
    switch (color) {
        case 'success':
            return faCheckCircle;
        case 'error':
            return faCircleExclamation;
        case 'warning':
            return faTriangleExclamation;
        case 'info':
            return faInfoCircle;
        default:
            return undefined;
    }
};

const Snackbar = forwardRef<HTMLDivElement, SnackbarProps>(
    ({ id, title, variant, color = 'info', message, hideIcon, ...rest }, ref) => {
        const snackbarIcon = getSnackbarIcon(color);
        return (
            <SnackbarContent
                ref={ref}
                color={color}
                className={`snackbar-content ${color}`}
                {...rest}
            >
                <div className='snackbar-message'>
                    {hideIcon !== true && snackbarIcon && <FontAwesomeIcon icon={snackbarIcon} />}
                    <div>
                        {title && <h5>{title}</h5>}
                        {message && <p>{message}</p>}
                    </div>
                </div>
                <div className='snackbar-dismiss'>
                    <SnackbarButtonDismiss snackbarKey={id} />
                </div>
            </SnackbarContent>
        );
    },
);

export default Snackbar;
