import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Texts from '../../Functions/Texts.json';
import axios from 'axios';
import './../css/SignIn.css';
import config from '../../settings/settings';
import * as QRCode from 'qrcode';
import { getObjectPartOfPayload } from '../../Functions/Functions';
import TokenStateService from '../../Functions/Storage/TokenStateService';
import {
    BankIDCollectResponse,
    InitialBankIDCollectResponse,
    InitialResponse,
    UserRegistrationToken,
} from '../../Types/Api/Response/Authentication';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import useAuthentication from '../../Core/Authentication/useAuthentication';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import settings from '../../settings/settings';
import RegisterView from './RegisterView';
import useUserInfoRequest from '../../Api/UserInfo/useUserInfoRequest';
import useGetSystemConfiguration from '../../Api/SystemConfiguration/useGetSystemConfiguration';

type SignInFormType = {
    personalNumber: string;
};

const SignIn = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<SignInFormType>();
    const { login, logout, signedOutMessage } = useAuthentication();
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const { data: systemConfiguration } = useGetSystemConfiguration();
    const showPersonalNumberInput = config.bankId.showPersonalNumberInput;
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCode, setQrCode] = useState<string | undefined>(undefined);
    const [cancelRef, setCancelRef] = useState('');
    const [cancelStatusOk, setCancelStatusOk] = useState(false);
    const userAgent = navigator.userAgent || navigator.vendor;
    const [loginOnSameDevice, setLoginOnSameDevice] = useState(
        /android/i.test(userAgent) || /iPhone|iPad/.test(userAgent),
    );
    const [unauthorized, setUnauthorized] = useState(false);
    const [forceLicenseChoice, setForceLicenseChoice] = useState<boolean | CorporatePortalUser>(
        false,
    );
    // Used to make it so that the logoutMessage is only shown once per logout
    const [showLogoutMessage, setShowLogoutMessage] = useState(true);
    const [userName, setUserName] = useState('');
    const [userInfo, setUserInfo] = useState<CorporatePortalUser | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { getUserDataAsync } = useUserInfoRequest();
    const monthlyPlus = systemConfiguration?.data
        ? systemConfiguration?.data.find((sc) => sc.key === 'monthlyPlus')?.value ?? ''
        : '';
    const yearlyPlus = systemConfiguration?.data
        ? systemConfiguration?.data.find((sc) => sc.key === 'yearlyPlus')?.value ?? ''
        : '';

    const onSubmit = async (formData: SignInFormType) => {
        setCancelStatusOk(false);
        setShowLogoutMessage(false);
        let personalNumber = getPersonalNumber(formData.personalNumber);
        if (personalNumber === undefined || personalNumber === '') {
            personalNumber = null;
        }
        setIsAuthenticating(true);
        try {
            const initialUserAuthResponse = await axios.post(
                `${config.apiBase}authentication/user/token`,
                {
                    personalNumber: personalNumber,
                },
            );
            const initialUserResponseData = initialUserAuthResponse.data as InitialResponse;
            const { orderRef, autoStartToken, animatedQrCode } = initialUserResponseData;
            if (loginOnSameDevice) {
                // On Android 6 and later and on iOS, this is the preferred URL syntax
                let url = 'https://app.bankid.com/';
                let androidVersion = /Android (\d+)/i.exec(navigator.userAgent)?.[1];
                if (androidVersion && parseInt(androidVersion, 10) < 6) {
                    // Only preferred on Android < 6
                    url = 'bankid:///';
                }
                // We need to add the "#" to prevent Safari from reloading
                let redirect = /ipad|iphone|ipod/i.test(navigator.userAgent)
                    ? encodeURIComponent(window.location.href + '#')
                    : null;
                window.location.href = `${url}?autostarttoken=${autoStartToken}&redirect=${redirect}`;
            }
            updateQrCode('pending', animatedQrCode, orderRef);

            let userAuthResponse = InitialBankIDCollectResponse;
            while (userAuthResponse.status === 'pending' && !cancelStatusOk) {
                await new Promise((r) => setTimeout(r, 2000));
                const res = await axios.put(
                    `${config.apiBase}authentication/user/token/${orderRef}`,
                    {
                        scope: '',
                        source: 'CorpPortal',
                    },
                );
                userAuthResponse = res.data as BankIDCollectResponse;
                updateQrCode(userAuthResponse.status, userAuthResponse.animatedQrCode);
            }
            if (userAuthResponse.status === 'register') {
                setIsAuthenticating(false);
                extractUserName(userAuthResponse.userRegistrationToken!);
                setUnauthorized(true);
                return;
            }
            if (userAuthResponse.status === 'failed') {
                setIsAuthenticating(false);
                if (userAuthResponse.hint === 'userCancel') {
                    setErrorMessage(Texts.bankIDLoginWasCancelled);
                } else if (userAuthResponse.hint === 'expiredTransaction') {
                    setErrorMessage(Texts.bankIDSessionExpired);
                } else {
                    setErrorMessage(Texts.bankIDGeneralErrorMessage);
                }
                return;
            }

            TokenStateService.UpdateToken(
                userAuthResponse.authenticationToken!.accessToken,
                userAuthResponse.authenticationToken!.refreshToken,
                true,
            );

            const authRes = await getUserDataAsync();
            setIsAuthenticating(false);
            if (authRes.type === 'success') {
                login(authRes.userInfo, authRes.license);
            } else if (authRes.type === 'unauthorized') {
                setUnauthorized(true);
                setUserInfo(authRes.userInfo);
                setUserName(authRes.userName);
            }
        } catch (error: any) {
            setIsAuthenticating(false);
            setErrorMessage(error?.errorMessage ?? Texts.bankIDGeneralErrorMessage);
        }
    };

    const extractUserName = (userRegistrationToken: UserRegistrationToken) => {
        const json = getObjectPartOfPayload(userRegistrationToken.user);
        const { name } = JSON.parse(json);
        setUserName(name);
    };

    const activateUser = (usrData: CorporatePortalUser, license: LicenseChoiceModel) => {
        setUnauthorized(false);
        setUserInfo(usrData);
        login(usrData, license);
    };

    const cancelActivation = () => {
        setErrorMessage('');
        setUserInfo(null);
        setForceLicenseChoice(false);
        setUnauthorized(false);
        logout('');
    };

    const getPersonalNumber = (personalNumber: string) => {
        if (personalNumber == null) {
            return null;
        }
        const _personalNumber = personalNumber.replace('-', '');
        if (_personalNumber.length === 10) {
            let prefix = '19';
            if (parseInt(_personalNumber.substr(0, 2), 10) < new Date().getFullYear() % 100) {
                // If no prefix is given, assume the person is younger than 100
                prefix = '20';
            }
            return prefix + _personalNumber;
        }
        return _personalNumber;
    };

    const updateQrCode = (status: string, animatedQrCode: string, orderRef?: string) => {
        if (orderRef) {
            setCancelRef(orderRef);
        }
        if (status !== 'pending' || loginOnSameDevice) {
            return;
        }
        setQrCode(animatedQrCode);
    };

    useEffect(() => {
        const qrOpts: QRCode.QRCodeRenderersOptions = {
            errorCorrectionLevel: 'L',
            margin: 1,
            color: {
                dark: '#000000FF',
                light: '#FFFFFFFF',
            },
            width: 200,
        };

        if (canvasRef.current && qrCode) {
            QRCode.toCanvas(canvasRef.current, qrCode, qrOpts);
        }
    }, [canvasRef, qrCode]);

    const cancelLogin = async (ref: string) => {
        const res = await axios.delete(`${settings.apiBase}authentication/user/token/${ref}`);
        if (res && res.status && res.status === 200) {
            setCancelStatusOk(true);
        }
    };

    const renderIsAuthenticating = () => {
        return (
            <div className='container waiting-container'>
                <h2 className='header view-header h2'>
                    {Texts.identifyYourselfInBankIdAppHeaderText}
                </h2>
                <div className='message info-message'>
                    {loginOnSameDevice
                        ? Texts.openBankIDInfoMessageText
                        : Texts.openBankIDQRInfoMessageText}
                </div>
                {qrCode ? (
                    <canvas className='qr-code-img' ref={canvasRef} />
                ) : (
                    <div className='spinner-wrapper'>
                        <div className='spinner'></div>
                    </div>
                )}
                <p>
                    <button
                        className='link'
                        onClick={() => {
                            cancelLogin(cancelRef);
                            setQrCode('');
                        }}
                    >
                        {Texts.bankIDLoginCancel}
                    </button>
                </p>
                <div className='bank-id-stand-alone-logo'></div>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form className='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='input-container sign-in-input-container'>
                    {errorMessage && !cancelStatusOk && (
                        <div className='message error-message'>{errorMessage}</div>
                    )}
                    {errorMessage && cancelStatusOk && (
                        <div className='message error-message'>{Texts.bankIDLoginWasCancelled}</div>
                    )}

                    {showPersonalNumberInput && (
                        <>
                            <label className='label personal-number-input-label'>
                                {Texts.personalNumberInputPlaceholderText}
                            </label>
                            {errors && errors.personalNumber && (
                                <span className='message validation-message error-message'>
                                    {errors.personalNumber.message}
                                </span>
                            )}
                            <input
                                className='input personal-number-input bank-id-input'
                                disabled={isAuthenticating}
                                type='text'
                                placeholder={Texts.personalNumberInputPlaceholderText}
                                {...register('personalNumber', {})}
                            />
                        </>
                    )}

                    <button
                        className='button sign-in-button bank-id-button'
                        type='submit'
                        value='Submit'
                        disabled={isAuthenticating}
                    >
                        {Texts.bankIDLoginButtonText}
                        <div className='button-icon'></div>
                    </button>

                    {loginOnSameDevice ? (
                        <button
                            onClick={() => {
                                setLoginOnSameDevice(false);
                                setQrCode('');
                            }}
                            className='link bank-id-device-change'
                            type='submit'
                            value='Submit'
                            disabled={isAuthenticating}
                        >
                            {Texts.openBankIDOnOtherDeviceButtonText}
                            <div className='button-icon'></div>
                        </button>
                    ) : (
                        <button
                            onClick={() => {
                                setLoginOnSameDevice(true);
                                setQrCode('');
                            }}
                            className='link bank-id-device-change'
                            type='submit'
                            value='Submit'
                            disabled={isAuthenticating}
                        >
                            {Texts.openBankIDOnSameDeviceButtonText}
                            <div className='button-icon'></div>
                        </button>
                    )}
                </div>
            </form>
        );
    };

    return (
        <>
            {showLogoutMessage && signedOutMessage && signedOutMessage !== '' && (
                <div className='signed-out-message'>{signedOutMessage}</div>
            )}
            {unauthorized && (
                <RegisterView
                    userName={userName}
                    userInfo={userInfo}
                    activateUser={activateUser}
                    cancelActivation={cancelActivation}
                />
            )}
            {forceLicenseChoice === false && !unauthorized && (
                <div id='sign-in-content'>
                    <div id='sign-in-top-bar'>
                        <div id='sign-in-intro'>
                            <h2>{Texts.corporate_portal__descriptive_heading}</h2>
                            <div dangerouslySetInnerHTML={{ __html: Texts.signin.intro }} />
                        </div>
                        <div id='sign-in'>
                            <div className='form-wrapper'>
                                {isAuthenticating ? renderIsAuthenticating() : renderForm()}
                            </div>
                        </div>
                    </div>
                    <div id='sign-in-bottom'>
                        <div id='sme-push'>
                            <h3>{Texts.signin.sme_header}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: Texts.signin.sme_push
                                        .replace('{monthlyPlus}', monthlyPlus)
                                        .replace('{yearlyPlus}', yearlyPlus),
                                }}
                            />
                        </div>
                        <div id='firm-push'>
                            <h3>{Texts.signin.firms_header}</h3>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: Texts.signin.firms_push
                                        .replace('{monthlyPlus}', monthlyPlus)
                                        .replace('{yearlyPlus}', yearlyPlus),
                                }}
                            />
                        </div>
                        <div id='b2b-push'>
                            <h3>{Texts.signin.b2b_header}</h3>
                            <div dangerouslySetInnerHTML={{ __html: Texts.signin.b2b_push }} />
                        </div>
                    </div>
                    <p className='moms-text'>{Texts.signin.exklusive_moms}</p>
                </div>
            )}
            <div className='footer sign-in-footer'>
                {Texts.address__FooterText},{' '}
                <a href={Texts.phone_link__FooterText}>{Texts.phone__FooterText}</a>{' '}
                <a href={Texts.email_link__FooterText}>{Texts.email__FooterText}</a>
            </div>
        </>
    );
};

export default SignIn;
