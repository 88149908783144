import { format } from 'date-fns';
import React from 'react';
import { concatenateOfficialData } from '../Functions/Functions';
import Texts from "../Functions/Texts.json";
import { StackedColumnChart } from './StackedColumnChart';

type BalanceSheet = {
  financialYearFrom: string
  financialYearTo: string
  financialYear: string
  totalFixAssets: number
  totalLiquidAssets: number
  equity: number
  totalLongTermLiabilities: number
  totalCurrentLiabilities: number
  untaxedReserves: number
  provision: number
}
export type BalanceSheetChartProps = {
  officialBalanceSheets: BalanceSheet[] | undefined
  balanceSheetsAggregated: BalanceSheet[] | undefined
}
export const BalanceSheetChart = ({ officialBalanceSheets, balanceSheetsAggregated }: BalanceSheetChartProps) => {

  const balanceSheetsData = officialBalanceSheets && balanceSheetsAggregated && concatenateOfficialData(officialBalanceSheets, balanceSheetsAggregated).slice(0, 3);

  return <div className="chart-box">
    <h4>{Texts.charts.balance_sheets}</h4>
    {balanceSheetsData && <StackedColumnChart
      chartsAreLoading={!balanceSheetsData}
      data={[[
        {
          seriesName: Texts.charts.totalFixAssets,
          values: balanceSheetsData.map(d => d.totalFixAssets),
          color: "#3a306e",
        }, {
          seriesName: Texts.charts.totalLiquidAssets,
          values: balanceSheetsData.map(d => d.totalLiquidAssets),
          color: "#599ef9",
        }
      ], [
        {
          seriesName: Texts.balancesheets__equity,
          values: balanceSheetsData.map(d => d.equity),
          color: "#2aa268",
        }, {
          seriesName: Texts.charts.totalLiabilities,
          values: balanceSheetsData.map(d => d.totalLongTermLiabilities + d.totalCurrentLiabilities + d.untaxedReserves + d.provision),
          color: "#e6007e",
        }
      ]]}
      xLabels={balanceSheetsData.map(y => `${format(new Date(y.financialYearTo), "yyyy-MM")}${y.isOfficial ? "*" : ""}`)}
      chartConfig={{
        legendNumColumns: 2,
        plotToolText: "$seriesName $label{br}<b>$dataValue</b>",
        labelDisplay: "auto",
        drawCrossLine: 0,
      }}
    />}
  </div>
}