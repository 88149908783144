import { forwardRef, useEffect, useMemo, useState, useImperativeHandle, useRef } from 'react';
import CompanyDetails from '../../Types/Api/Response/CompanyDetails';
import Texts from '../../Functions/Texts.json';
import useDebounce from '../../Functions/useDebounce';
import GroupOverviewCompanySelectService from '../../Functions/Storage/GroupOverviewCompanySelectService';
import Alert from '../Alert';

type CompanyPickerProps = {
    selectedCompanies: CompanyDetails[] | null;
    companiesList: CompanyDetails[] | null;
    setSelectedCompanies: (companies: CompanyDetails[]) => void;
    licenseQueryString: string;
};

type SearchCompany = {
    id: string;
    companyName: string;
};

export type OkHandle = {
    onOk: () => void;
};

export type GroupOverviewStoredSelection = {
    companies: CompanyDetails[];
    licenseQueryString: string;
};

const maximumSelectedCount = 6;

const CompanyPicker = forwardRef(
    (
        {
            selectedCompanies,
            companiesList,
            setSelectedCompanies,
            licenseQueryString,
        }: CompanyPickerProps,
        ref,
    ) => {
        const [filter, setFilter] = useState<string>('');
        const [filteredCompanies, setFilteredCompanies] = useState<CompanyDetails[]>(
            companiesList?.slice(0, 10) ?? [],
        );
        const [showFilteredCompanies, setShowFilteredCompanies] = useState<boolean>(false);
        const [highlightedCompany, setHighlightedCompany] = useState<CompanyDetails | undefined>(
            undefined,
        );
        const [localSelectedCompanies, setLocalSelectedCompanies] = useState<CompanyDetails[]>(
            selectedCompanies ?? [],
        );
        const [showMaximumInfo, setShowMaxiumInfo] = useState<boolean>(false);
        const inputRef = useRef<HTMLInputElement>(null);
        const lowerCaseCompanies: Array<SearchCompany> = useMemo(
            () =>
                companiesList
                    ? companiesList.map((c) => ({
                          id: c.id,
                          companyName: c.organizationName?.toLocaleLowerCase() ?? '',
                      }))
                    : [],
            [companiesList],
        );

        useImperativeHandle(ref, () => {
            return {
                onOk() {
                    GroupOverviewCompanySelectService.SaveStorageData({
                        companies: localSelectedCompanies,
                        licenseQueryString,
                    });
                    setSelectedCompanies(localSelectedCompanies);
                },
            };
        });

        const debouncedFilter = useDebounce(filter, 30);

        useEffect(() => {
            if (debouncedFilter?.trim().length > 1) {
                const companies = lowerCaseCompanies.filter(
                    (c) =>
                        !localSelectedCompanies.find((x) => x.id === c.id) &&
                        c.companyName.indexOf(debouncedFilter.trim().toLocaleLowerCase()) > -1,
                );
                setFilteredCompanies(
                    companiesList
                        ? companiesList.filter((c) => companies.find((x) => x.id === c.id))
                        : [],
                );
            } else {
                setFilteredCompanies(
                    companiesList
                        ? companiesList.filter(
                              (c) =>
                                  !localSelectedCompanies.find((x) => x.id === c.id) &&
                                  lowerCaseCompanies.find((x) => x.id === c.id),
                          )
                        : [],
                );
            }
        }, [companiesList, debouncedFilter, lowerCaseCompanies, localSelectedCompanies]);

        useEffect(() => {
            if (filter.trim().length > 0) {
                setShowFilteredCompanies(true);
            }
        }, [filter]);

        useEffect(() => {
            if (
                highlightedCompany === undefined ||
                !filteredCompanies.some((c) => c.id === highlightedCompany.id)
            ) {
                setHighlightedCompany(
                    filteredCompanies.length > 0 ? filteredCompanies[0] : undefined,
                );
            }
        }, [filteredCompanies, highlightedCompany]);

        const onBlur = () => {
            setTimeout(() => setFilter(''), 100);
            setShowFilteredCompanies(false);
        };

        const onKeyDown = (event: React.KeyboardEvent) => {
            switch (event.code) {
                case 'ArrowDown':
                    stepSelect(1);
                    break;
                case 'ArrowUp':
                    stepSelect(-1);
                    break;
                case 'Tab':
                case 'Enter':
                    if (!event.shiftKey && filteredCompanies.length > 0 && highlightedCompany) {
                        selectCompany(highlightedCompany);
                        setShowFilteredCompanies(false);
                        event.preventDefault();
                    }
                    break;
                case 'Escape':
                    setShowFilteredCompanies(false);
                    break;
                default:
                    break;
            }
        };

        const selectCompany = (c: CompanyDetails) => {
            if (localSelectedCompanies.length >= maximumSelectedCount) {
                setShowMaxiumInfo(true);
            } else {
                setLocalSelectedCompanies([...localSelectedCompanies, c]);
            }
            setFilter('');
            setShowFilteredCompanies(false);
        };

        const deselectCompany = (c: CompanyDetails) => {
            setLocalSelectedCompanies(
                localSelectedCompanies.filter((company) => company.id !== c.id),
            );
            setShowMaxiumInfo(false);
        };

        const stepSelect = (val: number) => {
            if (filteredCompanies.length === 0) {
                return;
            }
            if (highlightedCompany === undefined) {
                setHighlightedCompany(filteredCompanies[0]);
                return;
            }
            const highlightedIndex = filteredCompanies.indexOf(highlightedCompany);
            let newIndex = highlightedIndex + val;
            if (newIndex < 0) {
                newIndex = filteredCompanies.length - 1;
            } else if (newIndex >= filteredCompanies.length) {
                newIndex = 0;
            }
            setHighlightedCompany(filteredCompanies[newIndex]);
        };

        return (
            <div className='company-picker'>
                <div className='selector-wrapper'>
                    <div
                        className='input-control'
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                            inputRef.current?.focus();
                            setShowFilteredCompanies(!showFilteredCompanies);
                        }}
                    >
                        <div className='input-wrapper'>
                            <input
                                className='input'
                                type='text'
                                ref={inputRef}
                                value={filter}
                                placeholder={Texts.group_overview.search_company}
                                onChange={(e) => setFilter(e.target.value)}
                                onBlur={() => setTimeout(onBlur, 100)}
                                onKeyDown={onKeyDown}
                                onBeforeInput={() => setShowFilteredCompanies(true)}
                            ></input>
                        </div>
                        <div className='input-expand'>
                            <span className='expand-separator'></span>
                            <div className='input-expand-dropdown'></div>
                        </div>
                    </div>
                    {showFilteredCompanies && (
                        <ul className='picker-menu'>
                            {filteredCompanies.map((c) => (
                                <li
                                    key={`f_${c.id}`}
                                    className={
                                        highlightedCompany && highlightedCompany.id === c.id
                                            ? 'highlighted'
                                            : ''
                                    }
                                    onMouseOver={() => setHighlightedCompany(c)}
                                    onMouseDown={(e) => e.preventDefault()}
                                    onClick={() => selectCompany(c)}
                                >
                                    {c.organizationName}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {localSelectedCompanies.length > 0 && (
                    <ul className='selected'>
                        {localSelectedCompanies?.map((c) => (
                            <li key={c.id}>
                                <span>{c.organizationName}</span>
                                <button
                                    className='fa-button trash'
                                    onClick={() => deselectCompany(c)}
                                >
                                    {Texts.group_overview.deselect}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
                {showMaximumInfo && (
                    <Alert
                        severity='error'
                        message={Texts.group_overview.maximum_warning.replace(
                            '{max}',
                            maximumSelectedCount.toLocaleString(),
                        )}
                    />
                )}
            </div>
        );
    },
);

export default CompanyPicker;
