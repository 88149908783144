import { Quarter } from './GetQuartersFromFiscalYears';
import React, { memo } from 'react';
import Texts from '../../Functions/Texts.json';

type QuarterSelectorProps = {
  quarters: Quarter[];
  selectedQuarters: string[];
  toggleSelectedQuarter: (quarter: string) => void;
};

const QuarterSelector = memo(
  ({ quarters, selectedQuarters, toggleSelectedQuarter }: QuarterSelectorProps) => {
    return (
      <>
        <h4>{Texts.report_generator.choose_quarter}</h4>
        <div className='report-generator-row'>
          {quarters.map((quarter, i, arr) => (
            <React.Fragment key={'quarter-' + quarter.label + quarter.financialYear}>
              {arr[i - 1]?.financialYear !== quarter.financialYear && (
                <div className='report-quarter-financial-year'>{quarter.financialYear}:</div>
              )}

              <div className='checkbox-wrapper'>
                <label className='checkbox-container'>
                  <input
                    value={quarter.label}
                    key={`${quarter.financialYear}-${quarter.label}`}
                    className={'quarter-checkbox'}
                    type='checkbox'
                    checked={selectedQuarters.some((q) => q === quarter.label)}
                    onChange={() => toggleSelectedQuarter(quarter.label)}
                  />
                  <span className='checkmark'></span>
                  <div>{quarter.q}</div>
                </label>
              </div>
            </React.Fragment>
          ))}
        </div>
      </>
    );
  },
);

export default QuarterSelector;
