import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import useGetPendingCompaniesListQuery from './useGetPendingCompaniesListQuery';

const useGetPendingCompaniesRequest = (licenseChoice: LicenseChoiceModel) => {
    const { data, isLoading } = useGetPendingCompaniesListQuery(licenseChoice.queryString ?? '', {
        enabled: licenseChoice.type === 'creditor',
    });

    const pendingCompanies = data
        ? {
              companies: data.data.pendingCompanies,
              metaInformation: {
                  ...data.data.metaInformation,
                  allSelected: 0,
                  sortReverse: 0,
              },
          }
        : null;

    return { isLoading, pendingCompanies };
};

export default useGetPendingCompaniesRequest;
