import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import PendingCompanyList from '../../Types/Api/Response/PendingCompanyList';

const useGetPendingCompaniesListQuery = (
    licenseChoiceQueryString: string,
    options?: UseQueryOptions<PendingCompanyList>,
) =>
    useQuery(
        ['pendingCompanies', licenseChoiceQueryString],
        async () =>
            axiosInstance.get<PendingCompanyList>(
                `corporatePortal/PendingCompany/list?${licenseChoiceQueryString}`,
            ),
        {
            cacheTime: 5000,
            ...options,
        },
    );

export default useGetPendingCompaniesListQuery;
