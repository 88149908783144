import React, { useEffect, useRef, useState } from 'react';
import Texts from '../../Functions/Texts.json';
import useDebounce from '../../Functions/useDebounce';

type BudgetNameProps = {
    budgetName: string;
    updateBudgetName: (value: string) => void;
};

const BudgetName = ({ budgetName, updateBudgetName }: BudgetNameProps) => {
    const [name, setName] = useState<string>(budgetName);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const debouncedName = useDebounce(name, 100);

    useEffect(() => {
        if (debouncedName !== budgetName) {
            updateBudgetName(debouncedName);
        }
    }, [debouncedName, budgetName, updateBudgetName]);

    return (
        <div id='budget-name'>
            <span className='title'>{Texts.budget.budget_name}</span>
            {isEditing ? (
                <input
                    className='input'
                    type='text'
                    ref={inputRef}
                    value={name}
                    placeholder={Texts.budget.budget_name_placeholder}
                    onChange={(e) => setName(e.target.value)}
                    autoFocus
                    onBlur={() => {
                        setIsEditing(false);
                        if (name !== budgetName) {
                            updateBudgetName(name);
                        }
                    }}
                ></input>
            ) : (
                <h3
                    className='budget-name'
                    onClick={() => {
                        setIsEditing(true);
                    }}
                    title={Texts.budget.budget_name_tooltip}
                >
                    {name}
                </h3>
            )}
        </div>
    );
};

export default BudgetName;
