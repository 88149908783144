import { useSetRecoilState } from 'recoil';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import LicenseChoiceService from '../../Functions/Storage/LicenseChoiceService';
import { useCompaniesListStateReset } from './CompanyListState';
import { userLicenseChoiceState } from './LicenseChoiceState';

const useSetLicenseChoice = () => {
    const setLicense = useSetRecoilState(userLicenseChoiceState);
    const companyListStateReset = useCompaniesListStateReset();

    const setLicenseChoice = (licenseChoice: LicenseChoiceModel) => {
        switch (licenseChoice.type) {
            case 'sme':
                licenseChoice.queryString = 'sme=true';
                break;
            case 'firm':
                licenseChoice.queryString = `firmId=${licenseChoice.id}`;
                break;
            case 'creditor':
                licenseChoice.queryString = `creditorId=${licenseChoice.id}`;
                break;
            default:
                break;
        }

        LicenseChoiceService.SaveStorageData(licenseChoice);
        setLicense(licenseChoice);
        companyListStateReset();
    };

    return { setLicenseChoice };
};

export default useSetLicenseChoice;
