import axios from 'axios';
import config from '../settings/settings';
import TokenStateService from '../Functions/Storage/TokenStateService';
import { add } from 'date-fns';
import TokenState from '../Types/Context/TokenState';

const axiosInstance = axios.create({
    baseURL: config.apiBase,
    headers: {
        'Content-Type': 'application/json',
    },
});

let refreshPromise: Promise<any> | null = null;

const verifyAccessToken = async () => {
    const authState = TokenStateService.GetParsedStorageData();
    if (!authState.isAuthenticated) {
        return Promise.resolve(false);
    }
    const now = new Date();
    const expires = new Date(authState.tokenExpireTimestamp! * 1000);
    if (expires > now) {
        return Promise.resolve(authState.token);
    }

    let loginValidUntil = new Date(authState.loginValidUntil!);
    if (loginValidUntil < add(new Date(), { minutes: 10 })) {
        // If there's less than 10 minutes left until autologout, prolong
        // loginValidUntil for another 30 mins
        loginValidUntil = add(loginValidUntil, { minutes: 30 });
        TokenStateService.UpdateAuthenticationState({ loginValidUntil });
    }

    return refreshToken(authState);
};

const refreshToken = (authState: TokenState) => {
    if (authState.refreshToken === null) {
        return Promise.resolve(false);
    }
    if (refreshPromise !== null) {
        return refreshPromise;
    }
    refreshPromise = axios
        .post(`${config.apiBase}authentication/user/token/refresh`, {
            refreshToken: authState.refreshToken,
            source: 'CorpPortal',
        })
        .then((res) => {
            //this.storeToken(res.data.accessToken, res.data.refreshToken, false);
            TokenStateService.UpdateToken(res.data.accessToken, res.data.refreshToken, false);
            return res.data.accessToken;
        })
        .catch((e) => {
            // logout(Texts.logged_out_because_session_expired__LoggedOutMessage);
            // TODO: Fix getting logged out here
            TokenStateService.ClearAll();

            return false;
        })
        .finally(() => {
            refreshPromise = null;
        });

    return refreshPromise;
};

/**
 * Request interceptor for API calls
 */
axiosInstance.interceptors.request.use(async (config) => {
    const newConfig = config;
    //const accessToken = await AuthenticationStateService.GetAccessToken();
    const accessToken = await verifyAccessToken();

    if (accessToken) {
        newConfig.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }
    return newConfig;
});

export default axiosInstance;
