import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SnackbarKey, useSnackbar } from 'notistack';

export type SnackbarButtonDismissProps = {
    snackbarKey: SnackbarKey;
};

const SnackbarButtonDismiss = ({ snackbarKey }: SnackbarButtonDismissProps) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <div onClick={() => closeSnackbar(snackbarKey)} className='snackbar-close'>
            <FontAwesomeIcon icon={faXmark} />
        </div>
    );
};

export default SnackbarButtonDismiss;
