import { atom, DefaultValue, selector, useResetRecoilState } from 'recoil';
import SessionSettings from '../../Types/Context/SessionSettings';

const formKey = 'sessionSettingsState';

export const rowTableFilterState = atom<SessionSettings['rowTableFilter']>({
    key: `${formKey}-rowTableFilter`,
    default: '',
});

export const sortByState = atom<SessionSettings['sortBy']>({
    key: `${formKey}-sortBy`,
    default: '',
});

export const sortReverseState = atom<SessionSettings['sortReverse']>({
    key: `${formKey}-sortByReverse`,
    default: false,
});

export const helpIsOpenState = atom<SessionSettings['helpIsOpen']>({
    key: `${formKey}-helpIsOpen`,
    default: false,
});
export const tableIsLoadingState = atom<SessionSettings['tableIsLoading']>({
    key: `${formKey}-tableIsLoading`,
    default: false,
});
export const activeRequestsState = atom<SessionSettings['activeRequests']>({
    key: `${formKey}-activeRequests`,
    default: 0,
});

export const useSessionSettingStateReset = () => {
    const rowTableFilter = useResetRecoilState(rowTableFilterState);
    const sortBy = useResetRecoilState(sortByState);
    const sortReverse = useResetRecoilState(sortReverseState);
    const helpIsOpen = useResetRecoilState(helpIsOpenState);
    const tableIsLoading = useResetRecoilState(tableIsLoadingState);
    const activeRequests = useResetRecoilState(activeRequestsState);

    return () => {
        rowTableFilter();
        sortBy();
        sortReverse();
        helpIsOpen();
        tableIsLoading();
        activeRequests();
    };
};

export const sessionSettingStateSelector = selector({
    key: `${formKey}-selector`,
    get: ({ get }): SessionSettings => {
        const rowTableFilter = get(rowTableFilterState);
        const sortBy = get(sortByState);
        const sortReverse = get(sortReverseState);
        const helpIsOpen = get(helpIsOpenState);
        const tableIsLoading = get(tableIsLoadingState);
        const activeRequests = get(activeRequestsState);

        const sessionSettingState = {
            rowTableFilter,
            sortBy,
            sortReverse,
            helpIsOpen,
            tableIsLoading,
            activeRequests,
        };
        return sessionSettingState;
    },
    set: ({ set }, value: SessionSettings | DefaultValue) => {
        if (value instanceof DefaultValue) {
            set(rowTableFilterState, value);
            set(sortByState, value);
            set(sortReverseState, value);
            set(helpIsOpenState, value);
            set(tableIsLoadingState, value);
            set(activeRequestsState, value);
        } else {
            set(rowTableFilterState, value.rowTableFilter);
            set(sortByState, value.sortBy);
            set(sortReverseState, value.sortReverse);
            set(helpIsOpenState, value.helpIsOpen);
            set(tableIsLoadingState, value.tableIsLoading);
            set(activeRequestsState, value.activeRequests);
        }
    },
});
