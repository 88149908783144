import { useParams } from 'react-router-dom';
import {
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../../Components/NewColumnBasedTable';
import Texts from '../../Functions/Texts.json';
import {
    FinancialYearAsYearMonth,
    NumberAsEuropeanDecimalNumber,
} from '../../Functions/Formatting';
import useGetOverviewFinancialRatio, {
    ExpandedFinancialRatioRow,
} from './Hooks/useGetOverviewFinancialRatio';

const TableRow: (
    props: TableRowProps<ExpandedFinancialRatioRow>,
) => TableRowInstance<ExpandedFinancialRatioRow> = TableRowBase;

const DashboardFinancialRatioTable = () => {
    const { ratios, isLoading, isDataMissing } = useGetOverviewFinancialRatio(useParams());

    return (
        <>
            {isLoading && !isDataMissing && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {isDataMissing && (
                <div className='chart-data-missing'>{Texts.company_dashboard.data_missing}.</div>
            )}
            {ratios && (
                <NewColumnBasedTable<ExpandedFinancialRatioRow>
                    tableIsLoading={isLoading}
                    columnWidth={170}
                    headerMinWidth={150}
                    data={ratios}
                    style={{ maxWidth: '90%' }}
                >
                    <TableRow
                        className='column-header'
                        label=''
                        selector={(d) =>
                            d.isOfficial
                                ? Texts.official__TableHeader
                                : Texts.mynumbers__TableHeader
                        }
                    />
                    <TableRow
                        className='bold number'
                        label=''
                        selector={(d) =>
                            `${FinancialYearAsYearMonth(d.financialYear)}${d.isOfficial ? '*' : ''}`
                        }
                    />
                    <TableRow
                        className='tiny-row'
                        label={Texts.financial_ratio__ViewTitle}
                        selector={(d) => `${d.financialYear.split(':')[1]}`}
                    />
                    <TableRow
                        className='line-under compare-to-previous number'
                        label={`${Texts.financialratio__g6_netMargin} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g6_netMargin ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under compare-to-previous number'
                        label={`${Texts.financialratio__g5_operatingMargin} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g5_operatingMargin ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under compare-to-previous number'
                        label={`${Texts.financialratio__g1_returnOnEquity} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g1_returnOnEquity ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under compare-to-previous number'
                        label={`${Texts.financialratio__t3_interestCoverageRatio} ${Texts.ggr__TableLabel}`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(
                                d.t3_interestCoverageRatio && d.t3_interestCoverageRatio !== 0
                                    ? d.t3_interestCoverageRatio
                                    : '',
                            )
                        }
                    />
                    <TableRow
                        className='line-under compare-to-previous number'
                        label={`${Texts.financialratio__g12_quickRatio} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g12_quickRatio ?? '')}
                    />
                    <TableRow
                        className='number'
                        label={`${Texts.financialratio__g9_solvency} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g9_solvency ?? '')}
                    />
                </NewColumnBasedTable>
            )}
        </>
    );
};

export default DashboardFinancialRatioTable;
