import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SavedBudget } from '../../Functions/Budget';
import { BudgetTable } from '../../Components/Budget/BudgetTable';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import useBudgetRequests from '../../Api/Budget/useBudgetRequests';
import BudgetName from './BudgetName';
import BudgetPeriod from './BudgetPeriod';

type EditBudgetProps = {
    budgets: SavedBudget[];
    yearlyData: ProfitAndLossRow[];
    monthlyData: ProfitAndLossRow[];
    organizationId: string;
};
export const BudgetEditor = ({
    budgets,
    yearlyData,
    monthlyData,
    organizationId,
}: EditBudgetProps) => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const { budgetId } = useParams();
    const selectedBudget = budgets.find((b) => b.id === budgetId);
    const [editedBudget, setEditedBudget] = useState<SavedBudget | undefined>(selectedBudget);
    const [isDirty, setIsDirty] = useState(false);
    const [saveTime, setSaveTime] = useState<Date | null>(null);
    const { saveBudget, isLoading } = useBudgetRequests();
    const [budgetName, setBudgetName] = useState<string>(selectedBudget?.name ?? '');

    useEffect(() => {
        const message = 'Dina ändringar har inte sparats';
        const cb = (e: BeforeUnloadEvent) => {
            if (isDirty) {
                e.preventDefault();
                e.returnValue = message;
            }
        };
        window.addEventListener('beforeunload', cb);
        return () => window.removeEventListener('beforeunload', cb);
    }, [isDirty]);

    if (!editedBudget || !selectedBudget) {
        return <>{Texts.error__NotFound}</>;
    }

    const _setEditedBudget = (budget: SavedBudget) => {
        setIsDirty(budget !== selectedBudget);
        setEditedBudget(budget);
    };

    const _saveBudget = async (budget: SavedBudget) => {
        saveBudget({ ...budget, name: budgetName }, organizationId, () => {
            setSaveTime(new Date());
            setIsDirty(false);
        });
    };

    return (
        <div>
            {helpIsOpen && <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['budget']} />}
            <div className='budget-title-row'>
                <div id='controls'>
                    <BudgetName
                        budgetName={budgetName}
                        updateBudgetName={(value) => {
                            setBudgetName(value);
                            setIsDirty(true);
                        }}
                    />
                    <BudgetPeriod
                        budgetPeriod={selectedBudget.period}
                        budget={selectedBudget}
                        budgetDirty={isDirty}
                        organizationId={organizationId}
                    />
                </div>
                <div>
                    {saveTime && (
                        <button
                            className='disappear fa-button check'
                            // key makes sure that the entire element is replaced when saveTime changes, restarting the animation
                            key={saveTime.valueOf()}
                        >
                            {Texts.budget.saved}
                        </button>
                    )}
                    <button className='help' onClick={() => setHelpIsOpen(true)}>
                        {Texts.help__HelpButtonText}
                    </button>
                    <button
                        className='save'
                        disabled={!isDirty || isLoading}
                        onClick={() => _saveBudget(editedBudget)}
                    >
                        {isLoading ? Texts.budget.saving : Texts.budget.save}
                    </button>
                </div>
            </div>

            <BudgetTable
                initialBudget={selectedBudget}
                budgetUpdated={_setEditedBudget}
                yearlyData={yearlyData}
                monthlyData={monthlyData}
                // Lazy way to remount this component if the targetting FY has changed
                key={`budget-table-${selectedBudget.period}`}
            />
        </div>
    );
};
