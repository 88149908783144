import React, { useEffect, useState } from 'react';
import Texts from '../Functions/Texts.json';
import ReactFC from 'react-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { CurrentYear } from '../Functions/Calculations';
import { CompareData } from '../Views/CompareCompanies';
import { LineChart } from './LineChart';
import { PnlProperty, getPnlValue, pnlProperties } from '../Functions/ProfitAndLossChartHelpers';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';
import { RenderAs } from '../Functions/Chart';
import Alert from './Alert';

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme);

type ChartCompareProps = {
    companiesComparison: CompareData[];
    chartsAreLoading: boolean;
};

type YearlyData = {
    [year: string]: ProfitAndLossRow[];
};

const monthsAsShortText = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
];

const ChartCompare = ({ companiesComparison, chartsAreLoading }: ChartCompareProps) => {
    const [enoughData, setEnoughData] = useState<boolean>(false);
    const [warningNotSameSni, setWarningNotSameSni] = useState<boolean>(false);
    const [warningNotSameFiscalYear, setWarningNotSameFiscalYear] = useState<boolean>(false);
    const [selectedYear, setSelectedYear] = useState<string>(CurrentYear().toString());
    const [selectedProperty, setSelectedProperty] = useState<PnlProperty>(
        pnlProperties.find((p) => p.property === 'revenue')!,
    );
    const [availableYears, setAvailableYears] = useState<Array<string>>([]);
    const [compareChartRenderAs, setCompareChartRenderAs] = useState<RenderAs>('spline');

    useEffect(() => {
        const years = [
            ...new Set(
                companiesComparison.flatMap((c) => c.data.flatMap((d) => d.period.substring(0, 4))),
            ),
        ].sort();
        setAvailableYears(years);
        setEnoughData(!companiesComparison.some((c) => !c.data || c.data.length === 0));
        setWarningNotSameSni(
            companiesComparison.some((c) =>
                companiesComparison.some((c2) => c.mainSni !== c2.mainSni),
            ),
        );

        // Check if there are different financial years by checking that if a period exist for
        // more than one company, then the financial year should be the same
        const allPeriods = companiesComparison.flatMap((c) =>
            c.data.flatMap((d) => ({ period: d.period, financialYear: d.financialYear })),
        );
        const periodFYs = new Set(allPeriods.map((p) => `${p.period} ${p.financialYear}`));
        const uniquePeriods = new Set(allPeriods.map((p) => p.period));
        setWarningNotSameFiscalYear(periodFYs.size !== uniquePeriods.size);
    }, [companiesComparison]);

    const yearlyData = companiesComparison.map((c) => ({
        name: c.name,
        data: c.data.reduce(
            (groups, item) => ({
                ...groups,
                [item.period.substring(0, 4)]: [
                    ...(groups[item.period.substring(0, 4) as keyof typeof groups] || []),
                    item,
                ],
            }),
            {},
        ) as YearlyData,
    }));

    const chartData = yearlyData.flatMap((yd) => ({
        seriesName: yd.name,
        values: yd.data[selectedYear]?.map((m) => getPnlValue(m, selectedProperty)) ?? [],
        renderAs: compareChartRenderAs,
    }));

    const warningNotAllDataAvailable = yearlyData.some((yd) =>
        yearlyData.some(
            (yd2) => (yd.data[selectedYear]?.length ?? 0) !== (yd2.data[selectedYear]?.length ?? 0),
        ),
    );

    return (
        <>
            {chartsAreLoading && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {companiesComparison &&
                companiesComparison.length > 1 &&
                companiesComparison.length < 5 &&
                enoughData && (
                    <div className='compare-wrapper'>
                        {availableYears.length > 0 && (
                            <div className='compare-chart-menu'>
                                {availableYears.map((year, index) => (
                                    <span
                                        className={`${year === selectedYear ? 'active' : ''}`}
                                        key={year}
                                        onClick={() => setSelectedYear(year)}
                                    >
                                        {year}
                                    </span>
                                ))}
                            </div>
                        )}

                        <div className='compare-chart-menu key-value-selector'>
                            <span
                                className={`prop-revenue ${
                                    selectedProperty.property === 'revenue' ? ' active' : ''
                                }`}
                                onClick={() =>
                                    setSelectedProperty(
                                        pnlProperties.find((p) => p.property === 'revenue')!,
                                    )
                                }
                            >
                                Nettoomsättning
                            </span>
                            <span
                                className={`prop-ebit ${
                                    selectedProperty.property === 'ebit' ? ' active' : ''
                                }`}
                                onClick={() =>
                                    setSelectedProperty(
                                        pnlProperties.find((p) => p.property === 'ebit')!,
                                    )
                                }
                            >
                                EBIT
                            </span>
                            <span
                                className={`prop-gross ${
                                    selectedProperty.property === 'grossProfit' ? ' active' : ''
                                }`}
                                onClick={() =>
                                    setSelectedProperty(
                                        pnlProperties.find((p) => p.property === 'grossProfit')!,
                                    )
                                }
                            >
                                Bruttovinst
                            </span>
                        </div>

                        <div
                            className={`render-as-switch ${
                                compareChartRenderAs === 'column' && 'active'
                            }`}
                            onClick={() =>
                                setCompareChartRenderAs(
                                    compareChartRenderAs === 'spline' ? 'column' : 'spline',
                                )
                            }
                        ></div>

                        {
                            <div className='chart-box no-buttons'>
                                <LineChart
                                    chartsAreLoading={chartsAreLoading}
                                    data={chartData}
                                    xLabels={monthsAsShortText}
                                    chartConfig={{
                                        showLegend: 1,
                                        bgColor: '#FFFFFF',
                                        caption: `${selectedProperty.label} ${selectedYear}`,
                                    }}
                                    width={'100%'}
                                    height={'400'}
                                />
                            </div>
                        }
                        {(warningNotSameSni ||
                            warningNotAllDataAvailable ||
                            warningNotSameFiscalYear) && (
                            <div className='compare-warnings'>
                                {warningNotSameSni && (
                                    <Alert
                                        severity='warning'
                                        message={Texts.not_same_sni__CompareOverlayWarning}
                                        variant='compact'
                                    />
                                )}
                                {warningNotAllDataAvailable && (
                                    <Alert
                                        severity='warning'
                                        message={Texts.data_missing__CompareOverlayWarning}
                                        variant='compact'
                                    />
                                )}
                                {warningNotSameFiscalYear && (
                                    <Alert
                                        severity='warning'
                                        message={Texts.year_end_difference__CompareOverlayWarning}
                                        variant='compact'
                                    />
                                )}
                            </div>
                        )}
                    </div>
                )}
        </>
    );
};

export default ChartCompare;
