import React, { useState } from 'react';
import Texts from '../Functions/Texts.json';
import { add, format } from 'date-fns';
import { Dialog } from '../Components/Dialog';
import { CorporatePortalUser } from '../Types/Api/Response/CorporatePortalUser';
import axiosInstance from '../Core/Axios';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { userStateSelector, userTrialUntilState } from '../Core/State/UserState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import { mapCorporatePortalUserToUser } from '../Functions/Authentication';
import useSnackbar from '../Components/Snackbar/useSnackbar';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../Components/Wrappers/ErrorFallback';

const billingIntervalVal = (interval: number | null): string => {
    switch (interval) {
        case 1:
            return Texts.settings.billing_interval_1;
        case 12:
            return Texts.settings.billing_interval_12;
        case null:
            return Texts.settings.billing_interval_not_set;
        default:
            return '';
    }
};

const Settings = () => {
    const [deactivateIsOpen, setDeactivateIsOpen] = useState<Date | null>(null);
    const [spinnerIsOpen, setSpinnerIsOpen] = useState(false);
    const [errorMsgIsOpen, setErrorMsgIsOpen] = useState(false);
    const setUserState = useSetRecoilState(userStateSelector);

    const trialUntil = useRecoilValue(userTrialUntilState);
    const user = useRecoilValue(userStateSelector);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const { enqueueErrorSnackbar } = useSnackbar();

    const openDeactivateDialog = async () => {
        setSpinnerIsOpen(true);
        try {
            var response = await axiosInstance.get('corporatePortal/EndOfCurrentBillingPeriod');
            setDeactivateIsOpen(new Date(response.data));
        } catch {
            setErrorMsgIsOpen(true);
        } finally {
            setSpinnerIsOpen(false);
        }
    };

    const deactivate = async () => {
        setDeactivateIsOpen(null);
        setSpinnerIsOpen(true);
        try {
            const res = await axiosInstance.post<CorporatePortalUser>(
                'corporatePortal/DeactivateLicense',
            );
            const mappedUser = mapCorporatePortalUserToUser(res.data);
            setUserState(mappedUser);
        } catch {
            enqueueErrorSnackbar(Texts.responses.general);
            setErrorMsgIsOpen(true);
        } finally {
            setSpinnerIsOpen(false);
        }
    };

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className='content-wrapper'>
                <div className='content-heading clear'>
                    <h1>{Texts.settings.title}</h1>
                </div>
                <div id='settings-content'>
                    {spinnerIsOpen && (
                        <div className='content-overlay'>
                            <div className='spinner-wrapper'>
                                <div className='spinner'></div>
                            </div>
                        </div>
                    )}
                    <Dialog
                        open={errorMsgIsOpen}
                        header={Texts.error__ErrorMessageLabel}
                        ok={() => setErrorMsgIsOpen(false)}
                    >
                        {Texts.settings.request_error}/
                    </Dialog>
                    <Dialog
                        open={deactivateIsOpen !== null}
                        header={Texts.settings.deactivate_subscription}
                        ok={() => deactivate()}
                        cancel={() => setDeactivateIsOpen(null)}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: Texts.settings.verify_deactivation.replace(
                                    '{periodEnd}',
                                    deactivateIsOpen ? format(deactivateIsOpen, 'yyyy-MM-dd') : '',
                                ),
                            }}
                        />
                    </Dialog>
                    <div className='col'>
                        <div className='personal-settings'>
                            <h2>{Texts.settings.personal_settings}</h2>
                            <dl>
                                <div>
                                    <dt>{Texts.settings.name}</dt>
                                    <dd>
                                        {user.firstName} {user.lastName}
                                    </dd>
                                </div>
                                <div>
                                    <dt>{Texts.settings.email}</dt>
                                    <dd>{user.email}</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                    <div className='col'>
                        {licenseChoice.type !== 'creditor' && (
                            <>
                                <div className='subscription-settings'>
                                    <h2>{Texts.settings.subscription_settings}</h2>
                                    <dl>
                                        <div>
                                            <dt>{Texts.settings.billing_interval}</dt>
                                            <dd>{billingIntervalVal(user.billingInterval)}</dd>
                                        </div>
                                        {user.billingInterval && user.billingInterval > 0 && (
                                            <div>
                                                <dt>{Texts.settings.billing_period}</dt>
                                                {user.billingInterval === 12 ? (
                                                    <dd>
                                                        <span className='date'>
                                                            {format(
                                                                add(
                                                                    new Date(
                                                                        user.licenseValidUntil!,
                                                                    ),
                                                                    {
                                                                        years: -1,
                                                                    },
                                                                ),
                                                                'yyyy-MM-dd',
                                                            )}
                                                        </span>
                                                        <> - </>
                                                        <span className='date'>
                                                            {format(
                                                                new Date(user.licenseValidUntil!),
                                                                'yyyy-MM-dd',
                                                            )}
                                                        </span>
                                                    </dd>
                                                ) : user.cancellationDate === null ? (
                                                    <dd>
                                                        <span className='date'>
                                                            {format(
                                                                new Date(user.billingIntervalFrom!),
                                                                'yyyy-MM-dd',
                                                            )}
                                                        </span>{' '}
                                                        - {Texts.settings.until_further}{' '}
                                                        <button
                                                            className='fa-button trash'
                                                            onClick={async () =>
                                                                await openDeactivateDialog()
                                                            }
                                                        >
                                                            {Texts.settings.deactivate_subscription}
                                                        </button>
                                                    </dd>
                                                ) : (
                                                    <dd>
                                                        {Texts.settings.deactivated}{' '}
                                                        <span className='date'>
                                                            {format(
                                                                new Date(user.licenseValidUntil!),
                                                                'yyyy-MM-dd',
                                                            )}
                                                        </span>
                                                    </dd>
                                                )}
                                            </div>
                                        )}
                                        {trialUntil !== null && trialUntil.getFullYear() < 3000 && (
                                            <div>
                                                <dt>{Texts.settings.trial_until}</dt>
                                                <dd>
                                                    <span className='date'>
                                                        {format(trialUntil, 'yyyy-MM-dd')}
                                                    </span>
                                                </dd>
                                            </div>
                                        )}
                                    </dl>
                                </div>
                                <div className='billing-settings'>
                                    <h2>{Texts.settings.billing_settings}</h2>
                                    <dl>
                                        {user.billingEmail && (
                                            <div>
                                                <dt>{Texts.settings.email}</dt>
                                                <dd>{user.billingEmail}</dd>
                                            </div>
                                        )}
                                        {user.billingCompanyName && (
                                            <div>
                                                <dt>{Texts.settings.company}</dt>
                                                <dd>{user.billingCompanyName}</dd>
                                            </div>
                                        )}
                                    </dl>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default Settings;
