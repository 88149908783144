import { atom, DefaultValue, selector, useResetRecoilState } from 'recoil';
import CompanyList from '../../Types/Api/Response/CompanyList';

const formKey = 'companyListState';

export type CompanyListState = {
    companies: CompanyList | null;
    orgNrToIdMap: Record<string, string>;
};

export const companiesState = atom<CompanyList | null>({
    key: `${formKey}-companies`,
    default: null,
});

export const orgNrToIdMapState = atom<Record<string, string>>({
    key: `${formKey}-orgNrToIdMap`,
    default: {},
});

export const useCompaniesListStateReset = () => {
    const companies = useResetRecoilState(companiesState);
    const orgNrToIdMap = useResetRecoilState(orgNrToIdMapState);

    return () => {
        companies();
        orgNrToIdMap();
    };
};

export const companyListStateSelector = selector({
    key: `${formKey}-selector`,
    get: ({ get }): CompanyListState => {
        const companies = get(companiesState);
        const orgNrToIdMap = get(orgNrToIdMapState);

        return {
            companies,
            orgNrToIdMap,
        };
    },
    set: ({ set }, value: CompanyListState | DefaultValue) => {
        if (value instanceof DefaultValue) {
            set(companiesState, value);
            set(orgNrToIdMapState, value);
        } else {
            set(companiesState, value.companies);
            set(orgNrToIdMapState, value.orgNrToIdMap);
        }
    },
});
