import React from 'react';
import Texts from '../Functions/Texts.json';
import ReactFC from 'react-fusioncharts';
import { CommonChartProps, defaultChartConfig } from '../Functions/Chart';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts';
import PowerCharts from 'fusioncharts/fusioncharts.powercharts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

type BaseDataPoint = {
    label: string;
    color?: string;
};
export type ValueDataPoint = BaseDataPoint & {
    value: number;
};
export type SumDataPoint = BaseDataPoint & {
    isSum: true;
    cumulative?: boolean;
};
export type DataPoint = ValueDataPoint | SumDataPoint;

ReactFC.fcRoot(FusionCharts, Charts, PowerCharts, FusionTheme);

export type WaterfallChartProps = Omit<CommonChartProps, 'xLabels' | 'palette'> & {
    data: DataPoint[];
    positiveColor?: string;
    negativeColor?: string;
    hideZeroValues: boolean;
};
export const WaterfallChart = (props: WaterfallChartProps) => {
    const { chartsAreLoading, data, chartConfig, positiveColor, negativeColor, hideZeroValues } =
        props;

    if (chartsAreLoading) {
        return (
            <div className='bar-chart-wrapper'>
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            </div>
        );
    }

    const dataset = data
        .filter((d) => !hideZeroValues || 'isSum' in d || d.value !== 0)
        .map((d) =>
            'isSum' in d
                ? {
                      ...d,
                      cumulative: d.cumulative ?? true,
                  }
                : d,
        );

    const fcConfig = {
        width: '500',
        height: '350',
        type: 'waterfall2d',
        dataFormat: 'json',
        dataSource: {
            data: dataset,
            chart: {
                ...defaultChartConfig,
                positiveColor: positiveColor ?? '#3a306e',
                negativeColor: negativeColor ?? '#e6007e',
                plotToolText: '$label <b>$dataValue</b>',
                showSumAtEnd: false,
                ...chartConfig,
            },
        },
    };

    return (
        <div className='bar-chart-wrapper'>
            {data.length > 0 ? (
                <div className='chart-wrapper'>
                    <ReactFC {...fcConfig} />
                </div>
            ) : (
                <div className='chart-data-missing'>{Texts.chart_data_missing__InfoText}</div>
            )}
        </div>
    );
};
