import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import ExternalFinancialRatioRow from '../../Types/Api/Response/ExternalFinancialRatioRow';

const useGetOfficialFinancialRatioQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<ExternalFinancialRatioRow[]>,
) =>
    useQuery(
        ['officialFinancialRatio', companyId],
        async () =>
            axiosInstance.get<ExternalFinancialRatioRow[]>(
                `Reports/financialratios/${companyId}/official`,
            ),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetOfficialFinancialRatioQuery;
