import React, { useEffect, useState } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import { useRecoilValue } from 'recoil';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import { companiesState } from '../Core/State/CompanyListState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import { userCreditorNameState } from '../Core/State/UserState';
import PendingCompanyInformation from '../Types/Api/Response/PendingCompanyInformation';
import { FormatOrganizationNumber } from '../Functions/Formatting';
import { SortByTextAndNull } from '../Functions/Calculations';
import axiosInstance from '../Core/Axios';
import { useQueryClient } from 'react-query';
import useSnackbar from '../Components/Snackbar/useSnackbar';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';

const PendingCompanies = () => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );
    const { getCompanyList } = useGetCompanyListRequest();
    const creditorName = useRecoilValue(userCreditorNameState);
    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
    const queryClient = useQueryClient();
    const { enqueueErrorSnackbar } = useSnackbar();

    const checkboxCallback = (isChecked: boolean, value: string) => {
        if (isChecked) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems(selectedItems.filter((i) => i !== value));
        }
    };

    const toggleCheckboxes = (isChecked: boolean, visibleValues: Array<string>) => {
        if (isChecked) {
            // Only select items that are actually selectable
            setSelectedItems(
                pendingCompanies?.companies
                    .filter((c) => visibleValues.some((v) => v === c.organizationNumber))
                    .map((c) => c.organizationNumber) ?? [],
            );
        } else {
            setSelectedItems([]);
        }
    };

    const setupTable = (companies: PendingCompanyInformation[] | undefined) => {
        if (!companies) {
            return emptyTable;
        }

        const table: RowTable = {
            head: [
                {
                    label: ' ',
                    type: 'checkbox',
                    className: 'checkbox-col',
                },
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium right',
                },
            ],
            rows: [],
            actions: [
                <button
                    key='approve-key1'
                    className={`approve-button ${selectedItems.length > 0 ? 'is-activated' : ''}`}
                    onClick={() => handleSelected('approve')}
                >
                    <div className='mask'></div>
                    {Texts.approve__ApproveButtonText}
                </button>,
                <button
                    key='delete-key2'
                    className={`reject-button' ${selectedItems.length > 0 ? 'is-activated' : ''}`}
                    onClick={() => handleSelected('delete')}
                >
                    <div className='mask'></div>
                    {Texts.reject__RejectButtonText}
                </button>,
            ],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        const rows = companies.map(
            (company) =>
                ({
                    metaInformation: {
                        visible: true,
                        value: company.organizationNumber,
                    },
                    cells: [
                        {
                            text: '',
                            func: 'checkbox',
                            value: company.organizationNumber,
                            className: 'checkbox-col',
                            isChecked: selectedItems.some((s) => s === company.organizationNumber),
                            checkboxCallback: checkboxCallback,
                        },
                        {
                            text: company.organizationName,
                            className: 'large',
                        },
                        {
                            text: FormatOrganizationNumber(company.organizationNumber),
                            className: 'medium right',
                        },
                    ],
                } as RowTableRow),
        );

        table.rows = rows != null && rows.length > 0 ? rows.sort(SortByTextAndNull(0)) : [];

        return table;
    };

    const deleteCompany = async (organizationNumber: string) => {
        const companyId = pendingCompanies?.companies.find(
            (c) => c.organizationNumber === organizationNumber,
        )?.id;
        try {
            await axiosInstance.delete(
                `corporatePortal/PendingCompany/${companyId}/${licenseChoice.id}`,
            );
        } catch (e) {
            enqueueErrorSnackbar(Texts.responses.general);
        }
    };

    const approveCompany = async (organizationNumber: string) => {
        const companyId = pendingCompanies?.companies.find(
            (c) => c.organizationNumber === organizationNumber,
        )?.id;
        try {
            await axiosInstance.put(
                `corporatePortal/PendingCompany/${companyId}/${licenseChoice.id}/approve`,
            );
        } catch (e) {
            enqueueErrorSnackbar(Texts.responses.general);
        }
    };

    const handleSelected = async (action: 'delete' | 'approve') => {
        if (action === 'delete') {
            await Promise.all(selectedItems?.map((orgNr) => deleteCompany(orgNr)));
        } else if (action === 'approve') {
            await Promise.all(selectedItems?.map((orgNr) => approveCompany(orgNr)));
        }

        queryClient.invalidateQueries(['pendingCompanies']);
    };

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(pendingCompanies?.companies);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['pendingCompanies', 'dashboard']}
                />
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                toggleHelp={() => setHelpIsOpen(true)}
                viewTitle={Texts.dashboard_pending_companies__PendingCompaniesViewTitle}
            />
            <RowBasedTableWrapper>
                <div className='row-based-table-wrapper-heading'>
                    <p>
                        {Texts.below_list_shows__PendingCompaniesPreamble} {creditorName}.
                    </p>
                </div>
                <RowBasedTable table={table} toggleCheckboxes={toggleCheckboxes} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default PendingCompanies;
