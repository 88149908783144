import { add, format } from 'date-fns';
import FinancialYear from './Types/FinancialYear';

export type Quarter = {
    from: string;
    to: string;
    label: string;
    compactLabel: string;
    financialYear: string;
    q: string;
};

const GetQuartersFromFiscalYears = (financialYears: FinancialYear[]): Quarter[] => {
    let quarterObjects: Quarter[][] = [];

    // Make a copy to not change the order of the original
    const financialYearsReversed = [...financialYears].sort((a, b) => a.from.localeCompare(b.from));
    for (let i = 0; i < financialYearsReversed.length; i++) {
        const yStart = new Date(financialYearsReversed[i].from);

        const nextYearStartDate =
            i + 1 < financialYearsReversed.length
                ? new Date(financialYearsReversed[i + 1].from)
                : undefined;

        let qEnd = new Date();

        const yearQuarterObjects = [];
        for (let j = 0; j < 4; j++) {
            const qStart = add(yStart, { months: j * 3 });
            if (nextYearStartDate && nextYearStartDate <= qStart) {
                break;
            }
            qEnd = add(yStart, { months: (j + 1) * 3, days: -1 });
            if (nextYearStartDate && nextYearStartDate <= qEnd) {
                qEnd = add(nextYearStartDate, { days: -1 });
            }
            yearQuarterObjects.push({
                from: format(qStart, 'yyyy-MM-dd'),
                to: format(qEnd, 'yyyy-MM-dd'),
                label: `${format(qStart, 'yyyy-MM-dd')} - ${format(qEnd, 'yyyy-MM-dd')}`,
                compactLabel: `${format(qStart, 'yy-MM-dd')} - ${format(qEnd, 'yy-MM-dd')}`,
                financialYear: '',
                q: `Q${j + 1} `,
            });
        }
        yearQuarterObjects.forEach((element) => {
            element.financialYear = format(qEnd, 'yyyy');
        });

        quarterObjects.push(yearQuarterObjects);
    }

    return quarterObjects.reduce((prev, curr) => (curr = [...curr, ...prev]), []);
};

export default GetQuartersFromFiscalYears;
