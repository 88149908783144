import React from 'react';
import Texts from '../Functions/Texts.json';
import ReactFC from 'react-fusioncharts';
import {
    CommonChartProps,
    DataPoint,
    dataPointsToDataset,
    defaultChartConfig,
    defaultPalette,
    setDefaultProperties,
} from '../Functions/Chart';

export type LineChartProps = CommonChartProps & {
    data: DataPoint[];
    updateFunction?: (index: number) => void;
};
export const LineChart = (props: LineChartProps) => {
    const { type, ...chartConfig } = props.chartConfig ?? {};
    const dataInput = props.data;
    const paletteInput = props.palette;
    const { chartsAreLoading, updateFunction, xLabels } = props;

    if (chartsAreLoading) {
        return (
            <div className='bar-chart-wrapper'>
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            </div>
        );
    }

    const palette = paletteInput ?? defaultPalette;

    const data = setDefaultProperties(dataInput, palette, 'spline');
    const dataset = dataPointsToDataset(data);

    const fcConfig = {
        width: '500',
        height: '350',
        type: type ?? 'mscombi2d',
        dataFormat: 'json',
        dataSource: {
            dataset,
            categories: [
                {
                    category: xLabels.map((l) => ({ label: l })),
                },
            ],
            chart: {
                ...defaultChartConfig,
                ...chartConfig,
            },
        },
        ...props,
    };

    return (
        <div className='bar-chart-wrapper'>
            {data.length > 0 ? (
                <>
                    <div className='legend-buttons-wrapper '>
                        {data.map((element, index) => (
                            <div
                                onClick={() => updateFunction?.(index)}
                                className={`legends-button ${!element.hidden && ' checked'}`}
                                style={{ backgroundColor: element.color }}
                                key={'series' + index}
                            >
                                <div
                                    className='legends-icon'
                                    style={{ backgroundColor: element.color }}
                                ></div>
                                {element.seriesName}
                            </div>
                        ))}
                    </div>
                    <div className='chart-wrapper'>
                        <ReactFC {...fcConfig} />
                    </div>
                </>
            ) : (
                <div className='chart-data-missing'>{Texts.chart_data_missing__InfoText}</div>
            )}
        </div>
    );
};
