import ProfitAndLossRow from '../../../Types/Api/Response/ProfitAndLoss';
import Year from '../Types/Year';

const GetCalendarYearsFromPnL = (profitAndLossAggregated: ProfitAndLossRow[]): Year[] => {
    let allAvailableYears: Year[] = [];

    let currentYearObject: Year | undefined = undefined;

    const firstDayOfMonth = (month: string) => `${month.substring(0, 8)}01`;

    profitAndLossAggregated.forEach((pnl) => {
        if (
            currentYearObject === undefined ||
            pnl.period.substring(0, 4) !== currentYearObject.label
        ) {
            currentYearObject = {
                label: pnl.period.substring(0, 4),
                months: [
                    {
                        from: firstDayOfMonth(pnl.period),
                        to: pnl.period.substring(0, 10),
                    },
                ],
            };
            allAvailableYears.push({ ...currentYearObject });
        } else {
            currentYearObject!.months.push({
                from: firstDayOfMonth(pnl.period),
                to: pnl.period.substring(0, 10),
            });
        }
    });
    for (let i = 0; i <= profitAndLossAggregated.length; i++) {}
    return allAvailableYears.sort().reverse();
};

export default GetCalendarYearsFromPnL;
