import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import FusionCharts from 'fusioncharts';

FusionCharts.options.license({
    key: 'OuB3exvA8C7C3E6A5G4D3A2I3C8B1B4B1udnE4H1cgkA-13lB4A3C-7izA5C5B3D6F5F4E4E1B9A6C3F5F6E1A2E1mllC7E2B4dD4F3H3yxoF4E2D3isbB6C1E3ycaC1G4B1B8B4A4B3D3B4H3A33fhhD8B1SG4lhJ-7A9C11A5veE3NA1A1sslE2D6G2F3H3J3B8A5A4D4F4C2C2H4z==',
    creditLabel: false,
});

ReactDOM.render(<App />, document.getElementById('root'));
