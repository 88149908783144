export const DatetimeAsDate = (datetimeString: string | null) => {
    if (datetimeString === null) {
        return null;
    }
    var d = new Date(datetimeString),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return [year, month, day].join('-');
};

export const DatetimeAsYearMonth = (datetimeString: string) => {
    let outData = '-';
    if (datetimeString && datetimeString !== '' && datetimeString.length > 6) {
        return datetimeString.substring(0, 7);
    }
    return outData;
};

export const DatetimeAsMonth = (period: string) => {
    let monthOfPeriod = '12';
    monthOfPeriod = period.split('-')[1];
    return monthOfPeriod;
};

export const NumberAsEuropeanDecimalNumber = (unformattedNumber: number | string) => {
    let newDecimalNumber = '-';
    if (typeof unformattedNumber === 'number' && !isNaN(unformattedNumber)) {
        newDecimalNumber = (Math.round(unformattedNumber * 10) / 10).toString();
        newDecimalNumber = newDecimalNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        newDecimalNumber = newDecimalNumber.replace('.', ',');
    }
    if (newDecimalNumber.indexOf(',') === -1 && newDecimalNumber !== '-') {
        newDecimalNumber += ',0';
    }
    return newDecimalNumber;
};

export const FinancialYearAsYearMonth = (inData: string) => {
    let outData = '-';
    if (inData.indexOf(':') !== -1) {
        outData = inData.split(':')[1];
        outData = outData.split('-')[0].trim() + '-' + outData.split('-')[1].trim();
    }
    return outData;
};

export const FinancialYearAsMonthDate = (inData: string | null) => {
    let outData = '-';
    if (inData && inData.indexOf('T') !== -1) {
        outData = inData.split('T')[0];
        outData = outData.split('-')[2].trim() + '/' + outData.split('-')[1].trim();
    }
    return outData;
};

export const PeriodAsYearMonth = (inData: string) => {
    let outData = inData;
    outData = inData.split('-')[0].trim() + '-' + inData.split('-')[1].trim();
    return outData;
};

export const FinancialYearAsYearBreakMonth = (inData: string) => {
    if (!inData) {
        return;
    }

    let currentYear = new Date().getFullYear() + '';
    let lastMonth = new Date().getMonth().toString();

    if (lastMonth === '0') {
        lastMonth = '01'; // For now, maybe should be rewritten as ignore whole year if month is less than 2
    }

    lastMonth = '0' + lastMonth;
    lastMonth = lastMonth.replace('00', '0');
    if (lastMonth.length === 3) {
        lastMonth = lastMonth.substring(1);
    }

    let outData = inData;
    if (inData.indexOf(':') !== -1) {
        outData = inData.split(' : ')[1];
        outData = outData.split('-')[0] + '-' + outData.split('-')[1].trim();

        if (outData.split('-')[0] === currentYear && outData.split('-')[1] > lastMonth) {
            outData = currentYear + '-' + lastMonth;
        }
    }

    return outData;
};

export const FormatNumber = (inData: number) => {
    let outData = '-';
    if (typeof inData === 'number' && !isNaN(inData)) {
        outData = Math.round(inData).toString();
        outData = outData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return outData;
};

export const FormatFinancialRatioNumber = (inData: number | string) => {
    let outData = '-';
    if (typeof inData === 'number') {
        outData = (Math.round(inData * 10) / 10).toString();
        outData = outData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return outData;
};

export const FormatOrganizationNumber = (orgId: string) => {
    if (orgId && orgId.length === 10) {
        return `${orgId.substring(0, 6)}-${orgId.substring(6, 10)}`;
    }
    return orgId;
};
