import React from 'react';
import Texts from '../Functions/Texts.json';
import { NavLink } from 'react-router-dom';

const AllCompaniesMenu = () => {
    const reloadIfSameUrl = (linkUrl: string) => {
        const windowUrl = document.location.href
            .replace(window.location.hostname, '')
            .replace(':' + window.location.port, '')
            .replace(window.location.protocol + '//', '');
        if (linkUrl === windowUrl) {
            document.location.reload();
        }
    };

    return (
        <div className='top-buttons'>
            <NavLink
                onClick={() => {
                    reloadIfSameUrl('/');
                }}
                end
                className={({ isActive }) => (isActive ? 'current' : '')}
                to={'/'}
            >
                {Texts.summary__DashboardSubmenuItemText}
            </NavLink>
            <NavLink
                onClick={() => {
                    reloadIfSameUrl('/compare-companies/');
                }}
                end
                className={({ isActive }) => (isActive ? 'current' : '')}
                to={'/compare-companies/'}
            >
                {Texts.compare_companies__DashboardSubmenuItemText}
            </NavLink>
            <NavLink
                onClick={() => {
                    reloadIfSameUrl('/companies-basic-data/');
                }}
                end
                className={({ isActive }) => (isActive ? 'current' : '')}
                to={'/companies-basic-data/'}
            >
                {Texts.basic_data__DashboardSubmenuItemText}
            </NavLink>
            <NavLink
                onClick={() => {
                    reloadIfSameUrl('/group-overview/');
                }}
                end
                className={({ isActive }) => (isActive ? 'current' : '')}
                to={'/group-overview/'}
            >
                {Texts.group_overview.title}
            </NavLink>
        </div>
    );
};

export default AllCompaniesMenu;
