import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import {
    ColumnData,
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../../Components/NewColumnBasedTable';
import { useParams } from 'react-router-dom';
import { concatenateOfficialData } from '../../Functions/Functions';
import { format, min, sub } from 'date-fns';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetBalanceSheetsAggregated from '../../Api/Reports/useGetBalanceSheetsAggregated';
import useGetOfficialBalanceSheets from '../../Api/Reports/useGetOfficialBalanceSheets';
import OfficialBalanceSheetRow from '../../Types/Api/Response/OfficialBalanceSheetRow';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

type ExpandedBalanceSheetRow = Partial<BalanceSheetRow> &
    Partial<OfficialBalanceSheetRow> &
    ColumnData & { isOfficial?: boolean; otherOwnEquity: number };

const TableRow: (
    props: TableRowProps<ExpandedBalanceSheetRow>,
) => TableRowInstance<ExpandedBalanceSheetRow> = TableRowBase;

const BalanceSheets = () => {
    const { organizationNumber } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: balanceSheetsAggregated } = useGetBalanceSheetsAggregated(organizationId);
    const { data: officialBalanceSheets } = useGetOfficialBalanceSheets(organizationId);

    const concatData =
        officialBalanceSheets &&
        balanceSheetsAggregated &&
        concatenateOfficialData(officialBalanceSheets.data, balanceSheetsAggregated.data);
    const data = concatData?.map((d) => {
        if (d.isOfficial) {
            const f = d as OfficialBalanceSheetRow;
            return {
                ...f,
                restrictedEquity:
                    f.shareCapital +
                    f.sharePremiumReserve +
                    f.revaluationReserve +
                    f.otherStockholderEquity,
                otherOwnEquity:
                    f.profitOrLossBroughtForward +
                    f.groupContribution +
                    f.shareholdersContribution +
                    f.netProfitOrLossForYear,
                columnClassName: '',
            };
        } else {
            const e = d as BalanceSheetRow;
            return {
                ...e,
                accountsReceivableCorporateGroupOrInterestGroup:
                    e.accountsReceivableGroupCompanies +
                    e.accountsReceivableFromAssociatedCompanies,
                otherOwnEquity: e.nonRestrictedEquity + e.otherEquitySoleTrader,
                liabilitiesToCreditInstitutionsLongTerm:
                    e.otherLongTermDebtToCreditors + e.bankAdvancesLongTerm,
                liabilitiesToGroupOrAssociatedCompaniesLongTerm:
                    e.longTermLiabilitiesToGroupCompanies +
                    e.longTermLiabilitiesToAssociatedCompanies,
                liabilitiesToCreditInstitutionsShortTerm:
                    e.bankAdvancesShortTerm + e.otherCurrentLiabilitiesToCreditInstitutions,
                liabilitiesToGroupOrAssociatedCompaniesShortTerm:
                    e.accountsPayableAndCurrentLiabilitiesToGroupCompanies +
                    e.accountsPayableAndCurrentLiabilitiesToAssociatedCompanies,
                columnClassName: 'my-numbers-data',
            };
        }
    });

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['balance-sheets']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/balance-sheets/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'view-option-links by-year current ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-accounts/}`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts ',
                    },
                ]}
            />
            <NewColumnBasedTable
                columnWidth={190}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={true}
                data={data}
                csvExportFilename={`${Texts.balance_sheets__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                pdfExportFilename={{
                    prefix: Texts.balance_sheets__DownloadFileNamePrefix,
                    companyName: companyDetails?.data.organizationName ?? '',
                    orgNr: companyDetails?.data.organizationNumber ?? '',
                }}
                stepHeader={(first, last) => (
                    <>
                        {format(new Date(first.financialYearTo), 'yyyy-MM')}
                        {last && ' - ' + format(new Date(last.financialYearTo), 'yyyy-MM')}
                    </>
                )}
                style={{ maxWidth: 960 }}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) =>
                        d.isOfficial ? Texts.official__TableHeader : Texts.mynumbers__TableHeader
                    }
                />
                <TableRow
                    className='bold'
                    label={Texts.balance_sheets__ViewTitle + ' ' + Texts.tkr__TableLabel}
                    selector={(d) =>
                        format(
                            min([new Date(d.financialYearTo!), sub(new Date(), { months: 1 })]),
                            'yyyy-MM',
                        )
                    }
                />
                <TableRow
                    className='tiny-row'
                    label={Texts.financial_year__BalaceSheetsTableLabel}
                    selector={(d) => d.financialYear!.replace(/\s*:\s*/, ' - ')}
                />
                <TableRow
                    className='level-1-header'
                    label={Texts.assets__BalaceSheetsTableHeader}
                />
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__intangibleAssets}
                    selector={(d) => (d.intangibleAssets ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__researchAndDevelopment}
                        selector={(d) => (d.researchAndDevelopment ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__patentAndLicensesEtc}
                        selector={(d) => (d.patentAndLicensesEtc ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__goodwill}
                        selector={(d) => (d.goodwill ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__totalTangibleAssets}
                    selector={(d) => (d.totalTangibleAssets ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__buildingsAndFreeholdLand}
                        selector={(d) => (d.buildingsAndFreeholdLand ?? 0) / 1000}
                        doCompare={true}
                    />
                    <TableRow
                        label={Texts.balancesheets__equipmentsAndTools}
                        selector={(d) => (d.equipmentsAndTools ?? 0) / 1000}
                        doCompare={true}
                    >
                        <TableRow
                            label={Texts.balancesheets__plantAndMachinery}
                            selector={(d) => (d.plantAndMachinery ?? 0) / 1000}
                        />
                        <TableRow
                            label={Texts.balancesheets__equipmentToolsTransportAndInstallation}
                            selector={(d) => (d.equipmentToolsTransportAndInstallation ?? 0) / 1000}
                        />
                        <TableRow
                            label={Texts.balancesheets__otherTangibleFixedAssets}
                            selector={(d) => (d.otherTangibleFixedAssets ?? 0) / 1000}
                        />
                    </TableRow>
                </TableRow>
                <TableRow
                    label={Texts.balancesheets__financialFixAssets}
                    selector={(d) => (d.financialFixAssets ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__groupShare}
                        selector={(d) => (d.groupShare ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__longTermRecievablesCorporateGroup}
                        selector={(d) => (d.longTermRecievablesCorporateGroup ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__loanCoOwners}
                        selector={(d) => (d.loanCoOwners ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__otherFinancialFixAssets}
                        selector={(d) => (d.otherFinancialFixAssets ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='summary'
                    label={Texts.balancesheets__totalFixAssets}
                    selector={(d) => (d.totalFixAssets ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow className='empty-row' />

                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__warehouseAndWorkInProgress}
                    selector={(d) => (d.warehouseAndWorkInProgress ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__totalAccountsReceivable}
                    selector={(d) => (d.totalAccountsReceivable ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__accountsReceivableTrade}
                        selector={(d) => (d.accountsReceivableTrade ?? 0) / 1000}
                    />
                    <TableRow
                        label={
                            Texts.officialbalancesheets__accountsReceivableCorporateGroupOrInterestGroup
                        }
                        selector={(d) =>
                            (d.accountsReceivableCorporateGroupOrInterestGroup ?? 0) / 1000
                        }
                    />
                    <TableRow
                        label={Texts.balancesheets__otherAccountsReceivable}
                        selector={(d) => (d.otherAccountsReceivable ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__otherCurrentReceivablesAggregated}
                    selector={(d) => (d.otherCurrentReceivablesAggregated ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow
                    label={Texts.balancesheets__cashAndCashEquivalent}
                    selector={(d) => (d.cashAndCashEquivalent ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__shortTermInvestments}
                        selector={(d) => (d.shortTermInvestments ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__cashAndBank}
                        selector={(d) => (d.cashAndBank ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='summary'
                    label={Texts.balancesheets__totalLiquidAssets}
                    selector={(d) => (d.totalLiquidAssets ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow
                    className='level-1-footer'
                    label={Texts.balancesheets__totalAssets}
                    selector={(d) => (d.totalAssets ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow className='empty-row' />

                <TableRow
                    className='level-1-header'
                    label={Texts.equity_and_debt__BalanceSheetsTableHeader}
                />
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__equity}
                    selector={(d) => (d.equity ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__restrictedEquity}
                        selector={(d) => (d.restrictedEquity ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.non_restricted_equity_other_equity__BalanceSheetsTableLabel}
                        selector={(d) => (d.otherOwnEquity ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__untaxedReserves}
                    selector={(d) => (d.untaxedReserves ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__provision}
                    selector={(d) => (d.provision ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow
                    className='line-under'
                    label={Texts.balancesheets__totalLongTermLiabilities}
                    selector={(d) => (d.totalLongTermLiabilities ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__mandatoryLongTermDebt}
                        selector={(d) => (d.mandatoryLongTermDebt ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.officialbalancesheets__liabilitiesToCreditInstitutionsLongTerm}
                        selector={(d) => (d.liabilitiesToCreditInstitutionsLongTerm ?? 0) / 1000}
                    />
                    <TableRow
                        label={
                            Texts.officialbalancesheets__liabilitiesToGroupOrAssociatedCompaniesLongTerm
                        }
                        selector={(d) =>
                            (d.liabilitiesToGroupOrAssociatedCompaniesLongTerm ?? 0) / 1000
                        }
                    />
                    <TableRow
                        label={Texts.balancesheets__otherLongTermLiabilities}
                        selector={(d) => (d.otherLongTermLiabilities ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    label={Texts.balancesheets__totalCurrentLiabilities}
                    selector={(d) => (d.totalCurrentLiabilities ?? 0) / 1000}
                    doCompare={true}
                >
                    <TableRow
                        label={
                            Texts.officialbalancesheets__liabilitiesToCreditInstitutionsShortTerm
                        }
                        selector={(d) => (d.mandatoryLongTermDebt ?? 0) / 1000}
                    />
                    <TableRow
                        label={Texts.balancesheets__accountsPayableTrade}
                        selector={(d) => (d.accountsPayableTrade ?? 0) / 1000}
                    />
                    <TableRow
                        label={
                            Texts.officialbalancesheets__liabilitiesToGroupOrAssociatedCompaniesShortTerm
                        }
                        selector={(d) =>
                            (d.liabilitiesToGroupOrAssociatedCompaniesLongTerm ?? 0) / 1000
                        }
                    />
                    <TableRow
                        label={Texts.balancesheets__otherCurrentLiabilities}
                        selector={(d) => (d.otherCurrentLiabilities ?? 0) / 1000}
                    />
                </TableRow>
                <TableRow
                    className='level-1-footer'
                    label={Texts.balancesheets__sumOfLiabilitiesAndEquity}
                    selector={(d) => (d.sumOfLiabilitiesAndEquity ?? 0) / 1000}
                    doCompare={true}
                />
                <TableRow className='empty-row' />

                <TableRow
                    className='summary'
                    label={Texts.calculated_result__BalanceSheetsTableLabel}
                    selector={(d) => {
                        const numerator = (d.totalAssets ?? 0) - (d.sumOfLiabilitiesAndEquity ?? 0);
                        return numerator !== 0 ? numerator / 1000 : '-';
                    }}
                    doCompare={true}
                />
            </NewColumnBasedTable>
            <span className='official-data-disclaimer'>
                {Texts.official_data_from__DisclaimerText}
            </span>
        </ErrorBoundary>
    );
};

export default BalanceSheets;
