import { useQuery } from 'react-query';

import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import axiosInstance from '../../Core/Axios';
import UseQueryOptions from '../UseQueryOptions';

const useGetPnLOfficialQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<Array<ProfitAndLossRow>>,
) =>
    useQuery(
        ['GetPnLOfficial', companyId],
        async () =>
            axiosInstance.get<Array<ProfitAndLossRow>>(
                `Reports/profitAndLoss/${companyId}/official`,
            ),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetPnLOfficialQuery;
