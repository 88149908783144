import React from 'react';
import Texts from '../Functions/Texts.json';
import {
    ColumnBasedTableProps,
    ColumnData,
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from './NewColumnBasedTable';
import CashflowRow from '../Types/Api/Response/CashflowRow';

export type ExpandedCashflowRow = CashflowRow & ColumnData;

// This is done to fix the generic parameter, so that it doesn't need to be entered in every row.
const TableRow: (
    props: TableRowProps<ExpandedCashflowRow>,
) => TableRowInstance<ExpandedCashflowRow> = TableRowBase;

export type CashflowTableProps = ColumnBasedTableProps<CashflowRow> & {
    accumulatedCashflowLabel: string;
};
export const CashflowTable = ({
    children,
    data,
    accumulatedCashflowLabel,
    ...props
}: CashflowTableProps) => {
    return (
        <NewColumnBasedTable<ExpandedCashflowRow>
            data={data?.map((d) => ({
                ...d,
                columnClassName: 'my-numbers-data',
            }))}
            {...props}
        >
            {children as any /* it works */}

            <TableRow
                className='bold line-under'
                label={accumulatedCashflowLabel}
                selector={(d) => d.totalCashFlowAndLiquidity - d.bankOpeningBalance}
                doCompare={true}
            />
            <TableRow
                label={Texts.cashflow__bankOpeningBalanceMonths}
                selector={(d) => d.bankOpeningBalance}
            />
            <TableRow
                label={Texts.cashflow__totalCashFlowAndLiquidityMonths}
                selector={(d) => d.totalCashFlowAndLiquidity}
            />
            <TableRow />
            <TableRow
                className='line-under'
                label={Texts.cashflow__cashFlowFromOperationsPL}
                selector={(d) => d.cashFlowFromOperationsPL}
                doCompare={true}
            >
                <TableRow
                    label={Texts.cashflow__totalOperatingIncome_CF}
                    selector={(d) => d.totalOperatingIncome_CF}
                />
                <TableRow label={Texts.cashflow__directCosts} selector={(d) => d.directCosts} />
                <TableRow
                    label={Texts.cashflow__otherOperatingExpenses_CF}
                    selector={(d) => d.otherOperatingExpenses_CF}
                />
                <TableRow
                    label={Texts.cashflow__personnelCosts_CF}
                    selector={(d) => d.personnelCosts_CF}
                />
                <TableRow
                    label={Texts.cashflow__financialIncomes_CF}
                    selector={(d) => d.financialIncomes_CF}
                />
                <TableRow
                    label={Texts.cashflow__financialExpenses_CF}
                    selector={(d) => d.financialExpenses_CF}
                />
                <TableRow
                    label={Texts.cashflow__extraordinaryIncomeAndExpenses_CF}
                    selector={(d) => d.extraordinaryIncomeAndExpenses_CF}
                />
                <TableRow label={Texts.cashflow__tax_CF} selector={(d) => d.tax_CF} />
            </TableRow>
            <TableRow
                label={Texts.cashflow__cashFlowFromCapitalBalanceSheet}
                selector={(d) => d.cashFlowFromCapitalBalanceSheet}
                doCompare={true}
            >
                <TableRow
                    label={Texts.cashflow__changeInWarehouseAndWorkInProgress}
                    selector={(d) => d.changeInWarehouseAndWorkInProgress}
                />
                <TableRow
                    label={Texts.cashflow__changeInAccountsReceivable}
                    selector={(d) => d.changeInAccountsReceivable}
                />
                <TableRow
                    label={Texts.cashflow__changeInOtherCurrentReceivables}
                    selector={(d) => d.changeInOtherCurrentReceivables}
                />
                <TableRow
                    label={Texts.cashflow__changeInCurrentLiabilities}
                    selector={(d) => d.changeInCurrentLiabilities}
                />
            </TableRow>
            <TableRow
                className='bold summary'
                label={Texts.cashflow__accumulatedCashFlowFromOperatingActivities}
                selector={(d) => d.accumulatedCashFlowFromOperatingActivities}
                doCompare={true}
            />
            <TableRow />
            <TableRow
                label={Texts.cashflow__cashFlowFromInvestments}
                selector={(d) => d.cashFlowFromInvestments}
                doCompare={true}
            >
                <TableRow
                    label={Texts.fixed_assets_change__CashflowTableLabel}
                    selector={(d) => d.cashFlowFromInvestments}
                />
            </TableRow>
            <TableRow
                label={Texts.cashflow__cashFlowFromFinancing}
                selector={(d) => d.cashFlowFromFinancing}
                doCompare={true}
            >
                <TableRow
                    label={Texts.cashflow__netChangeInTheLoans}
                    selector={(d) => d.netChangeInTheLoans}
                />
                <TableRow
                    label={Texts.cashflow__netChangeInEquity}
                    selector={(d) => d.netChangeInEquity}
                />
            </TableRow>
            <TableRow
                className='bold summary'
                label={Texts.cashflow_from_investments_and_financing__CashflowTableLabel}
                selector={(d) => d.cashFlowFromFinancing + d.cashFlowFromInvestments}
                doCompare={true}
            />
            <TableRow
                className='bold summary'
                label={accumulatedCashflowLabel}
                selector={(d) => d.accumulatedCashFlow}
            />
        </NewColumnBasedTable>
    );
};
