import { useMutation } from 'react-query';
import UseMutationOptions from '../UseMutationOptions';
import { SavedBudget } from '../../Functions/Budget';
import axiosInstance from '../../Core/Axios';

type PutBudgetRequest = {
    budget: SavedBudget;
    organizationId: string;
};

const usePutBudgetMutation = (options?: UseMutationOptions<PutBudgetRequest, SavedBudget>) =>
    useMutation(
        ({ budget, organizationId }: PutBudgetRequest) =>
            axiosInstance.put<SavedBudget>(
                `budget/myBudgets/${organizationId}/${budget.id}`,
                budget,
            ),
        options,
    );

export default usePutBudgetMutation;
