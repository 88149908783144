import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import DisabledCompany from '../../Types/Api/Response/DisabledCompany';

const useGetDisabledCompaniesListQuery = (
    licenseChoiceQueryString: string,
    options?: UseQueryOptions<DisabledCompany[]>,
) =>
    useQuery(
        ['disabledCompanies', licenseChoiceQueryString],
        async () =>
            axiosInstance.get<DisabledCompany[]>(
                `corporatePortal/DisabledCompaniesList?${licenseChoiceQueryString}`,
            ),
        options,
    );

export default useGetDisabledCompaniesListQuery;
