import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { CurrentYear } from '../../Functions/Calculations';
import { useParams } from 'react-router-dom';
import { ColumnData, TableRow } from '../../Components/NewColumnBasedTable';
import { ProfitAndLossTable } from '../../Components/ProfitAndLossTable';
import { format, isPast, startOfMonth } from 'date-fns';
import { sortBy } from '../../Functions/Lists';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const ProfitAndLossByMonth = () => {
    const params = useParams();
    const organizationNumber = params.organizationNumber;
    const { organizationId } = useGetOrgId(params);
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: profitAndLoss } = useGetPnLQuery(organizationId);

    const sorted = profitAndLoss && sortBy(profitAndLoss.data, 'period').reverse();
    const data = sorted
        ?.filter((d) => isPast(startOfMonth(new Date(d.period))))
        .map((d) => ({ ...d, columnClassName: 'my-numbers-data' }));

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['profit-and-loss']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/profit-and-loss/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'by-year',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month current',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-accounts/${CurrentYear()}`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-waterfall/`,
                        text: Texts.charts__ViewOptionButtonText,
                        className: 'view-option-links by-chart',
                    },
                ]}
            />
            <ProfitAndLossTable
                columnWidth={170}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={false}
                data={data}
                csvExportFilename={`${Texts.profit_and_loss_by_month__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                stepHeader={(first, last) => (
                    <>
                        {format(new Date(first.period), 'yyyy-MM-dd')}
                        {last && ' - ' + format(new Date(last.period), 'yyyy-MM-dd')}
                    </>
                )}
                style={{ marginRight: 90 }}
                netProfitLabel={Texts.netProfit_monthly}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & { period: string }>
                    className='bold'
                    label={Texts.profit_and_loss__ViewTitle}
                    selector={(d) => format(new Date(d.period), 'yyyy-MM-dd')}
                />
            </ProfitAndLossTable>
        </ErrorBoundary>
    );
};

export default ProfitAndLossByMonth;
