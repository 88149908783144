import React, { useState, useEffect } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import {
    IsNewSinceTwoDays,
    IsNewSinceLastLogin,
    SortByTextAndNull,
} from '../Functions/Calculations';
import { DatetimeAsDate, FormatOrganizationNumber } from '../Functions/Formatting';
import { useRecoilValue } from 'recoil';
import { userLastLoginState } from '../Core/State/UserState';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import { companiesState } from '../Core/State/CompanyListState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import CompanyList from '../Types/Api/Response/CompanyList';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';

const NewCompanies = () => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const userLastLogin = useRecoilValue(userLastLoginState);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );
    const { getCompanyList } = useGetCompanyListRequest();

    const setupTable = (tableData: CompanyList | null): RowTable => {
        if (!tableData) {
            return emptyTable;
        }

        let consentDateOrCompanyTypeText = Texts.company_form__CompaniesTableLabel;
        if (licenseChoice.type === 'creditor') {
            consentDateOrCompanyTypeText = Texts.connected__CompaniesTableLabel;
        }

        const rows: RowTableRow[] = [];
        let table: RowTable = {
            head: [
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small right',
                },
                {
                    label: Texts.updated__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small right',
                },
                {
                    label: consentDateOrCompanyTypeText,
                    type: 'sort',
                    className: 'small right',
                },
                {
                    label: Texts.status__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small',
                },
                {
                    label: Texts.erp_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small',
                },
                {
                    label: Texts.erp_connection_info__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small',
                },
            ],
            rows: [],
            actions: [],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        let newCompanies = tableData.companies;

        newCompanies.forEach((newCompany) => {
            if (IsNewSinceLastLogin(userLastLogin, newCompany.consentDate)) {
                const latestVoucher = newCompany.latestVoucher
                    ? DatetimeAsDate(newCompany.latestVoucher!)
                    : null;
                const latestUpload = newCompany.latestUpload
                    ? DatetimeAsDate(newCompany.latestUpload)
                    : null;

                const consentDateOrLatestVoucher =
                    newCompany.consentDate !== null && licenseChoice.type === 'creditor'
                        ? DatetimeAsDate(newCompany.consentDate)
                        : DatetimeAsDate(latestVoucher);

                let extractorType = Texts.automatic__TableCellValue;
                if (newCompany.extractorType === 'SIE4') {
                    extractorType = Texts.manual__TableCellValue;
                }
                if (newCompany.extractorType == null) {
                    extractorType = '-';
                }

                let consentStatus: JSX.Element = <>{Texts.active__TableCellValue}</>;
                if (newCompany.consentStatus === 'DISABLED') {
                    consentStatus = (
                        <span className='status-not-ok'>{Texts.disabled__TableCellValue}</span>
                    );
                }
                if (
                    newCompany.consentStatus === 'OK' &&
                    IsNewSinceTwoDays(newCompany.latestUpload)
                ) {
                    consentStatus = (
                        <span className='status-ok'>{Texts.active__TableCellValue}</span>
                    );
                }
                if (newCompany.consentStatus === 'OK' && newCompany.latestUpload == null) {
                    consentStatus = (
                        <span className='status-not-ok'>{Texts.data_missing__TableCellValue}</span>
                    );
                }
                if (
                    newCompany.consentStatus === 'OK' &&
                    !IsNewSinceTwoDays(newCompany.latestUpload) &&
                    newCompany.latestUpload != null
                ) {
                    consentStatus = (
                        <span className='status-neutral'>
                            {Texts.not_daily_update__TableCellValue}
                        </span>
                    );
                }

                let row: RowTableRow = {
                    metaInformation: {
                        visible: true,
                    },
                    cells: [
                        {
                            text: newCompany.organizationName,
                            link:
                                '/company/' +
                                FormatOrganizationNumber(newCompany.organizationNumber),
                            className: 'large',
                        },
                        {
                            text: FormatOrganizationNumber(newCompany.organizationNumber),
                            link:
                                '/company/' +
                                FormatOrganizationNumber(newCompany.organizationNumber),
                            className: 'small right',
                        },
                        {
                            text: latestUpload ?? '',
                            className: 'small right',
                        },
                        {
                            text: consentDateOrLatestVoucher ?? '-',
                            className: 'small right',
                        },
                        {
                            text: consentStatus,
                            className: 'small',
                        },
                        {
                            text: newCompany.program,
                            className: 'small',
                        },
                        {
                            text: extractorType,
                            className: 'small',
                        },
                    ],
                };
                rows.push(row);
            }
        });

        table.rows = rows != null && rows.length > 0 ? rows.sort(SortByTextAndNull(0)) : [];

        return table;
    };

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(companyList);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['companies', 'dashboard']}
                />
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                toggleHelp={() => setHelpIsOpen(true)}
                viewTitle={Texts.dashboard_new_companies__NewCompaniesViewTitle}
            />
            <RowBasedTableWrapper>
                <RowBasedTable table={table} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default NewCompanies;
