import { FallbackProps } from 'react-error-boundary';
import Texts from '../../Functions/Texts.json';
import Alert from '../Alert';

const ErrorFallback = ({ error }: FallbackProps) => {
    /**
     * TODO: Add Application insights logging of the error here
     */
    console.warn({ error });
    return (
        <Alert message={Texts.something_seems_to_have_gone_wrong__ErrorMessage} severity='error' />
    );
};

export default ErrorFallback;
