import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { useParams } from 'react-router-dom';
import { sortBy } from '../../Functions/Lists';
import { format, isPast, startOfMonth } from 'date-fns';
import { BalanceSheetsTable } from '../../Components/BalanceSheetsTable';
import { ColumnData, TableRow } from '../../Components/NewColumnBasedTable';
import { QuarterHolder, addQuarters } from '../../Functions/Functions';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetBalanceSheets from '../../Api/Reports/useGetBalanceSheets';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

type QuarterExpandedBalanceSheetRow = BalanceSheetRow & Partial<QuarterHolder>;

const BalanceSheetsByQuarter = () => {
    const { organizationNumber } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });

    const { data: balanceSheets } = useGetBalanceSheets(organizationId);

    const sorted = balanceSheets && sortBy(balanceSheets.data, 'period').reverse();
    const grouped = sorted
        ?.filter((d) => isPast(startOfMonth(new Date(d.period))))
        .map((d) => addQuarters(d))
        .reduce(
            (
                { set, list }: { set: Set<string>; list: Array<BalanceSheetRow & QuarterHolder> },
                current,
            ) => {
                // We only want the last of each period, since balance sheets are not additive.
                if (set.has(current.quarterPeriod)) {
                    return { set, list };
                }
                return {
                    set: set.add(current.quarterPeriod),
                    list: [...list, current],
                };
            },
            { set: new Set<string>(), list: [] },
        );
    const data = grouped
        ? grouped?.list.map((b) => ({ ...b, columnClassName: 'my-numbers-data' }))
        : [];

    const quarterOfYear = (d: QuarterExpandedBalanceSheetRow) =>
        `Q${d.quarter} ${format(new Date(d.financialYearTo), 'yyyy')}`;

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['balance-sheets']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/balance-sheets/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'view-option-links by-year ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month current ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month ',
                    },
                    {
                        link: `/company/${organizationNumber}/balance-sheets-accounts/`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts ',
                    },
                ]}
            />
            <BalanceSheetsTable
                columnWidth={190}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={true}
                data={data}
                csvExportFilename={`${Texts.balance_sheets_by_quarter__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                stepHeader={(first, last) => (
                    <>
                        {quarterOfYear(first)}
                        {last && ` - ${quarterOfYear(last)}`}
                    </>
                )}
                style={{ marginRight: 90 }}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & QuarterExpandedBalanceSheetRow>
                    className='bold'
                    label={Texts.balance_sheets__ViewTitle}
                    selector={quarterOfYear}
                />
                <TableRow<ColumnData & { quarterPeriod: string }>
                    className='tiny-row'
                    label={Texts.balancesheets__period}
                    selector={(d) => d.quarterPeriod}
                />
            </BalanceSheetsTable>
        </ErrorBoundary>
    );
};

export default BalanceSheetsByQuarter;
