import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import axiosInstance from '../../Core/Axios';
import CompanyDetails from '../../Types/Api/Response/CompanyDetails';
import ListMetaInformation from '../../Types/Api/Response/ListMetaInformation';
import CompanyList from '../../Types/Api/Response/CompanyList';
import { companiesState, orgNrToIdMapState } from '../../Core/State/CompanyListState';
import { range } from '../../Functions/Lists';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import { tableIsLoadingState } from '../../Core/State/SessionSettingsState';

const useGetCompanyListRequest = () => {
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const [companyList, setCompanyList] = useRecoilState(companiesState);
    const setTableIsLoadingState = useSetRecoilState(tableIsLoadingState);
    const setOrgNrToIdMap = useSetRecoilState(orgNrToIdMapState);

    const requestPage = async (page: number) => {
        const endpoint = `corporatePortal/CompanyList?page=${page}&${licenseChoice.queryString}`;
        const res = await axiosInstance.get<CompanyList>(endpoint);
        return res.data;
    };

    const updateCompanyState = (
        companies: CompanyDetails[],
        metaInformation: ListMetaInformation,
    ) => {
        setCompanyList({
            companies,
            metaInformation: {
                ...metaInformation,
                allSelected: 0,
                sortReverse: 0,
            },
        });
        setOrgNrToIdMap(Object.fromEntries(companies.map((c) => [c.organizationNumber, c.id])));
    };

    const getCompanyList = async () => {
        if (companyList !== null) {
            return;
        }
        setTableIsLoadingState(true);
        const { companies, metaInformation } = await requestPage(1);

        // This is done so that the first page feels more responsive
        // (Similar to the behaviour before this function was refactored)
        updateCompanyState(companies, metaInformation);

        const pageResults = await Promise.all(
            range(metaInformation.totalPages - 1)
                .map((x) => x + 2)
                .map((page) => requestPage(page)),
        );
        const allCompanies = companies.concat(...pageResults.map((res) => res.companies));

        updateCompanyState(allCompanies, metaInformation);
        setTableIsLoadingState(false);
    };

    return { getCompanyList };
};

export default useGetCompanyListRequest;
