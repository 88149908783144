import User, { initialUserState } from '../Types/Context/User';
import LicenseChoiceModel from '../Types/Context/LicenseChoiceModel';
import { CorporatePortalUser } from '../Types/Api/Response/CorporatePortalUser';

export type ActivateTrialRequest = {
    sme: boolean;
    accountingFirms: string[];
};

export type ActivateLicenseRequest = {
    billingInterval: number;
    companyId: string;
    accountingFirmId: string;
    billingEmailAddress: string;
};

export type UnauthorizedResponse = {
    type: 'unauthorized';
    userName: string;
    userInfo: CorporatePortalUser;
};
export type SuccessResponse = {
    type: 'success';
    userInfo: CorporatePortalUser;
    license: LicenseChoiceModel | null; // null means force license choice
};

export const mapCorporatePortalUserToUser = (usrData: CorporatePortalUser): User => ({
    ...initialUserState,
    publicId: usrData.user.id,
    firstName: usrData.user.firstName,
    lastName: usrData.user.lastName,
    email: usrData.user.email,
    info: '',
    hasLicense: usrData.hasLicense,
    lastLogin: new Date(usrData.lastLogin),
    creditors: usrData.creditors,
    accountingFirms: usrData.accountingFirms,
    billingInterval: usrData.billingInterval,
    billingCompanyName: usrData.billingCompanyName,
    billingIntervalFrom: usrData.billingIntervalFrom,
    billingEmail: usrData.billingEmail,
    cancellationDate: usrData.cancellationDate,
    licenseValidUntil: usrData.licenseValidUntil,
    trialUntil: usrData.user.corporatePortalLicense
        ? new Date(usrData.user.corporatePortalLicense)
        : null,
});
