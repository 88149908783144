import { ReactNode } from 'react';
import TopBar from '../Components/TopBar';
import { AuthState } from './Authentication/AuthenticationContext';
import useAuthentication from './Authentication/useAuthentication';
import LicenseChoice from '../Components/LicenseChoice';
import SignInWrapper from '../Views/Authentication/SignInWrapper';

type Props = {
    children: ReactNode;
};

const ChromeWrapper = ({ children }: Props) => {
    const { authState } = useAuthentication();

    return (
        <>
            {authState === AuthState.unauthorized ? (
                <SignInWrapper>{children}</SignInWrapper>
            ) : authState === AuthState.forceLicenseChoice ? (
                <SignInWrapper>
                    <LicenseChoice />
                </SignInWrapper>
            ) : (
                <div>
                    <TopBar />
                    <div className='content-wrapper'>{children}</div>
                </div>
            )}
        </>
    );
};

export default ChromeWrapper;
