import AccountingFirm from '../Api/Response/AccountingFirm';
import Creditor from '../Api/Response/Creditor';

export const initialUserState: User = {
    id: 0,
    publicId: '',
    firstName: '',
    lastName: '',
    email: '',
    info: '',
    hasLicense: false,
    creditorName: null,
    lastLogin: new Date('1970-01-01T00:00:00.001Z'),
    creditors: [],
    accountingFirms: [],
    billingInterval: null,
    billingCompanyName: '',
    billingIntervalFrom: null,
    billingEmail: '',
    cancellationDate: null,
    licenseValidUntil: null,
    trialUntil: null,
};

export type User = {
    id: number;
    publicId: string;
    firstName: string;
    lastName: string;
    email: string;
    info: string;
    hasLicense: boolean;
    creditorName: string | null;
    lastLogin: Date | null;
    creditors: Array<Creditor>;
    accountingFirms: Array<AccountingFirm>;
    billingInterval: number | null;
    billingCompanyName: string;
    billingIntervalFrom: Date | null;
    billingEmail: string;
    cancellationDate: Date | null;
    licenseValidUntil: Date | null;
    trialUntil: Date | null;
};

export default User;
