import { format, isPast } from 'date-fns';
import React, { useState } from 'react';
import { LineChart } from './LineChart';
import { SavedBudget } from '../Functions/Budget';
import { RenderAs } from '../Functions/Chart';
import { getPnlValue, pnlProperties } from '../Functions/ProfitAndLossChartHelpers';
import Texts from '../Functions/Texts.json';
import { sortBy } from '../Functions/Lists';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';

export type MonthlyCompareChartProps = {
    profitAndLoss: ProfitAndLossRow[] | undefined;
    profitAndLossAggregated: ProfitAndLossRow[] | undefined;
    budgets: SavedBudget[];
};
export const MonthlyCompareChart = ({
    profitAndLoss,
    profitAndLossAggregated,
    budgets,
}: MonthlyCompareChartProps) => {
    const [monthlyChartRenderAs, setMonthlyChartRenderAs] = useState<RenderAs>('spline');
    const [selectedCompare1, setSelectedCompare1] = useState(0);
    const [selectedCompare2, setSelectedCompare2] = useState(1);
    const [selectedCompareProperty, setSelectedCompareProperty] = useState(
        pnlProperties.find((p) => p.property === 'revenue')!,
    );

    const sortedProfitAndLoss = profitAndLoss && sortBy(profitAndLoss, 'period');
    const monthCompare = sortedProfitAndLoss &&
        profitAndLossAggregated &&
        budgets && [
            ...sortBy(profitAndLossAggregated, 'financialYear')
                .filter((y) => isPast(new Date(y.financialYearFrom)))
                .map((y) => ({
                    name: y.financialYear.split(' : ')[1],
                    yearlyProfitAndLoss: y,
                    monthlyProfitAndLoss: sortedProfitAndLoss.filter(
                        (m) => m.financialYear === y.financialYear && isPast(new Date(m.period)),
                    ),
                }))
                .reverse(),
            ...budgets,
        ];
    const monthlyChartIsLoading = !monthCompare;
    const monthlyDataMissing = monthCompare?.length === 0;

    return (
        <div className='chart-box legends-as-legends'>
            {!monthlyChartIsLoading && !monthlyDataMissing && (
                <>
                    <h4>
                        {Texts.company_dashboard.monthly_comparison}
                        <div
                            className={`render-as-switch ${
                                monthlyChartRenderAs === 'column' && 'active'
                            }`}
                            onClick={() =>
                                setMonthlyChartRenderAs(
                                    monthlyChartRenderAs === 'spline' ? 'column' : 'spline',
                                )
                            }
                        ></div>
                    </h4>

                    <div className='dropdowns-wrapper'>
                        <div className='secondary-legends'>
                            <span>{Texts.company_dashboard.choose_year}:</span>
                            {monthCompare.map((year, index) => (
                                <div
                                    onClick={() => setSelectedCompare1(index)}
                                    key={index}
                                    className={`secondary-legends-button ${
                                        index === selectedCompare1 && ' checked'
                                    } ${index === selectedCompare2 && ' hidden'} `}
                                >
                                    {year.name}
                                </div>
                            ))}
                        </div>
                        {monthCompare.length > 1 && (
                            <div className='secondary-legends'>
                                <span>{Texts.company_dashboard.compare_with}:</span>
                                {monthCompare.map((year, index) => (
                                    <div
                                        onClick={() => setSelectedCompare2(index)}
                                        key={index}
                                        className={`secondary-legends-button ${
                                            index === selectedCompare2 && ' checked'
                                        } ${index === selectedCompare1 && ' hidden'} `}
                                    >
                                        {year.name}
                                    </div>
                                ))}
                            </div>
                        )}

                        <div className='secondary-legends right'>
                            <span>{Texts.charts.key_figures}:</span>
                            {pnlProperties.map((element, index) => (
                                <div
                                    onClick={() => setSelectedCompareProperty(element)}
                                    className={`secondary-legends-button ${
                                        element === selectedCompareProperty && ' checked'
                                    }`}
                                    key={index}
                                >
                                    {element.label}
                                </div>
                            ))}
                        </div>
                    </div>
                    <LineChart
                        chartsAreLoading={!monthCompare}
                        data={[monthCompare[selectedCompare1], monthCompare[selectedCompare2]]
                            .filter((x) => !!x)
                            .map((y) => ({
                                seriesName: y.name,
                                values: y.monthlyProfitAndLoss.map((m) =>
                                    getPnlValue(m, selectedCompareProperty),
                                ),
                                renderAs: monthlyChartRenderAs,
                            }))}
                        // Chooses labels from the selected year that has the most data points
                        xLabels={[monthCompare[selectedCompare1], monthCompare[selectedCompare2]]
                            .reduce((x, y) => {
                                if (
                                    x.monthlyProfitAndLoss.length < y?.monthlyProfitAndLoss.length
                                ) {
                                    return y;
                                }
                                return x;
                            })
                            .monthlyProfitAndLoss.map((m) => format(new Date(m.period), 'MMM'))}
                    />
                </>
            )}
            {monthlyChartIsLoading && !monthlyDataMissing && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {monthlyDataMissing && (
                <div className='chart-data-missing'>{Texts.chart_data_missing__InfoText}</div>
            )}
        </div>
    );
};
