import React, { useState } from 'react';
import { concatenateOfficialData } from '../Functions/Functions';
import Texts from '../Functions/Texts.json';
import { LineChart } from './LineChart';
import { format } from 'date-fns';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';

export type YearlyEBITChartProps = {
    officialProfitAndLoss: ProfitAndLossRow[] | undefined;
    profitAndLossAggregated: ProfitAndLossRow[] | undefined;
};
export const YearlyEBITChart = ({
    officialProfitAndLoss,
    profitAndLossAggregated,
}: YearlyEBITChartProps) => {
    const [isHiddenYearly, setIsHiddenYearly] = useState([false, false, false]);

    const yearlyData =
        officialProfitAndLoss &&
        profitAndLossAggregated &&
        concatenateOfficialData(officialProfitAndLoss, profitAndLossAggregated).slice(0, 5);

    const yearlyDataMissing =
        officialProfitAndLoss?.length === 0 || profitAndLossAggregated?.length === 0;

    const updateYearlyChart = (interactedWith: number) => {
        setIsHiddenYearly(isHiddenYearly.map((v, idx) => (idx === interactedWith ? !v : v)));
    };

    return (
        <div className='chart-box'>
            {yearlyData && !yearlyDataMissing && (
                <>
                    <h4>Årsvis</h4>
                    <LineChart
                        chartsAreLoading={!yearlyData}
                        chartConfig={{
                            type: 'stackedcolumn2dline',
                            labelDisplay: 'auto',
                        }}
                        updateFunction={updateYearlyChart}
                        data={[
                            {
                                seriesName: Texts.ebit__CompareOverlayChartHeading,
                                color: '#599ef9',
                                renderAs: 'line',
                                values: yearlyData.map((y) => y.ebit),
                                hidden: isHiddenYearly[0],
                            },
                            {
                                seriesName: Texts.charts.sumOfExpenses,
                                color: '#e6007e',
                                renderAs: 'column',
                                values: yearlyData.map((y) =>
                                    y.isOfficial
                                        ? y.directCost +
                                          y.otherOperatingExpensesAggregated +
                                          y.personnelCosts +
                                          y.depreciationAndWritedowns
                                        : y.sumOfExpenses,
                                ),
                                hidden: isHiddenYearly[1],
                            },
                            {
                                seriesName: Texts.charts.income,
                                color: '#3a306e',
                                renderAs: 'column',
                                values: yearlyData.map((y) => y.revenue + y.otherOperatingIncome),
                                hidden: isHiddenYearly[2],
                            },
                        ]}
                        xLabels={yearlyData.map(
                            (y) =>
                                `${format(new Date(y.financialYearTo), 'yyyy-MM')}${
                                    y.isOfficial ? '*' : ''
                                }`,
                        )}
                    />
                </>
            )}
            {!yearlyData && !yearlyDataMissing && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}
            {yearlyDataMissing && (
                <div className='chart-data-missing'>{Texts.chart_data_missing__InfoText}</div>
            )}
        </div>
    );
};
