import React, { useRef, useState } from "react";

type BudgetInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  viewMode: boolean;
  value: string | number;
};
const BudgetInput = ({
  viewMode,
  value,
  style,
  onChange,
  ...props
}: BudgetInputProps) => {
  const [active, setActive] = useState(false);
  const [val, setVal] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  if (viewMode) return <span style={style}>{value}</span>;

  const _onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setVal(e.target.value);
    if (onChange) onChange(e);
  };
  const onFocus = () => {
    inputRef.current?.select();
    setVal(value);
    setActive(true);
  };
  const onBlur = () => {
    setVal(value);
    setActive(false);
  };

  return (
    <input
      ref={inputRef}
      type="tel"
      style={style}
      value={active ? val : value}
      onChange={_onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      {...props}
    />
  );
};

export default BudgetInput;
