import { ProfitAndLossRowWithDerivedValues } from "../Types/Api/Response/ProfitAndLoss";
import { DerivedProfitAndLossKey, ProfitAndLossKey } from "./Budget";

export type PnlProperty = {
  label: string;
  property: ProfitAndLossKey | DerivedProfitAndLossKey;
  isNegative: boolean;
};
export const pnlProperties: PnlProperty[] = [
  { label: "Nettoomsättning", property: "revenue", isNegative: false },
  {
    label: "Övriga rörelseintäkter",
    property: "otherOperatingIncome",
    isNegative: false,
  },
   { label: "Totala intäkter", property: "totalOperatingIncome", isNegative: false },  
   { label: "Bruttovinst", property: "grossProfit", isNegative: false }, 
   {
    label: "Material & varukostnader",
    property: "directCost",
    isNegative: true,
  },
  { label: "Personalkostnader", property: "personnelCosts", isNegative: true },
  { label: "Totala Kostnader", property: "sumOfExpenses", isNegative: true },
  { label: "EBIT", property: "ebit", isNegative: false },
  { label: "Resultat", property: "netProfit", isNegative: false },
];

export function getPnlValue(
  row: ProfitAndLossRowWithDerivedValues,
  property: PnlProperty
) {
  const val = row[property.property];
  return property.isNegative ? -val : val;
}
