import { atom } from 'recoil';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';

const formKey = 'licenseState';

export const userLicenseChoiceState = atom<LicenseChoiceModel>({
    key: `${formKey}-licenseChoice`,
    default: {
        type: 'sme',
        id: '',
        title: '',
        queryString: '',
    },
});
