import { OptionsObject, VariantType, useSnackbar as useNotisSnackbar } from 'notistack';
import Snackbar, { SnackbarProps } from './Snackbar';

export type UseAlertSnackbarComponentProps = Omit<SnackbarProps, 'id' | 'color'>;

export type UseAlertSnackbarConfigProps = Omit<OptionsObject, 'variant' | 'content'>;

const useSnackbar = () => {
    const { enqueueSnackbar: enqueueNotisSnackbar, closeSnackbar } = useNotisSnackbar();

    const enqueueCustomSnackbar = (
        props: UseAlertSnackbarComponentProps | string,
        variant: VariantType,
        config?: UseAlertSnackbarConfigProps,
    ) => {
        if (typeof props === 'object') {
            return enqueueNotisSnackbar(props.title ?? '', {
                persist: false,
                content: (key) => <Snackbar id={key as string} color={variant} {...props} />,
                ...config,
            });
        }
        return enqueueNotisSnackbar(props, {
            persist: false,
            content: (key) => <Snackbar id={key as string} color={variant} title={props} />,
            ...config,
        });
    };
    const enqueueInfoSnackbar = (
        props: UseAlertSnackbarComponentProps | string,
        config?: UseAlertSnackbarConfigProps,
    ) => {
        enqueueCustomSnackbar(props, 'info', config);
    };
    const enqueueSuccessSnackbar = (
        props: UseAlertSnackbarComponentProps | string,
        config?: UseAlertSnackbarConfigProps,
    ) => {
        enqueueCustomSnackbar(props, 'success', config);
    };
    const enqueueWarningSnackbar = (
        props: UseAlertSnackbarComponentProps | string,
        config?: UseAlertSnackbarConfigProps,
    ) => {
        enqueueCustomSnackbar(props, 'warning', config);
    };
    const enqueueErrorSnackbar = (
        props: UseAlertSnackbarComponentProps | string,
        config?: UseAlertSnackbarConfigProps,
    ) => {
        enqueueCustomSnackbar(props, 'error', config);
    };
    return {
        enqueueInfoSnackbar,
        enqueueSuccessSnackbar,
        enqueueWarningSnackbar,
        enqueueErrorSnackbar,
        closeSnackbar,
    };
};

export default useSnackbar;
