import { GroupOverviewStoredSelection } from '../../Components/GroupOverview/CompanyPicker';

const storageName = 'groupOverviewSelection';

const GroupOverviewCompanySelectService = {
    GetParsedStorageData: () => {
        const data = localStorage.getItem(storageName);
        if (data) {
            return JSON.parse(data) as GroupOverviewStoredSelection;
        }
        return undefined;
    },
    SaveStorageData: (data: GroupOverviewStoredSelection) => {
        localStorage.setItem(storageName, JSON.stringify(data));
    },
    ClearAll: () => {
        localStorage.removeItem(storageName);
    },
};

export default GroupOverviewCompanySelectService;
