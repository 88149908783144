import React, { useState, useEffect } from 'react';
import { Budget, ProfitAndLossKey, valueSelectors, getBudgetValue } from '../../Functions/Budget';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import BudgetInput from './BudgetInput';
import Texts from '../../Functions/Texts.json';
import BudgetUpdater from './BudgetUpdater';

function parseValue(v: string): number {
    const res = parseFloat(v);
    return isNaN(res) ? 0 : res;
}

export type BudgetRowProps = React.HtmlHTMLAttributes<HTMLTableRowElement> & {
    budget: Budget;
    setValue: BudgetUpdater;
    linearDistribution: (key: ProfitAndLossKey) => void;
    proRataDistribution: (key: ProfitAndLossKey) => void;
    field: string;
    label?: string;
    negativeField?: boolean;
    forceExpand: boolean;
    inputDisabled?: boolean;
};
const BudgetRow = ({
    budget,
    setValue,
    linearDistribution,
    proRataDistribution,
    field,
    label,
    negativeField,
    forceExpand,
    className,
    inputDisabled = false,
    ...props
}: BudgetRowProps) => {
    const [tempEditMonths, setEditMonths] = useState(false);
    useEffect(() => {
        setEditMonths(forceExpand);
    }, [forceExpand]);

    const { selector } = valueSelectors[field];
    const disabled = selector.length > 1;
    const factor = negativeField ? -1 : 1;
    const { copyYear } = budget;

    const editMonths = tempEditMonths && !disabled;

    const printValue = (row: ProfitAndLossRow) =>
        Math.round((getBudgetValue(row, field) * factor) / 1000);
    const inputChange: (yearOrMonthIndex: number) => React.ChangeEventHandler<HTMLInputElement> =
        (yearOrMonthIndex) => (e) => {
            setValue(selector[0], yearOrMonthIndex, parseValue(e.target.value) * factor);
        };

    const getPercent = (prevRow: ProfitAndLossRow, newRow: ProfitAndLossRow) => {
        const prevVal = printValue(prevRow);
        if (prevVal === 0) {
            return 0;
        }
        const newVal = printValue(newRow);
        const ratio = newVal / prevVal;
        const percent = (ratio - 1) * 100;
        return Math.round(percent);
    };
    const txt =
        label ?? (Texts as unknown as Record<string, string>)['profitandloss__' + field] ?? field;

    return (
        <>
            {editMonths && copyYear && (
                <tr className='shaded top-row'>
                    <td className='shaded left-start'></td>
                    <td className='shaded'></td>
                    <td className='detail-column shaded'>
                        {copyYear.yearlyData.financialYearFrom.substring(0, 4)}
                    </td>
                    {copyYear.monthlyData.map((m, idx) => (
                        <td key={idx} className='money-column shaded'>
                            {printValue(m)}
                        </td>
                    ))}
                    <td className='money-column shaded right-end'>
                        {printValue(copyYear.yearlyData)}
                    </td>
                </tr>
            )}
            <tr
                {...props}
                className={`${className} ${editMonths && 'shaded'} ${
                    !copyYear && 'top-row bottom-row'
                }`}
            >
                <td
                    className={`row-name ${editMonths && 'green-cell'} shaded left-start`}
                    title={txt}
                >
                    <div className='expand-cell'>
                        <span>{txt}</span>
                        {!disabled && (
                            <button
                                onClick={() => setEditMonths(!editMonths)}
                                tabIndex={-1}
                                className='no-icon'
                            >
                                {editMonths ? 'Klar' : 'Fyll i'}
                            </button>
                        )}
                    </div>
                </td>
                <td className='shaded'></td>
                <td className='detail-column shaded'>{editMonths && <span>Budget</span>}</td>
                {budget.monthlyProfitAndLoss.map((month, idx) => (
                    <td className='money-column shaded' key={idx}>
                        <BudgetInput
                            viewMode={disabled || !editMonths}
                            style={{ width: 50 }}
                            value={printValue(month)}
                            onChange={inputChange(idx)}
                            disabled={inputDisabled}
                        />
                    </td>
                ))}
                <td className='money-column shaded right-end'>
                    {printValue(budget.yearlyProfitAndLoss)}
                </td>
            </tr>
            {editMonths && copyYear && (
                <tr className='percent-row shaded bottom-row'>
                    <td className='detail-column shaded left-start'></td>
                    <td className='shaded'></td>
                    <td className='detail-column shaded'>%-diff</td>
                    {copyYear.monthlyData.map((m, idx) => (
                        <td key={idx} className='money-column shaded'>
                            {getPercent(m, budget.monthlyProfitAndLoss[idx])}%
                        </td>
                    ))}
                    <td className='money-column shaded right-end'>
                        {getPercent(copyYear.yearlyData, budget.yearlyProfitAndLoss)}%
                    </td>
                </tr>
            )}
        </>
    );
};

export default BudgetRow;
