import ProfitAndLossRow from '../../../Types/Api/Response/ProfitAndLoss';
import FinancialYear from '../Types/FinancialYear';

const GetFinancialYearsFromPnL = (profitAndLossAggregated: ProfitAndLossRow[]): FinancialYear[] => {
    let allAvailableFinancialYears: FinancialYear[] = [];

    let currentYear = '';
    let currentFY: FinancialYear | undefined = undefined;

    const firstDayOfMonth = (month: string) => `${month.substring(0, 8)}01`;

    profitAndLossAggregated.forEach((pnl) => {
        if (pnl.financialYear !== currentYear) {
            currentYear = pnl.financialYear;
            currentFY = {
                from: pnl.financialYearFrom.substring(0, 10),
                to: pnl.financialYearTo.substring(0, 10),
                label: pnl.financialYear,
                months: [
                    {
                        from: firstDayOfMonth(pnl.period),
                        to: pnl.period.substring(0, 10),
                    },
                ],
            };
            allAvailableFinancialYears.push({ ...currentFY });
        } else {
            currentFY!.months.push({
                from: firstDayOfMonth(pnl.period),
                to: pnl.period.substring(0, 10),
            });
        }
    });
    allAvailableFinancialYears.sort((a, b) => b.from.localeCompare(a.from));
    return allAvailableFinancialYears;
};

export default GetFinancialYearsFromPnL;
