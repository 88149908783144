import React from 'react';
import Texts from '../Functions/Texts.json';

const NotFound = () => {
    return (
        <div className='content-wrapper'>
            <div className='data-missing-wrapper'>
                <div className='company-data-missing-message'>
                    {Texts.view_missing__InformationMessage}
                </div>
            </div>
        </div>
    );
};

export default NotFound;
