import { Navigate, useLocation } from 'react-router-dom';
import useAuthentication from './useAuthentication';
import { AuthState } from './AuthenticationContext';

type Props = {
    children: JSX.Element;
};

const RequireAuthentication = ({ children }: Props) => {
    const location = useLocation();
    const { authState } = useAuthentication();

    return authState === AuthState.authorized ? (
        children
    ) : (
        <Navigate
            to='/signin'
            state={{
                from: location,
            }}
            replace
        />
    );
};

export default RequireAuthentication;
