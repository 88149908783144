import React, { useState, useEffect } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import AllCompaniesMenu from '../Components/AllCompaniesMenu';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import { SortByTextAndNull } from '../Functions/Calculations';
import {
    DatetimeAsDate,
    FinancialYearAsMonthDate,
    FormatOrganizationNumber,
} from '../Functions/Formatting';
import { GetMainSni } from '../Functions/Functions';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import ListMetaInformation from '../Types/Api/Response/ListMetaInformation';
import { companiesState } from '../Core/State/CompanyListState';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';

const CompaniesBasicData = () => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { getCompanyList } = useGetCompanyListRequest();
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );

    const setupTable = (
        tableData:
            | {
                  companies: CompanyDetails[];
                  metaInformation: ListMetaInformation;
              }
            | null
            | undefined,
    ): RowTable => {
        if (!tableData) {
            return emptyTable;
        }

        let rows = [];
        let newTable: RowTable = {
            head: [
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.company_form__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small right',
                },
                {
                    label: Texts.sni__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium',
                },
                {
                    label: Texts.statement_date__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small',
                },
                {
                    label: Texts.no_of_employees__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'medium right',
                },
                {
                    label: Texts.lastest_verification__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium',
                },
            ],
            rows: [],
            actions: [],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        let companies = tableData.companies;

        for (let i = 0; i < companies.length; i++) {
            const latestVoucher = DatetimeAsDate(companies[i].latestVoucher);

            let sniInfo = '-';
            let sniTooltip = '';
            if (companies[i].officialInformation && companies[i].officialInformation.sniCodes) {
                sniInfo = GetMainSni(companies[i].officialInformation.sniCodes);
                for (let ii = 0; ii < companies[i].officialInformation.sniCodes.length; ii++) {
                    sniTooltip +=
                        companies[i].officialInformation.sniCodes[ii].sni +
                        ' ' +
                        companies[i].officialInformation.sniCodes[ii].descriptionSwedish +
                        ', ';
                }
                if (companies[i].officialInformation.sniCodes[1]) {
                    sniInfo += Texts.etc__TableCellValue;
                }
            }

            let row: RowTableRow = {
                metaInformation: {
                    visible: true,
                },
                cells: [
                    {
                        text: companies[i].organizationName,
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'large',
                    },
                    {
                        text: FormatOrganizationNumber(companies[i].organizationNumber),
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'small right',
                    },
                    {
                        text: companies[i].officialInformation.companyType,
                        className: 'small right',
                    },
                    {
                        text: sniInfo,
                        className: 'medium hide-overflow',
                        func: 'tooltip',
                        value: sniTooltip,
                    },
                    {
                        text: FinancialYearAsMonthDate(companies[i].financialYearToDate) || '-',
                        className: 'small',
                    },
                    {
                        text: companies[i].officialInformation.nrEmployees,
                        className: 'medium right',
                    },
                    {
                        text: latestVoucher ?? '-',
                        className: 'medium',
                    },
                ],
            };
            rows.push(row);
        }

        if (rows != null && rows.length > 0) {
            rows = rows.sort(SortByTextAndNull(0));
            newTable.metaInformation = tableData.metaInformation;
        }

        newTable.rows = rows;

        return newTable;
    };

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(companyList);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['companies', 'dashboard']}
                />
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                viewTitle={Texts.dashboard_all_companies__DashboardViewTitle}
                toggleHelp={() => setHelpIsOpen(true)}
            />
            <AllCompaniesMenu />
            <RowBasedTableWrapper>
                <div className='row-based-table-wrapper-heading'>
                    <p>{Texts.displaying_basic_data__BasicDataCompaniesViewTitle}</p>
                </div>
                <RowBasedTable table={table} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default CompaniesBasicData;
