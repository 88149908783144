import React from 'react';
import Texts from '../Functions/Texts.json';
import DashboardMenu from './DashboardMenu';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import ListMetaInformation from '../Types/Api/Response/ListMetaInformation';
import DisabledCompany from '../Types/Api/Response/DisabledCompany';
import PendingCompanyInformation from '../Types/Api/Response/PendingCompanyInformation';
import { useRecoilValue } from 'recoil';
import { userLastLoginState } from '../Core/State/UserState';

type Props = {
    companies:
        | { companies: CompanyDetails[]; metaInformation: ListMetaInformation }
        | null
        | undefined;
    pendingCompanies:
        | {
              companies: PendingCompanyInformation[];
              metaInformation: ListMetaInformation;
          }
        | null
        | undefined;
    disabledCompanies: DisabledCompany[] | null | undefined;
    toggleHelp: () => void;
    viewTitle: string;
};

const DashboardHeader = ({
    companies,
    pendingCompanies,
    disabledCompanies,
    toggleHelp,
    viewTitle,
}: Props) => {
    const lastLogin = useRecoilValue(userLastLoginState);

    let pendingCompaniesCount = 0;
    let newCompaniesCount = 0;
    let disabledCompaniesCount = 0;
    let companiesCount = 0;

    if (companies && pendingCompanies && disabledCompanies) {
        // No summary right now, might be reinstated, just count and pass on to next component

        if (companies.metaInformation != null) {
            companiesCount = companies.metaInformation.totalResources;
        }

        newCompaniesCount = companies.companies.filter(
            (c) => lastLogin && new Date(c.consentDate) >= lastLogin,
        ).length;

        disabledCompaniesCount = disabledCompanies.length;

        if (pendingCompanies.metaInformation != null) {
            pendingCompaniesCount = pendingCompanies.metaInformation.totalResources;
        }
    }

    return (
        <div className='content-heading'>
            <div className='help' id='helpOpener' onClick={() => toggleHelp()}>
                <div className='mask'></div>
                {Texts.help__HelpButtonText}
            </div>
            <h1>{viewTitle}</h1>
            <DashboardMenu
                companiesCount={companiesCount}
                disabledCompaniesCount={disabledCompaniesCount}
                pendingCompaniesCount={pendingCompaniesCount}
                newCompaniesCount={newCompaniesCount}
            />
        </div>
    );
};

export default DashboardHeader;
