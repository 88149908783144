import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import SystemConfiguration from '../../Types/Api/Response/SystemConfiguration';

const useGetSystemConfiguration = (options?: UseQueryOptions<SystemConfiguration[]>) =>
    useQuery(
        ['systemConfiguration'],
        async () => axiosInstance.get<SystemConfiguration[]>(`SystemConfiguration`),
        {
            ...options,
        },
    );

export default useGetSystemConfiguration;
