import React from 'react';
import { useEffect, useState } from 'react';
import Texts from '../../Functions/Texts.json';
import GetQuartersFromFiscalYears, { Quarter } from './GetQuartersFromFiscalYears';
import BudgetSelector from './BudgetSelector';
import FinancialYearSelector from './FinancialYearSelector';
import QuarterSelector from './QuarterSelector';
import useReportGenerator from './useReportGenerator';
import GetFinancialYearsFromPnL from './Helpers/GetFinancialYearsFromPnL';
import FinancialYear from './Types/FinancialYear';
import { add, format, lastDayOfMonth } from 'date-fns';
import MonthSelection from './Types/MonthSelection';
import MonthSelector from './MonthSelector';
import Year from './Types/Year';
import GetCalendarYearsFromPnL from './Helpers/GetCalendarYearsFromPnL';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import useGetPnLAggregatedQuery from '../../Api/Reports/useGetPnLAggregatedQuery';
import useGetBalanceSheets from '../../Api/Reports/useGetBalanceSheets';
import useGetBalanceSheetsAggregated from '../../Api/Reports/useGetBalanceSheetsAggregated';
import useGetActiveBudgetsQuery from '../../Api/Budget/useGetActiveBudgetsQuery';
import CompanyDetails from '../../Types/Api/Response/CompanyDetails';

export enum TimeSpan {
    'year',
    'quarter',
    'month',
}

type ReportsProps = {
    setReportGeneratorOverlayIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    companyDetails: CompanyDetails;
};

const Reports = ({ setReportGeneratorOverlayIsOpen, companyDetails }: ReportsProps) => {
    const [availableFinancialYears, setAvailableFinancialYears] = useState<FinancialYear[]>([]);
    const [availableYears, setAvailableYears] = useState<Year[]>([]);
    const [selectedTimespan, setSelectedTimespan] = useState<TimeSpan>(TimeSpan.year);
    const [selectedBudgetId, setSelectedBudgetId] = useState<string | null | undefined>(null);
    const [selectedFinancialYears, setSelectedFinancialYears] = useState<Array<string>>([]);
    const [selectedQuarters, setSelectedQuarters] = useState<Array<string>>([]);
    const [quarters, setQuarters] = useState<Array<Quarter>>([]);
    const [monthSelection, setMonthSelection] = useState<MonthSelection>({
        primary: undefined,
        secondary: undefined,
    });
    const [isPnLReport, setIsPnLReport] = useState<boolean>(false);
    const [isBSReport, setIsBSReport] = useState<boolean>(false);
    const { GenerateReport } = useReportGenerator();

    const organizationId = companyDetails.id;
    const { data: profitAndLoss } = useGetPnLQuery(organizationId);
    const { data: profitAndLossAggregated } = useGetPnLAggregatedQuery(organizationId);
    const { data: balanceSheetsAggregated } = useGetBalanceSheetsAggregated(organizationId);
    const { data: balanceSheets } = useGetBalanceSheets(organizationId);
    const { data: budgets } = useGetActiveBudgetsQuery(organizationId);

    useEffect(() => {
        if (profitAndLoss !== undefined) {
            const availableFYs = GetFinancialYearsFromPnL(profitAndLoss.data);
            setAvailableFinancialYears(availableFYs);
            setQuarters(GetQuartersFromFiscalYears(availableFYs));

            // Set default month choice
            setAvailableYears(GetCalendarYearsFromPnL(profitAndLoss.data));
            const lastOfCurrentMonth = `${format(
                lastDayOfMonth(new Date()),
                'yyyy-MM-dd',
            )}T00:00:00`;
            const lastOfCurrentPrevYear = `${format(
                add(new Date(lastOfCurrentMonth), {
                    years: -1,
                }),
                'yyyy-MM-dd',
            )}T00:00:00`;
            setMonthSelection({
                primary: profitAndLoss.data.some((pnl) => pnl.period === lastOfCurrentMonth)
                    ? lastOfCurrentMonth.substring(0, 10)
                    : undefined,
                secondary: profitAndLoss.data.some((pnl) => pnl.period === lastOfCurrentPrevYear)
                    ? lastOfCurrentPrevYear.substring(0, 10)
                    : undefined,
            });
        }
    }, [profitAndLoss]);

    const toggleSelectedFinancialYear = (year: string) => {
        if (selectedFinancialYears.some((y) => y === year)) {
            setSelectedFinancialYears(selectedFinancialYears.filter((y) => y !== year));
        } else {
            setSelectedFinancialYears([...selectedFinancialYears, year]);
        }
    };

    const toggleSelectedQuarter = (quarter: string) => {
        if (selectedQuarters.some((y) => y === quarter)) {
            setSelectedQuarters(selectedQuarters.filter((y) => y !== quarter));
        } else {
            setSelectedQuarters([...selectedQuarters, quarter]);
        }
    };

    const dataAvailable =
        companyDetails &&
        profitAndLossAggregated &&
        balanceSheetsAggregated &&
        balanceSheets &&
        profitAndLoss &&
        budgets;

    const generateSelectedReports = () => {
        if (!dataAvailable) {
            return;
        }
        GenerateReport({
            profitAndLoss: profitAndLoss.data,
            profitAndLossAggregated: profitAndLossAggregated.data,
            companyDetails: companyDetails,
            balanceSheetsAggregated: balanceSheetsAggregated.data,
            balanceSheets: balanceSheets.data,
            budgets: budgets.data,
            isPnLReport,
            isBSReport,
            timeSpan: selectedTimespan,
            selectedFinancialYears,
            selectedQuarters,
            selectedBudgetId,
            quarters,
            monthSelection,
        });
    };

    const createDisabled =
        (!isPnLReport && !isBSReport) ||
        (selectedTimespan === TimeSpan.year &&
            selectedBudgetId === null &&
            selectedFinancialYears.length === 0) ||
        (selectedTimespan === TimeSpan.quarter && selectedQuarters.length === 0) ||
        (selectedTimespan === TimeSpan.month && monthSelection.primary === undefined) ||
        selectedBudgetId === undefined;

    return (
        <div className='report-generator-overlay'>
            <div className='report-generator-wrapper'>
                <div
                    className='report-generator-close'
                    onClick={(e) => setReportGeneratorOverlayIsOpen(false)}
                >
                    {Texts.report_generator.close}
                    <div className='mask'></div>
                </div>
                <div className='report-generator-scroll-container'>
                    <div className='report-generator-dashboard'>
                        {!dataAvailable && (
                            <div className='spinner-wrapper'>
                                <div className='spinner'></div>
                            </div>
                        )}
                        {dataAvailable && (
                            <div className='report-generator'>
                                <h3>
                                    {Texts.report_generator.reports_for}{' '}
                                    {companyDetails.organizationName} -{' '}
                                    {companyDetails.organizationNumber}
                                </h3>
                                <div className='overlay-menu'>
                                    <ul>
                                        <li>
                                            <button
                                                onClick={() => setSelectedTimespan(TimeSpan.year)}
                                                className={
                                                    selectedTimespan === TimeSpan.year
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                {Texts.report_generator.financial_year}
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={() =>
                                                    setSelectedTimespan(TimeSpan.quarter)
                                                }
                                                className={
                                                    selectedTimespan === TimeSpan.quarter
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                {Texts.report_generator.quarter}
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                onClick={() => setSelectedTimespan(TimeSpan.month)}
                                                className={
                                                    selectedTimespan === TimeSpan.month
                                                        ? 'active'
                                                        : ''
                                                }
                                            >
                                                {Texts.report_generator.monthly}
                                            </button>
                                        </li>
                                    </ul>
                                </div>

                                <p>
                                    <em>{Texts.report_generator.recommended_limit}</em>
                                </p>

                                <div className='report-generator-form'>
                                    {selectedTimespan === TimeSpan.year && (
                                        <div className='year-row'>
                                            <FinancialYearSelector
                                                availableFinancialYears={availableFinancialYears}
                                                selectedFinancialYears={selectedFinancialYears}
                                                toggleSelectedFinancialYear={
                                                    toggleSelectedFinancialYear
                                                }
                                            />
                                            {!(isBSReport && !isPnLReport) && (
                                                <BudgetSelector
                                                    budgets={budgets.data}
                                                    selectedBudgetId={selectedBudgetId}
                                                    setSelectedBudgetId={setSelectedBudgetId}
                                                />
                                            )}
                                        </div>
                                    )}

                                    {selectedTimespan === TimeSpan.quarter && (
                                        <QuarterSelector
                                            quarters={quarters}
                                            toggleSelectedQuarter={toggleSelectedQuarter}
                                            selectedQuarters={selectedQuarters}
                                        />
                                    )}

                                    <h4>{Texts.report_generator.report_type}</h4>
                                    <div className='report-generator-row'>
                                        <label className='checkbox-container'>
                                            <input
                                                id='profitAndLossCheckbox'
                                                type='checkbox'
                                                onChange={() => setIsPnLReport(!isPnLReport)}
                                            />
                                            <span className='checkmark'></span>
                                            <div>{Texts.report_generator.profitAndLoss}</div>
                                        </label>
                                        <label className='checkbox-container'>
                                            <input
                                                id='balanceSheetsCheckbox'
                                                type='checkbox'
                                                onChange={() => setIsBSReport(!isBSReport)}
                                            />
                                            <span className='checkmark'></span>
                                            <div>{Texts.report_generator.balanceSheets}</div>
                                        </label>
                                    </div>
                                    {selectedTimespan === TimeSpan.month && (
                                        <div className='month-selector-wrapper'>
                                            <MonthSelector
                                                monthSelection={monthSelection}
                                                setMonthSelection={setMonthSelection}
                                                availableYears={availableYears}
                                            />
                                            <BudgetSelector
                                                budgets={budgets.data}
                                                selectedBudgetId={selectedBudgetId}
                                                setSelectedBudgetId={setSelectedBudgetId}
                                            />{' '}
                                        </div>
                                    )}
                                    <div>
                                        <button
                                            onClick={() => generateSelectedReports()}
                                            className='button'
                                            disabled={createDisabled}
                                        >
                                            {Texts.report_generator.generate_reports}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reports;
