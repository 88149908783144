import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import FinancialRatioRow from '../../Types/Api/Response/FinancialRatioRow';

const useGetFinancialRatioQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<FinancialRatioRow[]>,
) =>
    useQuery(
        ['financialRatio', companyId],
        async () =>
            axiosInstance.get<FinancialRatioRow[]>(`Reports/financialratios/${companyId}/monthly`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetFinancialRatioQuery;
