import Texts from '../Functions/Texts.json';

export type RenderAs = 'line' | 'spline' | 'column' | 'splinearea';
export type DataPoint = {
    seriesName: string;
    values: number[];
    hidden?: boolean;
    color?: string;
    renderAs?: RenderAs;
};
export type Dataset = {
    seriesName: string;
    color: string;
    initiallyHidden: number;
    renderAs: RenderAs;
    data: {
        value: number;
    }[];
};
export type CommonChartProps = {
    chartsAreLoading: boolean;
    xLabels: string[];
    chartConfig?: {
        numberSuffix?: string;
        legendNumColumns?: number;
        numberScaleUnit?: string;
        type?: string;
        labelDisplay?: string;
        plotToolText?: string;
        drawCrossLine?: number;
        forceDecimals?: boolean;
        showLegend?: number;
        bgColor?: string;
        caption?: string;
    };
    palette?: string[];
    width?: string;
    height?: string;
    caption?: string;
};

export const defaultPalette = ['#3a306e', '#599ef9', '#DA546C', '#27B6B0'];
export const defaultChartConfig = {
    bgColor: '#fbfbff',
    theme: 'fusion',

    baseFont: 'Lato',
    baseFontSize: '14',
    baseFontColor: '#160A25',

    showLegend: 0,
    legendItemFontBold: 0,
    legendItemFont: 'Lato',
    legendItemFontSize: '14',
    legendItemFontColor: '#160A25',
    legendNumRows: 2,
    legendNumColumns: 1,
    legendIconScale: '1.2',

    minimizeTendency: 1,

    numberSuffix: Texts.chartNumberSuffix,
    numberScaleValue: '1000,1000,1000',
    numberScaleUnit: Texts.revenueChartNumberScaleUnit,
    formatnumberscale: 1,
    formatNumber: 1,
    decimals: 2,
    decimalSeparator: ',',
    thousandSeparator: ' ',

    labelDisplay: 'rotate',
    slantLabel: 1,

    drawCrossLine: 1,

    plotToolText: '$seriesName <b>$dataValue</b>',
    captionFont: 'Lato',
    captionFontSize: '16',
    alignCaptionWithCanvas: '0',
    captionAlignment: 'center',
};

export function setDefaultProperties(
    arr: DataPoint[],
    palette: string[],
    defaultRenderAs: RenderAs,
): Required<DataPoint>[] {
    return arr.map((d, idx) => ({
        ...d,
        color: d.color ?? palette[idx % palette.length],
        hidden: d.hidden ?? false,
        renderAs: d.renderAs ?? defaultRenderAs,
    }));
}
export function dataPointsToDataset(data: Required<DataPoint>[]): Dataset[] {
    return data.map((d) => ({
        seriesName: d.seriesName,
        color: d.color,
        initiallyHidden: d.hidden ? 1 : 0,
        renderAs: d.renderAs,
        data: d.values.map((v) => ({ value: v })),
    }));
}
