export function range(n: number): number[] {
    if (n < 0) {
        return [];
    }
    return [...Array(n).keys()];
}

export const sum = (values: number[]): number => values.reduce((x, y) => x + y);

export function sortBy<T>(l: T[], prop: keyof T): T[] {
    return [...l].sort((a, b) => {
        const [x, y] = [a[prop], b[prop]];
        if (x === y) {
            return 0;
        }
        return x < y ? -1 : 1;
    });
}

export function* pairwiseLazy<T>(l: T[]): Generator<[T, T]> {
    for (let i = 0; i < l.length; i++) {
        yield [l[i], l[i + 1]];
    }
}
export function pairwise<T>(l: T[]): [T, T][] {
    return [...pairwiseLazy(l)];
}
