import { createContext } from 'react';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';

export enum AuthState {
    unset,
    initializing,
    forceLicenseChoice,
    authorized,
    unauthorized,
}

export type AuthenticationStateType = {
    authState: AuthState;
    logout: (message: string, stopNavigation?: boolean) => void;
    login: (
        usrData: CorporatePortalUser,
        license: LicenseChoiceModel | null,
        noNavigate?: boolean,
    ) => void;
    setLicense: (choice: LicenseChoiceModel, callback?: () => void) => void;
    signedOutMessage: string;
};

const AuthenticationContext = createContext<AuthenticationStateType>({
    authState: AuthState.initializing,
    logout: () => {},
    login: () => {},
    setLicense: () => {},
    signedOutMessage: '',
});

export default AuthenticationContext;
