import { useUserStateReset } from '../../Core/State/UserState';
import GroupOverviewCompanySelectService from './GroupOverviewCompanySelectService';
import LicenseChoiceService from './LicenseChoiceService';
import TokenStateService from './TokenStateService';

const useClearStorage = () => {
    const userStateReset = useUserStateReset();

    const clearStorage = () => {
        TokenStateService.ClearAll();
        LicenseChoiceService.ClearAll();
        GroupOverviewCompanySelectService.ClearAll();
        userStateReset();
    };

    return { clearStorage };
};

export default useClearStorage;
