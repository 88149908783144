import React from 'react';
import Texts from '../Functions/Texts.json';
import { Link } from 'react-router-dom';
import { SortByTextAndNull, SortByNumber } from '../Functions/Calculations';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    rowTableFilterState,
    sortByState,
    sortReverseState,
    tableIsLoadingState,
} from '../Core/State/SessionSettingsState';

export type RowTableHead = {
    label: string;
    type: 'sort' | 'sortNumber' | 'checkbox';
    className: string;
};

export type RowTableCell = {
    text: JSX.Element | string;
    link?: string;
    className: string;
    func?: 'checkbox' | 'tooltip';
    value?: string;
    isChecked?: boolean;
    checkboxCallback?: (isChecked: boolean, value: string) => void;
};

export type RowTableRow = {
    metaInformation: {
        visible: boolean;
        value?: string;
    };
    cells: Array<RowTableCell>;
};

export type RowTable = {
    head: Array<RowTableHead>;
    rows: Array<RowTableRow>;
    actions: Array<JSX.Element>;
    metaInformation: {
        allSelected: number;
        currentPage: number;
        sortReverse: number;
        totalPages: number;
        totalResources: number;
    };
};

export const emptyTable = {
    head: [],
    rows: [],
    actions: [],
    metaInformation: {
        allSelected: 0,
        currentPage: 0,
        sortReverse: 0,
        totalPages: 0,
        totalResources: 0,
    },
};

type Props = {
    table: RowTable;
    toggleCheckboxes?: (isChecked: boolean, visibleValues: Array<string>) => void;
};

const RowBasedTable = ({ table, toggleCheckboxes }: Props) => {
    const [sortReverse, setSortReverse] = useRecoilState(sortReverseState);
    const [sortBy, setSortBy] = useRecoilState(sortByState);
    const [rowTableFilter, setRowTableFilter] = useRecoilState(rowTableFilterState);
    const tableIsLoading = useRecoilValue(tableIsLoadingState);

    const filterRows = (rows: RowTableRow[], filterString: string) => {
        if (!rows) {
            return;
        }
        filterString = filterString.toLowerCase();

        return rows.filter((row) => {
            let texts = Object.values(row.cells).map((item) => {
                if (typeof item?.text === 'string') {
                    return item.text;
                }
                if (typeof item?.text?.props?.children === 'string') {
                    return item.text.props.children;
                }
                return null;
            });
            return texts.findIndex((str) => str?.toLowerCase().indexOf(filterString) > -1) !== -1;
        });
    };

    const sortRows = (rows: RowTableRow[] | undefined) => {
        if (sortBy === null || !rows || rows.length === 0 || !table) {
            return rows;
        }

        const headObjects = table.head;
        const columnIndex = Math.max(
            headObjects.findIndex((item) => unifyString(item.label) === sortBy),
            0,
        );
        const sortType = headObjects[columnIndex].type;

        if (sortType === 'sortNumber') {
            rows = rows.sort(SortByNumber(columnIndex));
        } else {
            rows = rows.sort(SortByTextAndNull(columnIndex));
        }
        if (sortReverse) {
            rows = rows.reverse();
        }
        return rows;
    };

    const unifyString = (val: string | undefined) => {
        if (!val) {
            return;
        }

        return val.replace(/[^\w\s!?]/g, '').toLowerCase();
    };

    const head = table.head;
    const actions = table.actions;
    const filteredRows = filterRows(table.rows, rowTableFilter);
    const rows = sortRows(filteredRows);

    const sortTable = (column: string) => {
        let reverse = false;
        if (column === sortBy) {
            reverse = !sortReverse;
        }
        setSortBy(column);
        setSortReverse(reverse);
    };

    return tableIsLoading ? (
        <div className='spinner-wrapper'>
            <div className='spinner'></div>
        </div>
    ) : (
        <div className='row-based-table'>
            {table.rows?.length > 0 && (
                <div key='filter-wrapper' className='row-based-table-filter-wrapper'>
                    <input
                        type='text'
                        value={rowTableFilter}
                        onChange={(e) => setRowTableFilter(e.target.value)}
                        autoFocus={rowTableFilter !== ''}
                        placeholder={Texts.filter__FilterLabel}
                    />
                </div>
            )}
            {actions && actions.length > 0 && table.rows?.length > 0 && (
                <div key='table-actions' className='row-based-table-actions'>
                    {actions.map((item, index) => item)}
                </div>
            )}
            <div className='row-based-table-head'>
                {head &&
                    table.rows?.length > 0 &&
                    head.map((item, index) => {
                        let className = '';
                        if (item.className) {
                            className = item.className;
                        }

                        let cell = <div className={className} key={'cell-' + index}></div>;
                        if (item.label) {
                            cell = (
                                <div className={className} key={'cell-' + index}>
                                    {item.label}
                                </div>
                            );
                        }
                        if (sortBy === unifyString(item.label)) {
                            className += ' active';
                            if (sortReverse) {
                                className += ' reversed';
                            }
                        }
                        if (item.type && item.type === 'sort' && item.label) {
                            cell = (
                                <div
                                    className={className}
                                    key={'cell-' + index}
                                    onClick={() => {
                                        const column = unifyString(item.label);
                                        if (column) {
                                            sortTable(column);
                                        }
                                    }}
                                    //     unifyString(item.label) &&
                                    //     sortTable(
                                    //         unifyString(item.label),
                                    //     )
                                    // }
                                >
                                    <span>{item.label}</span>
                                    <div className='row-based-table-column-sort a-z'></div>
                                </div>
                            );
                        }
                        if (item.type && item.type === 'sortNumber' && item.label) {
                            cell = (
                                <div
                                    className={className}
                                    key={'cell-' + index}
                                    onClick={() => {
                                        const column = unifyString(item.label);
                                        if (column) {
                                            sortTable(column);
                                        }
                                    }}
                                >
                                    <span>{item.label}</span>
                                    <div className='row-based-table-column-sort number'></div>
                                </div>
                            );
                        }
                        if (item.type && item.type === 'checkbox') {
                            cell = (
                                <div className={className} key={'cell-' + index}>
                                    <label className='checkbox-container'>
                                        <span>!</span>
                                        <input
                                            type='checkbox'
                                            onChange={(e) =>
                                                toggleCheckboxes?.(
                                                    e.target.checked,
                                                    filteredRows
                                                        ?.filter(
                                                            (r) =>
                                                                r.metaInformation.value !==
                                                                undefined,
                                                        )
                                                        .map((r) => r.metaInformation.value!) ?? [],
                                                )
                                            }
                                        />
                                        <span className='checkmark'></span>
                                    </label>
                                </div>
                            );
                        }
                        return cell;
                    })}
            </div>
            {(table.rows?.length === 0 || rows === undefined) && (
                <div className='no-pending-companies-message'>
                    {Texts.no_data_to_display_in_this_View__InfoText}
                </div>
            )}
            {rows &&
                rows.map((row, index) => (
                    <div key={`row-${index}`} className='row-based-table-row'>
                        {row.cells.map((item, index) => {
                            let className = '';
                            if (item.className) {
                                className = item.className;
                            }
                            let cell = (
                                <div className={className} key={'row-cell-' + index}>
                                    {row.constructor.name}
                                </div>
                            );
                            if (item.text == null) {
                                cell = (
                                    <div className={className} key={'row-cell-' + index}>
                                        -
                                    </div>
                                );
                            }
                            if (item.text) {
                                cell = (
                                    <div className={className} key={'row-cell-' + index}>
                                        {item.text}
                                    </div>
                                );
                            }
                            if (item.link && item.text) {
                                cell = (
                                    <div className={className} key={'row-cell-' + index}>
                                        <Link to={item.link} key={'link' + index}>
                                            {item.text}{' '}
                                        </Link>
                                    </div>
                                );
                            }
                            if (
                                item.func &&
                                item.func === 'checkbox' &&
                                item.value &&
                                item.checkboxCallback
                            ) {
                                cell = (
                                    <div className={className} key={'row-cell-' + index}>
                                        <label className='checkbox-container'>
                                            <input
                                                type='checkbox'
                                                onChange={(e) =>
                                                    item.checkboxCallback?.(
                                                        e.target.checked,
                                                        item.value!,
                                                    )
                                                }
                                                value={item.value}
                                                checked={item.isChecked}
                                            />
                                            <span className='checkmark'></span>
                                        </label>
                                    </div>
                                );
                            }
                            if (item.func && item.func === 'tooltip' && item.value && item.text) {
                                cell = (
                                    <div className={className} key={'row-cell-' + index}>
                                        {item.text}
                                        <span className='tooltip'>{item.value}</span>
                                    </div>
                                );
                            }
                            return cell;
                        })}
                    </div>
                ))}
        </div>
    );
};

export default RowBasedTable;
