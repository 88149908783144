import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { useParams } from 'react-router-dom';
import {
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../../Components/NewColumnBasedTable';
import { ExpandedProfitAndLossRow, ProfitAndLossTable } from '../../Components/ProfitAndLossTable';
import { concatenateOfficialData, toThousands } from '../../Functions/Functions';
import { format, min, sub } from 'date-fns';
import { sumToDate } from '../../Functions/Budget';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetActiveBudgetsQuery from '../../Api/Budget/useGetActiveBudgetsQuery';
import useGetPnLOfficialQuery from '../../Api/Reports/useGetPnLOfficialQuery';
import useGetPnLAggregatedQuery from '../../Api/Reports/useGetPnLAggregatedQuery';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const ProfitAndLoss = () => {
    const { organizationNumber } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const [selectedBudgetIndex, setSelectedBudgetIndex] = useState<number>(-1);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: budgets } = useGetActiveBudgetsQuery(organizationId);
    const { data: officialProfitAndLoss } = useGetPnLOfficialQuery(organizationId);
    const { data: profitAndLossAggregated } = useGetPnLAggregatedQuery(organizationId);

    const concatData =
        officialProfitAndLoss &&
        profitAndLossAggregated &&
        concatenateOfficialData(officialProfitAndLoss.data, profitAndLossAggregated.data);
    const pnlData = concatData?.map((d) =>
        toThousands({
            ...d,

            columnClassName: !d.isOfficial ? 'my-numbers-data' : '',
            totalOperatingIncome: d.isOfficial
                ? (d.otherOperatingIncome ?? 0) + (d.revenue ?? 0)
                : d.totalOperatingIncome ?? 0,
            sumOfExpenses: d.isOfficial
                ? (d.directCost ?? 0) +
                  (d.otherOperatingExpensesAggregated ?? 0) +
                  (d.personnelCosts ?? 0) +
                  (d.depreciationAndWritedowns ?? 0)
                : d.sumOfExpenses ?? 0,
            sumOfFinancialPosts: d.isOfficial
                ? (d.financialIncomes ?? 0) + (d.financialExpenses ?? 0)
                : d.sumOfFinancialPosts ?? 0,
        }),
    );

    const selectedBudget = budgets && budgets.data[selectedBudgetIndex];

    const TableRow: (
        props: TableRowProps<ExpandedProfitAndLossRow>,
    ) => TableRowInstance<ExpandedProfitAndLossRow> = TableRowBase;

    const budgetEndDate = selectedBudget ? new Date(selectedBudget.period) : new Date();
    budgetEndDate.setMonth(new Date().getMonth());

    const data: ExpandedProfitAndLossRow[] = selectedBudget
        ? [
              {
                  ...toThousands(sumToDate(selectedBudget, budgetEndDate)),
                  isBudget: true,
                  columnClassName: 'budget-data',
              },
              ...(pnlData !== undefined ? pnlData.map((pnl) => ({ ...pnl, isBudget: false })) : []),
          ]
        : pnlData?.map((pnl) => ({ ...pnl, isBudget: false })) ?? [];

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['profit-and-loss']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/profit-and-loss/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'by-year current',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-accounts/`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-waterfall/`,
                        text: Texts.charts__ViewOptionButtonText,
                        className: 'view-option-links by-chart',
                    },
                ]}
            />
            <ProfitAndLossTable
                columnWidth={190}
                headerMinWidth={250}
                tableIsLoading={data == null}
                showExpandAll={false}
                data={data}
                csvExportFilename={`${Texts.profit_and_loss__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                pdfExportFilename={{
                    prefix: Texts.profit_and_loss__DownloadFileNamePrefix,
                    companyName: companyDetails?.data.organizationName ?? '',
                    orgNr: companyDetails?.data.organizationNumber ?? '',
                }}
                stepHeader={(first, last) => (
                    <>
                        {format(new Date(first.financialYearTo), 'yyyy-MM')}
                        {last && ' - ' + format(new Date(last.financialYearTo), 'yyyy-MM')}
                    </>
                )}
                style={{ maxWidth: 960 }}
                netProfitLabel={Texts.profitandloss__netProfit}
                extraButtons={
                    budgets &&
                    budgets.data.length > 0 && (
                        <>
                            <div
                                onClick={() =>
                                    setSelectedBudgetIndex(selectedBudgetIndex === -1 ? 0 : -1)
                                }
                                className={`btn fa-button toggle-${
                                    selectedBudgetIndex === -1 ? 'off' : 'on'
                                }`}
                            >
                                {Texts.profitandloss__show_budgets}
                            </div>
                            {selectedBudgetIndex > -1 && (
                                <label className='btn choose-budget'>
                                    {Texts.choose_budget}{' '}
                                    <select
                                        onChange={(e) =>
                                            setSelectedBudgetIndex(parseInt(e.target.value, 10))
                                        }
                                    >
                                        {budgets.data.map((b, idx) => (
                                            <option key={b.id} value={idx}>
                                                {b.name}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            )}
                        </>
                    )
                }
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => {
                        if (d.isBudget) {
                            return selectedBudget?.name ?? '';
                        }
                        return d.isOfficial
                            ? Texts.official__TableHeader
                            : Texts.mynumbers__TableHeader;
                    }}
                />
                <TableRow
                    className='bold'
                    label={Texts.profit_and_loss__ViewTitle + ' ' + Texts.tkr__TableLabel}
                    selector={(d) => {
                        if (d.isBudget) {
                            return format(sub(budgetEndDate, { months: 1 }), 'yyyy-MM');
                        }
                        return format(
                            min([new Date(d.financialYearTo), sub(new Date(), { months: 1 })]),
                            'yyyy-MM',
                        );
                    }}
                />
                <TableRow
                    className='tiny-row'
                    label={Texts.financial_year__ProfitAndLossTableLabel}
                    selector={(d) => d.financialYear.replace(/\s*:\s*/, ' - ')}
                />
            </ProfitAndLossTable>
            <span className='official-data-disclaimer'>
                {Texts.official_data_from__DisclaimerText}
            </span>
        </ErrorBoundary>
    );
};

export default ProfitAndLoss;
