import React from 'react';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route } from 'react-router-dom';
import SignIn from './Views/Authentication/SignIn';
import NotFound from './Views/404';
import PendingCompanies from './Views/PendingCompanies';
import CompaniesBasicData from './Views/CompaniesBasicData';
import CompareCompanies from './Views/CompareCompanies';
import NewCompanies from './Views/NewCompanies';
import GroupOverview from './Views/GroupOverview';
import DisabledCompanies from './Views/DisabledCompanies';
import Settings from './Views/Settings';
import { SsoAuthView } from './Views/Authentication/SsoAuth';
import { SsoInitiate } from './Views/SsoInitiate';
import AuthenticationProvider from './Core/Authentication/AuthenticationProvider';
import Companies from './Views/Companies';
import RequireAuthentication from './Core/Authentication/RequireAuthentication';
import ChromeWrapper from './Core/ChromeWrapper';
import ProfitAndLoss from './Views/Company/ProfitAndLoss';
import ProfitAndLossByMonth from './Views/Company/ProfitAndLossByMonth';
import ProfitAndLossByQuarter from './Views/Company/ProfitAndLossByQuarter';
import ProfitAndLossAccounts from './Views/Company/ProfitAndLossAccounts';
// @ts-ignore
import ProfitAndLossWaterfall from './Views/Company/ProfitAndLossWaterfall';
import BalanceSheets from './Views/Company/BalanceSheets';
import BalanceSheetsByMonth from './Views/Company/BalanceSheetsByMonth';
import BalanceSheetsByQuarter from './Views/Company/BalanceSheetsByQuarter';
import BalanceSheetsAccounts from './Views/Company/BalanceSheetsAccounts';
import Cashflow from './Views/Company/Cashflow';
import CashflowByMonth from './Views/Company/CashflowByMonth';
// @ts-ignore
import CashflowWaterfall from './Views/Company/CashflowWaterfall';
import Charts from './Views/Company/Charts';
import CompanyDetailsView from './Components/CompanyDetails';
import BudgetView from './Views/Budget/Budget';
import CompanyDashboard from './Views/Company/CompanyDashboard';
import FinancialRatio from './Views/Company/FinancialRatio';

const Router = () =>
    createBrowserRouter(
        createRoutesFromElements(
            <Route
                path='/'
                element={
                    <AuthenticationProvider>
                        <ChromeWrapper>
                            <Outlet />
                        </ChromeWrapper>
                    </AuthenticationProvider>
                }
            >
                <Route path='/signin' element={<SignIn />}></Route>
                <Route path='/sso/initiate' element={<SsoInitiate />}></Route>
                <Route path='/ssoAuth' element={<SsoAuthView />}></Route>
                <Route
                    index
                    element={
                        <RequireAuthentication>
                            <Companies />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/pending-companies'
                    element={
                        <RequireAuthentication>
                            <PendingCompanies />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/new-companies'
                    element={
                        <RequireAuthentication>
                            <NewCompanies />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/disabled-companies'
                    element={
                        <RequireAuthentication>
                            <DisabledCompanies />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/compare-companies'
                    element={
                        <RequireAuthentication>
                            <CompareCompanies />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/companies-basic-data'
                    element={
                        <RequireAuthentication>
                            <CompaniesBasicData />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/group-overview'
                    element={
                        <RequireAuthentication>
                            <GroupOverview />
                        </RequireAuthentication>
                    }
                />
                <Route
                    path='/company/:organizationNumber/*'
                    element={
                        <RequireAuthentication>
                            <>
                                <CompanyDetailsView />
                                <Outlet />
                            </>
                        </RequireAuthentication>
                    }
                >
                    <Route index element={<CompanyDashboard />} />
                    <Route path='profit-and-loss/' element={<ProfitAndLoss />} />
                    <Route path='profit-and-loss-by-month' element={<ProfitAndLossByMonth />} />
                    <Route path='profit-and-loss-by-quarter' element={<ProfitAndLossByQuarter />} />
                    <Route path='profit-and-loss-accounts' element={<ProfitAndLossAccounts />} />
                    <Route
                        path='profit-and-loss-accounts/:financialyear'
                        element={<ProfitAndLossAccounts />}
                    />
                    <Route path='profit-and-loss-waterfall' element={<ProfitAndLossWaterfall />} />
                    <Route
                        path='profit-and-loss-waterfall/:financialyear'
                        element={<ProfitAndLossWaterfall />}
                    />
                    <Route path='balance-sheets' element={<BalanceSheets />} />
                    <Route path='balance-sheets-by-month' element={<BalanceSheetsByMonth />} />
                    <Route path='balance-sheets-by-quarter' element={<BalanceSheetsByQuarter />} />
                    <Route path='balance-sheets-accounts' element={<BalanceSheetsAccounts />} />
                    <Route
                        path='balance-sheets-accounts/:financialyear'
                        element={<BalanceSheetsAccounts />}
                    />
                    <Route path='cashflow' element={<Cashflow />} />
                    <Route path='cashflow-by-month' element={<CashflowByMonth />} />
                    <Route path='cashflow-waterfall' element={<CashflowWaterfall />} />
                    <Route
                        path='cashflow-waterfall/:financialyear'
                        element={<CashflowWaterfall />}
                    />
                    <Route path='financial-ratio' element={<FinancialRatio />} />
                    <Route path='charts' element={<Charts />} />
                    <Route path='budget/*' element={<BudgetView />} />
                </Route>{' '}
                <Route
                    path='/settings'
                    element={
                        <RequireAuthentication>
                            <Settings />
                        </RequireAuthentication>
                    }
                />
                <Route path='' element={<NotFound />} />
            </Route>,
        ),
    );
export default Router;
