import { add, differenceInYears } from 'date-fns';
import useGetAllFinancialYears from '../../../Api/FinancialYears/useGetAllFinancialYears';
import { useMemo } from 'react';
import { range } from '../../../Functions/Lists';

const useGetBudgetYears = (organizationId: string) => {
    const { data: financialYears, isLoading } = useGetAllFinancialYears(organizationId);

    const now = new Date();
    const currentYear = financialYears?.data.find(
        (f) => new Date(f.fromdate) <= now && now <= new Date(f.todate),
    );
    const currentOrLatestYear =
        currentYear ||
        financialYears?.data.sort((a, b) => b.fromdate.localeCompare(a.fromdate)).find(() => true);

    const startPeriod = currentOrLatestYear
        ? add(new Date(currentOrLatestYear.fromdate), {
              years: differenceInYears(now, new Date(currentOrLatestYear.fromdate)),
          })
        : undefined;
    const budgetYears = useMemo(
        () => (startPeriod ? range(5).map((i) => add(startPeriod, { years: i })) : []),
        [startPeriod],
    );

    /**
     * budgetYearsWithOld are selectable years when changing the FY of a budget.
     *
     * A user should be able to select a couple of years back and also some future years.
     * This code filters out FY:s that are 2-3 years old and then adds new years based
     * off the start date of the latest financial year
     **/

    const aCoupleOfYearsBack = add(now, { years: -2 });
    const oldFinancialYears = financialYears?.data
        .sort((a, b) => a.fromdate.localeCompare(b.fromdate))
        .filter((y) => new Date(y.todate) > aCoupleOfYearsBack);

    // Startdate of the last FY
    const lastStartPeriod = useMemo(
        () =>
            oldFinancialYears && oldFinancialYears?.length > 0
                ? new Date(oldFinancialYears[oldFinancialYears.length - 1].fromdate)
                : undefined,
        [oldFinancialYears],
    );

    const budgetYearsWithOld = useMemo(
        () =>
            lastStartPeriod
                ? [
                      ...oldFinancialYears!.map((fy) => new Date(fy.fromdate)), // existing FY:s
                      ...range(4).map((i) => add(lastStartPeriod, { years: i + 1 })), // add future FYs
                  ].slice(0, 8) // slice if there are already lots of future FY:s in DB
                : budgetYears, // fallback if there are no FY:s in DB
        [oldFinancialYears, lastStartPeriod, budgetYears],
    );

    return { startPeriod, budgetYears, budgetYearsWithOld, isLoading };
};

export default useGetBudgetYears;
