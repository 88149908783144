import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userCreditorNameState } from '../Core/State/UserState';

type HelpProps = {
    closeHelp: (e?: React.MouseEvent) => void;
    helpItems: string[];
};

const Help = ({ closeHelp, helpItems }: HelpProps) => {
    const creditorName = useRecoilValue(userCreditorNameState);

    useEffect(() => {
        const escFunction = (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                closeHelp();
            }
        };
        document.addEventListener('keydown', escFunction, false);
        return () => document.removeEventListener('keydown', escFunction, false);
    }, [closeHelp]);

    return (
        <div className='help-overlay'>
            <div
                className='help-overlay-closer'
                onClick={(e) => {
                    closeHelp(e);
                }}
            ></div>
            <div className='help-wrapper'>
                <div
                    className='help-close'
                    onClick={(e) => {
                        closeHelp(e);
                    }}
                >
                    <div className='mask'></div>
                    Stäng
                </div>
                {(helpItems.indexOf('dashboard') !== -1 || helpItems.length === 0) && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-dashboard help-generell'>
                            <h1>Dashboard</h1>
                            <p>
                                Det finns tre olika typer av licenser för företagsportalen,
                                företagsanvändare, byrå samt B2B. Har du fler licenser växlar du
                                mellan dem högst upp till höger på sidan. Dashboarden innehåller två
                                menyer beroende på vilken licens du är inloggad med:
                            </p>
                            <ul>
                                <li>
                                    <strong>Meny för företagsinformation</strong> — Visas för alla
                                    licensanvändare och är uppdelad i fyra flikar.
                                </li>
                                <li>
                                    <strong>Meny för hantering av anslutna företag</strong> — En
                                    meny synlig endast för B2B-användare där du kan hantera företag
                                    som valt att dela data med {creditorName}.
                                </li>
                            </ul>
                            <h3>Meny för företagsinformation</h3>
                            <p>
                                För samtliga licenser finns en undermeny med fyra val som består av
                                tre fördefinierade informationsvyer samt en rapportfunktion
                                bestående av en koncernsammanställning.
                            </p>
                            <h4>1. Översikt</h4>
                            <p>
                                Visar alla företag som är kopplade till dig som användare och via
                                klicka på bolagsnamn eller organisationsnummer leder till en
                                detaljerad sida med företagets status, basinformation samt
                                ekonomiska rapporter.
                                <br />
                                <br />
                                Den information som visas i listan är:
                            </p>
                            <ul>
                                <li>
                                    <strong>Bolagsnamn</strong> — Det bolagsnamn som finns
                                    registrerat hos Bolagsverket .
                                </li>
                                <li>
                                    <strong>Organisationsnummer</strong> — Hämtat från Bolagsverket
                                    i samband med MyNumbers autentiseringsförfarande vid
                                    kontoskapande.
                                </li>
                                <li>
                                    <strong>Uppdaterat</strong> — Datum för MyNumbers senaste
                                    hämtning eller access mot företagets bokföringssystem.
                                </li>
                                <li>
                                    <strong>Anslutet</strong> — Datum då företaget accepterade att
                                    dela data till {creditorName}. Visas endast för B2B-användare.
                                </li>
                                <li>
                                    <strong>Status</strong> — "Aktivt", företaget har en aktiv
                                    koppling till ett bokföringsprogram. "Saknar data", företaget
                                    har inte kopplat ett bokföringssystem eller laddat upp
                                    SIE4-filer. "Sen uppdat.", företagets koppling till
                                    bokföringssystemet har slutat fungera eller företaget har laddat
                                    upp SIE4-filer.
                                </li>
                                <li>
                                    <strong>Senaste ver.</strong> — Datum för senast registrerade
                                    verifikat i bokföringssystemet som MyNumbers erhållit. Visas
                                    endast för företags- och byråanvändare.
                                </li>
                                <li>
                                    <strong>Bokföringprogram</strong> — Det bokföringssystem
                                    företaget använder. Idag har MyNumbers integrationer till
                                    Fortnox, Visma eEkonomi, Björn Lundén, Wint och PE Accounting.
                                    Har en SIE4-fil laddats upp visas även namnet på
                                    bokföringssystemet här.
                                </li>
                                <li>
                                    <strong>Hämtning</strong> — "Automatisk", integration finns mot
                                    bokföringssystemet. "Manuell", visas när uppladdning skett med
                                    en SIE4-fil vilken måste uppdateras manuellt om ny data ska
                                    tillföras.
                                </li>
                                <li>
                                    <strong>Bokslut</strong> — Den månad företagts räkenskapsår
                                    slutar i. Erhålls från Bolagsverket.
                                </li>
                            </ul>
                            <h4>2. Jämför företag</h4>
                            <p>
                                En vy där man kan välja fler företag att jämföra i en graf med olika
                                nyckeltal. För att välja företag krävs att koppling av
                                bokföringssystem har gjorts.
                            </p>
                            <p>
                                Genom att klicka på bolagsnamn eller organisationsnummer kommer ni
                                till en detaljerad sida med företagets status, basinformation samt
                                ekonomiska rapporter. Klickar ni på något av nyckeltalen kommer ni
                                in på respektive undersida, ex. omsättning går direkt till
                                resultaträkningssidan för valt företag.
                            </p>
                            <h4>3. Grunddata</h4>
                            <p>
                                En vy som visar utökad information om företagen i listan, bl.a.
                                SNI-kod, bolagsform samt antal anställda, hämtat från Bolagsverket.
                            </p>
                            <p>
                                Genom att klicka på bolagsnamn eller organisationsnummer kommer ni
                                till en detaljerad sida med företagets status, basinformation samt
                                ekonomiska rapporter.
                            </p>
                            <h4>4. Koncernsammanställning</h4>
                            <p>
                                Koncernsammanställningen gör det möjligt att aggregera upp till sex
                                företag för en valfri period och summera i en totalkolumn. Det går
                                att välja en valfri period med från och till datum. Välj samma månad
                                för en enskild månadsrapport. Det går även bra att välja en period
                                som sträcker sig över flera år om så önskas.
                            </p>
                            <p>
                                Har ni mindre än sex företag visas alla per automatik men det går
                                att hantera via knappen "Välj företag".
                            </p>
                            <p>
                                Utöver att aggregera flera företag går det bra att använda
                                sammanställningen för ett enskilt företag.
                            </p>
                            <p>
                                Det finns två layouter, en standard som visar hela resultaträkningen
                                samt en som visar en kortare resultaträkning med fokus på
                                bruttovinst. Det går även att exportera vald sammanställning till
                                Excel och PDF.
                            </p>
                        </div>

                        <div className='help-item help-dashboard help-alla-foretag'>
                            <h3>Meny för hantering av anslutna företag</h3>

                            <h4>Alla</h4>
                            <p>
                                Visar vyn översikt där ni ser alla företag med ett aktivt medgivande
                                att dela data med {creditorName}. Ni ser även, i upp till 30 dagar,
                                de företag som inaktiverat sitt medgivande till datadelning.
                            </p>
                        </div>

                        <div className='help-item help-dashboard help-foretag-att-godkänna'>
                            <h3>Företag att godkänna</h3>
                            <p>
                                Här visas de företag som via MyNumbers app- eller webbtjänst givit
                                ett medgivande att dela data till {creditorName}. Det kan vara
                                kunder eller ett företag som av någon anledning vill dela data med
                                er utan att ni har en kundrelation. Möjligheten för
                                {creditorName} att synliggöras för företag i MyNumbers app- och
                                webbtjänst sker endast vid ömsesidigt godkännande mellan
                                {creditorName} och MyNumbers. Om ni valt att synliggöras och ett
                                företag önskar att dela sin data måste ni först acceptera
                                dataleveransen från respektive företag under fliken ”Företag att
                                godkänna”.
                            </p>
                        </div>

                        <div className='help-item help-dashboard help-borttagna-foretag'>
                            <h3>Borttagna företag</h3>
                            <p>
                                Här visas alla de företag som avslutat sin delning till{' '}
                                {creditorName} och de företag ni valt att avvisa under fliken
                                ”Företag att godkänna”.
                                <br />
                                <br />
                                Ett företag som avslutat sin delning ligger kvar i företagsportalen
                                i 30 dagar. Både under fliken ”Alla företag” samt ”Borttagna
                                företag”. Vill ni att ett företag ska fortsätta/återaktivera delning
                                måste ni kontakta aktuellt företag och be dem återaktivera
                                delningen.
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('company-dashboard') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Sammanfattning</h3>
                            <p>
                                En snabb ekonomisk överblick och periodjämförelse av företagets
                                huvudsakliga nyckeltal.
                                <br />
                                <br />
                                Den månadsvisa grafen visar utvecklingstrenden på intäkter,
                                kostnader och resultat. Jämför mellan år för att lätt se månatliga
                                skillnader mellan år.
                                <br />
                                <br />
                                Den årsvisa grafen summerar totala intäkter och kostnader (exklusive
                                finansiella-, extraordinära-, dispositions- och skatteposter) och
                                rörelseresultat före räntor och skatt.
                                <br />
                                <br />
                                I nyckeltalstabellen har vi sammanställt ett antal av de vanligaste
                                förekommande finansiella nyckeltalen, för mer nyckeltal se
                                nyckeltalsfliken.
                                <br />
                                <br />
                                <i>
                                    Observera att när det inte finns officiella årsredovisningar att
                                    tillgå baseras alla uppgifter på företagets bokföringsdata såsom
                                    aggregerat och beräknat av MyNumbers. Eftersom månadsbokslut
                                    stängs olika för olika företag kan det innebära att de allra
                                    senaste månadernas uppgifter i förekommande fall inte ger en
                                    fullständig bild av nuläget.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Vi underhåller löpande beräkningslogiken som ligger till grund
                                    för alla uppgifter tabeller och grafer för att säkerställa
                                    korrekt siffror. Vår ambition är att alltid försöka belysa
                                    eventuella avvikelser och vad de kan bero på.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter som ej går att härleda till bokföringen
                                    uppskattar innerligt om ni hör av er till oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('profit-and-loss') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Resultaträkning</h3>
                            <p>
                                Välj att se resultaträkningen på <strong>års-</strong>,{' '}
                                <strong>kvartals-</strong>, eller
                                <strong>månadsbasis</strong>. Välj <strong>detaljerad vy</strong> om
                                du vill se resultaträkningen på kontonivå. Klicka på plustecknet (
                                <strong>+</strong>) för att fälla ut radsummeringarna.
                                <br />
                                <br />
                                MyNumbers avrundar alla belopp till närmsta tusental eller noll om
                                under 500kr.
                                <br />
                                <br />
                                För att slippa se rader med nollvärden så går det att klicka i{' '}
                                <strong>Dölj nollvärden</strong>, i de fall bokfört värde är mellan
                                0-500kr avrundas dessa till noll. I dessa fall kommer nollvärdena
                                finnas kvar trots att <strong>Dölj nollvärden</strong> är iklickat.
                                <br />
                                <br />
                                Ta ut en rapport på förvald vy genom att klicka på{' '}
                                <strong>Spara som PDF</strong>. Vill du efterbearbeta i t. ex. Excel
                                går det även att <strong>Spara som CSV</strong>.
                                <br />
                                <br />
                                <i>
                                    Observera att när det inte finns officiella årsredovisningar att
                                    tillgå baseras alla uppgifter på företagets bokföringsdata såsom
                                    aggregerat och beräknat av MyNumbers. Eftersom månadsbokslut
                                    stängs olika för olika företag kan det innebära att de allra
                                    senaste månadernas uppgifter i förekommande fall inte ger en
                                    fullständig bild av nuläget.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Vi underhåller löpande beräkningslogiken som ligger till grund
                                    för alla uppgifter tabeller och grafer för att säkerställa
                                    korrekt siffror. Vår ambition är att alltid försöka belysa
                                    eventuella avvikelser och vad de kan bero på.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter som ej går att härleda till bokföringen
                                    uppskattar innerligt om ni hör av er till oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('balance-sheets') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Balansräkning</h3>
                            <p>
                                Välj att se balansräkningen på <strong>års-</strong>,{' '}
                                <strong>kvartals-</strong>, eller
                                <strong>månadsbasis</strong>. Välj <strong>detaljerad vy</strong> om
                                du vill se balansräkningen på kontonivå. Klicka på plustecknet (
                                <strong>+</strong>) för att fälla ut radsummeringarna.
                                <br />
                                <br />
                                MyNumbers avrundar alla belopp till närmsta tusental eller noll om
                                under 500kr.
                                <br />
                                <br />
                                För att slippa se rader med nollvärden så går det att klicka i{' '}
                                <strong>Dölj nollvärden</strong>, i de fall bokfört värde är mellan
                                0-500kr avrundas dessa till noll. I dessa fall kommer nollvärdena
                                finnas kvar trots att <strong>Dölj nollvärden</strong> är iklickat.
                                <br />
                                <br />
                                Ta ut en rapport på förvald vy genom att klicka på{' '}
                                <strong>Spara som PDF</strong>. Vill du efterbearbeta i t. ex. Excel
                                går det även att <strong>Spara som CSV</strong>.
                                <br />
                                <br />
                                <i>
                                    Observera att när det inte finns officiella årsredovisningar att
                                    tillgå baseras alla uppgifter på företagets bokföringsdata såsom
                                    aggregerat och beräknat av MyNumbers. Eftersom månadsbokslut
                                    stängs olika för olika företag kan det innebära att de allra
                                    senaste månadernas uppgifter i förekommande fall inte ger en
                                    fullständig bild av nuläget.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Vi underhåller löpande beräkningslogiken som ligger till grund
                                    för alla uppgifter tabeller och grafer för att säkerställa
                                    korrekt siffror. Vår ambition är att alltid försöka belysa
                                    eventuella avvikelser och vad de kan bero på.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter som ej går att härleda till bokföringen
                                    uppskattar innerligt om ni hör av er till oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('financial-ratio') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Nyckeltal</h3>
                            <p>
                                MyNumbers tillämpar FARs beräkningsmetoder för samtliga finansiella
                                nyckeltal. Många av de traditionella nyckeltalen blir mest
                                rättvisande när de beräknas på stängda helårssiffror. Av den
                                anledningen ber vi er observera följande när ni granskar de
                                nyckeltal MyNumbers beräknar direkt från bokföringen
                                <br />
                                <br />
                                Alla kvotbaserade nyckeltal använder sig av aggregerade poster
                                vilket månadsvis innebär avvikelser från helåret. T. ex. använder
                                avkastning på eget kapital innevarande räkenskapsårs aggregerade
                                resultatet dividerat med eget kapital som aggregeras över
                                årsskiften.
                                <br />
                                <br />
                                Om ett nyckeltal ser onormalt ut i jämförelse med officiellt
                                nyckeltal så kan det t. ex. bero på att en kostnad i sin helhet ännu
                                inte är bokförd. T. ex. att mindre dröjsmålsräntor bokats men att en
                                större räntekostnad ännu ej bokförts. I sådant exempel kan
                                räntetäckningsgraden komma att se onormal ut.
                                <br />
                                <br />
                                Genom att regelbundet stämma av de löpande beräknade nyckeltalen kan
                                man snabbt upptäcka eventuella fel eller missar i bokföringen.
                                <br />
                                <br />
                                <i>
                                    Observera att när det inte finns officiella årsredovisningar att
                                    tillgå baseras alla uppgifter på företagets bokföringsdata såsom
                                    aggregerat och beräknat av MyNumbers. Eftersom månadsbokslut
                                    stängs olika för olika företag kan det innebära att de allra
                                    senaste månadernas uppgifter i förekommande fall inte ger en
                                    fullständig bild av nuläget.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Vi underhåller löpande beräkningslogiken som ligger till grund
                                    för alla uppgifter tabeller och grafer för att säkerställa
                                    korrekt siffror. Vår ambition är att alltid försöka belysa
                                    eventuella avvikelser och vad de kan bero på.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter som ej går att härleda till bokföringen
                                    uppskattar innerligt om ni hör av er till oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('charts') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Grafer</h3>
                            <p>
                                <strong>Vattenfallsdiagrammet</strong> ger en visuell översikt av
                                resultaträkningen för vald period. För innevarande år aggregeras
                                bokförda värdena fram till innevarande månad.
                                <br />
                                <br />
                                <strong>Balansräkningsgrafen</strong> ger en visuell överblick av
                                företagets tillgångar och skulder. Om ett räkenskapsår inte är
                                stängt och balanser ej är överförda så kommer tillgångs- och
                                skuldsidan vara i obalans.
                                <br />
                                <br />
                                Grafen <strong>Månadsvis jämförelse mellan räkenskapsår</strong> ger
                                dig möjlighet att jämföra månadsvisutveckling av flertalet
                                finansiella mätetal mellan olika räkenskaps år.
                                <br />
                                <br />
                                <i>
                                    Observera att när det inte finns officiella årsredovisningar att
                                    tillgå baseras alla uppgifter på företagets bokföringsdata såsom
                                    aggregerat och beräknat av MyNumbers. Eftersom månadsbokslut
                                    stängs olika för olika företag kan det innebära att de allra
                                    senaste månadernas uppgifter i förekommande fall inte ger en
                                    fullständig bild av nuläget.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Vi underhåller löpande beräkningslogiken som ligger till grund
                                    för alla uppgifter tabeller och grafer för att säkerställa
                                    korrekt siffror. Vår ambition är att alltid försöka belysa
                                    eventuella avvikelser och vad de kan bero på.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter som ej går att härleda till bokföringen
                                    uppskattar innerligt om ni hör av er till oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('budget') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Budget</h3>
                            <p>
                                Med budgetfunktionen vill vi ge våra användare möjligheten att
                                enkelt kunna skapa en övergripande budget för att sedan smidigt
                                kunna följa upp mot faktiskt utfall månad för månad.
                                <br />
                                <br />
                                För att din budgetvärden ska hamna på rätt plats så rekommenderar vi
                                att du kollar vilka kontoklassar vi inkluderar i respektive
                                budgetpost.
                                <br />
                                <br />
                                Varje budgetpost ifylles manuellt per månad för ditt budgetår. Du
                                kan läsa in ett historiskt räkenskapsårs för att enkelt få en bra
                                förståelse för hur varje inmatat budgetvärde förhåller sig mot
                                förgående periods utfall.
                                <br />
                                <br />
                                När du är klar sparar du din budget genom att trycka på
                                sparaknappen. Nu kan du följa upp dina budgetar i diagramform under
                                sammanfattningsvyn och i tabellform under resultaträkningsvyn.
                                <br />
                                <br />
                                Det går även att ladda och redigera en tidigare skapad budget.
                                <br />
                                <br />I nuvarande version kan endast budgetvärden anges för de mest
                                förekommande resultaträkningsposterna, framöver vill vi även kunna
                                erbjuda er möjlighet att arbeta med specialanpassade kontomallar
                                samt kostnads- och projektställen.
                            </p>
                            <br />
                            <h3>Budgetguiden</h3>
                            Nedan funktioner och hjälpmedel finns tillgängliga för att bygga din
                            budget.
                            <br />
                            <br />
                            Om du är osäker på vad som tex ingår i en budgetpost så rekommenderar vi
                            att du stämmer av vilka konton som ingår i respektive post i nedan
                            kontospecifikation.
                            <br />
                            <br />
                            <ul>
                                <li>
                                    <strong>Skapa ny budget:</strong> Namnge din budget
                                </li>
                                <li>
                                    <strong>Välj budget:</strong> Ange de år budgeten avser
                                </li>
                                <li>
                                    <strong>Välj ett referensår:</strong> Hämta månadsdata från ett
                                    av dina tidigare räkenskapsår. Genom att göra det så får du
                                    månatliga referensvärden när du fyller i dina budget.
                                </li>
                                <li>
                                    <strong>För in budgetvärden:</strong> Tryck på Fyll i knapp för
                                    varje budgetpost och ange dina budgetvärden månad för månad
                                </li>
                                <li>
                                    <strong>Nollställ värden:</strong> Vill du börja om från början,
                                    kan du snabbt radera alla värden per budgetpost du arbetar med.{' '}
                                </li>
                                <li>
                                    <strong>Ångra/Gör om:</strong> Genom att trycka på dessa
                                    funktionsknappar kan du enkelt välja att ångra och göra om
                                    steg-för-steg
                                </li>
                                <li>
                                    <strong>Spara:</strong> När du är klar med din budget, trycker
                                    du på spara.{' '}
                                </li>
                            </ul>
                            <br />
                            <h3>Kontoklassbeskrivning</h3>
                            <table>
                                <tr>
                                    <td>Resultaträkningsposter</td>
                                    <td>BASKONTON</td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Intäkter</strong>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Nettoomsättning</td>
                                    <td>3000-3599</td>
                                </tr>
                                <tr>
                                    <td>Huvudintäkter</td>
                                    <td>36XX</td>
                                </tr>
                                <tr>
                                    <td>Sidointäkter</td>
                                    <td>37XX</td>
                                </tr>
                                <tr>
                                    <td>Intäktskorrigeringar, Övriga rörelseintäkter m.m.</td>
                                    <td>38XX</td>
                                </tr>
                                <tr>
                                    <td>Aktiverat arbete för egen räkning</td>
                                    <td>39XX</td>
                                </tr>
                                <tr>
                                    <td>
                                        Övriga rörelseintäkter, Förändring av lager, produkter och
                                        pågående arbeten
                                    </td>
                                    <td>49XX (excl 4910, 4931, 4960 och 4980)</td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Kostnader</strong>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Material och varukostnader</td>
                                    <td>4000-48XX, 4910-4931, 4960 och 4980</td>
                                </tr>
                                <tr>
                                    <td>Lokalkostnader</td>
                                    <td>5000-5199</td>
                                </tr>
                                <tr>
                                    <td>Försäljningskostnader</td>
                                    <td>5900-6090</td>
                                </tr>
                                <tr>
                                    <td>Administrationskostnader</td>
                                    <td>6100-62XX och 6400-6799</td>
                                </tr>
                                <tr>
                                    <td>Övriga rörelsekostnader</td>
                                    <td>5200-5899, 63XX, 6800-6999 och 79XX</td>
                                </tr>
                                <tr>
                                    <td>Personalkostnader</td>
                                    <td>7000-7699</td>
                                </tr>
                                <tr>
                                    <td>Avskrivningar och nedskrivningar</td>
                                    <td>7700-7899</td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Finansiella intäkter och kostnader</strong>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Finansiella intäkter</td>
                                    <td>8000-8399</td>
                                </tr>
                                <tr>
                                    <td>Finansiella kostnader</td>
                                    <td>84XX</td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Extraordinära intäkter och kostnader</strong>
                                    </td>
                                    <td>87XX</td>
                                </tr>
                                <tr>
                                    <td>
                                        <br />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Bokslutsdispositioner</strong>
                                    </td>
                                    <td>8800-889X</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                )}
                {helpItems.indexOf('cashflow') !== -1 && (
                    <div className='help-scroll-container'>
                        <div className='help-item help-company'>
                            <h3>Kassaflöde</h3>
                            <p>
                                Vi använder oss av bokförda ingående kassabalanser för perioden och
                                bokföringstransaktioner i respektive period för att summera
                                kassaflödespåverkande händelser. Dessa händelser delas upp i den
                                löpande verksamheten, förändringar i rörelsekapitalet samt
                                investerings- och finansieringsverksamheten.
                                <br />
                                <br />
                                Om föregående räkenskapsårs balanser ej överförts till nästa kommer
                                ni se en differens i den sista månadens periodiska kassaflöde.
                                <br />
                                <br />
                                Kassaflödesrapporten är helt och hållet beräknad utifrån
                                bokföringsdata. Om eventuella differenser mellan bokförda balanser
                                och de periodiska kassaflödena uppstår belyses de tillsammans med
                                förklaringar på de vanligaste bokföringstekniska orsakerna.
                                <br />
                                <br />
                                <i>
                                    Observera att MyNumbers ej har tillgång till eventuella
                                    checkkrediter vilka därför inte inkluderas i det beräknade
                                    kassaflödet.{' '}
                                </i>
                                <br />
                                <br />
                                <i>
                                    Hittar ni felaktigheter eller avvikelser i det löpande
                                    beräknande kassaflödet uppskattar innerligt om ni hör av er till
                                    oss.
                                </i>
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Help;
