import React, { useEffect, useState } from 'react';
import Texts from '../Functions/Texts.json';
import { FormatNumber } from '../Functions/Formatting';
import { pairwise } from '../Functions/Lists';
import { makeRow, PdfStyleNames } from '../Functions/PdfExport';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const nestedIndentation = 20;

function formatValue(v: React.ReactText) {
    if (typeof v === 'number') {
        return FormatNumber(v);
    }
    return v ?? '-';
}

export type ColumnData = {
    columnClassName?: string;
};
export type TableRowInstance<T> = React.ReactElement<TableRowProps<T>>;
export type TableRowProps<T> = React.HTMLAttributes<HTMLDivElement> & {
    label?: string;
    headerMinWidth?: number;
    headerMaxWidth?: number;
    columnWidth?: number;
    paddingLeft?: boolean;
    data?: [T, T][];
    selector?: (d: T) => React.ReactText;
    children?: TableRowInstance<T> | TableRowInstance<T>[];
    doCompare?: boolean;
    expandAll?: boolean;
};
export const TableRow = <T extends ColumnData>({
    label,
    headerMinWidth,
    headerMaxWidth,
    columnWidth,
    paddingLeft = false,
    data: inData,
    selector,
    children,
    className,
    doCompare = false,
    expandAll = false,
    ...props
}: TableRowProps<T>): TableRowInstance<T> => {
    const [expandedState, setExpanded] = useState(expandAll);

    const hasChildren = React.Children.count(children) > 0;
    const expanded = hasChildren && expandedState;

    useEffect(() => {
        setExpanded(expandAll);
    }, [expandAll]);

    // key is set by the parent table, so we know it always exists
    const data = inData as [T & { key: string }, T & { key: string }][];

    const compare = (current: T, prev: T) => {
        const a = selector?.(current);
        const b = prev && selector?.(prev);
        if (typeof a === 'number' && typeof b === 'number') {
            if (a < b) {
                return 'trend-arrow-down';
            } else if (a > b) {
                return 'trend-arrow-up';
            }
        }
        if (typeof a === 'string' && typeof b === 'string') {
            const aN = parseFloat(a);
            const bN = parseFloat(b);
            if (isNaN(aN) || isNaN(bN)) {
                return '';
            }
            if (aN < bN) {
                return 'trend-arrow-down';
            } else if (aN > bN) {
                return 'trend-arrow-up';
            }
        }
        return '';
    };
    const render = (d: T) => {
        if (!selector) return null;

        return formatValue(selector(d));
    };

    return (
        <div
            {...props}
            className={`table-row ${className ?? ''}`}
            style={{ paddingLeft: paddingLeft ? nestedIndentation : 0 }}
        >
            {hasChildren && (
                <div
                    className={`unfold-icon ${expanded ? 'is-open' : ''}`}
                    onClick={() => setExpanded(!expanded)}
                ></div>
            )}
            <div
                className={`labels-column ${expanded ? 'bold' : ''}`}
                style={{ minWidth: headerMinWidth, maxWidth: headerMaxWidth }}
            >
                <div className='cell' title={label}>
                    {label}
                </div>
            </div>
            {expanded && (
                <>
                    {data?.map(([current]) => (
                        <div
                            style={{ width: columnWidth }}
                            className={current.columnClassName}
                            key={current.key}
                        >
                            <div className='cell'></div>
                        </div>
                    ))}
                    <div className='nested-row'>
                        {children !== undefined &&
                            React.Children.map(children, (child) => {
                                if (!React.isValidElement(child)) return child;

                                return React.cloneElement(child, {
                                    columnWidth,
                                    headerMinWidth: headerMinWidth! - nestedIndentation,
                                    headerMaxWidth: headerMaxWidth! - nestedIndentation,
                                    paddingLeft: true,
                                    data,
                                    expandAll,
                                });
                            })}
                    </div>
                    <div
                        className='labels-column'
                        style={{ minWidth: headerMinWidth, maxWidth: headerMaxWidth }}
                    >
                        <div className='cell'></div>
                    </div>
                </>
            )}
            {data?.map(([current, prev]) => (
                <div
                    style={{ width: columnWidth }}
                    className={current.columnClassName}
                    key={current.key}
                >
                    <div
                        className={`cell ${expanded ? 'bold' : ''} ${
                            doCompare ? compare(current, prev) : ''
                        }`}
                    >
                        {render(current)}
                    </div>
                </div>
            ))}
        </div>
    );
};

function getChildProperties<T>(
    children: TableRowInstance<T>[],
    getNested: boolean,
): TableRowProps<T>[] {
    const count = React.Children.count(children);
    if (count === 0) {
        return [];
    }
    // If there is only one child, react does not supply it in an array
    const childrenList = count === 1 ? [children as unknown as TableRowInstance<T>] : children;

    return childrenList
        .map((child) => {
            if (Array.isArray(child))
                return getChildProperties(child as TableRowInstance<T>[], getNested);
            if (!React.isValidElement(child)) return [];

            return [
                ...(getNested
                    ? getChildProperties(child.props.children as TableRowInstance<T>[], true)
                    : []),
                // If there are any children, this is a summary row, so this row's values should be last
                child.props,
            ];
        })
        .reduce((agg, next) => agg.concat(next));
}

export type ColumnBasedTableProps<T> = React.HTMLAttributes<HTMLDivElement> & {
    tableIsLoading: boolean;
    headerMinWidth: number;
    columnWidth: number;
    data: T[] | undefined;
    stepHeader?: (leftmostItem: T, rightmostItem: T | undefined) => React.ReactNode;
    showExpandAll?: boolean;
    csvExportFilename?: string;
    pdfExportFilename?: { prefix: string; companyName: string; orgNr: string };
    extraButtons?: React.ReactNode;
    children: TableRowInstance<T>[];
    disableStep?: boolean; // Shows no stepHeader, and all columns are rendered
    orientation?: 'p' | 'landscape' | 'portrait' | 'l' | undefined;
};
export const NewColumnBasedTable = <T extends ColumnData>({
    tableIsLoading,
    headerMinWidth,
    columnWidth,
    children,
    data: rawData,
    stepHeader,
    showExpandAll = false,
    csvExportFilename,
    pdfExportFilename,
    extraButtons,
    disableStep = false,
    orientation = 'portrait',
    ...props
}: ColumnBasedTableProps<T>) => {
    const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [width, setWidth] = useState(0);
    const [offset, setOffset] = useState(0);
    const [expandAll, setExpandAll] = useState(false);
    const [animationClass, setAnimationClass] = useState<null | string>(null);

    useEffect(() => {
        if (!wrapper) return;

        if (wrapper.offsetWidth !== width) {
            setWidth(wrapper.offsetWidth);
        }

        const cb = (e: UIEvent) => {
            setWidth(wrapper.offsetWidth);
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', cb);
        return () => window.removeEventListener('resize', cb);
    }, [wrapper, windowWidth]);

    if (tableIsLoading || !rawData) {
        return (
            <div className='spinner-wrapper'>
                <div className='spinner'></div>
            </div>
        );
    }
    if (rawData.length === 0) {
        return (
            <div className='data-missing-wrapper'>
                <div className='company-data-missing-message'>
                    {Texts.company_data_missing__InformationMessage}
                </div>
            </div>
        );
    }
    const data = rawData.map((d, index) => ({
        ...d,
        key: `col${index}`,
    }));

    const maxColumns = Math.max(1, Math.floor((width - headerMinWidth) / columnWidth));
    // If disableStep is true, then make sure the headers are of equal width
    const headerMaxWidth = disableStep ? headerMinWidth : width - maxColumns * columnWidth;

    const columns = disableStep
        ? pairwise(data)
        : pairwise(data).slice(offset, offset + maxColumns);
    const move = (steps: number) => {
        const newOffset = Math.max(0, Math.min(data.length - maxColumns, offset + steps));
        if (newOffset !== offset) {
            setAnimationClass(null);
            setTimeout(() => setAnimationClass(steps > 0 ? 'right' : 'left'));
        }
        setOffset(newOffset);
    };

    const exportCsv = (fileName: string) => {
        const escapeCsv = (s: string | number) => {
            // Only needs escaping when a semicolon is present
            if (typeof s === 'number' || !s.includes(';')) {
                return s;
            }
            // In order to escape, the text needs to be in quotes. To escape quotes, use double quotes.
            // Source: https://stackoverflow.com/a/8501818
            s = s.replace(/"/g, `""`);
            return `"${s}"`;
        };

        const rows = getChildProperties(children, true)
            .filter(({ label }) => typeof label === 'string')
            .map(({ label, selector }) => {
                return [label!, ...data.map((d) => (selector ? formatValue(selector(d)) : ''))]
                    .map((s) => escapeCsv(s))
                    .join(';');
            });
        const csvFile = rows.join('\r\n') + '\r\n';

        const universalBOM = '\uFEFF';
        var a = window.document.createElement('a');
        a.setAttribute(
            'href',
            'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvFile),
        );
        a.setAttribute('download', fileName + '.csv');
        window.document.body.appendChild(a);
        a.click();
        window.document.body.removeChild(a);
    };
    const exportPdf = () => {
        const { prefix, companyName, orgNr } = pdfExportFilename!;

        const cols = columns.map(([d]) => d);
        const nonCols = cols.map(() => ({}));

        const childProps = getChildProperties(children, false);
        const pdfRows = childProps
            .map(({ label = '', selector, className }) => {
                return [
                    className?.includes('summary') && {
                        label: '',
                        selector: undefined as typeof selector,
                        styleNames: ['line-over'] as PdfStyleNames[],
                    },
                    {
                        label,
                        selector,
                        styleNames: (className?.split(/\s+/) as PdfStyleNames[]) ?? [],
                    },
                    className?.includes('line-under') && {
                        label: '',
                        selector: undefined as typeof selector,
                        styleNames: ['is-line'] as PdfStyleNames[],
                    },
                ];
            })
            .reduce((prev, next) => [...prev, ...next], [])
            .filter((x): x is Exclude<typeof x, false | undefined> => !!x);

        const rows = [
            makeRow(['pdf-top-title'], prefix, () => '', nonCols, orientation),
            companyName && orgNr
                ? makeRow(
                      ['pdf-top-company-name'],
                      companyName + ' (' + orgNr + ')',
                      () => '',
                      nonCols,
                      orientation,
                  )
                : [],
            makeRow(
                [],
                'Skapad: ' + new Date().toLocaleString('sv-SE').substring(0, 16),
                () => '',
                nonCols,
                orientation,
            ),
            ...pdfRows.map(({ label, selector, styleNames }) =>
                makeRow(
                    styleNames,
                    label,
                    (d) => (selector ? formatValue(selector(d)) : ''),
                    cols,
                    orientation,
                ),
            ),
        ];

        const doc = new jsPDF(orientation);

        autoTable(doc, {
            theme: 'plain',
            head: [],
            body: rows,
        });

        doc.save(`${prefix} ${companyName} ${orgNr}.pdf`);
    };

    return (
        <div
            {...props}
            className={`column-based-table-wrapper new-no-float ${
                animationClass ? 'animation-class ' + animationClass : ''
            } ${disableStep ? 'no-wrap' : ''}`}
            ref={setWrapper}
        >
            <div className='table-button-row'>
                <div className='button-group action-group'>
                    {showExpandAll && (
                        <div
                            onClick={() => setExpandAll(!expandAll)}
                            className={`btn fold ${expandAll ? 'is-expanded' : ''}`}
                        >
                            {expandAll ? Texts.fold_all__ToggleText : Texts.show_folded__ToggleText}
                        </div>
                    )}
                    {csvExportFilename && (
                        <div onClick={() => exportCsv(csvExportFilename)} className={'btn csv'}>
                            {Texts.save_as_csv__ButtonText}
                        </div>
                    )}
                    {pdfExportFilename && (
                        <div onClick={() => exportPdf()} className={'btn pdf'}>
                            {Texts.save_as_pdf__ButtonText}
                        </div>
                    )}
                    {extraButtons}
                </div>
                {!disableStep && (
                    <div className='button-group step-button-group'>
                        {maxColumns !== 1 && (
                            <div
                                onClick={() => move(-maxColumns)}
                                className={`btn left-fast ${offset === 0 ? 'inactive' : ''}`}
                            ></div>
                        )}
                        <div
                            onClick={() => move(-1)}
                            className={`btn left ${offset === 0 ? 'inactive' : ''}`}
                        ></div>
                        {stepHeader && (
                            <div className='step-header'>
                                {stepHeader(
                                    data[offset],
                                    maxColumns > 1 && data.length > 1
                                        ? data[offset + maxColumns - 1] ?? data[data.length - 1]
                                        : undefined,
                                )}
                            </div>
                        )}
                        <div
                            onClick={() => move(1)}
                            className={`btn right ${
                                offset + maxColumns >= data.length ? 'inactive' : ''
                            }`}
                        ></div>
                        {maxColumns !== 1 && (
                            <div
                                onClick={() => move(maxColumns)}
                                className={`btn right-fast ${
                                    offset + maxColumns >= data.length ? 'inactive' : ''
                                }`}
                            ></div>
                        )}
                    </div>
                )}
            </div>
            <div className='column-based-table'>
                {React.Children.map(children, (child) => {
                    if (!React.isValidElement(child)) return child;

                    return React.cloneElement(child, {
                        headerMinWidth,
                        headerMaxWidth,
                        columnWidth,
                        data: columns,
                        expandAll,
                    });
                })}
            </div>
        </div>
    );
};
