import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { useParams, NavLink } from 'react-router-dom';
import { sortBy } from '../../Functions/Lists';
import { format, isPast, isWithinInterval } from 'date-fns';
import { ColumnData, NewColumnBasedTable, TableRow } from '../../Components/NewColumnBasedTable';
import {
    aggregatesToColumnsAndGroups,
    MonthlyAggregateTableRow,
    MonthlyAggregateTableSumRow,
} from '../../Components/MonthlyAggregateTableRow';
import useGetMonthlyAccountAggregatesQuery from '../../Api/MonthlyAccountAggregates/useGetMonthlyAccountAggregatesQuery';
import useGetOrgId from '../../Functions/useGetOrgId';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import { useRecoilValue } from 'recoil';
import useGetAllFinancialYears from '../../Api/FinancialYears/useGetAllFinancialYears';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const ProfitAndLossAccounts = () => {
    const { organizationNumber, financialyear } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const [helpIsOpen, setHelpIsOpen] = useState(false);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: monthlyAccountAggregates } = useGetMonthlyAccountAggregatesQuery(organizationId);
    const { data: allFinancialYears } = useGetAllFinancialYears(organizationId);

    const sortedYears =
        allFinancialYears &&
        sortBy(allFinancialYears.data, 'fromdate')
            .reverse()
            .filter((y) => isPast(new Date(y.fromdate)));
    const selectedYear =
        sortedYears?.find((y) => financialyear === format(new Date(y.fromdate), 'yyyy-MM')) ??
        sortedYears?.[0];

    const aggregates =
        (selectedYear &&
            monthlyAccountAggregates?.data.filter(
                (agg) =>
                    agg.reportStructure === 'ProfitLoss' &&
                    isPast(new Date(agg.period)) &&
                    isWithinInterval(new Date(agg.period), {
                        start: new Date(selectedYear.fromdate),
                        end: new Date(selectedYear.todate),
                    }),
            )) ??
        [];

    const [aggColumns, aggProps] = aggregatesToColumnsAndGroups(aggregates);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['profit-and-loss']} />
            )}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/profit-and-loss/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'by-year',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-quarter/`,
                        text: Texts.quarterly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-accounts/`,
                        text: Texts.accounts__ViewOptionButtonText,
                        className: 'view-option-links accounts current',
                    },
                    {
                        link: `/company/${organizationNumber}/profit-and-loss-waterfall/`,
                        text: Texts.charts__ViewOptionButtonText,
                        className: 'view-option-links by-chart',
                    },
                ]}
            />
            <div className='profit-and-loss-account-years-menu'>
                {sortedYears
                    ?.map((year) => format(new Date(year.fromdate), 'yyyy-MM'))
                    .map((year, index) => (
                        <NavLink
                            className={({ isActive }) =>
                                isActive || (!financialyear && index === 0) ? 'active' : ''
                            }
                            key={year}
                            end
                            to={`/company/${organizationNumber}/profit-and-loss-accounts/${year}`}
                        >
                            {year}
                        </NavLink>
                    ))}
            </div>
            <NewColumnBasedTable
                key={financialyear} // Key is added so that the whole table rerenders when financialyear in the URL changes
                columnWidth={160}
                headerMinWidth={350}
                tableIsLoading={!aggColumns}
                showExpandAll={true}
                data={aggColumns}
                csvExportFilename={`${Texts.balance_sheets_accounts__DownloadFileNamePrefix} accounts ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                style={{ marginRight: 170 }}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & { period: string }>
                    className='bold'
                    label={Texts.accounts__ProfitAndLossTableHeader}
                    selector={(d) => format(new Date(d.period), 'yyyy-MM')}
                />
                <MonthlyAggregateTableRow
                    valueBy='transactionSum'
                    structureIndex={10}
                    groupIndex={10}
                    groups={aggProps}
                />
                <MonthlyAggregateTableRow
                    valueBy='transactionSum'
                    structureIndex={10}
                    groupIndex={11}
                    groups={aggProps}
                />
                <MonthlyAggregateTableRow
                    valueBy='transactionSum'
                    structureIndex={10}
                    groupIndex={12}
                    groups={aggProps}
                />
                <MonthlyAggregateTableSumRow
                    className='summary bold'
                    label={Texts.sum__ProfitAndLossTableFooter}
                    valueBy='transactionSum'
                    structureIndex={10}
                    groups={aggProps}
                />
            </NewColumnBasedTable>
        </ErrorBoundary>
    );
};

export default ProfitAndLossAccounts;
