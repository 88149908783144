import { SuccessResponse, UnauthorizedResponse } from '../../Functions/Authentication';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import useGetUserInfoMutation from './useGetUserInfoMutation';

const useUserInfoRequest = () => {
    const {
        isLoading,
        data: userInfo,
        mutate: getUserInfo,
        mutateAsync,
    } = useGetUserInfoMutation();

    const parseUserData = (data: CorporatePortalUser): UnauthorizedResponse | SuccessResponse => {
        if (
            data.hasOverdueInvoice ||
            ((!data.hasLicense || data.hasExpiredLicense) &&
                data.accountingFirms.length === 0 &&
                data.creditors.length === 0)
        ) {
            return {
                type: 'unauthorized',
                userInfo: data,
                userName: `${data.user.firstName} ${data.user.lastName}`,
            };
        }

        const ret: SuccessResponse = {
            type: 'success',
            license: null,
            userInfo: data,
        };
        if ((data.hasLicense ? 1 : 0) + data.accountingFirms.length + data.creditors.length === 1) {
            // User has only one license, do not display choice
            if (data.hasLicense) {
                ret.license = { type: 'sme', id: 'sme', title: '' };
            } else if (data.accountingFirms.length === 1) {
                ret.license = {
                    type: 'firm',
                    id: data.accountingFirms[0].id,
                    title: data.accountingFirms[0].name,
                };
            } else {
                ret.license = {
                    type: 'creditor',
                    id: data.creditors[0].clientIdAuth0,
                    title: data.creditors[0].creditorName,
                };
            }
        }
        return ret;
    };

    const userData = userInfo ? parseUserData(userInfo.data) : undefined;

    const getUserDataAsync = async () => {
        const usrData = await mutateAsync(null);
        return parseUserData(usrData.data);
    };

    return {
        getUserInfo,
        isLoading,
        userData,
        getUserDataAsync,
    };
};

export default useUserInfoRequest;
