import Texts from '../../Functions/Texts.json';
import BalanceSheetRow from '../../Types/Api/Response/BalanceSheetRow';
import { Tkr } from '../../Functions/Calculations';
import { FinancialYearAsYearBreakMonth, FormatNumber } from '../../Functions/Formatting';
import { Quarter } from './GetQuartersFromFiscalYears';
import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import TableColumn from './Types/TableColumn';

const formatCompactFinancialYear = (fyLabel: string) => {
    const regex =
        /([0-9]{2})([0-9]{2}-[0-9]{2}-[0-9]{2}) : ([0-9]{2})([0-9]{2}-[0-9]{2}-[0-9]{2})/i;
    return fyLabel.replace(regex, '$2 - $4');
};

export const BalanceSheetsYearsLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
    },
    rows: [
        {
            value: ' ',
            className: '',
        },
        {
            value: Texts.balance_sheets__ViewTitle + ' ' + Texts.tkr__TableLabel,
            className: ' level-1-header ',
        },
        {
            value: Texts.financial_year__BalaceSheetsTableLabel,
            className: ' ',
        },
        {
            value: Texts.assets__BalaceSheetsTableHeader,
            className: ' level-1-header',
        },
        {
            value: Texts.balancesheets__intangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalTangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__financialFixAssets,
            className: '  foldable',
        },
        {
            value: Texts.balancesheets__totalFixAssets,
            className: 'summary    space-under',
        },
        {
            value: Texts.balancesheets__warehouseAndWorkInProgress,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__totalAccountsReceivable,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__otherCurrentReceivablesAggregated,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__cashAndCashEquivalent,
            className: '   foldable',
        },
        {
            value: Texts.balancesheets__totalLiquidAssets,
            className: 'summary    ',
        },
        {
            value: Texts.balancesheets__totalAssets,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.equity_and_debt__BalanceSheetsTableHeader,
            className: '  level-1-header',
        },
        {
            value: Texts.balancesheets__equity,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__untaxedReserves,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__provision,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__totalLongTermLiabilities,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalCurrentLiabilities,
            className: '  foldable ',
        },
        {
            value: Texts.balancesheets__sumOfLiabilitiesAndEquity,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.calculated_result__BalanceSheetsTableLabel,
            className: 'summary background  ',
        },
    ],
};

export const populateBalanceSheetsYears = (balanceSheets: BalanceSheetRow): TableColumn => {
    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: balanceSheets.period,
            className: 'my-numbers-data',
        },
        rows: [
            {
                value: ' ',
                className: ' number ',
            },
            {
                value: FinancialYearAsYearBreakMonth(balanceSheets.financialYear) ?? '',
                className: ' number bold',
            },
            {
                value: formatCompactFinancialYear(balanceSheets.financialYear),
                className: ' number tiny-cell',
            },
            {
                value: ' ',
                className: 'level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.intangibleAssets)),
                className: '  number compare-to-previous foldable line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalTangibleAssets)),
                className: '  number compare-to-previous foldable line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.financialFixAssets)),
                className: '  number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalFixAssets)),
                className: 'summary  number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.warehouseAndWorkInProgress)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalAccountsReceivable)),
                className: '  number compare-to-previous  foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.otherCurrentReceivablesAggregated)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.cashAndCashEquivalent)),
                className: ' number compare-to-previous  foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalLiquidAssets)),
                className: 'summary  number compare-to-previous  ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalAssets)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: ' ',
                className: '  level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.equity)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.untaxedReserves)),
                className: '  number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.provision)),
                className: '  number compare-to-previous  line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalLongTermLiabilities)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.totalCurrentLiabilities)),
                className: ' number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheets.sumOfLiabilitiesAndEquity)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: FormatNumber(
                    Tkr(balanceSheets.totalAssets - balanceSheets.sumOfLiabilitiesAndEquity),
                ),
                className: 'background summary  number compare-to-previous ',
            },
        ],
    };
    return column;
};

export const BalanceSheetsQuartersLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
    },
    rows: [
        {
            value: ' ',
            className: '',
        },
        {
            value: Texts.balance_sheets__ViewTitle + ' ' + Texts.tkr__TableLabel,
            className: 'bold',
        },
        {
            value: Texts.financial_year__BalaceSheetsTableLabel,
            className: ' ',
        },
        {
            value: Texts.assets__BalaceSheetsTableHeader,
            className: ' level-1-header',
        },
        {
            value: Texts.balancesheets__intangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalTangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__financialFixAssets,
            className: '  foldable',
        },
        {
            value: Texts.balancesheets__totalFixAssets,
            className: 'summary    space-under',
        },
        {
            value: Texts.balancesheets__warehouseAndWorkInProgress,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__totalAccountsReceivable,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__otherCurrentReceivablesAggregated,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__cashAndCashEquivalent,
            className: '   foldable',
        },
        {
            value: Texts.balancesheets__totalLiquidAssets,
            className: 'summary    ',
        },
        {
            value: Texts.balancesheets__totalAssets,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.equity_and_debt__BalanceSheetsTableHeader,
            className: '  level-1-header',
        },
        {
            value: Texts.balancesheets__equity,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__untaxedReserves,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__provision,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__totalLongTermLiabilities,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalCurrentLiabilities,
            className: '  foldable ',
        },
        {
            value: Texts.balancesheets__sumOfLiabilitiesAndEquity,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.calculated_result__BalanceSheetsTableLabel,
            className: 'summary background  ',
        },
    ],
};

export const PopulateBalanceSheetsQuarters = (balanceSheet: BalanceSheetRow, quarter: Quarter) => {
    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: balanceSheet.period,
            className: 'my-numbers-data',
        },
        rows: [
            {
                value: ' ',
                className: ' number ',
            },
            {
                value: quarter.q,
                className: ' number bold',
            },
            {
                value: quarter.compactLabel,
                className: ' number tiny-cell',
            },
            {
                value: ' ',
                className: 'level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.intangibleAssets)),
                className: '  number compare-to-previous foldable line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalTangibleAssets)),
                className: '  number compare-to-previous foldable line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.financialFixAssets)),
                className: '  number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalFixAssets)),
                className: 'summary  number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.warehouseAndWorkInProgress)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalAccountsReceivable)),
                className: '  number compare-to-previous  foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.otherCurrentReceivablesAggregated)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.cashAndCashEquivalent)),
                className: ' number compare-to-previous  foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalLiquidAssets)),
                className: 'summary  number compare-to-previous  ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalAssets)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: ' ',
                className: '  level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.equity)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.untaxedReserves)),
                className: '  number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.provision)),
                className: '  number compare-to-previous  line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalLongTermLiabilities)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalCurrentLiabilities)),
                className: ' number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.sumOfLiabilitiesAndEquity)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: FormatNumber(
                    Tkr(balanceSheet.totalAssets - balanceSheet.sumOfLiabilitiesAndEquity),
                ),
                className: 'background summary  number compare-to-previous ',
            },
        ],
    };
    return column;
};

export const BalanceSheetsMonthsLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
    },
    rows: [
        {
            value: ' ',
            className: '',
        },
        {
            value: Texts.balance_sheets__ViewTitle + ' ' + Texts.tkr__TableLabel,
            className: 'bold',
        },
        {
            value: Texts.balancesheets__period,
            className: ' ',
        },
        {
            value: Texts.assets__BalaceSheetsTableHeader,
            className: ' level-1-header',
        },
        {
            value: Texts.balancesheets__intangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalTangibleAssets,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__financialFixAssets,
            className: '  foldable',
        },
        {
            value: Texts.balancesheets__totalFixAssets,
            className: 'summary    space-under',
        },
        {
            value: Texts.balancesheets__warehouseAndWorkInProgress,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__totalAccountsReceivable,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__otherCurrentReceivablesAggregated,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__cashAndCashEquivalent,
            className: '   foldable',
        },
        {
            value: Texts.balancesheets__totalLiquidAssets,
            className: 'summary    ',
        },
        {
            value: Texts.balancesheets__totalAssets,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.equity_and_debt__BalanceSheetsTableHeader,
            className: '  level-1-header',
        },
        {
            value: Texts.balancesheets__equity,
            className: '   foldable line-under',
        },
        {
            value: Texts.balancesheets__untaxedReserves,
            className: '  line-under',
        },
        {
            value: Texts.balancesheets__provision,
            className: '   line-under',
        },
        {
            value: Texts.balancesheets__totalLongTermLiabilities,
            className: '  foldable line-under',
        },
        {
            value: Texts.balancesheets__totalCurrentLiabilities,
            className: '  foldable ',
        },
        {
            value: Texts.balancesheets__sumOfLiabilitiesAndEquity,
            className: '  level-1-footer space-under',
        },
        {
            value: Texts.calculated_result__BalanceSheetsTableLabel,
            className: 'summary background  ',
        },
    ],
};

export enum MonthReportColumnType {
    Primary,
    Secondary,
    Budget,
}

const GetColumnType = (periodType: MonthReportColumnType) => {
    switch (periodType) {
        case MonthReportColumnType.Primary:
            return 'my-numbers-data';
        case MonthReportColumnType.Secondary:
            return 'secondary-data';
        case MonthReportColumnType.Budget:
            return 'budget-data';
    }
};

export const PopulateBalanceSheetsMonths = (
    balanceSheet: BalanceSheetRow,
    periodType: MonthReportColumnType,
) => {
    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: balanceSheet.period,
            className: GetColumnType(periodType),
        },
        rows: [
            {
                value:
                    periodType === MonthReportColumnType.Primary
                        ? Texts.balancesheets__period
                        : Texts.report_generator.previous_period,
                className: ' number ',
            },
            {
                value: ' ',
                className: ' bold number ',
            },
            {
                value: balanceSheet.period.substring(0, 7),
                className: ' number ',
            },
            {
                value: ' ',
                className: 'level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.intangibleAssets)),
                className: '  number compare-to-previous foldable line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalTangibleAssets)),
                className: '  number compare-to-previous foldable line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.financialFixAssets)),
                className: '  number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalFixAssets)),
                className: 'summary  number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.warehouseAndWorkInProgress)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalAccountsReceivable)),
                className: '  number compare-to-previous  foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.otherCurrentReceivablesAggregated)),
                className: '   number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.cashAndCashEquivalent)),
                className: ' number compare-to-previous  foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalLiquidAssets)),
                className: 'summary  number compare-to-previous  ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalAssets)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: ' ',
                className: '  level-1-header',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.equity)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.untaxedReserves)),
                className: '  number compare-to-previous  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.provision)),
                className: '  number compare-to-previous  line-under ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalLongTermLiabilities)),
                className: '  number compare-to-previous foldable  line-under',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.totalCurrentLiabilities)),
                className: ' number compare-to-previous foldable ',
            },
            {
                value: FormatNumber(Tkr(balanceSheet.sumOfLiabilitiesAndEquity)),
                className: ' number compare-to-previous level-1-footer space-under',
            },
            {
                value: FormatNumber(
                    Tkr(balanceSheet.totalAssets - balanceSheet.sumOfLiabilitiesAndEquity),
                ),
                className: 'background summary  number compare-to-previous ',
            },
        ],
    };
    return column;
};

export const ProfitAndLossYearsLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
    },
    rows: [
        {
            value: ' ',
            className: ' ',
        },
        {
            value: Texts.profit_and_loss__ViewTitle + ' ' + Texts.tkr__TableLabel,
            className: 'level-1-header',
        },
        {
            value: Texts.financial_year__ProfitAndLossTableLabel,
            className: ' ',
        },
        {
            value: Texts.profitandloss__revenue,
            className: ' hideable line-under ',
        },
        {
            value: Texts.profitandloss__otherOperatingIncome,
            className: ' hideable',
        },
        {
            value: Texts.total_income__ProfitAndLossTableLabel,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__directCost,
            className: ' hideable  line-under',
        },
        {
            value: Texts.profitandloss__otherOperatingExpensesAggregated,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__personnelCosts,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__depreciationAndWritedowns,
            className: 'hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfExpenses,
            className: 'bold summary space-under',
        },
        {
            value: Texts.profitandloss__ebit,
            className: ' bold space-under',
        },
        {
            value: Texts.profitandloss__financialIncomes,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__financialExpenses,
            className: ' hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfFinancialPosts,
            className: ' bold',
        },
        {
            value: Texts.profitandloss__currentProfitEarningBeforeTax,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__extraordinaryIncomeAndExpenses,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__appropriations,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__tax,
            className: ' bold ',
        },
        {
            value: Texts.profitandloss__netProfit,
            className: 'bold summary ',
        },
    ],
};

export const ProfitAndLossQuartersLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
        className: '',
    },
    rows: [
        {
            value: ' ',
            className: ' ',
        },
        {
            value: `${Texts.profit_and_loss__ViewTitle} ${Texts.tkr__TableLabel}`,
            className: 'bold',
        },
        {
            value: Texts.profit_and_loss__FinancialYear,
            className: ' ',
        },
        {
            value: Texts.profitandloss__revenue,
            className: ' hideable line-under ',
        },
        {
            value: Texts.profitandloss__otherOperatingIncome,
            className: ' hideable',
        },
        {
            value: Texts.total_income__ProfitAndLossTableLabel,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__directCost,
            className: ' hideable line-under ',
        },
        {
            value: Texts.profitandloss__otherOperatingExpensesAggregated,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__personnelCosts,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__depreciationAndWritedowns,
            className: 'hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfExpenses,
            className: 'bold summary space-under',
        },
        {
            value: Texts.profitandloss__ebit,
            className: ' bold  space-under',
        },
        {
            value: Texts.profitandloss__financialIncomes,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__financialExpenses,
            className: ' hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfFinancialPosts,
            className: ' bold',
        },
        {
            value: Texts.profitandloss__currentProfitEarningBeforeTax,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__extraordinaryIncomeAndExpenses,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__appropriations,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__tax,
            className: ' bold ',
        },
        {
            value: Texts.report_generator.quarterResult,
            className: 'bold summary space-under',
        },
    ],
};

export const ProfitAndLossMonthsLabels: TableColumn = {
    metaInformation: {
        labelsColumn: 1,
        period: '',
    },
    rows: [
        {
            value: ' ',
            className: ' ',
        },
        {
            value: Texts.profit_and_loss__ViewTitle + ' ' + Texts.tkr__TableLabel,
            className: 'level-1-header',
        },
        {
            value: Texts.profitandloss__period,
            className: ' ',
        },
        {
            value: Texts.profitandloss__revenue,
            className: ' hideable line-under ',
        },
        {
            value: Texts.profitandloss__otherOperatingIncome,
            className: ' hideable',
        },
        {
            value: Texts.total_income__ProfitAndLossTableLabel,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__directCost,
            className: ' hideable  line-under',
        },
        {
            value: Texts.profitandloss__otherOperatingExpensesAggregated,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__personnelCosts,
            className: 'hideable line-under ',
        },
        {
            value: Texts.profitandloss__depreciationAndWritedowns,
            className: 'hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfExpenses,
            className: 'bold summary space-under',
        },
        {
            value: Texts.profitandloss__ebit,
            className: ' bold space-under',
        },
        {
            value: Texts.profitandloss__financialIncomes,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__financialExpenses,
            className: ' hideable line-under',
        },
        {
            value: Texts.profitandloss__sumOfFinancialPosts,
            className: ' bold',
        },
        {
            value: Texts.profitandloss__currentProfitEarningBeforeTax,
            className: ' bold summary space-under',
        },
        {
            value: Texts.profitandloss__extraordinaryIncomeAndExpenses,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__appropriations,
            className: 'hideable  line-under',
        },
        {
            value: Texts.profitandloss__tax,
            className: ' bold ',
        },
        {
            value: Texts.report_generator.monthResult,
            className: 'bold summary ',
        },
    ],
};

export const PopulateProfitAndLossQuarters = (
    profitAndLoss: ProfitAndLossRow,
    quarter: Quarter,
) => {
    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: quarter.label,
            className: 'my-numbers-data',
        },
        rows: [
            {
                value: ' ',
                className: 'bold number ',
            },
            {
                value: quarter.q,
                className: 'bold number tiny-cell ',
            },
            {
                value: quarter.compactLabel,
                className: ' number tiny-cell ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.revenue)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingIncome)),
                className: ' hideable number ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.totalOperatingIncome)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.directCost)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingExpensesAggregated)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.personnelCosts)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.depreciationAndWritedowns)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfExpenses)),
                className: ' bold summary number compare-to-previous space-under ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.ebit)),
                className: 'bold  number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialIncomes)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialExpenses)),
                className: ' hideable number line-under ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfFinancialPosts)),
                className: 'bold number compare-to-previous ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.currentProfitEarningBeforeTax)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.extraordinaryIncomeAndExpenses)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.appropriations)),
                className: 'hideable number line-under ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.tax)),
                className: ' bold number compare-to-previous',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.netProfit)),
                className: 'bold summary number compare-to-previous space-under',
            },
        ],
    };

    return column;
};

export const PopulateProfitAndLossYears = (
    profitAndLoss: ProfitAndLossRow,
    budgetName: string | null,
): TableColumn => {
    const columnTitle =
        budgetName ?? FinancialYearAsYearBreakMonth(profitAndLoss.financialYear) ?? '';

    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: profitAndLoss.period,
            className: 'my-numbers-data',
        },
        rows: [
            {
                value: ' ',
                className: ' number ',
            },
            {
                value: columnTitle,
                className: ' bold number ',
            },
            {
                value: formatCompactFinancialYear(profitAndLoss.financialYear),
                className: ' number tiny-cell ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.revenue)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingIncome)),
                className: ' hideable number ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.totalOperatingIncome)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.directCost)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingExpensesAggregated)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.personnelCosts)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.depreciationAndWritedowns)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfExpenses)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.ebit)),
                className: 'bold number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialIncomes)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialExpenses)),
                className: ' hideable number  line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfFinancialPosts)),
                className: 'bold number compare-to-previous ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.currentProfitEarningBeforeTax)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.extraordinaryIncomeAndExpenses)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.appropriations)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.tax)),
                className: ' bold number compare-to-previous',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.netProfit)),
                className: 'bold summary number compare-to-previous ',
            },
        ],
    };
    return column;
};

export const PopulateProfitAndLossMonth = (
    profitAndLoss: ProfitAndLossRow,
    budgetName: string | null,
    periodType: MonthReportColumnType,
): TableColumn => {
    let column = {
        metaInformation: {
            labelsColumn: 0,
            period: profitAndLoss.period,
            className: GetColumnType(periodType),
        },
        rows: [
            {
                value:
                    budgetName ??
                    (periodType === MonthReportColumnType.Primary
                        ? Texts.profitandloss__period
                        : Texts.report_generator.previous_period),
                className: ' number ',
            },
            {
                value: ' ',
                className: ' bold number ',
            },
            {
                value: profitAndLoss.period.substring(0, 7),
                className: ' number ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.revenue)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingIncome)),
                className: ' hideable number ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.totalOperatingIncome)),
                className: ' bold summary number compare-to-previous space-under',
            },

            {
                value: FormatNumber(Tkr(profitAndLoss.directCost)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.otherOperatingExpensesAggregated)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.personnelCosts)),
                className: ' hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.depreciationAndWritedowns)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfExpenses)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.ebit)),
                className: 'bold number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialIncomes)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.financialExpenses)),
                className: ' hideable number  line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.sumOfFinancialPosts)),
                className: 'bold number compare-to-previous ',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.currentProfitEarningBeforeTax)),
                className: ' bold summary number compare-to-previous space-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.extraordinaryIncomeAndExpenses)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.appropriations)),
                className: 'hideable number line-under',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.tax)),
                className: ' bold number compare-to-previous',
            },
            {
                value: FormatNumber(Tkr(profitAndLoss.netProfit)),
                className: 'bold summary number compare-to-previous ',
            },
        ],
    };
    return column;
};
