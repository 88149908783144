import { atom, DefaultValue, selector, useResetRecoilState } from 'recoil';
import User from '../../Types/Context/User';

const formKey = 'userState';

export const userIdState = atom<User['id']>({
    key: `${formKey}-id`,
    default: 0,
});

export const userPublicIdState = atom<User['publicId']>({
    key: `${formKey}-publicId`,
    default: '',
});

export const userFirstNameState = atom<User['firstName']>({
    key: `${formKey}-firstName`,
    default: '',
});

export const userLastNameState = atom<User['lastName']>({
    key: `${formKey}-lastName`,
    default: '',
});
export const userEmailState = atom<User['email']>({
    key: `${formKey}-email`,
    default: '',
});
export const userInfoState = atom<User['info']>({
    key: `${formKey}-info`,
    default: '',
});
export const userHasLicenseState = atom<User['hasLicense']>({
    key: `${formKey}-hasLicense`,
    default: false,
});
export const userCreditorNameState = atom<User['creditorName']>({
    key: `${formKey}-creditorName`,
    default: '',
});
export const userLastLoginState = atom<User['lastLogin']>({
    key: `${formKey}-lastLogin`,
    default: new Date('1970-01-01T00:00:00.001Z'),
});
export const userCreditorsState = atom<User['creditors']>({
    key: `${formKey}-creditors`,
    default: [],
});
export const userAccountingFirmsState = atom<User['accountingFirms']>({
    key: `${formKey}-accountingFirms`,
    default: [],
});
export const userBillingIntervalState = atom<User['billingInterval']>({
    key: `${formKey}-billingInterval`,
    default: null,
});
export const userBillingCompanyNameState = atom<User['billingCompanyName']>({
    key: `${formKey}-billingCompanyName`,
    default: '',
});
export const userBillingIntervalFromState = atom<User['billingIntervalFrom']>({
    key: `${formKey}-billingIntervalFrom`,
    default: null,
});
export const userBillingEmailState = atom<User['billingEmail']>({
    key: `${formKey}-billingEmail`,
    default: '',
});
export const userCancellationDateState = atom<User['cancellationDate']>({
    key: `${formKey}-cancellationDate`,
    default: null,
});
export const userLicenseValidUntilState = atom<User['licenseValidUntil']>({
    key: `${formKey}-licenseValidUntil`,
    default: null,
});
export const userTrialUntilState = atom<User['trialUntil']>({
    key: `${formKey}-trialUntil`,
    default: null,
});

export const useUserStateReset = () => {
    const id = useResetRecoilState(userIdState);
    const publicId = useResetRecoilState(userPublicIdState);
    const firstName = useResetRecoilState(userFirstNameState);
    const lastName = useResetRecoilState(userLastNameState);
    const email = useResetRecoilState(userEmailState);
    const info = useResetRecoilState(userInfoState);
    const hasLicense = useResetRecoilState(userHasLicenseState);
    const creditorName = useResetRecoilState(userCreditorNameState);
    const lastLogin = useResetRecoilState(userLastLoginState);
    const creditors = useResetRecoilState(userCreditorsState);
    const accountingFirms = useResetRecoilState(userAccountingFirmsState);
    const billingInterval = useResetRecoilState(userBillingIntervalState);
    const billingCompanyName = useResetRecoilState(userBillingCompanyNameState);
    const billingIntervalFrom = useResetRecoilState(userBillingIntervalFromState);
    const billingEmail = useResetRecoilState(userBillingEmailState);
    const cancellationDate = useResetRecoilState(userCancellationDateState);
    const licenseValidUntil = useResetRecoilState(userLicenseValidUntilState);
    const trialUntil = useResetRecoilState(userTrialUntilState);

    return () => {
        id();
        publicId();
        firstName();
        lastName();
        email();
        info();
        hasLicense();
        creditorName();
        lastLogin();
        creditors();
        accountingFirms();
        billingInterval();
        billingCompanyName();
        billingIntervalFrom();
        billingEmail();
        cancellationDate();
        licenseValidUntil();
        trialUntil();
    };
};

export const userStateSelector = selector({
    key: `${formKey}-selector`,
    get: ({ get }): User => {
        const id = get(userIdState);
        const publicId = get(userPublicIdState);
        const firstName = get(userFirstNameState);
        const lastName = get(userLastNameState);
        const email = get(userEmailState);
        const info = get(userInfoState);
        const hasLicense = get(userHasLicenseState);
        const creditorName = get(userCreditorNameState);
        const lastLogin = get(userLastLoginState);
        const creditors = get(userCreditorsState);
        const accountingFirms = get(userAccountingFirmsState);
        const billingInterval = get(userBillingIntervalState);
        const billingCompanyName = get(userBillingCompanyNameState);
        const billingIntervalFrom = get(userBillingIntervalFromState);
        const billingEmail = get(userBillingEmailState);
        const cancellationDate = get(userCancellationDateState);
        const licenseValidUntil = get(userLicenseValidUntilState);
        const trialUntil = get(userTrialUntilState);

        const userState = {
            id,
            publicId,
            firstName,
            lastName,
            email,
            info,
            hasLicense,
            creditorName,
            lastLogin,
            creditors,
            accountingFirms,
            billingInterval,
            billingCompanyName,
            billingIntervalFrom,
            billingEmail,
            cancellationDate,
            licenseValidUntil,
            trialUntil,
        };
        return userState;
    },
    set: ({ set }, value: User | DefaultValue) => {
        if (value instanceof DefaultValue) {
            set(userIdState, value);
            set(userPublicIdState, value);
            set(userFirstNameState, value);
            set(userLastNameState, value);
            set(userEmailState, value);
            set(userInfoState, value);
            set(userHasLicenseState, value);
            set(userCreditorNameState, value);
            set(userLastLoginState, value);
            set(userCreditorsState, value);
            set(userAccountingFirmsState, value);
            set(userBillingIntervalState, value);
            set(userBillingCompanyNameState, value);
            set(userBillingIntervalFromState, value);
            set(userBillingEmailState, value);
            set(userCancellationDateState, value);
            set(userLicenseValidUntilState, value);
            set(userTrialUntilState, value);
        } else {
            set(userIdState, value.id);
            set(userPublicIdState, value.publicId);
            set(userFirstNameState, value.firstName);
            set(userLastNameState, value.lastName);
            set(userEmailState, value.email);
            set(userInfoState, value.info);
            set(userHasLicenseState, value.hasLicense);
            set(userCreditorNameState, value.creditorName);
            set(userLastLoginState, value.lastLogin);
            set(userCreditorsState, value.creditors);
            set(userAccountingFirmsState, value.accountingFirms);
            set(userBillingIntervalState, value.billingInterval);
            set(userBillingCompanyNameState, value.billingCompanyName);
            set(userBillingIntervalFromState, value.billingIntervalFrom);
            set(userBillingEmailState, value.billingEmail);
            set(userCancellationDateState, value.cancellationDate);
            set(userLicenseValidUntilState, value.licenseValidUntil);
            set(userTrialUntilState, value.trialUntil);
        }
    },
});
