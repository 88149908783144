import React, { useEffect, useState, useRef } from 'react';
import Texts from '../Functions/Texts.json';
import Help from '../Components/Help';
import DashboardHeader from '../Components/DashboardHeader';
import RowBasedTable, { RowTable, RowTableRow, emptyTable } from '../Components/RowBasedTable';
import AllCompaniesMenu from '../Components/AllCompaniesMenu';
import ChartCompare from '../Components/ChartCompare';
import axiosInstance from '../Core/Axios';
import {
    SortByTextAndNull,
    Tkr,
    RoundIfExceeds100Percent,
    FactorHundredForCompare,
} from '../Functions/Calculations';
import {
    DatetimeAsDate,
    FormatNumber,
    FormatOrganizationNumber,
    NumberAsEuropeanDecimalNumber,
} from '../Functions/Formatting';
import { GetMainSni } from '../Functions/Functions';
import { useRecoilValue } from 'recoil';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';
import ListMetaInformation from '../Types/Api/Response/ListMetaInformation';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import { companiesState } from '../Core/State/CompanyListState';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import useSnackbar from '../Components/Snackbar/useSnackbar';
import { add, format, startOfMonth } from 'date-fns';
import RowBasedTableWrapper from '../Components/Wrappers/RowBasedTableWrapper';
import Alert from '../Components/Alert';

export type CompareData = {
    name: string;
    mainSni: string;
    data: Array<ProfitAndLossRow>;
};

const CompareCompanies = () => {
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const [companiesComparison, setCompaniesComparison] = useState<CompareData[]>([]);
    const [chartsAreLoading, updateChartsAreLoading] = useState(true);
    const [compareOverlayIsOpen, setCompareOverlayIsOpen] = useState<boolean>(false);
    const [showMaximumInfo, setShowMaximumInfo] = useState<boolean>(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { getCompanyList } = useGetCompanyListRequest();
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );
    const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const { enqueueErrorSnackbar } = useSnackbar();

    const getCompanyMainSni = (company: CompanyDetails) => {
        const sniCodes = company.officialInformation?.sniCodes ?? [];
        const sni = GetMainSni(sniCodes);
        return sni ?? 'Verksamhetsbeskriving saknas';
    };

    const futurePeriod = format(startOfMonth(add(new Date(), { months: 1 })), 'yyy-MM-dd');

    const getCompareData = async (organizationNumber: string) => {
        const company = companyList?.companies?.find(
            (c) => c.organizationNumber === organizationNumber,
        );
        if (!company) {
            throw new Error(`Could not find company with organizationNumer ${organizationNumber}`);
        }

        try {
            const pnlRes = await axiosInstance.get<Array<ProfitAndLossRow>>(
                `Reports/profitAndLoss/${company.id}/monthly`,
            );

            return {
                name: company.organizationName,
                mainSni: getCompanyMainSni(company),
                data: pnlRes.data.filter((pnl) => pnl.period < futurePeriod),
            };
        } catch (e) {
            enqueueErrorSnackbar(Texts.responses.general);
            throw e;
        }
    };

    const checkboxCallback = (isChecked: boolean, value: string) => {
        setShowMaximumInfo(false);
        if (isChecked) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems(selectedItems.filter((i) => i !== value));
        }
    };

    const toggleCheckboxes = (isChecked: boolean, visibleValues: Array<string>) => {
        setShowMaximumInfo(false);
        if (isChecked) {
            // Only select items that are actually selectable
            setSelectedItems(
                companyList?.companies
                    .filter(
                        (c) =>
                            c.consentStatus === 'OK' &&
                            c.latestUpload != null &&
                            c.latestVoucher != null &&
                            c.financialYearToDate != null &&
                            visibleValues.some((v) => v === c.organizationNumber),
                    )
                    .map((c) => c.organizationNumber) ?? [],
            );
        } else {
            setSelectedItems([]);
        }
    };

    const handleSelected = async (action: string) => {
        if (selectedItems.length > 4) {
            setShowMaximumInfo(true);
        } else {
            setCompareOverlayIsOpen(true);
            updateChartsAreLoading(true);
            const data = await Promise.all(selectedItems?.map((orgNr) => getCompareData(orgNr)));
            setCompaniesComparison(data);
            updateChartsAreLoading(false);
        }
    };

    const setupTable = (
        tableData:
            | { companies: CompanyDetails[]; metaInformation: ListMetaInformation }
            | null
            | undefined,
    ): RowTable => {
        if (!tableData) {
            return emptyTable;
        }

        let rows = [];
        let newTable: RowTable = {
            head: [
                {
                    label: ' ',
                    type: 'checkbox',
                    className: 'checkbox-col',
                },
                {
                    label: Texts.company_name__CompaniesTableLabel,
                    type: 'sort',
                    className: 'large',
                },
                {
                    label: Texts.organization_number__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.financial_year__CompaniesTableLabel,
                    type: 'sort',
                    className: 'small right',
                },
                {
                    label: Texts.sni__CompaniesTableLabel,
                    type: 'sort',
                    className: 'medium',
                },
                {
                    label: Texts.revenue__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'medium right',
                },
                {
                    label: Texts.net_profit__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'medium right',
                },
                {
                    label: Texts.solvency__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.quick_ratio__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'small right',
                },
                {
                    label: Texts.cashflow__CompaniesTableLabel,
                    type: 'sortNumber',
                    className: 'medium right',
                },
            ],
            rows: [],
            actions: [
                <button
                    key='compare-key1'
                    className={`compare-button ${selectedItems.length > 1 ? 'is-activated' : ''}`}
                    ref={buttonRef}
                    onClick={() => handleSelected('compare')}
                >
                    <div className='mask'></div>
                    {Texts.compare_selected__CompareButtonText}
                </button>,
            ],
            metaInformation: {
                allSelected: 0,
                currentPage: 0,
                sortReverse: 0,
                totalPages: 0,
                totalResources: 0,
            },
        };

        let companies = tableData.companies;

        for (let i = 0; i < companies.length; i++) {
            let keyRatios = companies[i].currentKeyRatio;

            const latestVoucher = DatetimeAsDate(companies[i].latestVoucher);
            const latestUpload = DatetimeAsDate(companies[i].latestUpload);

            let sniInfo = '-';
            let sniTooltip = '';
            if (companies[i].officialInformation && companies[i].officialInformation.sniCodes) {
                sniInfo = GetMainSni(companies[i].officialInformation.sniCodes) ?? '-';
                for (let ii = 0; ii < companies[i].officialInformation.sniCodes.length; ii++) {
                    sniTooltip +=
                        companies[i].officialInformation.sniCodes[ii].sni +
                        ' ' +
                        companies[i].officialInformation.sniCodes[ii].descriptionSwedish +
                        ', ';
                }
                if (companies[i].officialInformation.sniCodes[1]) {
                    sniInfo += Texts.etc__TableCellValue;
                }
            }

            let financialYearToDate = '-';
            if (companies[i].financialYearToDate) {
                financialYearToDate = companies[i].financialYearToDate?.substring(0, 10) ?? '';
            }

            let row: RowTableRow = {
                metaInformation: {
                    visible: true,
                    value: companies[i].organizationNumber,
                },
                cells: [
                    {
                        text: '',
                        func: 'checkbox',
                        value: FormatOrganizationNumber(companies[i].organizationNumber),
                        className:
                            companies[i].consentStatus === 'OK' &&
                            latestUpload != null &&
                            latestVoucher != null &&
                            companies[i].financialYearToDate != null
                                ? 'checkbox-col'
                                : 'checkbox-col checkbox-disabled',
                        isChecked: selectedItems.some((s) => s === companies[i].organizationNumber),
                        checkboxCallback: checkboxCallback,
                    },
                    {
                        text: companies[i].organizationName,
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'large',
                    },
                    {
                        text: FormatOrganizationNumber(companies[i].organizationNumber),
                        link:
                            '/company/' + FormatOrganizationNumber(companies[i].organizationNumber),
                        className: 'small right',
                    },
                    {
                        text: financialYearToDate || '-',
                        className: 'small right',
                    },
                    {
                        text: sniInfo,
                        className: 'medium hide-overflow',
                        func: 'tooltip',
                        value: sniTooltip,
                    },
                    {
                        text: keyRatios ? FormatNumber(Tkr(keyRatios.revenue)) : '-',
                        className: 'medium right',
                        link: `/company/${companies[i].organizationNumber}/profit-and-loss/`,
                    },
                    {
                        text: keyRatios ? FormatNumber(Tkr(keyRatios.netProfit)) : '-', // inte currentProfitEarningBeforeTax?
                        className: 'medium right',
                        link: `/company/${companies[i].organizationNumber}/profit-and-loss/`,
                    },
                    {
                        text: keyRatios
                            ? NumberAsEuropeanDecimalNumber(
                                  RoundIfExceeds100Percent(
                                      FactorHundredForCompare(keyRatios.solvency),
                                  ),
                              )
                            : '-',
                        className: 'small right',
                        link: `/company/${companies[i].organizationNumber}/financial-ratio/`,
                    },
                    {
                        text: keyRatios
                            ? NumberAsEuropeanDecimalNumber(
                                  RoundIfExceeds100Percent(
                                      FactorHundredForCompare(keyRatios.quickRatio),
                                  ),
                              )
                            : '-',
                        className: 'small right',
                        link: `/company/${companies[i].organizationNumber}/financial-ratio/`,
                    },
                    {
                        text: keyRatios ? FormatNumber(Tkr(keyRatios.accumulatedCashFlow)) : '-',
                        className: 'medium right',
                        link: `/company/${companies[i].organizationNumber}/cashflow/`,
                    },
                ],
            };
            rows.push(row);
        }

        if (rows != null && rows.length > 0) {
            rows = rows.sort(SortByTextAndNull(0));
            newTable.metaInformation = tableData.metaInformation;
        }

        newTable.rows = rows;

        return newTable;
    };

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const table = setupTable(companyList);

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help
                    closeHelp={() => setHelpIsOpen(false)}
                    helpItems={['companies', 'dashboard']}
                />
            )}
            {compareOverlayIsOpen && companiesComparison && (
                <div className='compare-overlay'>
                    <div className='compare-wrapper'>
                        <h2>{Texts.compare_companies__CompareOverlayHeading}</h2>
                        <div
                            className='compare-close'
                            onClick={(e) => {
                                setCompareOverlayIsOpen(false);
                                setCompaniesComparison([]);
                            }}
                        >
                            {Texts.close__CompareOverlayCloseButtonText}
                            <div className='mask'></div>
                        </div>
                        <div className='compare-scroll-container'>
                            <div className='compare-item compare-dashboard'>
                                <ChartCompare
                                    companiesComparison={companiesComparison}
                                    chartsAreLoading={chartsAreLoading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <DashboardHeader
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                companies={companyList}
                viewTitle={Texts.dashboard_compare_companies__DashboardViewTitle}
                toggleHelp={() => setHelpIsOpen(true)}
            />
            <AllCompaniesMenu />
            <RowBasedTableWrapper>
                <div className='row-based-table-wrapper-heading'>
                    <p>{Texts.displaying_basic_data__BasicDataCompaniesViewTitle}</p>
                </div>
                {showMaximumInfo && (
                    <Alert
                        severity='error'
                        message={Texts.too_many_companies__CompareOverlayText}
                    />
                )}
                <RowBasedTable table={table} toggleCheckboxes={toggleCheckboxes} />
            </RowBasedTableWrapper>
        </div>
    );
};

export default CompareCompanies;
