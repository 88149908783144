import { useState } from 'react';
import Help from '../../Components/Help';
import Texts from '../../Functions/Texts.json';
import {
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../../Components/NewColumnBasedTable';
import { ExpandedFinancialRatioRow } from './Hooks/useGetFinancialRatio';
import { useParams } from 'react-router-dom';
import {
    FinancialYearAsYearMonth,
    FormatFinancialRatioNumber,
    NumberAsEuropeanDecimalNumber,
} from '../../Functions/Formatting';
import useGetFinancialRatio from './Hooks/useGetFinancialRatio';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetOrgId from '../../Functions/useGetOrgId';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const TableRow: (
    props: TableRowProps<ExpandedFinancialRatioRow>,
) => TableRowInstance<ExpandedFinancialRatioRow> = TableRowBase;

const FinancialRatio = () => {
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState<boolean>(false);
    const { ratios, isLoading } = useGetFinancialRatio(useParams());
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });

    const rt = (val: number | undefined) => (val === undefined || val === 0 ? '-' : val);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['financial-ratio']} />
            )}
            <div className='company-heading'>
                <div
                    className='help help-above'
                    id='helpOpener'
                    onClick={() => setHelpIsOpen(true)}
                >
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            {ratios && (
                <NewColumnBasedTable<ExpandedFinancialRatioRow>
                    tableIsLoading={isLoading}
                    columnWidth={180}
                    headerMinWidth={240}
                    data={ratios}
                    csvExportFilename={`${Texts.financial_ratio__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                    pdfExportFilename={{
                        prefix: Texts.financial_ratio__DownloadFileNamePrefix,
                        companyName: companyDetails?.data.organizationName ?? '',
                        orgNr: companyDetails?.data.organizationNumber ?? '',
                    }}
                    style={{ maxWidth: '90%' }}
                >
                    <TableRow
                        className='column-header'
                        label=''
                        selector={(d) =>
                            d.isOfficial
                                ? Texts.official__TableHeader
                                : Texts.mynumbers__TableHeader
                        }
                    />
                    <TableRow
                        className='bold number'
                        label=''
                        selector={(d) =>
                            `${FinancialYearAsYearMonth(d.financialYear)}${d.isOfficial ? '*' : ''}`
                        }
                    />
                    <TableRow
                        className='tiny-row'
                        label={Texts.financial_year__FinancialRatioTableHeader}
                        selector={(d) => d.financialYear.replace(':', '-')}
                    />
                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_result}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.profitandloss__revenue} ${Texts.tkr__TableLabel}`}
                        selector={(d) => FormatFinancialRatioNumber(d.revenue)}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.profitandloss__currentProfitEarningBeforeTax} ${Texts.tkr__TableLabel}`}
                        selector={(d) =>
                            FormatFinancialRatioNumber(d.currentProfitEarningBeforeTax)
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_profitability}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g6_netMargin} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g6_netMargin ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__t1_grossProfitMargin} (%)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(d.t1_grossProfitMargin ?? '')
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g5_operatingMargin} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g5_operatingMargin ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g4_riskBufferTotalCapital} (%)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(d.g4_riskBufferTotalCapital ?? '')
                        }
                        doCompare
                    />

                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_return}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g1_returnOnEquity} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g1_returnOnEquity ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g2_returnOnTotalCapital} (%)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(d.g2_returnOnTotalCapital ?? '')
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__t3_interestCoverageRatio} ${Texts.ggr__TableLabel}`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(rt(d.t3_interestCoverageRatio))
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__t31_returnOnWorkingCapital} (%)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(d.t31_returnOnWorkingCapital ?? '')
                        }
                        doCompare
                    />

                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_shortPay}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g12_quickRatio} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g12_quickRatio ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__t46_currentRatio} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.t46_currentRatio ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g11_workingCapital} ${Texts.tkr__TableLabel}`}
                        selector={(d) => FormatFinancialRatioNumber(d.g11_workingCapital ?? '')}
                        doCompare
                    />

                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_longPay}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g9_solvency} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g9_solvency ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g3_interestPayable} (%)`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(d.g3_interestPayable ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__t26_degreeOfDebt} ${Texts.ggr__TableLabel}`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(rt(d.t26_degreeOfDebt))}
                        doCompare
                    />

                    <TableRow
                        className='line-under-strong bold'
                        label={Texts.financialratio__FinancialRatioRowHeader_operation}
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__x4_averagePaymentPeriodDays} (dgr)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(rt(d.x4_averagePaymentPeriodDays))
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g10_capitalTurnover} ${Texts.ggr__TableLabel}`}
                        selector={(d) => NumberAsEuropeanDecimalNumber(rt(d.g10_capitalTurnover))}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__x2_cashFlowFromOperation} (%)`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(d.x2_cashFlowFromOperation ?? '')
                        }
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__g7_netRevPerEmployee} ${Texts.tkr__TableLabel}`}
                        selector={(d) => FormatFinancialRatioNumber(d.g7_netRevPerEmployee ?? '')}
                        doCompare
                    />
                    <TableRow
                        className='line-under number'
                        label={`${Texts.financialratio__x3_accountsReceivableTurnover} ${Texts.ggr__TableLabel}`}
                        selector={(d) =>
                            NumberAsEuropeanDecimalNumber(rt(d.x3_accountsReceivableTurnover))
                        }
                        doCompare
                    />
                </NewColumnBasedTable>
            )}
            <span className='official-data-disclaimer'>
                {Texts.official_data_from__DisclaimerText}
            </span>
        </ErrorBoundary>
    );
};

export default FinancialRatio;
