import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import MonthlyAccountAggregate from '../../Types/Api/Response/MonthlyAccountAggregate';

const useGetMonthlyAccountAggregatesQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<MonthlyAccountAggregate[]>,
) =>
    useQuery(
        ['monthlyAccountAggregates', companyId],
        async () =>
            axiosInstance.get<MonthlyAccountAggregate[]>(
                `corporatePortal/MonthlyAccountAggregates/${companyId}`,
            ),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetMonthlyAccountAggregatesQuery;
