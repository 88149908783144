/**
 *
 * Merge this with Formatting.js when the typescript conversion has been completed
 *
 */

import { MonthRange } from '../Components/GroupOverview/MonthRangePicker';

export const FormatFraction = (numerator: number, denominator: number, precision?: number) => {
    if (denominator === 0 || typeof numerator !== 'number' || typeof denominator !== 'number') {
        return '-';
    }
    const ratioPercentage = (100 * numerator) / denominator;
    const rounded = ratioPercentage.toFixed(precision ?? 1);
    const parts = rounded.toString().split('.');
    const decimals = parts.length > 1 && !parts[1].match(/^0+$/g) ? `.${parts[1]}` : '';
    return `${parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}${decimals} %`;
};

export const FormatSelectedMonthRange = (selectedMonthRange: MonthRange) =>
    `${selectedMonthRange.from.year}-${selectedMonthRange.from.month < 10 ? '0' : ''}${
        selectedMonthRange.from.month
    } – ${selectedMonthRange.to.year}-${selectedMonthRange.to.month < 10 ? '0' : ''}${
        selectedMonthRange.to.month
    }`;
