import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import TableColumn from '../Components/Reports/Types/TableColumn';

export type ExportConfig = {
    action: string;
    name: string;
};

function addPrePDFRows(
    className: string,
    rowsLength: number,
    myNumbersColumns: Array<{ columnIndex: number; className: string }>,
) {
    let extraRow = [];
    let c = className;
    for (let i = 0; i < rowsLength; i++) {
        c = className;
        const myNumbersColumn = myNumbersColumns.find((c) => c.columnIndex === i);
        if (myNumbersColumn && className.indexOf('empty-row') !== -1) {
            c += myNumbersColumn.className;
        }
        extraRow.push(c);
    }
    return extraRow;
}

function addPrePDFClasses(
    columns: TableColumn[],
    companyName: string,
    organizationNumber: string,
    fileName: string,
) {
    let rowsLength = 0;
    let columsLength = columns.length;

    if (columns[0] && columns[0].rows) {
        rowsLength = columns[0].rows.length;
    }

    let tBody = [];

    for (let i = 0; i < rowsLength; i++) {
        let row = [];
        let rowIsDivider = 0;
        let rowIsEmpty = 0;
        let rowHasLineUnder = 0;
        let myNumbersColumns: Array<{ columnIndex: number; className: string }> = [];
        for (let ii = 0; ii < columns.length; ii++) {
            let columnClassName = '';
            const className = columns[ii]?.metaInformation?.className;
            if (className) {
                myNumbersColumns.push({ columnIndex: ii, className });
                columnClassName = className;
            }

            if (columns[ii].rows[i] !== undefined) {
                row.push(
                    columns[ii].rows[i].value +
                        '||||' +
                        columns[ii].rows[i].className +
                        ' ' +
                        columnClassName,
                );

                if (columns[ii].rows[i].className.indexOf('summary') !== -1) {
                    rowIsDivider = 1;
                }
                if (columns[ii].rows[i].className.indexOf('space-under') !== -1) {
                    rowIsEmpty = 1;
                }
                if (columns[ii].rows[i].className.indexOf('line-under') !== -1) {
                    rowHasLineUnder = 1;
                }
            }
        }

        if (rowIsDivider === 1) {
            tBody.push(addPrePDFRows('||||line-over', columsLength, myNumbersColumns));
            rowIsDivider = 0;
        }

        tBody.push(row);

        if (rowHasLineUnder === 1) {
            tBody.push(addPrePDFRows('||||is-line', columsLength, myNumbersColumns));
            rowHasLineUnder = 0;
        }

        if (rowIsEmpty === 1) {
            tBody.push(addPrePDFRows('||||empty-row ', columsLength, myNumbersColumns));
            rowIsEmpty = 0;
        }
    }

    return tBody;
}

export function generateAndSavePDF(
    pdfConfig: ExportConfig,
    companyDetails: CompanyDetails,
    tableDatas: Array<Array<TableColumn>>,
) {
    if (companyDetails == null || companyDetails === undefined) {
        return;
    }

    const doc = new jsPDF();
    let fileName =
        pdfConfig.name +
        ' ' +
        companyDetails.organizationName +
        ' ' +
        companyDetails.organizationNumber;

    var saveDateAndTime = new Date().toLocaleString('sv-SE').substring(0, 16);

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(15);
    doc.text(pdfConfig.name, 17, 20);
    doc.setFontSize(13);
    doc.text(`${companyDetails.organizationName} (${companyDetails.organizationNumber})`, 17, 28);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    doc.text(`Skapad: ${saveDateAndTime}`, 17, 35);

    tableDatas.forEach((tableData, index) => {
        let columns = tableData;
        let pdfBody = addPrePDFClasses(
            columns,
            companyDetails.organizationName,
            companyDetails.organizationNumber,
            pdfConfig.name,
        );

        // Only use fixed columns widths if reasonable number of columns
        const cellWidths = { 2: 36, 3: 36, 4: 32 }[columns.length] ?? 'auto';
        const cellPadding =
            columns.length > 4 ? 2.4 : { top: 2.4, right: 4.0, bottom: 2.4, left: 2.4 };

        autoTable(doc, {
            theme: 'plain',
            head: [],
            body: pdfBody,
            pageBreak: 'avoid',
            ...(index === 0 ? { startY: 38 } : null),
            didParseCell: function (data) {
                data.cell.styles.cellPadding = 2.4;
                data.cell.styles.lineWidth = 0;
                data.cell.styles.minCellHeight = 0.2;

                if (data.cell.text.toString().indexOf('||||') !== -1) {
                    let classNames = data.cell.text.toString().split('||||')[1];

                    if (
                        classNames.indexOf('pdf-top-title') === -1 &&
                        classNames.indexOf('pdf-top-company-name') === -1
                    ) {
                        data.cell.styles.fillColor = '#ffffff';
                    }
                    if (classNames.indexOf('pdf-top-title') !== -1) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.fontSize = 15;
                        data.cell.styles.overflow = 'visible';
                    }
                    if (classNames.indexOf('pdf-top-company-name') !== -1) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.fontSize = 13;
                        data.cell.styles.overflow = 'visible';
                    }
                    if (classNames.indexOf('is-line') !== -1) {
                        data.cell.styles.fillColor = '#cdcdcd';
                        data.cell.styles.fontSize = 0.1;
                        data.cell.styles.cellPadding = 0.1;
                    }
                    if (classNames.indexOf('line-over') !== -1) {
                        data.cell.styles.fillColor = '#000000';
                        data.cell.styles.fontSize = 0.1;
                        data.cell.styles.cellPadding = 0.1;
                    }
                    if (classNames.indexOf('-data') !== -1) {
                        // Matches our data columns
                        data.cell.styles.cellWidth = cellWidths;
                        data.cell.styles.cellPadding = cellPadding;
                    }
                    if (classNames.indexOf('my-numbers-data') !== -1) {
                        data.cell.styles.fillColor = '#f0fafa';
                    } else if (classNames.indexOf('secondary-data') !== -1) {
                        data.cell.styles.fillColor = '#fbfbff';
                    } else if (classNames.indexOf('budget-data') !== -1) {
                        data.cell.styles.fillColor = '#efeaff';
                    }
                    if (classNames.indexOf('tiny-cell') !== -1) {
                        data.cell.styles.fontSize = 8;
                        data.cell.styles.overflow = 'ellipsize';
                    }
                    if (classNames.indexOf('new-page') !== -1) {
                        data.cell.styles.minCellHeight = 15;
                        data.cell.styles.fillColor = '#ffffff';
                    }
                    if (classNames.indexOf('bold') !== -1) {
                        data.cell.styles.fontStyle = 'bold';
                    }
                    if (classNames.indexOf('summary') !== -1) {
                        data.cell.styles.fontStyle = 'bold';
                    }
                    if (classNames.indexOf('level-1') !== -1) {
                        data.cell.styles.fontStyle = 'bold';
                        data.cell.styles.fontSize = 13;
                    }
                    if (classNames.indexOf('number') !== -1) {
                        data.cell.styles.halign = 'right';
                    }
                    if (classNames.indexOf('empty-row') !== -1) {
                        data.cell.styles.minCellHeight = 7.0;
                    }

                    data.cell.text = [data.cell.text.toString().split('||||')[0]];
                }
            },
        });
    });

    doc.save(fileName + '.pdf');
    return null;
}

function getNestedRows(tableData: Array<TableColumn>, rowIndex: number) {
    let rows = '';
    for (let ii = 0; ii < tableData[0].rows[rowIndex].rows!.length; ii++) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].rows[rowIndex].rows![ii].rows) {
                for (
                    let iiii = 0;
                    iiii < tableData[0].rows[rowIndex].rows![ii].rows!.length;
                    iiii++
                ) {
                    if (i === 0) {
                        for (let iii = 0; iii < tableData.length; iii++) {
                            rows += tableData[iii].rows[rowIndex].rows![ii].rows![iiii].value + ';';
                        }
                        rows += '\r\n';
                    }
                }
            }
            if (tableData[i].rows[rowIndex].rows![ii].value) {
                rows += `${tableData[i].rows[rowIndex].rows![ii].value};`;
            }
        }
        rows += '\r\n';
    }
    return rows;
}

export function generateAndSaveCSV(
    csvConfig: ExportConfig,
    companyDetails: CompanyDetails,
    tableData: Array<TableColumn>,
) {
    if (companyDetails == null || companyDetails === undefined) {
        return;
    }

    let csvTable = '';
    for (let ii = 0; ii < tableData[0].rows.length; ii++) {
        for (let i = 0; i < tableData.length; i++) {
            if (tableData[i].rows[ii].rows && i === 0) {
                csvTable += getNestedRows(tableData, ii);
            }
            if (tableData[i].rows[ii].value) {
                csvTable += tableData[i].rows[ii].value + ';';
            }
        }
        csvTable += '\r\n';
    }

    let fileName =
        csvConfig.name +
        ' ' +
        companyDetails.organizationName +
        ' ' +
        companyDetails.organizationNumber;

    const universalBOM = '\uFEFF';
    var a = window.document.createElement('a');
    a.setAttribute(
        'href',
        'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csvTable),
    );
    a.setAttribute('download', fileName + '.csv');
    window.document.body.appendChild(a);
    a.click();

    return null;
}
