import React from 'react';
import { useParams, Route, Routes } from 'react-router-dom';
import { BudgetSelector } from './BudgetSelector';
import { BudgetCreator } from './BudgetCreator';
import { BudgetEditor } from './BudgetEditor';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import useGetPnLAggregatedQuery from '../../Api/Reports/useGetPnLAggregatedQuery';
import useGetOrgId from '../../Functions/useGetOrgId';
import useGetMyBudgetsQuery from '../../Api/Budget/useGetMyBudgetsQuery';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const BudgetView = () => {
    const { organizationId } = useGetOrgId(useParams());
    const { data: profitAndLoss } = useGetPnLQuery(organizationId);
    const { data: profitAndLossAggregated } = useGetPnLAggregatedQuery(organizationId);
    const { data: budgetList } = useGetMyBudgetsQuery(organizationId);

    if (!profitAndLossAggregated || !profitAndLoss || !budgetList) return null;

    if (organizationId === null) {
        return null;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {/* This empty div is required to undo the float. Otherwise, margin-top won't work with `display: block;` */}
            <div style={{ clear: 'both' }}></div>
            <div className='budget-content'>
                <Routes>
                    <Route
                        path=''
                        element={
                            <BudgetSelector
                                budgets={budgetList?.data}
                                organizationId={organizationId}
                            />
                        }
                    />
                    <Route
                        path='/create'
                        element={
                            <BudgetCreator
                                monthlyData={profitAndLoss?.data}
                                yearlyData={profitAndLossAggregated?.data}
                                organizationId={organizationId}
                            />
                        }
                    />
                    <Route
                        path='/:budgetId'
                        element={
                            <BudgetEditor
                                budgets={budgetList?.data}
                                monthlyData={profitAndLoss?.data}
                                yearlyData={profitAndLossAggregated?.data}
                                organizationId={organizationId}
                            />
                        }
                    />
                </Routes>
            </div>
        </ErrorBoundary>
    );
};

export default BudgetView;
