import React from 'react';
import Texts from '../Functions/Texts.json';
import {
    ColumnBasedTableProps,
    ColumnData,
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from './NewColumnBasedTable';
import BalanceSheetRow from '../Types/Api/Response/BalanceSheetRow';

export type ExpandedBalanceSheetRow = BalanceSheetRow & ColumnData;

// This is done to fix the generic parameter, so that it doesn't need to be entered in every row.
const TableRow: (
    props: TableRowProps<ExpandedBalanceSheetRow>,
) => TableRowInstance<ExpandedBalanceSheetRow> = TableRowBase;

export const BalanceSheetsTable = ({
    children,
    ...props
}: ColumnBasedTableProps<ExpandedBalanceSheetRow>) => {
    return (
        <NewColumnBasedTable<ExpandedBalanceSheetRow> {...props}>
            {children as any /* it works */}
            <TableRow className='level-1-header' label={Texts.assets__BalaceSheetsTableHeader} />
            <TableRow
                className='line-under'
                label={Texts.balancesheets__intangibleAssets}
                selector={(d) => d.intangibleAssets}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__researchAndDevelopment}
                    selector={(d) => d.researchAndDevelopment}
                />
                <TableRow
                    label={Texts.balancesheets__patentAndLicensesEtc}
                    selector={(d) => d.patentAndLicensesEtc}
                />
                <TableRow label={Texts.balancesheets__goodwill} selector={(d) => d.goodwill} />
            </TableRow>
            <TableRow
                className='line-under'
                label={Texts.balancesheets__totalTangibleAssets}
                selector={(d) => d.totalTangibleAssets}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__buildingsAndFreeholdLand}
                    selector={(d) => d.buildingsAndFreeholdLand}
                    doCompare={true}
                />
                <TableRow
                    label={Texts.balancesheets__equipmentsAndTools}
                    selector={(d) => d.equipmentsAndTools}
                    doCompare={true}
                >
                    <TableRow
                        label={Texts.balancesheets__plantAndMachinery}
                        selector={(d) => d.plantAndMachinery}
                    />
                    <TableRow
                        label={Texts.balancesheets__equipmentToolsTransportAndInstallation}
                        selector={(d) => d.equipmentToolsTransportAndInstallation}
                    />
                    <TableRow
                        label={Texts.balancesheets__otherTangibleFixedAssets}
                        selector={(d) => d.otherTangibleFixedAssets}
                    />
                </TableRow>
            </TableRow>
            <TableRow
                label={Texts.balancesheets__financialFixAssets}
                selector={(d) => d.financialFixAssets}
                doCompare={true}
            >
                <TableRow label={Texts.balancesheets__groupShare} selector={(d) => d.groupShare} />
                <TableRow
                    label={Texts.balancesheets__longTermRecievablesCorporateGroup}
                    selector={(d) => d.longTermRecievablesCorporateGroup}
                />
                <TableRow
                    label={Texts.balancesheets__loanCoOwners}
                    selector={(d) => d.loanCoOwners}
                />
                <TableRow
                    label={Texts.balancesheets__otherFinancialFixAssets}
                    selector={(d) => d.otherFinancialFixAssets}
                />
            </TableRow>
            <TableRow
                className='summary'
                label={Texts.balancesheets__totalFixAssets}
                selector={(d) => d.totalFixAssets}
                doCompare={true}
            />
            <TableRow className='empty-row' />

            <TableRow
                className='line-under'
                label={Texts.balancesheets__warehouseAndWorkInProgress}
                selector={(d) => d.warehouseAndWorkInProgress}
                doCompare={true}
            />
            <TableRow
                className='line-under'
                label={Texts.balancesheets__totalAccountsReceivable}
                selector={(d) => d.totalAccountsReceivable}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__accountsReceivableTrade}
                    selector={(d) => d.accountsReceivableTrade}
                />
                <TableRow
                    label={Texts.balancesheets__accountsReceivableGroupCompanies}
                    selector={(d) => d.accountsReceivableGroupCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__accountsReceivableFromAssociatedCompanies}
                    selector={(d) => d.accountsReceivableFromAssociatedCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__otherAccountsReceivable}
                    selector={(d) => d.otherAccountsReceivable}
                />
            </TableRow>
            <TableRow
                className='line-under'
                label={Texts.balancesheets__otherCurrentReceivablesAggregated}
                selector={(d) => d.otherCurrentReceivablesAggregated}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__currentReceivablesFromGroupCompanies}
                    selector={(d) => d.currentReceivablesFromGroupCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__currentReceivablesFromAssociatedCompanies}
                    selector={(d) => d.currentReceivablesFromAssociatedCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__otherCurrentReceivables}
                    selector={(d) => d.otherCurrentReceivables}
                />
            </TableRow>
            <TableRow
                label={Texts.balancesheets__cashAndCashEquivalent}
                selector={(d) => d.cashAndCashEquivalent}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__shortTermInvestments}
                    selector={(d) => d.shortTermInvestments}
                />
                <TableRow
                    label={Texts.balancesheets__cashAndBank}
                    selector={(d) => d.cashAndBank}
                />
            </TableRow>
            <TableRow
                className='summary'
                label={Texts.balancesheets__totalLiquidAssets}
                selector={(d) => d.totalLiquidAssets}
                doCompare={true}
            />
            <TableRow
                className='level-1-footer'
                label={Texts.balancesheets__totalAssets}
                selector={(d) => d.totalAssets}
                doCompare={true}
            />
            <TableRow className='empty-row' />

            <TableRow
                className='level-1-header'
                label={Texts.equity_and_debt__BalanceSheetsTableHeader}
            />
            <TableRow
                className='line-under'
                label={Texts.balancesheets__equity}
                selector={(d) => d.equity}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__restrictedEquity}
                    selector={(d) => d.restrictedEquity}
                />
                <TableRow
                    label={Texts.balancesheets__nonRestrictedEquity}
                    selector={(d) => d.nonRestrictedEquity}
                />
                <TableRow
                    label={Texts.balancesheets__otherEquitySoleTrader}
                    selector={(d) => d.otherEquitySoleTrader}
                />
            </TableRow>
            <TableRow
                className='line-under'
                label={Texts.balancesheets__untaxedReserves}
                selector={(d) => d.untaxedReserves}
                doCompare={true}
            />
            <TableRow
                className='line-under'
                label={Texts.balancesheets__provision}
                selector={(d) => d.provision}
                doCompare={true}
            />
            <TableRow
                className='line-under'
                label={Texts.balancesheets__totalLongTermLiabilities}
                selector={(d) => d.totalLongTermLiabilities}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__bankAdvancesLongTerm}
                    selector={(d) => d.bankAdvancesLongTerm}
                />
                <TableRow
                    label={Texts.balancesheets__mandatoryLongTermDebt}
                    selector={(d) => d.mandatoryLongTermDebt}
                />
                <TableRow
                    label={Texts.balancesheets__otherLongTermDebtToCreditors}
                    selector={(d) => d.otherLongTermDebtToCreditors}
                />
                <TableRow
                    label={Texts.balancesheets__longTermLiabilitiesToGroupCompanies}
                    selector={(d) => d.longTermLiabilitiesToGroupCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__longTermLiabilitiesToAssociatedCompanies}
                    selector={(d) => d.longTermLiabilitiesToAssociatedCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__otherLongTermLiabilities}
                    selector={(d) => d.otherLongTermLiabilities}
                />
            </TableRow>
            <TableRow
                label={Texts.balancesheets__totalCurrentLiabilities}
                selector={(d) => d.totalCurrentLiabilities}
                doCompare={true}
            >
                <TableRow
                    label={Texts.balancesheets__bankAdvancesShortTerm}
                    selector={(d) => d.bankAdvancesShortTerm}
                />
                <TableRow
                    label={Texts.balancesheets__otherCurrentLiabilitiesToCreditInstitutions}
                    selector={(d) => d.otherCurrentLiabilitiesToCreditInstitutions}
                />
                <TableRow
                    label={Texts.balancesheets__accountsPayableTrade}
                    selector={(d) => d.accountsPayableTrade}
                />
                <TableRow
                    label={
                        Texts.balancesheets__accountsPayableAndCurrentLiabilitiesToGroupCompanies
                    }
                    selector={(d) => d.accountsPayableAndCurrentLiabilitiesToGroupCompanies}
                />
                <TableRow
                    label={
                        Texts.balancesheets__accountsPayableAndCurrentLiabilitiesToAssociatedCompanies
                    }
                    selector={(d) => d.accountsPayableAndCurrentLiabilitiesToAssociatedCompanies}
                />
                <TableRow
                    label={Texts.balancesheets__otherCurrentLiabilities}
                    selector={(d) => d.otherCurrentLiabilities}
                />
            </TableRow>
            <TableRow
                className='level-1-footer'
                label={Texts.balancesheets__sumOfLiabilitiesAndEquity}
                selector={(d) => d.sumOfLiabilitiesAndEquity}
                doCompare={true}
            />
            <TableRow className='empty-row' />

            <TableRow
                className='summary'
                label={Texts.calculated_result__BalanceSheetsTableLabel}
                selector={(d) => d.totalAssets - d.sumOfLiabilitiesAndEquity}
                doCompare={true}
            />
        </NewColumnBasedTable>
    );
};
