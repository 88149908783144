export type PdfStyleNames =
    | 'pdf-top-title'
    | 'pdf-top-company-name'
    | 'is-line'
    | 'line-over'
    | 'my-numbers-data'
    | 'tiny-row'
    | 'new-page'
    | 'bold'
    | 'summary'
    | 'level-1-header'
    | 'number'
    | 'empty-row'
    | 'none';

// Type definitions for StyleDef and ColumnDef are taken from
// https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/README.md
// but have been simplified for our usecase
type StyleDef = {
    font: 'helvetica' | 'times' | 'courier';
    fontStyle: 'normal' | 'bold' | 'italic' | 'bolditalic';
    overflow: 'linebreak' | 'ellipsize' | 'visible' | 'hidden';
    fillColor: string;
    textColor: string;
    cellWidth: 'auto' | 'wrap' | number;
    minCellWidth: number;
    minCellHeight: number;
    halign: 'left' | 'center' | 'right';
    valign: 'top' | 'middle' | 'bottom';
    fontSize: number;
    cellPadding: number;
    lineColor: string;
    lineWidth: number;
};
type ColumnDef = {
    content: string;
    styles: Partial<StyleDef>;
};

const pdfStylePortrait: Record<PdfStyleNames, Partial<StyleDef>> = {
    none: {
        cellPadding: 2.4,
        lineWidth: 0,
        minCellHeight: '0.2' as unknown as number,
    },
    'pdf-top-title': {
        fontStyle: 'bold',
        fontSize: 15,
        overflow: 'visible',
    },
    'pdf-top-company-name': {
        fontStyle: 'bold',
        fontSize: 13,
        overflow: 'visible',
    },
    'is-line': {
        fillColor: '#cdcdcd',
        fontSize: 1,
        cellPadding: 0,
    },
    'line-over': {
        fillColor: '#000000',
        fontSize: 1,
        cellPadding: 0,
    },
    'my-numbers-data': {
        fillColor: '#f0fafa',
    },
    'tiny-row': {
        fontSize: 8,
        overflow: 'ellipsize',
    },
    'new-page': {
        minCellHeight: '15' as unknown as number,
        fillColor: '#ffffff',
    },
    bold: {
        fontStyle: 'bold',
    },
    summary: {
        fontStyle: 'bold',
    },
    'level-1-header': {
        fontStyle: 'bold',
        fontSize: 13,
    },
    number: {
        halign: 'right',
    },
    'empty-row': {
        minCellHeight: '7.0' as unknown as number,
    },
};

const pdfStyleLandscape: Record<PdfStyleNames, Partial<StyleDef>> = {
    ...pdfStylePortrait,
    none: {
        ...pdfStylePortrait['none'],
        cellPadding: 1.4,
        fontSize: 9,
    },
    'pdf-top-title': {
        ...pdfStylePortrait['pdf-top-title'],
        fontSize: 13,
    },
    'pdf-top-company-name': {
        ...pdfStylePortrait['pdf-top-company-name'],
        fontSize: 11,
    },
    'tiny-row': {
        fontSize: 7,
        overflow: 'ellipsize',
    },
    'new-page': {
        ...pdfStylePortrait['new-page'],
        minCellHeight: '14' as unknown as number,
    },
    'level-1-header': {
        ...pdfStylePortrait['level-1-header'],
        fontSize: 12,
    },
    'empty-row': {
        minCellHeight: '6.0' as unknown as number,
    },
};

function styleFromString(s: string | undefined): Partial<StyleDef> {
    const idx = (s as PdfStyleNames) ?? 'none';
    return pdfStylePortrait[idx] ?? {};
}

export type ColumnData = {
    columnClassName?: string;
};
export function makeRow<T extends ColumnData>(
    styleNames: PdfStyleNames[],
    label: string,
    selector: (d: T) => string,
    data: T[],
    orientation: 'p' | 'landscape' | 'portrait' | 'l' = 'portrait',
): ColumnDef[] {
    const pdfStyle = orientation === 'landscape' ? pdfStyleLandscape : pdfStylePortrait;
    const styles = styleNames.reduce<Partial<StyleDef>>(
        (style, styleName) => ({
            ...style,
            ...pdfStyle[styleName],
        }),
        pdfStyle['none'],
    );

    return [
        { content: label, styles },
        ...data.map((d) => ({
            content: selector(d),
            styles: {
                halign: 'right' as const,
                ...styleFromString(d.columnClassName),
                ...styles,
            },
        })),
    ];
}
