import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthenticationContext, { AuthState } from './AuthenticationContext';
import TokenStateService from '../../Functions/Storage/TokenStateService';
import { userStateSelector } from '../State/UserState';
import { useSetRecoilState } from 'recoil';
import { mapCorporatePortalUserToUser } from '../../Functions/Authentication';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import Texts from '../../Functions/Texts.json';
import useInterval from '../../Functions/useInterval';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import useUserInfoRequest from '../../Api/UserInfo/useUserInfoRequest';
import LicenseChoiceService from '../../Functions/Storage/LicenseChoiceService';
import { QueryCache } from 'react-query';
import useSetLicenseChoice from '../State/useSetLicenseChoice';
import useClearStorage from '../../Functions/Storage/useClearStorage';

type Props = {
    children: ReactNode;
};

const AuthenticationProvider = ({ children }: Props) => {
    const [authState, setAuthState] = useState<AuthState>(AuthState.unset);
    const [signedOutMessage, setSignedOutMessage] = useState<string>('');
    const setUserState = useSetRecoilState(userStateSelector);
    const { setLicenseChoice } = useSetLicenseChoice();
    const { getUserInfo, userData } = useUserInfoRequest();
    const { clearStorage } = useClearStorage();

    const navigate = useNavigate();

    const tokenState = TokenStateService.GetParsedStorageData();

    const inactiveLogout = () => {
        if (authState !== AuthState.authorized) {
            return;
        }
        if (tokenState.loginValidUntil && new Date() > new Date(tokenState.loginValidUntil)) {
            logout(Texts.logged_out_because_session_expired__LoggedOutMessage);
            return Promise.resolve(false);
        }
    };

    useInterval(inactiveLogout, 60000);

    const logout = useCallback(
        (message: string, stopNavigation?: boolean) => {
            setAuthState(AuthState.unauthorized);
            setSignedOutMessage(message);
            clearStorage();

            const queryCache = new QueryCache();
            queryCache.clear(); // <- This might need to be verified that it works properly

            if (!stopNavigation) {
                navigate('/');
            }
        },
        [clearStorage, navigate],
    );

    const login = useCallback(
        (
            usrData: CorporatePortalUser,
            license: LicenseChoiceModel | null,
            noNavigate: boolean = false,
        ) => {
            const mappedUser = mapCorporatePortalUserToUser(usrData);
            if (license) {
                setLicenseChoice(license);
            }
            setUserState(mappedUser);
            setSignedOutMessage('');

            setAuthState(license ? AuthState.authorized : AuthState.forceLicenseChoice);
            if (!noNavigate) {
                navigate('/');
            }
        },
        [navigate, setLicenseChoice, setUserState],
    );

    const setLicense = (choice: LicenseChoiceModel, callback?: () => void) => {
        LicenseChoiceService.SaveStorageData(choice);
        setLicenseChoice(choice);
        setAuthState(AuthState.authorized);
        callback?.();
    };

    useEffect(() => {
        if (authState === AuthState.unset) {
            if (
                tokenState.token === null ||
                tokenState.loginValidUntil === null ||
                new Date() > new Date(tokenState.loginValidUntil)
            ) {
                // TODO: Check this, perhaps we should just try to get the user info (to
                // test if the refresh token works)
                setAuthState(AuthState.unauthorized);
            } else {
                // Initial load - fetch user info to initialize state
                getUserInfo(null);
                setAuthState(AuthState.initializing);
            }
        }
    }, [authState, getUserInfo, tokenState]);

    useEffect(() => {
        if (authState === AuthState.initializing && userData) {
            const licenseChoice = LicenseChoiceService.GetParsedStorageData();
            if (userData.type === 'success' && licenseChoice) {
                login(userData.userInfo, licenseChoice, true);
            } else {
                logout('');
            }
        }
    }, [authState, login, logout, setLicenseChoice, userData]);

    const memoedValue = useMemo(
        () => ({
            authState,
            logout,
            login,
            setLicense,
            signedOutMessage,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [authState],
    );

    return (
        <AuthenticationContext.Provider value={memoedValue}>
            {authState === AuthState.unset || authState === AuthState.initializing ? (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            ) : (
                children
            )}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
