import { useQuery } from 'react-query';
import UseQueryOptions from '../UseQueryOptions';
import axiosInstance from '../../Core/Axios';
import FinancialYear from '../../Types/Api/Response/FinancialYear';

const useGetAllFinancialYears = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<FinancialYear[]>,
) =>
    useQuery(
        ['allFinancialYears', companyId],
        async () => axiosInstance.get<FinancialYear[]>(`companies/${companyId}/allFinancialYears`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetAllFinancialYears;
