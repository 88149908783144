import { FieldValues, ValidateResult, useForm } from 'react-hook-form';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import { ActivateTrialRequest } from '../../Functions/Authentication';
import axiosInstance from '../../Core/Axios';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import { ActivationData } from './RegisterView';
import Texts from '../../Functions/Texts.json';
import useSnackbar from '../../Components/Snackbar/useSnackbar';
import useGetSystemConfiguration from '../../Api/SystemConfiguration/useGetSystemConfiguration';

type Props = {
    userInfo: CorporatePortalUser | null;
    userName: string;
    setLicenseChoice: React.Dispatch<React.SetStateAction<ActivationData | null>>;
    cancelActivation: () => void;
};

const MissingLicense = ({ userInfo, userName, setLicenseChoice, cancelActivation }: Props) => {
    const { enqueueErrorSnackbar } = useSnackbar();
    const { data: systemConfiguration } = useGetSystemConfiguration();

    const monthlyPlus = systemConfiguration?.data
        ? systemConfiguration?.data.find((sc) => sc.key === 'monthlyPlus')?.value ?? ''
        : '';

    if (userInfo === null) {
        throw new Error('userinfo is null');
    }

    const {
        register,
        handleSubmit,
        getValues,
        formState: { isDirty, isValid },
    } = useForm({ mode: 'onChange' });

    const validateTrialInput = (): ValidateResult => {
        const sme = getValues('sme');
        const firms = getValues('firms');
        return sme || firms?.length > 0;
    };

    const onTrialSubmit = async (formData: FieldValues) => {
        if (userInfo === null) {
            throw new Error('userinfo is null');
        }
        // react-hook-form will generate firms as false or the id if there is only one firms checkbox, an
        // array otherwise
        let accountingFirms: string[] = [];
        if (Object.prototype.toString.call(formData.firms) === '[object String]') {
            accountingFirms = [formData.firms];
        } else if (formData.firms && formData.firms.length > 0) {
            accountingFirms = formData.firms;
        }

        const data: ActivateTrialRequest = {
            sme: formData.sme,
            accountingFirms: accountingFirms,
        };

        try {
            var response = await axiosInstance.post<CorporatePortalUser>(
                'corporatePortal/activateTrial',
                data,
            );
            const license: LicenseChoiceModel =
                accountingFirms.length > 0
                    ? {
                          type: 'firm',
                          id: accountingFirms[0],
                          title:
                              userInfo.accountingFirmsNoLicense.find(
                                  (f) => f.id === accountingFirms[0],
                              )?.name ?? '',
                      }
                    : { type: 'sme', id: 'sme', title: '' };
            setLicenseChoice({
                isTrial: true,
                usrData: response.data,
                license,
            });
        } catch (e) {
            enqueueErrorSnackbar(Texts.responses.general);
            return;
        }
    };

    return (
        <div className='message' id='missing-license'>
            <div
                dangerouslySetInnerHTML={{
                    __html: Texts.signin.missing_license
                        .replace('{name}', userName)
                        .replace('{monthlyPlus}', monthlyPlus),
                }}
            ></div>
            <form className='form' onSubmit={handleSubmit(onTrialSubmit)}>
                {(!userInfo.accountingFirmsNoLicense ||
                    userInfo.accountingFirmsNoLicense.length === 0) && (
                    <div className='box-wrapper'>
                        <label className='checkbox-container' htmlFor='sme_checkbox'>
                            <input
                                id='sme_checkbox'
                                type='checkbox'
                                {...register('sme', {
                                    validate: validateTrialInput,
                                    onChange: validateTrialInput,
                                })}
                            ></input>
                            <span className='checkmark'></span>
                            <div className='checkbox-label'>{Texts.signin.sme}</div>
                        </label>
                    </div>
                )}
                {userInfo.accountingFirmsNoLicense?.map((firm) => (
                    <div className='box-wrapper' key={firm.id}>
                        <label className='checkbox-container' htmlFor={firm.id}>
                            <input
                                id={firm.id}
                                type='checkbox'
                                value={firm.id}
                                {...register('firms', {
                                    validate: validateTrialInput,
                                    onChange: validateTrialInput,
                                })}
                            ></input>
                            <span className='checkmark'></span>
                            <div className='checkbox-label'>{firm.name}</div>
                        </label>
                    </div>
                ))}
                <button
                    className='button'
                    type='submit'
                    value='Submit'
                    disabled={!isDirty || !isValid}
                >
                    {Texts.signin.activate}
                </button>
                <button className='button button-secondary' onClick={cancelActivation}>
                    {Texts.signin.cancel_activation}
                </button>
                <p>{Texts.signin.exklusive_moms}</p>
            </form>
        </div>
    );
};

export default MissingLicense;
