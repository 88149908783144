import React, { memo, useState } from 'react';
import { SavedBudget } from '../../Functions/Budget';
import Texts from '../../Functions/Texts.json';
import SingleSelect, { SelectOption } from '../InputFields/SingleSelect';

type BudgetSelectorProps = {
    budgets: SavedBudget[];
    selectedBudgetId: string | null | undefined;
    setSelectedBudgetId: React.Dispatch<React.SetStateAction<string | null | undefined>>;
};

const budgetTitle = (budget: SavedBudget) =>
    budget.yearlyProfitAndLoss.financialYear.substring(0, 4) ===
    budget.yearlyProfitAndLoss.financialYear.substring(13, 17)
        ? `${budget.name} ${budget.yearlyProfitAndLoss.financialYear.substring(0, 4)}`
        : `${budget.name} ${budget.yearlyProfitAndLoss.financialYear.substring(
              0,
              4,
          )}-${budget.yearlyProfitAndLoss.financialYear.substring(13, 17)}`;

const BudgetSelector = memo(
    ({ budgets, selectedBudgetId, setSelectedBudgetId }: BudgetSelectorProps) => {
        const [budgetEnabled, setBudgetEnabled] = useState<boolean>(false);
        const options: Array<SelectOption<SavedBudget>> = budgets.map((budget) => ({
            key: budget.id,
            value: budget,
            title: budgetTitle(budget),
        }));

        const value = selectedBudgetId
            ? options.find((budget) => budget.value.id === selectedBudgetId)!
            : null;

        const getDefaultBudget = () : string | null | undefined => {
            if (!options || options.length === 0) {
                return null;
            }
            const budgetsOfCurrentYear = options.filter(budget => new Date(budget.value.yearlyProfitAndLoss.financialYearFrom) <= new Date() &&
            new Date(budget.value.yearlyProfitAndLoss.financialYearTo) >= new Date());
            if (budgetsOfCurrentYear.length) {
                 return budgetsOfCurrentYear.sort((a,b) => b.value.modified.localeCompare(a.value.modified))[0].key;
            }
            return [...options].sort((a,b) => b.value.modified.localeCompare(a.value.modified))[0].key;
        };

        const toggleBudgetEnabled = () => {
            setSelectedBudgetId(
                budgetEnabled === true ? null : getDefaultBudget()
            );
            setBudgetEnabled(!budgetEnabled);
        };

        return (
            <div id='budgetsBlock' className={`budgets-block ${budgetEnabled ? 'enabled' : ''}`}>
                <h4>{Texts.report_generator.budget}</h4>
                <div className={options.length !== 1 ? 'multi' : ''}>
                    <label className='checkbox-container'>
                        <input
                            id='comparisonCheckbox'
                            type='checkbox'
                            checked={budgetEnabled}
                            onChange={toggleBudgetEnabled}
                            disabled={options.length === 0}
                        />
                        <span className='checkmark'></span>
                        {budgets.length === 1 && (
                            <div>{`${
                                budgets[0].name
                            } ${budgets[0].yearlyProfitAndLoss.financialYear.substring(
                                0,
                                4,
                            )}`}</div>
                        )}
                        {budgets.length > 1 && (<div>{Texts.report_generator.choose_budget}</div>)}
                    </label>
                    {options.length === 0 && <span>{Texts.report_generator.missing_budgets}</span>}
                    {budgets.length > 1 && (
                        <div className='budget-select'>
                            <SingleSelect
                                options={options}
                                value={value}
                                disabled={!budgetEnabled}
                                onChange={(value) =>
                                    setSelectedBudgetId(value ? value.value.id : null)
                                }
                                size='large'
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    },
);

export default BudgetSelector;
