import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';

const storageName = 'licenseChoice';

const LicenseChoiceService = {
    GetParsedStorageData: (): LicenseChoiceModel | null => {
        const data = localStorage.getItem(storageName);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    SaveStorageData: (data: LicenseChoiceModel) => {
        localStorage.setItem(storageName, JSON.stringify(data));
    },
    ClearAll: () => {
        localStorage.removeItem(storageName);
    },
};

export default LicenseChoiceService;
