import { useQuery } from 'react-query';

import ProfitAndLossRow from '../../Types/Api/Response/ProfitAndLoss';
import axiosInstance from '../../Core/Axios';
import UseQueryOptions from '../UseQueryOptions';

const useGetPnLAggregatedQuery = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<Array<ProfitAndLossRow>>,
) =>
    useQuery(
        ['GetPnLAggregated', companyId],
        async () =>
            axiosInstance.get<Array<ProfitAndLossRow>>(`Reports/profitAndLoss/${companyId}`),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetPnLAggregatedQuery;
