import React from 'react';
import Texts from '../Functions/Texts.json';
import LicenseChoiceModel, { LicenseType } from '../Types/Context/LicenseChoiceModel';
import {
    userAccountingFirmsState,
    userCreditorsState,
    userHasLicenseState,
} from '../Core/State/UserState';
import { useRecoilValue } from 'recoil';
import useAuthentication from '../Core/Authentication/useAuthentication';

export type LicenseChoiceProps = {
    choiceChangeCallback?: () => void;
};

const LicenseChoice = ({ choiceChangeCallback }: LicenseChoiceProps) => {
    const hasLicense = useRecoilValue(userHasLicenseState);
    const accountingFirms = useRecoilValue(userAccountingFirmsState);
    const creditors = useRecoilValue(userCreditorsState);

    const { setLicense } = useAuthentication();

    const setChoice = (choice: LicenseChoiceModel) => {
        setLicense(choice, choiceChangeCallback);
    };

    const description: Record<LicenseType, string> = {
        sme: Texts.licenseChoiceSMEDesciption,
        firm: Texts.licenseChoiceFirmDescription,
        creditor: Texts.licenseChoiceCreditorDescription,
    };
    let choices: LicenseChoiceModel[] = [];

    if (hasLicense) {
        choices.push({
            type: 'sme',
            id: 'sme',
            title: Texts.licenseChoiceSMEHeader,
        });
    }
    accountingFirms.forEach((firm) => {
        choices.push({
            type: 'firm',
            id: firm.id,
            title: firm.name,
        });
    });
    creditors.forEach((creditor) => {
        choices.push({
            type: 'creditor',
            title: creditor.creditorName,
            id: creditor.clientIdAuth0,
        });
    });

    return (
        <div className='license-choice-wrapper'>
            {choices.map((choice) => (
                <div className='license-choice' key={choice.id} onClick={() => setChoice(choice)}>
                    <h3>{choice.title}</h3>
                    <p>{description[choice.type]}</p>
                </div>
            ))}
        </div>
    );
};

export default LicenseChoice;
