import React from 'react';
import Texts from '../Functions/Texts.json';
import {
    ColumnBasedTableProps,
    ColumnData,
    NewColumnBasedTable,
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from './NewColumnBasedTable';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';

export type ExpandedProfitAndLossRow = ProfitAndLossRow &
    ColumnData & {
        isBudget?: boolean;
        isOfficial?: boolean;
    };

// This is done to fix the generic parameter, so that it doesn't need to be entered in every row.
const TableRow: (
    props: TableRowProps<ExpandedProfitAndLossRow>,
) => TableRowInstance<ExpandedProfitAndLossRow> = TableRowBase;

export type ProfitAndLossTableProps = ColumnBasedTableProps<ExpandedProfitAndLossRow> & {
    netProfitLabel: string;
};
export const ProfitAndLossTable = ({
    children,
    netProfitLabel,
    ...props
}: ProfitAndLossTableProps) => {
    return (
        <NewColumnBasedTable<ExpandedProfitAndLossRow> {...props}>
            {children as any /* it works */}
            <TableRow
                className='line-under'
                label={Texts.profitandloss__revenue}
                selector={(d) => d.revenue}
            />
            <TableRow
                className=''
                label={Texts.profitandloss__otherOperatingIncome}
                selector={(d) => d.otherOperatingIncome}
            />
            <TableRow
                className='summary bold'
                label={Texts.total_income__ProfitAndLossTableLabel}
                selector={(d) => d.totalOperatingIncome}
                doCompare
            />
            <TableRow className='empty-row' />

            <TableRow
                className='line-under'
                label={Texts.profitandloss__directCost}
                selector={(d) => d.directCost}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__otherOperatingExpensesAggregated}
                selector={(d) => d.otherOperatingExpensesAggregated}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__personnelCosts}
                selector={(d) => d.personnelCosts}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__depreciationAndWritedowns}
                selector={(d) => d.depreciationAndWritedowns}
            />
            <TableRow
                className='summary bold'
                label={Texts.profitandloss__sumOfExpenses}
                selector={(d) => d.sumOfExpenses}
                doCompare
            />
            <TableRow className='empty-row' />

            <TableRow
                className='bold'
                label={Texts.profitandloss__ebit}
                selector={(d) => d.ebit}
                doCompare
            />
            <TableRow className='empty-row' />

            <TableRow
                className='line-under'
                label={Texts.profitandloss__financialIncomes}
                selector={(d) => d.financialIncomes}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__financialExpenses}
                selector={(d) => d.financialExpenses}
            />
            <TableRow
                className='bold'
                label={Texts.profitandloss__sumOfFinancialPosts}
                selector={(d) => d.sumOfFinancialPosts}
                doCompare
            />
            <TableRow
                className='summary bold'
                label={Texts.profitandloss__currentProfitEarningBeforeTax}
                selector={(d) => d.currentProfitEarningBeforeTax}
                doCompare
            />
            <TableRow className='empty-row' />

            <TableRow
                className='line-under'
                label={Texts.profitandloss__extraordinaryIncomeAndExpenses}
                selector={(d) => d.extraordinaryIncomeAndExpenses}
            />
            <TableRow
                className='line-under'
                label={Texts.profitandloss__appropriations}
                selector={(d) => d.appropriations}
            />
            <TableRow
                className='line-under bold'
                label={Texts.profitandloss__tax}
                selector={(d) => d.tax}
                doCompare
            />
            <TableRow
                className='summary bold'
                label={netProfitLabel}
                selector={(d) => d.netProfit}
                doCompare
            />
        </NewColumnBasedTable>
    );
};
