import React, { ReactNode } from 'react';
import Texts from '../Functions/Texts.json';

export type DialogProps = {
    open: boolean;
    header?: string;
    children: ReactNode;
    ok: () => void;
    okText?: string;
    cancel?: () => void;
    cancelText?: string;
};
export const Dialog = ({
    open,
    header,
    children,
    ok,
    okText = Texts.ok,
    cancel,
    cancelText = Texts.cancel,
}: DialogProps) => {
    return open ? (
        <div className='dialog-overlay'>
            <div
                className='dialog-overlay-closer'
                onClick={() => (cancel !== null ? cancel!() : ok())}
            ></div>
            <div className='dialog-wrapper'>
                {header && <h2>{header}</h2>}
                <div>{children}</div>
                <div className='dialog-buttons'>
                    <div onClick={() => ok()}>
                        <div className='mask'></div>
                        {okText}
                    </div>
                    {cancel && (
                        <div onClick={() => cancel()}>
                            <div className='mask'></div>
                            {cancelText}
                        </div>
                    )}
                </div>
            </div>
        </div>
    ) : null;
};
