import { useMutation } from 'react-query';
import UseMutationOptions from '../UseMutationOptions';
import { SavedBudget } from '../../Functions/Budget';
import axiosInstance from '../../Core/Axios';

type DeleteBudgetRequest = {
    budget: SavedBudget;
    organizationId: string;
};

const useDeleteBudgetMutation = (options?: UseMutationOptions<DeleteBudgetRequest>) =>
    useMutation(
        ({ budget, organizationId }: DeleteBudgetRequest) =>
            axiosInstance.delete<SavedBudget>(`budget/myBudgets/${organizationId}/${budget.id}`),
        options,
    );

export default useDeleteBudgetMutation;
