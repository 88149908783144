import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import MonthSelection from './Types/MonthSelection';
import Year from './Types/Year';
import Month from './Types/Month';
import { sv } from 'date-fns/locale';
import { add, format, lastDayOfMonth } from 'date-fns';
import SingleSelect, { SelectOption } from '../InputFields/SingleSelect';

type MonthSelectorProps = {
    monthSelection: MonthSelection;
    setMonthSelection: (selection: MonthSelection) => void;
    availableYears: Year[];
};

const FindBestComparisonPeriod = (primary: string | undefined, availableYears: Year[]): string => {
    let candiDate = '';
    if (primary === undefined) {
        const lastOfCurrentMonth = format(lastDayOfMonth(new Date()), 'yyyy-MM-dd');
        candiDate = format(
            add(new Date(lastOfCurrentMonth), {
                years: -1,
            }),
            'yyyy-MM-dd',
        );
    } else {
        candiDate = format(add(new Date(primary), { years: -1 }), 'yyyy-MM-dd');
    }

    if (availableYears.find((y) => y.label === candiDate.substring(0, 4))) {
        const months = availableYears.find((y) => y.label === candiDate.substring(0, 4))!.months;
        return months.find((m) => m.to === candiDate)?.to ?? months[0]!.to;
    } else {
        return availableYears[0].months[0].to;
    }
};

const MonthSelector = ({
    monthSelection,
    setMonthSelection,
    availableYears,
}: MonthSelectorProps) => {
    const { primary, secondary } = monthSelection;
    const [secondaryManuallySet, setSecondaryManuallySet] = useState<boolean>(false);

    const selectedPrimaryYear =
        primary && availableYears.find((y) => y.label === primary?.substring(0, 4));
    const primaryMonths = selectedPrimaryYear ? selectedPrimaryYear.months : [];

    const selectedSecondaryYear =
        secondary && availableYears.find((y) => y.label === secondary?.substring(0, 4));
    const secondaryMonths = selectedSecondaryYear ? selectedSecondaryYear.months : [];

    const setPrimaryYear = (yearLabel: string | undefined) => {
        if (yearLabel === undefined) {
            setMonthSelection({
                ...monthSelection,
                primary: undefined,
            });
            return;
        }
        const availableMonths = availableYears.find((y) => y.label === yearLabel)!.months;
        let newPrimary = availableMonths[0].to;
        if (primary) {
            const primaryCandidate = format(
                new Date(primary).setFullYear(parseInt(yearLabel, 10)),
                'yyyy-MM-dd',
            );
            if (availableMonths.find((m) => m.to === primaryCandidate)) {
                newPrimary = primaryCandidate;
            }
        }
        setMonthSelection({
            primary: newPrimary,
            secondary: secondaryManuallySet
                ? monthSelection.secondary
                : FindBestComparisonPeriod(newPrimary, availableYears),
        });
    };

    const setPrimaryMonth = (month: Month | undefined) => {
        setMonthSelection({
            primary: month ? month.to : undefined,
            secondary:
                secondaryManuallySet || month === undefined
                    ? monthSelection.secondary
                    : FindBestComparisonPeriod(month.to, availableYears),
        });
    };

    const setSecondaryYear = (yearLabel: string | undefined) => {
        setSecondaryManuallySet(true);
        if (yearLabel === undefined) {
            setMonthSelection({
                ...monthSelection,
                secondary: undefined,
            });
            return;
        }
        const availableMonths = availableYears.find((y) => y.label === yearLabel)!.months;
        let newSecondary = availableMonths[0].to;
        if (primary) {
            const secondaryCandidate = format(
                new Date(primary).setFullYear(parseInt(yearLabel, 10)),
                'yyyy-MM-dd',
            );
            if (availableMonths.find((m) => m.to === secondaryCandidate)) {
                newSecondary = secondaryCandidate;
            }
        }
        setMonthSelection({
            ...monthSelection,
            secondary: newSecondary,
        });
    };

    const setSecondaryMonth = (month: Month | undefined) => {
        setSecondaryManuallySet(true);
        setMonthSelection({
            ...monthSelection,
            secondary: month ? month.to : undefined,
        });
    };

    const toggleComparison = () => {
        setMonthSelection({
            ...monthSelection,
            secondary: monthSelection.secondary
                ? undefined
                : FindBestComparisonPeriod(monthSelection.primary, availableYears),
        });
    };

    const yearOptions: Array<SelectOption<Year>> = availableYears.map((year) => ({
        key: year.label,
        value: year,
        title: year.label,
    }));

    const primaryMonthOptions: Array<SelectOption<Month>> = primaryMonths.map((month) => ({
        key: month.from,
        value: month,
        title: format(new Date(month.from), 'MMMM', { locale: sv }),
    }));

    const secondaryMonthOptions: Array<SelectOption<Month>> = secondaryMonths.map((month) => ({
        key: month.from,
        value: month,
        title: format(new Date(month.from), 'MMMM', { locale: sv }),
    }));

    const primaryYearValue =
        primary !== undefined
            ? yearOptions.find((y) => y.value.label === primary!.substring(0, 4))!
            : null;

    const primaryMonthValue =
        primary !== undefined ? primaryMonthOptions.find((m) => m.value.to === primary)! : null;

    const secondaryYearValue =
        secondary !== undefined
            ? yearOptions.find((y) => y.value.label === secondary!.substring(0, 4))!
            : null;

    const secondaryMonthValue =
        secondary !== undefined
            ? secondaryMonthOptions.find((m) => m.value.to === secondary)!
            : null;

    return (
        <div className='reports-month-selector'>
            <div>
                <h4>{Texts.report_generator.choose_month}</h4>
                <div className='reports-month-wrapper'>
                    <div className='reports-dropdown-wrapper'>
                        <SingleSelect
                            options={yearOptions}
                            value={primaryYearValue}
                            onChange={(year) => setPrimaryYear(year?.value.label)}
                            label={`${Texts.report_generator.choose_year}:`}
                        />
                    </div>
                    <div className='reports-dropdown-wrapper'>
                        <SingleSelect
                            options={primaryMonthOptions}
                            value={primaryMonthValue}
                            onChange={(month) => setPrimaryMonth(month?.value)}
                            label={`${Texts.report_generator.choose_month}:`}
                        />
                    </div>
                </div>
            </div>
            <div>
                <h4>{Texts.report_generator.comparison_month}</h4>
                <div className='reports-month-wrapper comparison'>
                    <label className='checkbox-container'>
                        <input
                            id='comparisonCheckbox'
                            type='checkbox'
                            checked={secondary !== undefined}
                            onChange={() => toggleComparison()}
                        />
                        <span className='checkmark'></span>
                    </label>

                    <div className='reports-dropdown-wrapper'>
                        <SingleSelect
                            options={yearOptions}
                            value={secondaryYearValue}
                            onChange={(year) => setSecondaryYear(year?.value.label)}
                            label={`${Texts.report_generator.choose_year}:`}
                            disabled={secondary === undefined}
                        />
                    </div>
                    <div className='reports-dropdown-wrapper'>
                        <SingleSelect
                            options={secondaryMonthOptions}
                            value={secondaryMonthValue}
                            onChange={(month) => setSecondaryMonth(month?.value)}
                            label={`${Texts.report_generator.choose_month}:`}
                            disabled={secondary === undefined}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MonthSelector;
