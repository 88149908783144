import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import { BalanceSheetChart } from '../../Components/BalanceSheetChart';
import { CashflowChart } from '../../Components/CashflowChart';
import { MonthlyCompareChart } from '../../Components/MonthlyCompareChart';
import { ProfitAndLossWaterfallChart } from '../../Components/ProfitAndLossWaterfallChart';
import { YearlyEBITChart } from '../../Components/YearlyEBITChart';
import BudgetCompareChart from '../../Components/Budget/BudgetCompareChart';
import useGetOrgId from '../../Functions/useGetOrgId';
import useGetActiveBudgetsQuery from '../../Api/Budget/useGetActiveBudgetsQuery';
import useGetPnLOfficialQuery from '../../Api/Reports/useGetPnLOfficialQuery';
import useGetPnLAggregatedQuery from '../../Api/Reports/useGetPnLAggregatedQuery';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import useGetCashflow from '../../Api/Reports/useGetCashflow';
import useGetOfficialBalanceSheets from '../../Api/Reports/useGetOfficialBalanceSheets';
import useGetBalanceSheetsAggregated from '../../Api/Reports/useGetBalanceSheetsAggregated';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const Charts = () => {
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const { data: activeBudgets } = useGetActiveBudgetsQuery(organizationId);
    const { data: officialProfitAndLoss } = useGetPnLOfficialQuery(organizationId);
    const { data: profitAndLoss } = useGetPnLQuery(organizationId);
    const { data: profitAndLossAggregated } = useGetPnLAggregatedQuery(organizationId);
    const { data: cashflow } = useGetCashflow(organizationId);
    const { data: officialBalanceSheets } = useGetOfficialBalanceSheets(organizationId);
    const { data: balanceSheetsAggregated } = useGetBalanceSheetsAggregated(organizationId);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['charts']} />}
            <div className='charts-wrapper company-charts'>
                <div className='info-boxes-charts'>
                    <MonthlyCompareChart
                        profitAndLoss={profitAndLoss?.data}
                        profitAndLossAggregated={profitAndLossAggregated?.data}
                        budgets={activeBudgets?.data ?? []}
                    />
                    <CashflowChart cashflow={cashflow?.data} />
                </div>

                <div className='info-boxes-charts'>
                    <YearlyEBITChart
                        officialProfitAndLoss={officialProfitAndLoss?.data}
                        profitAndLossAggregated={profitAndLossAggregated?.data}
                    />
                    <BudgetCompareChart
                        profitAndLoss={profitAndLoss?.data}
                        budgets={activeBudgets?.data ?? []}
                    />
                </div>

                <div className='info-boxes-charts'>
                    <ProfitAndLossWaterfallChart
                        officialProfitAndLoss={officialProfitAndLoss?.data}
                        profitAndLossAggregated={profitAndLossAggregated?.data}
                    />
                    <BalanceSheetChart
                        officialBalanceSheets={officialBalanceSheets?.data}
                        balanceSheetsAggregated={balanceSheetsAggregated?.data}
                    />
                </div>

                <div className='bottom-buttons-and-info'>
                    <span className='official-data-disclaimer'>
                        {Texts.official_data_from__DisclaimerText}
                    </span>
                    <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                        <div className='mask'></div>
                        {Texts.help__HelpButtonText}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default Charts;
