import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import './../css/Register.css';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import ExpiredLicense from './ExpiredLicense';
import MissingLicense from './MissingLicense';

export type RegisterViewProps = {
    userName: string;
    userInfo: CorporatePortalUser | null;
    activateUser: (usrData: CorporatePortalUser, license: LicenseChoiceModel) => void;
    cancelActivation: () => void;
};

export type ActivationData = {
    usrData: CorporatePortalUser | null;
    license: LicenseChoiceModel;
    isTrial: boolean;
};

const RegisterView = ({
    userName,
    userInfo,
    activateUser,
    cancelActivation,
}: RegisterViewProps) => {
    const [licenseChoice, setLicenseChoice] = useState<ActivationData | null>(null);

    const RenderUnregistered = (): JSX.Element => {
        return (
            <div className='message'>
                <div
                    dangerouslySetInnerHTML={{
                        __html: Texts.signin.unregistered.replace('{name}', userName),
                    }}
                ></div>
            </div>
        );
    };

    const RenderOverdueInvoice = (): JSX.Element => {
        return (
            <div className='message'>
                <div
                    dangerouslySetInnerHTML={{
                        __html: Texts.signin.overdue_invoice.replace('{name}', userName),
                    }}
                ></div>
            </div>
        );
    };

    const RenderConfirmation = (): JSX.Element => {
        if (userInfo === null) {
            throw new Error('userinfo is null');
        }
        if (licenseChoice === null || licenseChoice.usrData === null) {
            throw new Error('licenseChoice is null');
        }
        const text = licenseChoice!.isTrial
            ? Texts.signin.activation_confirmation_trial.replace('{name}', userName)
            : Texts.signin.activation_confirmation.replace('{name}', userName);
        return (
            <div id='activationConfirmation' className='message'>
                <div dangerouslySetInnerHTML={{ __html: text }}></div>
                <button
                    className='button'
                    onClick={() => activateUser(licenseChoice!.usrData!, licenseChoice!.license)}
                >
                    {Texts.signin.activation_confirmation_confirm}
                </button>
            </div>
        );
    };

    return (
        <div id='register'>
            {licenseChoice ? (
                <RenderConfirmation />
            ) : (
                <>
                    {userInfo === null && <RenderUnregistered />}
                    {userInfo?.hasOverdueInvoice && <RenderOverdueInvoice />}
                    {userInfo?.hasOverdueInvoice === false &&
                        (userInfo?.hasExpiredTrial ||
                            userInfo?.accountingFirmsExpiredTrial?.length > 0 ||
                            userInfo?.hasExpiredLicense ||
                            userInfo?.accountingFirmsExpiredLicense?.length > 0) && (
                            <ExpiredLicense
                                userInfo={userInfo}
                                userName={userName}
                                setLicenseChoice={setLicenseChoice}
                                cancelActivation={cancelActivation}
                            />
                        )}
                    {userInfo?.hasOverdueInvoice === false &&
                        !userInfo?.hasExpiredTrial &&
                        userInfo?.accountingFirmsExpiredTrial.length === 0 &&
                        !userInfo?.hasExpiredLicense &&
                        userInfo?.accountingFirmsExpiredLicense.length === 0 && (
                            <MissingLicense
                                userInfo={userInfo}
                                userName={userName}
                                setLicenseChoice={setLicenseChoice}
                                cancelActivation={cancelActivation}
                            />
                        )}
                </>
            )}
        </div>
    );
};

export default RegisterView;
