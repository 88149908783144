import { Params } from 'react-router-dom';
import useGetFinancialRatioQuery from '../../../Api/Reports/useGetFinancialRatioQuery';
import useGetOfficialFinancialRatioQuery from '../../../Api/Reports/useGetOfficialFinancialRatioQuery';
import { NextYear, GetYearData, DecimalToPercent } from '../../../Functions/Calculations';
import MapExternalFinancialRatio from '../../../Functions/MapExternalFinancialRatio';
import useGetOrgId from '../../../Functions/useGetOrgId';
import FinancialRatioRow from '../../../Types/Api/Response/FinancialRatioRow';
import { ColumnData } from '../../../Components/NewColumnBasedTable';

export type ExpandedFinancialRatioRow = FinancialRatioRow &
    ColumnData & {
        isOfficial?: boolean;
    };

const useGetOverviewFinancialRatio = (params: Readonly<Params<string>>) => {
    const { organizationId } = useGetOrgId(params);
    const { data: officialFinancialRatio, isLoading: officialFinancialRatioIsLoading } =
        useGetOfficialFinancialRatioQuery(organizationId);
    const { data: financialRatio, isLoading: financialRatioIsLoading } =
        useGetFinancialRatioQuery(organizationId);

    const isLoading = financialRatioIsLoading || officialFinancialRatioIsLoading;
    const isDataMissing =
        officialFinancialRatio?.data.length === 0 && financialRatio?.data.length === 0;

    const getRatios = (): ExpandedFinancialRatioRow[] => {
        if (!officialFinancialRatio || !financialRatio) {
            return [];
        }

        const yearsData: ExpandedFinancialRatioRow[] = [];
        const nextYear = NextYear();
        for (var year = nextYear; year > nextYear - 10 && yearsData.length < 6; year--) {
            const officialData = GetYearData(year.toString(), officialFinancialRatio.data);
            if (officialData) {
                yearsData.push({
                    ...MapExternalFinancialRatio(officialData),
                    isOfficial: true,
                    columnClassName: '',
                });
            } else {
                const mnData = GetYearData(year.toString(), financialRatio.data);
                if (mnData) {
                    yearsData.push({
                        ...mnData,
                        g6_netMargin: DecimalToPercent(mnData.g6_netMargin),
                        g5_operatingMargin: DecimalToPercent(mnData.g5_operatingMargin),
                        g1_returnOnEquity: DecimalToPercent(mnData.g1_returnOnEquity),
                        g12_quickRatio: DecimalToPercent(mnData.g12_quickRatio),
                        g9_solvency: DecimalToPercent(mnData.g9_solvency),
                        g8_employmentCost: DecimalToPercent(mnData.g8_employmentCost),
                        isOfficial: false,
                        columnClassName: 'my-numbers-data',
                    });
                }
            }
        }

        return yearsData;
    };

    const ratios = getRatios();

    return { ratios, isLoading, isDataMissing };
};

export default useGetOverviewFinancialRatio;
