import React, { useState } from 'react';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import ViewOptions from '../../Components/ViewOptions';
import { CashflowTable } from '../../Components/CashflowTable';
import { ColumnData, TableRow } from '../../Components/NewColumnBasedTable';
import { useParams } from 'react-router-dom';
import { sortBy } from '../../Functions/Lists';
import { format, isPast, startOfMonth } from 'date-fns';
import { toThousands } from '../../Functions/Functions';
import { useRecoilValue } from 'recoil';
import useGetCompanyDetailsQuery from '../../Api/Companies/useGetCompanyDetailsQuery';
import useGetCashflow from '../../Api/Reports/useGetCashflow';
import { userLicenseChoiceState } from '../../Core/State/LicenseChoiceState';
import useGetOrgId from '../../Functions/useGetOrgId';
import CashflowRow from '../../Types/Api/Response/CashflowRow';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const Cashflow = () => {
    const { organizationNumber } = useParams();
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);

    const { data: companyDetails } = useGetCompanyDetailsQuery({
        organizationId,
        licenseChoiceQueryString: licenseChoice.queryString,
    });
    const { data: cashflow } = useGetCashflow(organizationId);

    const sorted = cashflow && sortBy(cashflow.data, 'period');
    const data = sorted
        ?.filter((d) => isPast(startOfMonth(new Date(d.period))))
        .reduce(([prev, ...rest]: CashflowRow[], current: CashflowRow) => {
            if (!prev) {
                return [current];
            }
            if (prev.financialYear !== current.financialYear) {
                return [current, prev, ...rest];
            }
            const next = { ...current } as CashflowRow;
            next.cashFlowFromOperationsPL += prev.cashFlowFromOperationsPL;
            next.totalOperatingIncome_CF += prev.totalOperatingIncome_CF;
            next.directCosts += prev.directCosts;
            next.otherOperatingExpenses_CF += prev.otherOperatingExpenses_CF;
            next.personnelCosts_CF += prev.personnelCosts_CF;
            next.financialIncomes_CF += prev.financialIncomes_CF;
            next.financialExpenses_CF += prev.financialExpenses_CF;
            next.extraordinaryIncomeAndExpenses_CF += prev.extraordinaryIncomeAndExpenses_CF;
            next.tax_CF += prev.tax_CF;
            next.cashFlowFromCapitalBalanceSheet += prev.cashFlowFromCapitalBalanceSheet;
            next.changeInWarehouseAndWorkInProgress += prev.changeInWarehouseAndWorkInProgress;
            next.changeInAccountsReceivable += prev.changeInAccountsReceivable;
            next.changeInOtherCurrentReceivables += prev.changeInOtherCurrentReceivables;
            next.changeInCurrentLiabilities += prev.changeInCurrentLiabilities;
            next.accumulatedCashFlowFromOperatingActivities +=
                prev.accumulatedCashFlowFromOperatingActivities;
            next.cashFlowFromFinancing += prev.cashFlowFromFinancing;
            next.cashFlowFromInvestments += prev.cashFlowFromInvestments;
            next.netChangeInTheLoans += prev.netChangeInTheLoans;
            next.netChangeInTheLoans += prev.netChangeInTheLoans;
            next.netChangeInEquity += prev.netChangeInEquity;
            next.accumulatedCashFlow += prev.accumulatedCashFlow;
            next.bankOpeningBalance = prev.bankOpeningBalance;
            return [next, ...rest];
        }, [])
        .map(toThousands);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['cashflow']} />}
            <div className='company-heading'>
                <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                    <div className='mask'></div>
                    {Texts.help__HelpButtonText}
                </div>
            </div>
            <ViewOptions
                viewOptions={[
                    {
                        link: `/company/${organizationNumber}/cashflow-by-month/`,
                        text: Texts.monthly__ViewOptionButtonText,
                        className: 'view-option-links by-month',
                    },
                    {
                        link: `/company/${organizationNumber}/cashflow/`,
                        text: Texts.yearly__ViewOptionButtonText,
                        className: 'view-option-links by-year current',
                    },
                ]}
            />
            <CashflowTable
                columnWidth={190}
                headerMinWidth={350}
                tableIsLoading={data == null}
                showExpandAll={true}
                data={data}
                csvExportFilename={`${Texts.cashflow__DownloadFileNamePrefix} ${companyDetails?.data.organizationName} ${companyDetails?.data.organizationNumber}`}
                stepHeader={(first, last) => (
                    <>
                        {format(new Date(first.financialYearFrom), 'yyyy-MM')}
                        {last && ' - ' + format(new Date(last.financialYearFrom), 'yyyy-MM')}
                    </>
                )}
                style={{ marginRight: 100 }}
                accumulatedCashflowLabel={Texts.cashflow__accumulatedCashFlow}
            >
                <TableRow
                    className='column-header'
                    label=''
                    selector={(d) => Texts.mynumbers__TableHeader}
                />
                <TableRow<ColumnData & { period: string }>
                    className='bold'
                    label={`${Texts.cashflow__ViewTitle} ${Texts.tkr__TableLabel}`}
                    selector={(d) => format(new Date(d.period), 'yyyy-MM')}
                />
                <TableRow<ColumnData & { financialYear: string }>
                    className='tiny-row'
                    label={Texts.financial_year__CashflowTableLabel}
                    selector={(d) => d.financialYear.replace(':', '-')}
                />
            </CashflowTable>
        </ErrorBoundary>
    );
};

export default Cashflow;
