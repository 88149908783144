import React from 'react';
import { ColumnBasedTableProps } from '../NewColumnBasedTable';
import GroupOverviewTableRow from './Types/GroupOverviewTableRow';
import { ViewType } from '../../Views/GroupOverview';
import GroupOverviewStandardTable from './GroupOverviewStandardTable';
import GroupOverviewAlternativeTable from './GroupOverviewAlternativeTable';

type GroupOverviewTableProps = ColumnBasedTableProps<GroupOverviewTableRow> & {
    viewType: ViewType;
};

const GroupOverviewTable = ({ children, viewType, ...props }: GroupOverviewTableProps) => {
    if (viewType === 'groupoverview-view-standard') {
        return <GroupOverviewStandardTable {...props}>{children}</GroupOverviewStandardTable>;
    }
    if (viewType === 'groupoverview-view-alternative') {
        return <GroupOverviewAlternativeTable {...props}>{children}</GroupOverviewAlternativeTable>;
    }
    return null;
};

export default GroupOverviewTable;
