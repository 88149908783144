import { useQuery } from 'react-query';
import axiosInstance from '../../Core/Axios';
import UseQueryOptions from '../UseQueryOptions';
import OfficialBalanceSheetRow from '../../Types/Api/Response/OfficialBalanceSheetRow';

const useGetOfficialBalanceSheets = (
    companyId: string | null | undefined,
    options?: UseQueryOptions<OfficialBalanceSheetRow[]>,
) =>
    useQuery(
        ['officialBalanceSheets', companyId],
        async () =>
            axiosInstance.get<OfficialBalanceSheetRow[]>(
                `Reports/balancesheets/${companyId}/official`,
            ),
        {
            enabled: !!companyId,
            ...options,
        },
    );

export default useGetOfficialBalanceSheets;
