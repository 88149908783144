import { add, differenceInMonths, endOfMonth, format, isPast, startOfMonth } from 'date-fns';
import CompanyIndustry from '../Types/Api/Response/CompanyIndustry';

export function GetMainSni(codes: CompanyIndustry[]): string {
    let mainSni = '-';
    let main = codes.find((code) => code.mainIndustry === true);
    if (main != null) {
        mainSni = main.descriptionSwedish;
    }
    return mainSni;
}

export function getObjectPartOfPayload(body: string): string {
    // Removes the first three parts, but keeps the whole JSON-body, even if it contains '|'-symbols
    let parts = /^([^|]+\|){3}(.*)$/.exec(body) || ([] as string[]);
    return parts[2];
}

export function sortStringPairs([aFrom, aTo]: [string, string], [bFrom, bTo]: [string, string]) {
    const from = aFrom.localeCompare(bFrom);
    if (from !== 0) {
        return from;
    }
    return aTo.localeCompare(bTo);
}

export function equalStringPair(a: [string, string], b: [string, string]) {
    return sortStringPairs(a, b) === 0;
}

// Multiplies all number values by 1000.
// NOTE: Only works on PNL and BS data! Percentages and ratios in cashflow will be incorrect.
export function fromThousands<T extends Object>(data: T): T {
    return multiplyBy(data, 1000);
}
export function toThousands<T extends Object>(data: T): T {
    return multiplyBy(data, 1 / 1000);
}
function multiplyBy<T extends Object>(data: T, by: number): T {
    const excludedProperties = new Set([
        'financialYear',
        'financialYearFrom',
        'financialYearTo',
        'nrMonth',
        'organizationNumber',
    ]);
    return Object.fromEntries(
        Object.entries(data).map(([key, value]) => {
            if (typeof value !== 'number' || excludedProperties.has(key)) {
                return [key, value];
            }
            return [key, value * by];
        }),
    ) as T;
}

type FinancialYearHolder = {
    financialYearFrom: string;
    financialYearTo: string;
    financialYear: string;
};
// Returns a list that, for each unique financial year, contains the official data if available, otherwise the MyNumbers data.
export function concatenateOfficialData<
    T extends FinancialYearHolder,
    U extends FinancialYearHolder,
>(official: U[], myNumbersData: T[]): ((T | U) & { isOfficial?: boolean })[] {
    const allFinancialYearPairs = [
        ...official.map<[string, string]>((y) => [y.financialYearFrom, y.financialYearTo]),
        ...myNumbersData.map<[string, string]>((y) => [y.financialYearFrom, y.financialYearTo]),
    ]
        .sort(sortStringPairs)
        .reverse()
        .filter(([from]) => isPast(new Date(from)));
    const noDuplicates = allFinancialYearPairs.filter((p, index) => {
        return !allFinancialYearPairs.slice(0, index).find((p2) => equalStringPair(p, p2));
    });
    return noDuplicates.map((p) => {
        const officialEntry = official.find((y) =>
            equalStringPair(p, [y.financialYearFrom, y.financialYearTo]),
        );
        if (officialEntry) {
            return {
                ...fromThousands(officialEntry),
                isOfficial: true,
            };
        }
        return {
            ...myNumbersData.find((y) =>
                equalStringPair(p, [y.financialYearFrom, y.financialYearTo]),
            )!,
            isOfficial: false,
        };
    });
}

type PeriodHolder = FinancialYearHolder & {
    period: string;
};
export function getQuarterOfFinancialYear(d: PeriodHolder): number {
    // Add one, since for January the difference is 0, but January is the 1st month.
    const monthOfFinancialYear =
        differenceInMonths(new Date(d.period), new Date(d.financialYearFrom)) + 1;
    return Math.ceil(monthOfFinancialYear / 3);
}

export type QuarterHolder = {
    quarter: number;
    quarterFrom: Date;
    quarterTo: Date;
    quarterPeriod: string;
};
export function addQuarters<T extends PeriodHolder>(d: T): T & QuarterHolder {
    const quarter = getQuarterOfFinancialYear(d);
    const quarterFrom = startOfMonth(
        add(new Date(d.financialYearFrom), { months: 3 * (quarter - 1) }),
    );
    const quarterTo = endOfMonth(add(new Date(d.financialYearFrom), { months: 3 * quarter - 1 }));
    const quarterPeriod = `${format(quarterFrom, 'yyy-MM-dd')} - ${format(quarterTo, 'yyy-MM-dd')}`;
    return {
        ...d,
        quarter,
        quarterFrom,
        quarterTo,
        quarterPeriod,
    };
}

export const add30Days = (d: string) => {
    const dPlus30Days = add(new Date(d), { days: 30 });
    return format(dPlus30Days, 'yyyy-MM-dd');
};
