import React, { useEffect, useRef, useState } from 'react';
import Texts from '../Functions/Texts.json';
import AllCompaniesMenu from '../Components/AllCompaniesMenu';
import DashboardHeader from '../Components/DashboardHeader';
import CompanyDetails from '../Types/Api/Response/CompanyDetails';
import { Dialog } from '../Components/Dialog';
import CompanyPicker, { OkHandle } from '../Components/GroupOverview/CompanyPicker';
import ProfitAndLossRow from '../Types/Api/Response/ProfitAndLoss';
import {
    TableRow as TableRowBase,
    TableRowInstance,
    TableRowProps,
} from '../Components/NewColumnBasedTable';
import { profitAndLossKeys } from '../Functions/Budget';
import Help from '../Components/Help';
import { differenceInMonths } from 'date-fns';
import SingleSelect, { SelectOption } from '../Components/InputFields/SingleSelect';
import GroupOverviewTableRow from '../Components/GroupOverview/Types/GroupOverviewTableRow';
import GroupOverviewTable from '../Components/GroupOverview/GroupOverviewTable';
import { ExpandedProfitAndLossRow } from '../Components/ProfitAndLossTable';
import MonthRangePicker, { MonthRange } from '../Components/GroupOverview/MonthRangePicker';
import { FormatSelectedMonthRange } from '../Functions/FormattingTS';
import { useRecoilValue } from 'recoil';
import { userLicenseChoiceState } from '../Core/State/LicenseChoiceState';
import { companiesState } from '../Core/State/CompanyListState';
import useGetCompanyListRequest from '../Api/Companies/useGetCompanyListRequest';
import axiosInstance from '../Core/Axios';
import useGetPendingCompaniesRequest from '../Api/Companies/useGetPendingCompaniesRequest';
import useGetDisabledCompaniesListQuery from '../Api/Companies/useGetDisabledCompaniesListQuery';
import GroupOverviewCompanySelectService from '../Functions/Storage/GroupOverviewCompanySelectService';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../Components/Wrappers/ErrorFallback';
import Alert from '../Components/Alert';

type CompanyPnL = {
    company: CompanyDetails | undefined;
    profitAndLoss: Array<ProfitAndLossRow>;
};

export type ViewType = 'groupoverview-view-standard' | 'groupoverview-view-alternative';

const ViewOptions: SelectOption<ViewType>[] = [
    {
        key: 'groupoverview-view-standard',
        value: 'groupoverview-view-standard',
        title: Texts.group_overview.groupoverview_view_standard,
    },
    {
        key: 'groupoperview-view-alternative',
        value: 'groupoverview-view-alternative',
        title: Texts.group_overview.groupoverview_view_alternative,
    },
];

const GroupOverview = () => {
    const [selectedCompanies, setSelectedCompanies] = useState<CompanyDetails[] | null>(null);
    const [helpIsOpen, setHelpIsOpen] = useState<boolean>(false);
    const [chooseCompanies, setChooseCompanies] = useState<boolean>(false);
    const [selectedMonthRange, setSelectedMonthRange] = useState<MonthRange>({
        from: { year: new Date().getFullYear(), month: new Date().getMonth() },
        to: { year: new Date().getFullYear(), month: new Date().getMonth() },
    });
    const [pnLData, setPnLData] = useState<Array<CompanyPnL>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(false);
    const [missingMonths, setMissingMonths] = useState<boolean>(false);
    const [data, setData] = useState<Array<GroupOverviewTableRow>>([]);
    const [viewChoice, setViewChoice] = useState<SelectOption<ViewType> | null>(ViewOptions[0]);
    const licenseChoice = useRecoilValue(userLicenseChoiceState);
    const companyList = useRecoilValue(companiesState);
    const { getCompanyList } = useGetCompanyListRequest();
    const { pendingCompanies } = useGetPendingCompaniesRequest(licenseChoice);
    const { data: disabledCompanies } = useGetDisabledCompaniesListQuery(
        licenseChoice.queryString ?? '',
        { enabled: licenseChoice.type === 'creditor' },
    );

    const pickerRef = useRef<OkHandle>();

    useEffect(() => {
        getCompanyList();
    }, [getCompanyList, licenseChoice]);

    const companiesList = companyList ? companyList.companies : null;

    useEffect(() => {
        if (selectedCompanies !== null) {
            return;
        }
        const storedObj = GroupOverviewCompanySelectService.GetParsedStorageData();
        if (storedObj && storedObj.licenseQueryString === licenseChoice.queryString) {
            setSelectedCompanies(storedObj.companies);
        } else if (companiesList && companiesList.length > 0) {
            // Initialize the set of selected companies
            if (companiesList.length < 7) {
                setSelectedCompanies(companiesList);
            } else {
                setSelectedCompanies([]);
            }
        }
    }, [companiesList, selectedCompanies, licenseChoice.queryString]);

    useEffect(() => {
        const getPnLData = async () => {
            const requestPnL = async (companyId: string) => {
                const res = await axiosInstance.get<Array<ProfitAndLossRow>>(
                    `Reports/ProfitAndLoss/${companyId}/monthly/`,
                );
                return {
                    company: selectedCompanies!.find((c) => c.id === companyId),
                    profitAndLoss: res.data,
                };
            };

            if (selectedCompanies === null) {
                return;
            }

            setDataLoading(true);
            const pnLResults = await Promise.all(
                selectedCompanies?.map((company) => requestPnL(company.id)),
            );
            setPnLData(pnLResults);
            setDataLoading(false);
        };
        getPnLData();
        return () => {};
    }, [selectedCompanies]);

    useEffect(() => {
        setMissingMonths(false);
        if (pnLData.length === 0) {
            setData([]);
        }
        const pNLKeys = profitAndLossKeys();

        const monthsCount = differenceInMonths(
            new Date(selectedMonthRange.to.year, selectedMonthRange.to.month, 0),
            new Date(selectedMonthRange.from.year, selectedMonthRange.from.month - 1, 0),
        );

        const AggregatePeriodPnL = (
            monthRange: MonthRange,
            profitAndLoss: ProfitAndLossRow[],
        ): ProfitAndLossRow => {
            const fromMonthStr = `${selectedMonthRange.from.month < 10 ? '0' : ''}${
                selectedMonthRange.from.month
            }`;
            const toMonthCompare = selectedMonthRange.to.month + 1;
            const toMonthStr = `${toMonthCompare < 10 ? '0' : ''}${toMonthCompare}`;
            const months = profitAndLoss.filter(
                (pnl) =>
                    pnl.period >= `${selectedMonthRange.from.year}-${fromMonthStr}` &&
                    pnl.period < `${selectedMonthRange.to.year}-${toMonthStr}`,
            );
            if (months.length < monthsCount) {
                setMissingMonths(true);
            }
            let sumMonths = { ...months[0] };
            for (const month of months.slice(1)) {
                for (const key of pNLKeys) {
                    sumMonths[key] += typeof month[key] === 'number' ? month[key] : 0;
                }
            }

            return sumMonths;
        };

        const SumPnL = (profitAndLoss: ProfitAndLossRow[]): ExpandedProfitAndLossRow => {
            let sums = { ...profitAndLoss[0] };
            for (const month of profitAndLoss.slice(1)) {
                for (const key of pNLKeys) {
                    sums[key] += typeof month[key] === 'number' ? month[key] : 0;
                }
            }

            return { ...sums, columnClassName: 'my-numbers-data', isBudget: false };
        };

        // Aggregate selected month range
        const d = pnLData.map((companyPnl) =>
            AggregatePeriodPnL(selectedMonthRange, companyPnl.profitAndLoss),
        );
        const withSums = [...d, SumPnL(d)]; // last item in array now contains sums of the other

        // Populate extra props
        const expandedPnL: GroupOverviewTableRow[] = withSums.map((pnl, index) => ({
            ...pnl,
            isBudget: false,
            columnTitle:
                index < pnLData.length
                    ? pnLData[index].company?.organizationName
                    : Texts.group_overview.sum_title,
        }));
        setData(expandedPnL);
    }, [pnLData, selectedMonthRange]);

    const TableRow: (
        props: TableRowProps<GroupOverviewTableRow>,
    ) => TableRowInstance<GroupOverviewTableRow> = TableRowBase;

    return (
        <div className='content-wrapper'>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['dashboard']} />
            )}
            <DashboardHeader
                companies={companyList}
                pendingCompanies={pendingCompanies}
                disabledCompanies={disabledCompanies?.data}
                viewTitle={Texts.dashboard_group_overview__ViewTitle}
                toggleHelp={() => setHelpIsOpen(true)}
            />
            <AllCompaniesMenu />
            {/* can't just change the whole floating design, so need this */}
            <div className='no-float' />
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <div className='group-overview-wrapper'>
                    <div id='controls'>
                        <MonthRangePicker
                            monthRange={selectedMonthRange}
                            setMonthRange={setSelectedMonthRange}
                        />
                        <div
                            id='company-select'
                            className='button'
                            onClick={() => setChooseCompanies(true)}
                        >
                            <div>{Texts.group_overview.choose_companies}</div>
                        </div>
                        <div id='choice-select'>
                            <span>{Texts.group_overview.view_choice}</span>
                            <SingleSelect
                                options={ViewOptions}
                                value={viewChoice}
                                onChange={setViewChoice}
                                size='large'
                            />
                        </div>
                    </div>
                    <Dialog
                        open={chooseCompanies}
                        header={Texts.group_overview.choose_companies}
                        ok={() => {
                            pickerRef.current?.onOk();
                            setChooseCompanies(false);
                        }}
                        cancel={() => setChooseCompanies(false)}
                    >
                        <CompanyPicker
                            selectedCompanies={selectedCompanies}
                            companiesList={companiesList}
                            setSelectedCompanies={(companies) => setSelectedCompanies(companies)}
                            licenseQueryString={licenseChoice.queryString ?? ''}
                            ref={pickerRef}
                        />
                    </Dialog>

                    {dataLoading && (
                        <div className='spinner-wrapper'>
                            <div className='spinner'></div>
                        </div>
                    )}
                    {missingMonths && (
                        <Alert severity='error' message={Texts.group_overview.missing_months} />
                    )}
                    {selectedCompanies &&
                        selectedCompanies.length > 0 &&
                        data.length > 1 &&
                        viewChoice !== null && (
                            <GroupOverviewTable
                                columnWidth={data.length > 3 ? 160 : 190}
                                headerMinWidth={data.length > 2 ? 290 - 10 * data.length : 280}
                                tableIsLoading={data == null}
                                showExpandAll={false}
                                data={data}
                                csvExportFilename={`${Texts.profit_and_loss__DownloadFileNamePrefix} ${Texts.group_overview.title}`}
                                pdfExportFilename={{
                                    prefix: Texts.group_overview.pdf_file_name,
                                    companyName: '',
                                    orgNr: '',
                                }}
                                disableStep
                                viewType={viewChoice.value}
                                orientation={data.length > 3 ? 'landscape' : 'portrait'}
                            >
                                <TableRow
                                    className='column-header bold'
                                    label=''
                                    selector={(d) => d.columnTitle ?? ''}
                                />
                                <TableRow
                                    className='tiny-row'
                                    label={Texts.group_overview.period}
                                    selector={() => FormatSelectedMonthRange(selectedMonthRange)}
                                />
                            </GroupOverviewTable>
                        )}
                </div>
            </ErrorBoundary>
        </div>
    );
};

export default GroupOverview;
