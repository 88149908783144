import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import LicenseChoice from '../../Components/LicenseChoice';
import Texts from '../../Functions/Texts.json';
import RegisterView from './RegisterView';
import TokenStateService from '../../Functions/Storage/TokenStateService';
import LicenseChoiceModel from '../../Types/Context/LicenseChoiceModel';
import useAuthentication from '../../Core/Authentication/useAuthentication';
import { CorporatePortalUser } from '../../Types/Api/Response/CorporatePortalUser';
import settings from '../../settings/settings';
import useUserInfoRequest from '../../Api/UserInfo/useUserInfoRequest';

const useQuery = () => new URLSearchParams(useLocation().search);

export const SsoAuthView = () => {
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [unauthorized, setUnauthorized] = useState(false);
    const [userName, setUserName] = useState('');
    const [userInfo, setUserInfo] = useState<null | CorporatePortalUser>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [forceLicenseChoice, setForceLicenseChoice] = useState<boolean | CorporatePortalUser>(
        false,
    );
    const fetched = useRef<boolean>(false);
    const { login, logout } = useAuthentication();
    const { getUserDataAsync } = useUserInfoRequest();

    const navigate = useNavigate();

    const query = useQuery();
    const code = query.get('code');
    const state = query.get('state')?.split(';');
    const error = query.get('error');

    const oauthToken = state?.shift();

    // If no state, it is internal (Aurelia → Portal)
    const identityProvider = state?.shift() ?? 'MyNumbersInternal';

    const authenticate = async (code: string) => {
        if (error) {
            setErrorMessage(Texts.signin.sso_general_error);
            return;
        }

        if (identityProvider !== 'MyNumbersInternal') {
            const cookieToken = document.cookie
                ?.split('; ')
                ?.find((row) => row.startsWith('ssocToken='))
                ?.split('=')[1];

            // Verify token state for CSRF
            if (cookieToken !== oauthToken) {
                setErrorMessage(Texts.signin.sso_token_mismatch);
                return;
            }
        }

        setIsAuthenticating(true);
        try {
            const res = await axios.post(`${settings.apiBase}authentication/user/ssoToken`, {
                identityProvider: identityProvider,
                authorizationCode: code,
                source: 'CorpPortal (SSO)',
            });

            TokenStateService.UpdateToken(res.data.accessToken, res.data.refreshToken, true);
            const authRes = await getUserDataAsync();
            setIsAuthenticating(false);
            if (authRes.type === 'success') {
                login(authRes.userInfo, authRes.license);
            } else if (authRes.type === 'unauthorized') {
                setUnauthorized(true);
                setUserInfo(authRes.userInfo);
                setUserName(authRes.userName);
            }
        } catch (error) {
            setIsAuthenticating(false);
            navigate('/');
        }
    };

    const activateUser = (usrData: CorporatePortalUser, license: LicenseChoiceModel) => {
        setUnauthorized(false);
        setUserInfo(usrData);
        login(usrData, license);
    };

    const cancelActivation = () => {
        setUserInfo(null);
        setForceLicenseChoice(false);
        setUnauthorized(false);
        logout('');
        navigate('/');
    };

    useEffect(() => {
        if (!fetched.current) {
            fetched.current = true;
            authenticate(code as string);
        }
    });

    return (
        <>
            {errorMessage && (
                <div className='content-overlay'>
                    <div className='error-wrapper'>
                        <p>{errorMessage}</p>
                    </div>
                </div>
            )}

            {isAuthenticating && (
                <div className='spinner-wrapper'>
                    <div className='spinner'></div>
                </div>
            )}

            {forceLicenseChoice && (
                <div id='license-login-choice'>
                    <p>{Texts.licenseChoiceHeader}</p>
                    <LicenseChoice />
                </div>
            )}

            {unauthorized && (
                <RegisterView
                    userName={userName}
                    userInfo={userInfo as CorporatePortalUser}
                    activateUser={activateUser}
                    cancelActivation={cancelActivation}
                />
            )}
        </>
    );
};
