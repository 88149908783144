import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Texts from '../../Functions/Texts.json';
import Help from '../../Components/Help';
import BudgetCompareChart from '../../Components/Budget/BudgetCompareChart';
import { MonthlyCompareChart } from '../../Components/MonthlyCompareChart';
import useGetOrgId from '../../Functions/useGetOrgId';
import DashboardFinancialRatioTable from './DashboardFinancialRatioTable';
import useGetPnLQuery from '../../Api/Reports/useGetPnLQuery';
import useGetPnLAggregatedQuery from '../../Api/Reports/useGetPnLAggregatedQuery';
import useGetActiveBudgetsQuery from '../../Api/Budget/useGetActiveBudgetsQuery';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../Components/Wrappers/ErrorFallback';

const CompanyDashboard = () => {
    const { organizationId } = useGetOrgId(useParams());
    const [helpIsOpen, setHelpIsOpen] = useState(false);

    const { data: profitAndLoss } = useGetPnLQuery(organizationId);
    const { data: profitAndLossAggregated } = useGetPnLAggregatedQuery(organizationId);
    const { data: activeBudgets } = useGetActiveBudgetsQuery(organizationId);

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {helpIsOpen && (
                <Help closeHelp={() => setHelpIsOpen(false)} helpItems={['company-dashboard']} />
            )}
            <div className='charts-wrapper company-charts'>
                <div className='info-boxes-charts'>
                    <MonthlyCompareChart
                        profitAndLoss={profitAndLoss?.data}
                        profitAndLossAggregated={profitAndLossAggregated?.data}
                        budgets={activeBudgets?.data ?? []}
                    />
                    <BudgetCompareChart
                        profitAndLoss={profitAndLoss?.data}
                        budgets={activeBudgets?.data ?? []}
                    />
                </div>

                <div className='info-boxes'>
                    <DashboardFinancialRatioTable />
                </div>

                <div className='bottom-buttons-and-info'>
                    <span className='official-data-disclaimer'>
                        {Texts.official_data_from__DisclaimerText}
                    </span>
                    <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
                        <div className='mask'></div>
                        {Texts.help__HelpButtonText}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};

export default CompanyDashboard;
