import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

type Props = {
    children: ReactNode;
};

const RowBasedTableWrapper = ({ children }: Props) => {
    return (
        <div className='row-based-table-wrapper'>
            <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
        </div>
    );
};

export default RowBasedTableWrapper;
