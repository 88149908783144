// import Context from '../../Context';

// const SumMonthlyValues = (financialYear, value, dataArray) => {
//     let sum = 0;
//     for (let i = 0; i < dataArray.length; i++) {
//         if (dataArray[i].financialYear === financialYear) {
//             sum +=
//                 typeof dataArray[i][value] === 'string'
//                     ? parseFloat(dataArray[i][value])
//                     : dataArray[i][value];
//         }
//     }
//     return sum;
// };

/**
 * All this is commented and needs to be refactored into using non-context based state and new api
 */

const ProfitAndLossWaterfall = () => {
    // const waterFallChart = React.createRef();
    // const organizationNumber = params.organizationNumber;
    // const [helpIsOpen, setHelpIsOpen] = useState(false);
    // const [displayFinancialYear, updateDisplayFinancialYear] = useState(null);
    // const [drilldownStarted, updateDrilldownStarted] = useState(false);
    // const [availableYears, updateAvailableYears] = useState([]);
    // // const context = useContext(Context);
    // const [viewRenderStarted, updateViewRenderStarted] = useState(0);
    // const [profitAndLossByPostTypeChart, updateProfitAndLossByPostTypeChart] = useState(null);
    // const [profitAndLossByMonthChartConfig, updateProfitAndLossByMonthChartConfig] = useState({});
    // const [chartsAreLoading, updateChartsAreLoading] = useState(true);
    // const [viewConfig, setViewConfig] = useState({
    //     requiredData: ['profitAndLoss', 'companyDetails'],
    //     helpItems: ['profitAndLoss'],
    //     tableOptions: [
    //         {
    //             link: 'http://localhost:3000/profit-and-loss-waterfall/112234-0002/2021-12',
    //             action: 'link',
    //             name: '2021',
    //         },
    //     ],
    //     viewOptions: [
    //         {
    //             link: `/company/${organizationNumber}/profit-and-loss/`,
    //             text: Texts.yearly__ViewOptionButtonText,
    //             className: 'view-option-links by-year ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/profit-and-loss-by-quarter/`,
    //             text: Texts.quarterly__ViewOptionButtonText,
    //             className: 'view-option-links by-month ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/profit-and-loss-by-month/`,
    //             text: Texts.monthly__ViewOptionButtonText,
    //             className: 'view-option-links by-month ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/profit-and-loss-accounts/${CurrentYear()}`,
    //             text: Texts.accounts__ViewOptionButtonText,
    //             className: 'view-option-links accounts ',
    //         },
    //         {
    //             link: `/company/${organizationNumber}/profit-and-loss-waterfall/`,
    //             text: Texts.charts__ViewOptionButtonText,
    //             className: 'view-option-links by-chart current',
    //         },
    //     ],
    //     viewTitle: Texts.profitAndLoss__ViewTitle,
    //     translations: {},
    // });

    // const generalChartConfig = {
    //     baseFont: 'Lato',
    //     baseFontSize: '14',
    //     baseFontColor: '#160A25',
    //     legendItemFontBold: '0',
    //     legendItemFont: 'Lato',
    //     legendItemFontSize: '10',
    //     legendItemFontColor: '#160A25',
    //     legendNumRows: '2',
    //     legendNumColumns: '1',
    //     legendIconScale: '1.2',
    //     paletteColors: '#88DAD6,#27B6B0,#DA546C,#F9CE39',
    //     numberSuffix: Texts.chartNumberSuffix,
    //     decimalSeparator: ',',
    //     thousandSeparator: ' ',
    //     theme: 'fusion',
    //     animation: '0',
    //     showSumAtEnd: '0',
    //     positiveColor: '#27B6B0',
    //     negativeColor: '#DA546C',
    //     decimals: '2',
    //     numberScaleValue: '1000,1000,1000',
    //     numberScaleUnit: Texts.revenueChartNumberScaleUnit,
    //     formatNumberScale: '1',
    //     plotSpacePercent: '70',
    // };

    // const PrepareData = () => {
    //     if (drilldownStarted) {
    //         document.location.reload();
    //     }

    //     let profitAndLoss = context.apiRawData.profitAndLoss;

    //     if (params.financialyear != null) {
    //         updateDisplayFinancialYear(params.financialyear.trim());
    //     } else {
    //         getLatestFinancialYear(profitAndLoss);
    //     }

    //     let availableYears = [];
    //     for (let i = 0; i < profitAndLoss.length; i++) {
    //         if (availableYears.indexOf(profitAndLoss[i].financialYear) === -1) {
    //             availableYears.push(profitAndLoss[i].financialYear);
    //         }
    //     }
    //     updateAvailableYears(availableYears);

    //     if (!profitAndLoss || !displayFinancialYear) {
    //         return;
    //     }

    //     updateViewRenderStarted(1);
    //     setupProfitAndLossByMonthChart(profitAndLoss);
    //     setupProfitAndLossByPostTypeChart(profitAndLoss);
    //     updateChartsAreLoading(false);
    // };

    // const getLatestFinancialYear = (dataSet) => {
    //     if (dataSet.length === 0) {
    //         return;
    //     }
    //     const financialYear = dataSet.reduce(function (prev, current) {
    //         return prev.financialYear > current.financialYear ? prev : current;
    //     });
    //     updateDisplayFinancialYear(FinancialYearAsYearMonth(financialYear.financialYear));
    // };

    // const setupProfitAndLossByMonthChart = (profitAndLoss) => {
    //     let profitAndLossByMonthChartData = [];
    //     let profitAndLossByMonthLinkedData = [];
    //     let profitAndLossByMonthCalcualtedTotal = 0;

    //     for (let i = 0; i < profitAndLoss.length; i++) {
    //         if (
    //             FinancialYearAsYearMonth(profitAndLoss[i].financialYear) === displayFinancialYear &&
    //             !IsFutureDate(profitAndLoss[i].period)
    //         ) {
    //             profitAndLossByMonthCalcualtedTotal += parseInt(
    //                 profitAndLoss[i].currentProfitEarningBeforeTax,
    //             );

    //             profitAndLossByMonthChartData.push({
    //                 label: DatetimeAsYearMonth(profitAndLoss[i].period),
    //                 value: profitAndLoss[i].currentProfitEarningBeforeTax,
    //                 link: 'newchart-json-Level1AccumulatedProfitAndLoss' + i + '',
    //                 tooltext:
    //                     DatetimeAsYearMonth(profitAndLoss[i].period) +
    //                     '{br}' +
    //                     '<b>' +
    //                     Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                     ' ' +
    //                     FormatNumber(profitAndLoss[i].currentProfitEarningBeforeTax) +
    //                     Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                     '</b>',
    //             });

    //             profitAndLossByMonthLinkedData.push({
    //                 id: 'Level1AccumulatedProfitAndLoss' + i,
    //                 linkedchart: {
    //                     chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                         caption:
    //                             Texts.profitAndLoss__ProfitAndLossChartCaption +
    //                             DatetimeAsYearMonth(profitAndLoss[i].period),
    //                         subcaption: ' ' + Texts.per_post__ProfitAndLossSubCaption,
    //                         rotateValues: '0',
    //                         plottooltext: '$label {br}<b>$dataValue</b>',
    //                         renderAt: 'chartDrilldownContainer',
    //                     }),
    //                     data: [
    //                         {
    //                             label: Texts.profitandloss__revenue,
    //                             value: profitAndLoss[i].revenue,
    //                             link: 'newchart-json-Level2Revenue' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__otherOperatingIncome,
    //                             value: profitAndLoss[i].otherOperatingIncome,
    //                             link: 'newchart-json-Level2OtherOperatingIncome' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__directCost,
    //                             value: profitAndLoss[i].directCost,
    //                             link: 'newchart-json-Level2DirectCost' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__otherOperatingExpensesAggregated,
    //                             value: profitAndLoss[i].otherOperatingExpensesAggregated,
    //                             link:
    //                                 'newchart-json-Level2OtherOperatingExpensesAggregated' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__personnelCosts,
    //                             value: profitAndLoss[i].personnelCosts,
    //                             link: 'newchart-json-Level2PersonnelCosts' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__depreciationAndWritedowns,
    //                             value: profitAndLoss[i].depreciationAndWritedowns,
    //                             link: 'newchart-json-Level2DepreciationAndWritedowns' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__sumOfFinancialPosts,
    //                             value: profitAndLoss[i].sumOfFinancialPosts,
    //                             link: 'newchart-json-Level2SumOfFinancialPosts' + i + '',
    //                         },
    //                         {
    //                             label: Texts.profitandloss__currentProfitEarningBeforeTax,
    //                             value: profitAndLoss[i].currentProfitEarningBeforeTax,
    //                             color: '#421C52',
    //                             issum: '1',
    //                             cumulative: '1',
    //                         },
    //                     ],
    //                     linkeddata: [
    //                         {
    //                             id: 'Level2Revenue' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__revenue,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'revenue',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2OtherOperatingIncome' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__otherOperatingIncome,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'otherOperatingIncome',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2DirectCost' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__directCost,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'directCost',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2OtherOperatingExpensesAggregated' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption:
    //                                             Texts.profitandloss__otherOperatingExpensesAggregated,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'otherOperatingExpensesAggregated',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2PersonnelCosts' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__personnelCosts,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'personnelCosts',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2DepreciationAndWritedowns' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__depreciationAndWritedowns,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'depreciationAndWritedowns',
    //                                 ),
    //                             },
    //                         },
    //                         {
    //                             id: 'Level2SumOfFinancialPosts' + i,
    //                             linkedchart: {
    //                                 chart: Object.assign(
    //                                     JSON.parse(JSON.stringify(generalChartConfig)),
    //                                     {
    //                                         caption: Texts.profitandloss__sumOfFinancialPosts,
    //                                         subcaption:
    //                                             Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                                         type: 'MSColumn2d',
    //                                         renderAt: 'chartcontainer-1',
    //                                     },
    //                                 ),
    //                                 data: getMonthlyValues(
    //                                     profitAndLoss[i].financialYear,
    //                                     'sumOfFinancialPosts',
    //                                 ),
    //                             },
    //                         },
    //                     ],
    //                 },
    //             });
    //         }
    //     }

    //     profitAndLossByMonthChartData.push({
    //         label: Texts.total__ProfitAndLossChartLabel,
    //         value: profitAndLossByMonthCalcualtedTotal,
    //         tooltext:
    //             Texts.total__ProfitAndLossChartLabel +
    //             '{br}' +
    //             '<b>' +
    //             Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //             ' ' +
    //             FormatNumber(profitAndLossByMonthCalcualtedTotal) +
    //             Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //             '</b>',
    //         color: '#421C52',
    //         issum: '1',
    //         cumulative: '1',
    //     });

    //     let profitAndLossByMonthChart = Object.assign(
    //         JSON.parse(JSON.stringify(generalChartConfig)),
    //         {
    //             caption: Texts.profitandloss__currentProfitEarningBeforeTax, //Texts.profitAndLoss__ProfitAndLossChartCaption,
    //             subcaption:
    //                 displayFinancialYear.substring(0, displayFinancialYear.length - 3) +
    //                 ' ' +
    //                 Texts.per_month__ProfitAndLossSubCaption,
    //             yAxisname:
    //                 Texts.sum__ProfitAndLossChartLabel + ' ' + Texts.sek__ProfitAndLossChartLabel,
    //             connectordashed: '1',
    //             sumlabel: Texts.total__ProfitAndLossChartLabel,
    //         },
    //     );

    //     profitAndLossByMonthChartConfig.type = 'waterfall2d';
    //     profitAndLossByMonthChartConfig.width = '100%';
    //     profitAndLossByMonthChartConfig.height = '450';
    //     profitAndLossByMonthChartConfig.dataSource = {
    //         data: profitAndLossByMonthChartData,
    //         linkedData: profitAndLossByMonthLinkedData,
    //         chart: profitAndLossByMonthChart,
    //     };

    //     updateProfitAndLossByMonthChartConfig(profitAndLossByMonthChartConfig);
    // };

    // const setupProfitAndLossByPostTypeChart = (profitAndLoss) => {
    //     let financialYear;

    //     for (let i = 0; i < profitAndLoss.length; i++) {
    //         if (
    //             FinancialYearAsYearMonth(profitAndLoss[i].financialYear) === displayFinancialYear &&
    //             !IsFutureDate(profitAndLoss[i].period)
    //         ) {
    //             financialYear = profitAndLoss[i].financialYear;
    //         }
    //     }

    //     let profitAndLossByPostTypeData = [
    //         {
    //             label: Texts.profitandloss__revenue,
    //             value: SumMonthlyValues(financialYear, 'revenue', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__revenue +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(SumMonthlyValues(financialYear, 'revenue', profitAndLoss)) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedRevenue',
    //         },
    //         {
    //             label: Texts.profitandloss__otherOperatingIncome,
    //             value: SumMonthlyValues(financialYear, 'otherOperatingIncome', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__otherOperatingIncome +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 FormatNumber(
    //                     SumMonthlyValues(financialYear, 'otherOperatingIncome', profitAndLoss),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedOtherOperatingIncome',
    //         },
    //         {
    //             label: Texts.profitandloss__directCost,
    //             value: SumMonthlyValues(financialYear, 'directCost', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__directCost +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(SumMonthlyValues(financialYear, 'directCost', profitAndLoss)) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedDirectCost',
    //         },
    //         {
    //             label: Texts.profitandloss__otherOperatingExpensesAggregated,
    //             value: SumMonthlyValues(
    //                 financialYear,
    //                 'otherOperatingExpensesAggregated',
    //                 profitAndLoss,
    //             ),
    //             tooltext:
    //                 Texts.profitandloss__otherOperatingExpensesAggregated +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     SumMonthlyValues(
    //                         financialYear,
    //                         'otherOperatingExpensesAggregated',
    //                         profitAndLoss,
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedExpensesAggregated',
    //         },
    //         {
    //             label: Texts.profitandloss__personnelCosts,
    //             value: SumMonthlyValues(financialYear, 'personnelCosts', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__personnelCosts +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(SumMonthlyValues(financialYear, 'personnelCosts', profitAndLoss)) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedPersonnelCosts',
    //         },
    //         {
    //             label: Texts.profitandloss__depreciationAndWritedowns,
    //             value: SumMonthlyValues(financialYear, 'depreciationAndWritedowns', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__depreciationAndWritedowns +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     SumMonthlyValues(financialYear, 'depreciationAndWritedowns', profitAndLoss),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedDepreciationAndWritedowns',
    //         },
    //         {
    //             label: Texts.profitandloss__sumOfFinancialPosts,
    //             value: SumMonthlyValues(financialYear, 'sumOfFinancialPosts', profitAndLoss),
    //             tooltext:
    //                 Texts.profitandloss__sumOfFinancialPosts +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     SumMonthlyValues(financialYear, 'sumOfFinancialPosts', profitAndLoss),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             link: 'newchart-json-LinkedSumOfFinancialPosts',
    //         },
    //         {
    //             label: Texts.profitandloss__currentProfitEarningBeforeTax,
    //             value: SumMonthlyValues(
    //                 financialYear,
    //                 'currentProfitEarningBeforeTax',
    //                 profitAndLoss,
    //             ),
    //             tooltext:
    //                 Texts.profitandloss__currentProfitEarningBeforeTax +
    //                 '{br}' +
    //                 '<b>' +
    //                 Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                 ' ' +
    //                 FormatNumber(
    //                     SumMonthlyValues(
    //                         financialYear,
    //                         'currentProfitEarningBeforeTax',
    //                         profitAndLoss,
    //                     ),
    //                 ) +
    //                 Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                 '</b>',
    //             color: '#421C52',
    //             issum: '1',
    //             cumulative: '1',
    //         },
    //     ];

    //     let profitAndLossByPostTypeLinkedData = [
    //         {
    //             id: 'LinkedRevenue',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__revenue,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'revenue'),
    //             },
    //         },
    //         {
    //             id: 'LinkedOtherOperatingIncome',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__otherOperatingIncome,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'otherOperatingIncome'),
    //             },
    //         },
    //         {
    //             id: 'LinkedDirectCost',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__directCost,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'directCost'),
    //             },
    //         },
    //         {
    //             id: 'LinkedExpensesAggregated',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__otherOperatingExpensesAggregated,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'otherOperatingExpensesAggregated'),
    //             },
    //         },
    //         {
    //             id: 'LinkedPersonnelCosts',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__personnelCosts,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'personnelCosts'),
    //             },
    //         },
    //         {
    //             id: 'LinkedDepreciationAndWritedowns',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__depreciationAndWritedowns,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'depreciationAndWritedowns'),
    //             },
    //         },
    //         {
    //             id: 'LinkedSumOfFinancialPosts',
    //             linkedchart: {
    //                 chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //                     caption: Texts.profitandloss__sumOfFinancialPosts,
    //                     subcaption: Texts.change_by_month__ProfitAndLossChartSubCaption,
    //                     type: 'waterfall2d',
    //                 }),
    //                 data: getMonthlyValues(financialYear, 'sumOfFinancialPosts'),
    //             },
    //         },
    //     ];

    //     let profitAndLossByPostTypeDataSource = {
    //         data: profitAndLossByPostTypeData,
    //         linkedData: profitAndLossByPostTypeLinkedData,
    //         chart: Object.assign(JSON.parse(JSON.stringify(generalChartConfig)), {
    //             caption: Texts.profitAndLoss__ProfitAndLossChartCaption,
    //             subcaption:
    //                 displayFinancialYear.substring(0, displayFinancialYear.length - 3) +
    //                 ' ' +
    //                 Texts.per_post__ProfitAndLossSubCaption,
    //             yAxisname:
    //                 Texts.sum__ProfitAndLossChartLabel + ' ' + Texts.sek__ProfitAndLossChartLabel,
    //             connectordashed: '1',
    //             sumlabel: Texts.total__ProfitAndLossChartLabel,
    //         }),
    //     };

    //     let profitAndLossByPostTypeChart = {
    //         type: 'waterfall2d',
    //         renderAt: 'chartDrilldownContainer',
    //         width: '100%',
    //         height: '400',
    //         dataFormat: 'json',
    //         dataSource: profitAndLossByPostTypeDataSource,
    //     };

    //     updateProfitAndLossByPostTypeChart(profitAndLossByPostTypeChart);
    // };

    // const resetCharts = () => {
    //     document.location.reload();
    // };

    // const getMonthlyValues = (financialYear, value) => {
    //     let profitAndLoss = context.apiRawData.profitAndLoss;
    //     let data = [];
    //     let calculatedTotal = 0;
    //     for (let i = 0; i < profitAndLoss.length; i++) {
    //         if (profitAndLoss[i].financialYear === financialYear) {
    //             calculatedTotal += parseFloat(profitAndLoss[i][value]);
    //             data.push({
    //                 label: DatetimeAsYearMonth(profitAndLoss[i].period),
    //                 value: parseFloat(profitAndLoss[i][value]),
    //                 tooltext:
    //                     DatetimeAsYearMonth(profitAndLoss[i].period) +
    //                     '{br}' +
    //                     '<b>' +
    //                     Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //                     ' ' +
    //                     FormatNumber(parseFloat(profitAndLoss[i][value])) +
    //                     Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //                     '</b>',
    //             });
    //         }
    //     }
    //     data.push({
    //         label: Texts.total__ProfitAndLossChartLabel,
    //         value: parseFloat(calculatedTotal),
    //         tooltext:
    //             Texts.total__ProfitAndLossChartLabel +
    //             '{br}' +
    //             '<b>' +
    //             Texts.currencyprefix__ProfitAndLossChartTooltipText +
    //             ' ' +
    //             FormatNumber(parseFloat(calculatedTotal)) +
    //             Texts.currencysuffix__ProfitAndLossChartTooltipText +
    //             '</b>',
    //         color: '#421C52',
    //         issum: '1',
    //         cumulative: '1',
    //     });

    //     return data;
    // };

    // useEffect(() => {
    //     context.requireViewData(viewConfig.requiredData, organizationNumber);
    // }, []);

    // useEffect(() => {
    //     if (context.apiRawData.profitAndLoss && viewRenderStarted === 0) {
    //         context.requireViewData(viewConfig.requiredData, organizationNumber);
    //         PrepareData();
    //     }
    // });

    // return (
    //     <>
    //         {helpIsOpen && (
    //             <Help closeHelp={() => setHelpIsOpen(false)} helpItems={viewConfig.helpItems} />
    //         )}
    //         <div className='chart-view-options'>
    //             <ViewOptions viewOptions={viewConfig.viewOptions} />
    //         </div>
    //         <div className='company-heading company-waterfall-heading'>
    //             <div className='chart-options'>
    //                 {availableYears &&
    //                     availableYears.map(
    //                         (year, index) =>
    //                             year && (
    //                                 <NavLink
    //                                     className={`chart-option ${
    //                                         FinancialYearAsYearMonth(year) === displayFinancialYear
    //                                             ? 'current'
    //                                             : ''
    //                                     }`}
    //                                     key={Rand()}
    //                                     onClick={() => [
    //                                         updateViewRenderStarted(0),
    //                                         updateDisplayFinancialYear(),
    //                                     ]}
    //                                     to={`/company/${organizationNumber}/profit-and-loss-waterfall/${FinancialYearAsYearMonth(
    //                                         year,
    //                                     )}`}
    //                                 >
    //                                     {FinancialYearAsYearMonth(year)}
    //                                 </NavLink>
    //                             ),
    //                     )}
    //             </div>
    //             <div className='help' id='helpOpener' onClick={() => setHelpIsOpen(true)}>
    //                 <div className='mask'></div>
    //                 {Texts.help__HelpButtonText}
    //             </div>
    //             <div className='reset-charts' onClick={() => resetCharts()}>
    //                 <div className='mask'></div>
    //                 {Texts.reset_chart__ButtonText}
    //             </div>
    //         </div>
    //         <div className='charts-wrapper'>
    //             <WaterfallChart
    //                 ref={waterFallChart}
    //                 chartConfig={profitAndLossByMonthChartConfig}
    //                 viewOptiopns={viewConfig.viewOptions}
    //                 chartsAreLoading={chartsAreLoading}
    //                 updateDrilldownStarted={updateDrilldownStarted}
    //                 initialBreakdownChart={profitAndLossByPostTypeChart}
    //             />
    //         </div>
    //     </>
    // );

    return null;
};

export default ProfitAndLossWaterfall;
